import React, { useCallback, useEffect, useState } from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
// import CloseIcon from '@mui/icons-material/Close';
import Modal from '../../components/Modal'
import { IDashboard, IDashboardGroup } from '../../interfaces/index'
import './style.scss'

interface ISubMenuDashComponent {
  listMenu: Array<IDashboardGroup> | []
  action?: any
  activeDash?: any
}

const ContentItens = (props: any) => {
  const {
    listMenu, toggleColapseMenu, action, activeDash
  } = props
  return (
    <>
      <h2 className="flex items-center justify-between">
        <span>Dashboards</span>
        {/* <CloseIcon className="submenu-close-icon"  onClick={toggleContainerMenu} /> */}
      </h2>
      <div className="submenu-content">
        <ul>
          {listMenu.map((item: IDashboardGroup) => (
            <React.Fragment key={item.id}>
              {item.dashboards.length ?
                <li className={`item-group item-id-${item.id}`}>
                  <div className="submenu-title-group" onClick={() => toggleColapseMenu(`.item-id-${item.id}`)}>
                    <span>{item.name}</span> <KeyboardArrowRightIcon className="arrow-icon" />
                  </div>
                  <ul className="submenu-interno">
                    {item.dashboards.map((subItem: IDashboard) => (
                      <li key={subItem.id} onClick={() => action(subItem.id)}
                          className={`${Number(activeDash) === subItem.id ? 'active' : ''}`}>{subItem.name}</li>
                    ))}
                  </ul>
                </li>
                :
                ''
              }
            </React.Fragment>
          ))}
        </ul>
      </div>
    </>
  )
}

const SubMenuDash = (props: ISubMenuDashComponent) => {
  const { listMenu, action, activeDash } = props
  const [openModal, setOpenModal] = useState(false)

  function closeAll() {
    const allSubMenus = document.querySelectorAll('.item-group')
    allSubMenus.forEach(element => {
      element.classList.remove('show')
    })
  }

  const toggleColapseMenu = useCallback((subMenuId: string) => {
    const subMenu = document.querySelector(subMenuId)

    if (subMenu) {
      if (!subMenu.classList.contains('show')) {
        closeAll()
        subMenu.classList.add('show')
        return
      }
      subMenu.classList.remove('show')
    }
  }, [])

  useEffect(() => {
    const openColapseMenu = (subMenuId: string) => {
      const subMenu = document.querySelector(subMenuId)

      function closeAll() {
        const allSubMenus = document.querySelectorAll('.item-group')
        allSubMenus.forEach(element => {
          element.classList.remove('show')
        })
      }

      if (subMenu) {
        closeAll()
        subMenu.classList.add('show')
      }
    }
    if (activeDash && listMenu.length) {
      listMenu.forEach((item: IDashboardGroup) => {
        item.dashboards.forEach((subItem: IDashboard) => {
          if (subItem.id === Number(activeDash)) {
            openColapseMenu(`.item-id-${item.id}`)
          }
        })
      })
    }
  }, [listMenu, activeDash])

  const toggleContainerMenu = () => {
    const menuContainer = document.getElementById('wrapper-container-submenu')
    if (menuContainer) menuContainer.classList.toggle('hide')

    if (document.body.clientWidth < 768) {
      setOpenModal(true)
    }
  }

  return (
    <>
      <div className="wrapper-container-submenu" id="wrapper-container-submenu">
        <div className="btn-toggle-subMenu" onClick={toggleContainerMenu}>
          <KeyboardDoubleArrowLeftIcon />
        </div>
        <div className="close-title" style={{ left: '-24px' }}>
          Dashboards
        </div>
        <div className="wrapper-content-submenu">
          {!openModal &&
            <ContentItens
              toggleContainerMenu={toggleContainerMenu}
              listMenu={listMenu}
              toggleColapseMenu={toggleColapseMenu}
              action={action}
              activeDash={activeDash}
              setOpenModal={setOpenModal}
            />
          }

        </div>
      </div>

      <Modal
        size="sm"
        open={openModal}
        close={() => setOpenModal(false)}
        titulo={'Dashboards'}
      >
        <div className="conteiner-submenu-modal">
          {openModal &&
            <ContentItens
              toggleContainerMenu={toggleContainerMenu}
              listMenu={listMenu}
              toggleColapseMenu={toggleColapseMenu}
              action={action}
              activeDash={activeDash}
              setOpenModal={setOpenModal}
            />
          }
        </div>
      </Modal>

    </>
  )
}

export default SubMenuDash