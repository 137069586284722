import { useEffect, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import { Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import AddCircleIcon from '@mui/icons-material/AddCircle'

import { useAuth } from '../../../hooks/useAuth'
import Modal from '../../../components/Modal'
import { useNotification } from '../../../hooks/useNotification.js'
import { IBeneficio } from '../../../interfaces/index'
import services from '../../../services/index.js'

interface IBeneficios {
  beneficios: IBeneficio[] | []
  removeBeneficio: any
  addBeneficio: any
  ticketDataId?: number
  ticketStatus?: string
  indexLocal?: number
  indexFuncao?: number
  edicaoMode?: boolean
}

function Beneficios(props: IBeneficios) {
  const {
    beneficios, removeBeneficio, addBeneficio, ticketDataId,
    ticketStatus, indexLocal, indexFuncao, edicaoMode
  } = props
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  const { notify } = useNotification()
  const { promiseInProgress } = usePromiseTracker()

  const [modalAddBeneficio, setModalAddBeneficio] = useState<boolean>(false)
  const [descricoes, setDescricoes] = useState<[string] | []>([])
  const [beneficioNome, setBeneficioNome] = useState<string>('')
  const [beneficioValue, setBeneficioValue] = useState<string>('')

  const [blockEdicao, setBlockEdicao] = useState<boolean>(true)

  const resetModal = () => {
    setModalAddBeneficio(false)
    setBeneficioNome('')
    setBeneficioValue('')
  }

  const adicionarBeneficio = async () => {
    if (!beneficioNome) {
      notify('Informe o nome do beneficio', { variant: 'warning' })
      return false
    }
    if (!beneficioValue) {
      notify('Informe o valor do beneficio', { variant: 'warning' })
      return false
    }

    if (ticketDataId) {
      // tela de edição
      try {
        const res = await trackPromise(services.api.addBenefits(beneficioNome, beneficioValue, ticketDataId))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível adicionar o beneficio'
          )
        }
        addBeneficio(beneficioNome, beneficioValue, res.data.id)
        resetModal()
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    } else {
      addBeneficio(beneficioNome, beneficioValue, 0, indexLocal, indexFuncao)
      resetModal()
    }
  }

  const handleRemove = async (index: number) => {
    if (ticketDataId) {
      try {
        const res = await trackPromise(services.api.deleteBenefits(beneficios[index].id))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível remover o beneficio'
          )
        }
        removeBeneficio(index)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    } else {
      removeBeneficio(index, indexLocal, indexFuncao)
    }
  }

  const handleAddBeneficio = async () => {
    if (!descricoes.length) {
      try {
        const res = await trackPromise(services.api.getBeneficiosList())
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível carregar beneficios'
          )
        }
        setDescricoes(res.data)
        setModalAddBeneficio(true)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    } else {
      setModalAddBeneficio(true)
    }
  }

  useEffect(() => {
    if (!ticketStatus) {
      setBlockEdicao(false)
      return
    }
    if (
      (
        userRoles.includes('ticket_res_update') ||
        userRoles.includes('ticket_res_update_any')
      ) &&
      (ticketStatus === 'IN_PROGRESS' || ticketStatus === 'IN_REVISION')
    ) {
      setBlockEdicao(false)
      return
    }
    setBlockEdicao(true)
  }, [ticketStatus])

  return (
    <>
      <div className="flex gap-10 justify-between items-center mb-10"
           style={{ borderBottom: '1px solid rgb(234 234 234)', paddingBottom: '10px' }}>
        Beneficios
      </div>

      <div className="table-container">
        <table className="table_styled">
          <thead>
          <tr>
            <th>
              <div>
                <span className="th-title">Nome Beneficio</span>
              </div>
            </th>
            <th>
              <div>
                <span className="th-title">Detalhe Beneficio</span>
              </div>
            </th>
            {
              !edicaoMode &&
              <th>
                <div className="flex justify-end">
                  <Tooltip title="Adicionar Benefício">
                      <span>
                        <IconButton
                          style={{ color: 'white', marginRight: '6px', borderRadius: '4px' }}
                          onClick={handleAddBeneficio}
                          edge="end"
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </span>
                  </Tooltip>
                </div>
              </th>
            }
          </tr>
          </thead>
          <tbody>
          {beneficios?.length === 0 ?
            <tr>
              <td colSpan={!edicaoMode ? 3 : 2}>
                <div className="flex justify-center">Não ha benefiícios</div>
              </td>
            </tr> : ''
          }
          {
            beneficios?.map((beneficio, index) => (
              <tr key={index}>
                <td>
                  <div>{beneficio.benefitName}</div>
                </td>
                <td>
                  <div>{beneficio.benefitValue}</div>
                </td>
                {
                  !edicaoMode &&
                  <td>
                    <div className="flex justify-end">
                      <Tooltip title="Excluir benefício">
                          <span>
                            <IconButton
                              style={{
                                backgroundColor: 'red',
                                color: 'white',
                                marginRight: '0px',
                                borderRadius: '4px'
                              }}
                              onClick={() => handleRemove(index)}
                              edge="end"
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </span>
                      </Tooltip>
                    </div>
                  </td>
                }
              </tr>
            ))
          }
          </tbody>
        </table>
      </div>

      {/* modal add beneficio */}
      <Modal
        size="sm"
        open={modalAddBeneficio}
        close={() => setModalAddBeneficio(false)}
        titulo={'Adicionar Beneficio'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12} md={6}>
            <FormControl>
              <InputLabel shrink>Nome</InputLabel>
              <Select
                fullWidth
                required
                notched
                size="small"
                label="Nome"
                value={beneficioNome || ''}
                onChange={e =>
                  setBeneficioNome(e.target.value)
                }
              >
                <MenuItem value={''}>
                  Selecione
                </MenuItem>
                {
                  descricoes?.map((tipo: any, index: number) => (
                    <MenuItem value={tipo} key={index}>
                      {tipo}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              size="small"
              label="Descrição/Valor"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 120 }}
              value={beneficioValue}
              onChange={e =>
                setBeneficioValue(e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              type="button"
              size="small"
              color="inherit"
              onClick={() => setModalAddBeneficio(false)}
            >
              cancelar
            </Button>
            <Button
              variant="contained"
              type="button"
              size="small"
              color="secondary"
              disabled={promiseInProgress}
              onClick={adicionarBeneficio}
            >
              adicionar
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default Beneficios
