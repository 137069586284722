import { IconButton, TextField } from '@mui/material'
import { PageTitle } from '../../components/PageElements'
import { useAuth } from '../../hooks/useAuth'
import { useEffect, useState } from 'react'
import TableLog from './TableLog'
import { ITrctLog } from '../../interfaces'
import { listChatbotLogs } from '../../services/api'
import { useNotification } from '../../hooks/useNotification.js'
import SearchIcon from '@mui/icons-material/Search'

function TrctOverview() {
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<ITrctLog[]>([])

  const [commonSearch, setCommonSearch] = useState<string>('')
  const [modalNewItem, setModalNewItem] = useState<boolean>(false)

  const [orderDesc, setOrderDesc] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const { notify } = useNotification()


  const openNewItemModal = () => {
    setModalNewItem(true)
  }

  const handleReload = async () => {

  }

  const formatCpf = (cpf: string) => {
    const cleanCpf = cpf.replace(/\D+/g, '')
    return cleanCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
  const handlePagination = (page: number, pageSize?: number) => {
    setCurrentPage(page)
    if (pageSize) setPageSize(pageSize)
    fetchData(page, pageSize || 10)
  }
  const handleSearch = async () => {
    setLoading(true)
    try {
      const sanitizedSearchTerm = commonSearch.replace(/\D/g, '')

      const response = await listChatbotLogs(currentPage, pageSize, orderDesc, sanitizedSearchTerm)
      var formattedData = response.data.map((log: any) => ({
        cpf: formatCpf(log.cpf),
        pis: log.pis,
        telephone: log.telephone,
        createdAt: log.createdAt,
        success: log.success
      }))
      setData(formattedData)
      console.log(data)
    } catch (error) {
      console.error('Failed to search files', error)
    } finally {
      setLoading(false)
    }
  }
  const fetchData = async (page: number, pageSize: number) => {
    setData([])
    setLoading(true)
    try {
      const response = await listChatbotLogs(page, pageSize, orderDesc)
      var formattedData = response.data.map((log: any, i: number) => ({
        index: i,
        cpf: formatCpf(log.cpf),
        pis: log.pis,
        telephone: log.telephone,
        createdAt: log.createdAt,
        success: log.success
      }))
      setTotalCount(response.totalCount)
      setData(formattedData) // Supondo que a resposta tenha uma propriedade 'data' que é um array        
    } catch (error) {
      console.error('Error fetching logs:', error)
      return [] // Retorne um array vazio em caso de erro
    } finally {
      setLoading(false)
    }
  }
  const handleSortOrder = (data: any) => {
    try {
      setOrderDesc(!orderDesc)
    } catch {
    }
    fetchData(currentPage, pageSize)
  }

  useEffect(() => {
    fetchData(currentPage, pageSize)
  }, [currentPage, pageSize, orderDesc])

  return (
    <div className="wrapper-container-content bgwhite flex flex-col">
      <div className="wrapper-page-content" id="wrapper-page-content">
        <PageTitle subText={'Gerenciar Trct'}>
          <div>Termos de recisão contratual de trabalho</div>
        </PageTitle>
        <div className="panel">
          <div className="panel-body">

            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '15px' }}>
              <TextField
                size="small"
                label="Buscar por CPF"
                placeholder="Buscar por CPF"
                value={commonSearch}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '') // Remove non-numeric characters
                  if (value.length <= 11) { // Ensure no more than 11 characters
                    setCommonSearch(value)
                  }
                }}
                inputProps={{
                  maxLength: 11, // Max length of 11 characters
                  pattern: '[0-9]*' // Only allow numbers
                }}
                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
              />
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </div>

            <TableLog
              sortFunction={handleSortOrder}
              data={data}
              loading={loading}
              currentPage={currentPage}
              handlePagination={handlePagination}
              pageSize={pageSize}
              totalCount={totalCount}
            ></TableLog>

          </div>
        </div>

      </div>

    </div>
  )
}

export default TrctOverview
