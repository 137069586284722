import React, { useCallback, useEffect, useState } from 'react'

import { Button, Grid, TextField } from '@mui/material'
import Modal from '../../components/Modal'
import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification.js'
import { useAuth } from '../../hooks/useAuth'
import services from '../../services/index.js'

//import './style.scss'
import { Pagination } from '../../components/Table'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { trackPromise } from 'react-promise-tracker'
import TableDashboardRule from './TableDashboardRule'
import { IDashboardRule } from '../../interfaces'

function DashboardRules() {
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()


  const [dashboardRules, setDashboardRules] = useState<IDashboardRule[] | []>([])
  const [overviewTodos, setOverviewTodos] = useState<number | undefined>()
  const [overviewCpfs, setOverviewCpfs] = useState<number | undefined>()
  const [overviewCnpjs, setOverviewCnpjs] = useState<number | undefined>()
  const [overviewHigienizados, setOverviewHigienizados] = useState<number | undefined>()
  const [overviewAtualizar, setOverviewAtualizar] = useState<number | undefined>()
  const [overviewFilter, setOverviewFilter] = useState<string | undefined>()
  const [modalNewItem, setModalNewItem] = useState<boolean>(false)


  const [newName, setNewName] = useState<string | undefined>(undefined)
  const [newColumn, setNewColumn] = useState<string | undefined>(undefined)
  const [newTable, setNewTable] = useState<string | undefined>(undefined)


  const [overviewActive, setOverviewActive] = useState<string>()
  const [commonSearch, setCommonSearch] = useState<string>('')
  const [orderBy, setOrderBy] = useState<string | undefined>(undefined)
  const [orderDesc, setOrderDesc] = useState<boolean>(false)
  // paginacao
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [totalCount, setTotalCount] = useState(0)


  const getDashboardRules = useCallback(async () => {
    try {
      let res = await trackPromise(services.api.getDashboardRulesPaged(currentPage, pageSize, orderBy, commonSearch, orderDesc))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar as regras'
        )
      }
      setDashboardRules(res.data.data)
      setTotalCount(res.data.totalCount)
      setTotalPages(res.data.totalPages)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }, [overviewFilter, orderBy, orderDesc, currentPage, pageSize, commonSearch])


  useEffect(() => {
    const init = async () => {
      await getDashboardRules()
    }
    init().then()
  }, [])


  const handlePagination = async (page: number, busca: any, itemsPerPage: number) => {
    setPageSize(itemsPerPage)
    setCurrentPage(page)
  }

  const handleReload = async () => {

  }

  const handleSortOrder = (data: any) => {
    try {
      setOrderBy(data.column.key)
      setOrderDesc(data.order != 'ascend')
    } catch {
      setOrderBy(undefined)
    }
    getDashboardRules()
  }

  const openNewItemModal = () => {
    setModalNewItem(true)
  }

  const closeNewItemModal = () => {
    setModalNewItem(false)
  }


  // const handleCpfCnpj = async (data:string) => {
  //   setCpfCnpj(formatCpfCnpj(data))
  //   setCpfCnpjIsValid(validarCpfCnpj(data))
  // }


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      //getDasgboardRules()
    }, 50)

    return () => clearTimeout(delayDebounceFn)
  }, [currentPage, pageSize])


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getDashboardRules()
    }, 800)
    return () => clearTimeout(delayDebounceFn)
  }, [commonSearch])


  const createNewItem = useCallback(async () => {
    try {
      let data = {
        name: newName,
        table: newTable,
        column: newColumn
      }
      let res = await trackPromise(services.api.createNewDashboardRule(data))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível adicionar nova regra'
        )
      }
      window.location.href = '/app/dash_management/rules/' + res.data.id
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }, [newTable, newName, newColumn])


  return (
    <div className="wrapper-container-content bgwhite">
      <div className="wrapper-page-content" id="wrapper-page-content">
        <PageTitle subText={'Gerenciar as regras'}>
          <div>Regras dos dashboards</div>
        </PageTitle>

        <Grid container justifyContent="center" spacing={{ xs: 3 }}>
          <Grid item md={12} xs={12}>
            <div className="panel">
              <div className="panel-body">


                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '15px' }}>


                  <Button
                    variant="contained" type="button" color="secondary"
                    onClick={openNewItemModal}
                    startIcon={<AddCircleOutlineIcon fontSize="small" />}
                    //disabled={promiseInProgress}
                  >
                    <span>Novo</span>
                  </Button>


                  <TextField
                    size="small"
                    label="Buscar nas regras"
                    placeholder="Buscar nas regras"
                    value={commonSearch}
                    onChange={(e) => setCommonSearch(e.target.value)}
                    defaultValue=""
                  />

                </div>

                <TableDashboardRule
                  data={dashboardRules}
                  sortFunction={handleSortOrder}
                />

                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageSize={pageSize}
                  itensLength={200}
                  totalCount={totalCount}
                  handlePagination={handlePagination}
                  colunaBusca={''}
                  search={''}
                />

              </div>
            </div>
          </Grid>
        </Grid>


      </div>

      <Modal
        open={modalNewItem}
        close={closeNewItemModal}
        titulo={'Nova regra'}
      >
        <Grid container spacing={{ xs: 3 }} className="sm-min-500">

          <Grid item xs={12}>

            <div className="flex justify-center">
              <TextField
                size="small"
                label="Nome da regra"
                placeholder="Nome da regra"
                value={newName}
                onChange={e => setNewName(e.target.value)}
                defaultValue=""
              />
            </div>

          </Grid>

          <Grid item xs={12}>
            <div className="flex justify-center">
              <TextField
                size="small"
                label="Tabela"
                placeholder="Tabela"
                value={newTable}
                onChange={e => setNewTable(e.target.value)}
                defaultValue=""
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="flex justify-center">
              <TextField
                size="small"
                label="Coluna"
                placeholder="Coluna"
                value={newColumn}
                onChange={e => setNewColumn(e.target.value)}
                defaultValue=""
              />
            </div>
          </Grid>

          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              color="success"
              type="button"
              size="small"
              disabled={newName === undefined || newTable === undefined || newColumn === undefined}
              onClick={createNewItem}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Modal>

    </div>
  )
}

export default DashboardRules
