import React, { useCallback, useEffect, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { debounce } from 'lodash'
import Cookies from 'js-cookie'

import {
  Autocomplete,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  TextField,
  Tooltip
} from '@mui/material'

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import SettingsIcon from '@mui/icons-material/Settings'
import SearchIcon from '@mui/icons-material/Search'
import GroupWorkIcon from '@mui/icons-material/GroupWork'

import { IUser } from '../../interfaces/index'
import { useNotification } from '../../hooks/useNotification.js'
import services from '../../services/index.js'
import { useAuth } from '../../hooks/useAuth'
import Modal from '../../components/Modal'
import { Pagination, TabelaSearchColumn } from '../../components/Table'
import TabelaGrupo from './TabelaGrupo'

interface ITableaUsuario {
  mainList: Array<IUser>
  newRegister: any
  editRegistro: any
  handleRemoverRegistro: any
  idParentAtivo: number | undefined
  teams?: any
  currentPage: number
  totalPages: number
  handlePagination: any
  handleBusca: any
  pageSize: number
  totalCount: number
  objBusca: any
  setObjBusca: any
}


const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const tablesVisibles = ['checkbox', 'Login', 'Nome', 'Email', 'Status', 'Interno', 'Ações']

function TabelaHeaderTh(props: any) {
  const {
    indexRef, visibleColumn, paramns, setSearchChange, ordeby, idParentAtivo,
    allSelected, handleSelectAll
  } = props
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  return (
    <>
      {visibleColumn &&
        <>
          {/* {((userRoles === 'ADMINISTRATOR') || (userRoles === 'CUSTOMER_ADMINISTRATOR' && idParentAtivo)) ? */}
          {
            (
              paramns.label === 'Ações' &&
              (
                userRoles.includes('bi_teams_read_any')
              )
            ) || paramns.label !== 'Ações' ?

              <th>
                <div>
                  <div>
                    {
                      paramns?.showOrderby &&
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <IconButton
                          size="small"
                          onClick={() => ordeby(false, paramns.searchRef)}
                          style={{ padding: '2px' }}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{ fontSize: '12px' }} />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => ordeby(true, paramns.searchRef)}
                          style={{ padding: '2px' }}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{ fontSize: '12px' }} />
                        </IconButton>
                      </div>
                    }

                    {paramns.label === 'checkbox' && <>
                      {(idParentAtivo) ?
                        <span className="th-title">
                          <input type="checkbox"
                                 checked={allSelected}
                                 onChange={e =>
                                   handleSelectAll(e.target.checked)
                                 }
                          />
                        </span> :
                        ''
                      }
                    </>}

                    {paramns.label !== 'checkbox' &&
                      <span className="th-title">{paramns.label}</span>
                    }

                  </div>
                  {
                    paramns?.showSearch &&
                    <div>
                      <SearchIcon fontSize="small" className="tableSeachIccon"
                                  onClick={() =>
                                    setSearchChange((prevState: any) => [
                                      ...prevState.slice(0, indexRef),
                                      { ...prevState[indexRef], inputVisible: true },
                                      ...prevState.slice(indexRef + 1)
                                    ])
                                  }
                      />
                    </div>
                  }
                </div>
              </th>

              :
              ''
          }
        </>
      }
    </>
  )
}

function TabelaUsuarios(props: ITableaUsuario) {
  const {
    newRegister, mainList, editRegistro, idParentAtivo, handleBusca, pageSize, teams,
    handleRemoverRegistro, currentPage, totalPages, handlePagination, totalCount, objBusca, setObjBusca
  } = props
  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()
  const { getUserRoles, getUser } = useAuth()
  const userRoles = getUserRoles()
  const user = getUser()

  const [objBuscaGrupo, setObjBuscaGrupo] = useState<any>(
    [
      {
        label: 'ID',
        value: false,
        searchRef: 'id',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'boolean'
      },
      {
        label: 'Nome',
        value: '',
        searchRef: 'name',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Ações',
        value: '',
        searchRef: 'acao',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      }
    ]
  )

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [anchorElOrderby, setAnchorElOrderby] = useState<null | HTMLElement>(null)
  const openOrderby = Boolean(anchorElOrderby)

  const handleCloseOrderby = () => {
    setAnchorElOrderby(null)
  }

  const [filterList, setFilterList] = useState<any>([])

  const [allSelected, setAllSelected] = useState<boolean>(false)
  const [showActions, setShowActions] = useState<boolean>(false)

  const [modalDeletarUsuario, setModalDeletarUsuario] = useState<boolean>(false)
  const [colunaAtiva] = useState<string>('')

  const [teamsFilter, setTeamsFilter] = useState<any>([])
  const [teamSetToInclude, setTeamSetToInclude] = useState<any>('')
  const [teamsSetToInclude, setTeamsSetToInclude] = useState<any>([])
  const [modalGrupos, setModalGrupos] = useState<boolean>(false)
  const [modalPassword, setModalPassword] = useState<boolean>(false)
  const [typePassword, setTypePassword] = useState(true)
  const [typePasswordConfirm, setTypePasswordConfirm] = useState(true)
  const [userPassword, setUserPassword] = useState('')
  const [confirmSenha, setConfirmSenha] = useState('')
  const [userIdSelectToChangePassword, setUserIdSelectToChangePassword] = useState('')
  const [modalShowHideColumns, setModalShowHideColumns] = useState<boolean>(false)
  const [visibleColumns, setVisibleColumns] = useState<any>([])
  const [visibleColumnsControl, setVisibleColumnsControl] = useState<any>([])
  const [userSelectToShowGroups, setUserSelectToShowGroups] = useState<any>()
  const [gruposByUser, setGruposByUser] = useState<any>([])
  const [gruposByUserFilter, setGruposByUserFilter] = useState<any>([])

  const handleSelectAll = (checked: boolean) => {
    setAllSelected(checked)
    setFilterList(filterList.map((item: IUser) => {
      item.selecionado = checked
      return item
    }))
  }

  const handleChange = (role: any) => {
    console.log(role)

    const addRole = async (role: any) => {
      setTeamsSetToInclude((prevState: any) => [
        ...prevState,
        role
      ])
    }

    const removeRole = async (role: any) => {
      setTeamsSetToInclude((prevState: any) => prevState.filter((item: any) => item.id !== role.id))
    }

    let indexLast = role.length - 1

    if (indexLast === 0) {
      const roleBase = teams.filter((item: any) => item.id === role[role.length - 1])
      addRole(roleBase[0])
    } else {
      const itensJaInclusos = role.filter((item: any) => item?.id === role[role.length - 1])

      if (itensJaInclusos?.length > 0) {
        removeRole(itensJaInclusos[0])
      } else {
        const roleBase = teams.filter((item: any) => item.id === role[role.length - 1])
        addRole(roleBase[0])
      }
    }
  }

  const handleChangePassword = (userId: any) => {
    setUserIdSelectToChangePassword(userId)
    setModalPassword(true)
  }

  const handleOpenExcluiUser = () => {
    handleClose()
    setModalDeletarUsuario(true)
  }


  const deletarUsuario = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const itensSelecionados = filterList.filter((item: IUser) => item.selecionado).map((item: any) => item.id)

    try {
      if (!itensSelecionados.length) {
        throw new Error('Não foi possível identificar itens selecionados')
      }

      const res: any = await trackPromise(services.api.inativeUsers(itensSelecionados))
      if (res.fail) {
        if (itensSelecionados.length > 1) {
          setModalDeletarUsuario(false)
          handleRemoverRegistro()
        }
        throw new Error(res.error || 'Erro ao Remover usuário')
      }
      notify(`${itensSelecionados.length > 1 ? 'Usuários Removidos com Sucesso' : 'Usuário Removido com Sucesso'}`, { variant: 'success' })

      setModalDeletarUsuario(false)
      handleRemoverRegistro()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const removerUsuario = async () => {
    const itensSelecionados = filterList.filter((item: IUser) => item.selecionado)
    try {
      if (!itensSelecionados.length) {
        throw new Error('Não foi possível identificar itens selecionados')
      }
      if (!idParentAtivo) {
        throw new Error('Não foi possível identificar equipe')
      }
      const res: any = await trackPromise(services.api.removerUsersEquipeLote(idParentAtivo, itensSelecionados))
      if (res.fail) {
        if (itensSelecionados.length > 1) {
          handleRemoverRegistro()
        }
        throw new Error(res.error || 'Erro ao Remover usuário')
      }
      handleClose()
      notify(`${itensSelecionados.length > 1 ? 'Usuários Removidos com Sucesso' : 'Usuário Removido com Sucesso'}`, { variant: 'success' })
      handleRemoverRegistro()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const alterarSenhaUsuario = async () => {
    console.log()
    if (!userPassword) {
      notify('Informe uma senha', { variant: 'warning' })
      return
    }
    if (userPassword.length < 8) {
      notify('A senha deve ter pelo menos 8 caracteres', { variant: 'warning' })
      return
    }
    if (!confirmSenha) {
      notify('Informe o campo confirmar nova senha', { variant: 'warning' })
      return
    }
    if (userPassword !== confirmSenha) {
      notify('A confirmação esta diferente da senha informada', { variant: 'warning' })
      return
    }

    try {
      const res: any = await trackPromise(services.api.alterarSenhaUser(userIdSelectToChangePassword, userPassword))
      if (res.fail) {
        throw new Error(res.error || 'Erro ao Remover usuários')
      }
      notify('Senha alterada com sucesso', { variant: 'success' })
      setModalPassword(false)
      setTypePassword(false)
      setUserPassword('')
      setTypePasswordConfirm(false)
      setConfirmSenha('')
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const vincularEquipeUsuario = async () => {
    if (!teamSetToInclude) {
      notify(`Selecione uma Equipe`, { variant: 'warning' })
      return
    }
    try {
      if (!userSelectToShowGroups?.id) {
        throw new Error('Não foi possível identificar usuário')
      }
      const res: any = await trackPromise(services.api.vicularUsuarioComEquipe(userSelectToShowGroups.id, teamSetToInclude))
      if (res.fail) {
        throw new Error(res.error || 'Erro ao Vincular usuário')
      }

      notify(`Usuário vinculado com Sucesso`, { variant: 'success' })
      setTeamSetToInclude('')
      handleOpenGrupos(userSelectToShowGroups)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const vincularEquipeUsuarioMult = async () => {
    if (!teamsSetToInclude?.length) {
      notify(`Selecione uma Equipe`, { variant: 'warning' })
      return
    }
    console.log('teamsSetToInclude', teamsSetToInclude)

    const formatList = teamsSetToInclude.map((item: any) => {
      return {
        teamId: item.id,
        userId: userSelectToShowGroups.id
      }
    })

    console.log('formatList', formatList)

    try {
      if (!userSelectToShowGroups?.id) {
        throw new Error('Não foi possível identificar usuário')
      }
      const res: any = await trackPromise(services.api.vicularUsuarioComEquipeMult(formatList))
      if (res.fail) {
        throw new Error(res.error || 'Erro ao Vincular usuários')
      }

      notify(`Usuários vinculados com Sucesso`, { variant: 'success' })
      setTeamsSetToInclude([])
      handleOpenGrupos(userSelectToShowGroups)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const vincularEquipeUsuarioMult2 = async () => {
    if (!teamsSetToInclude?.length) {
      notify(`Selecione uma Equipe`, { variant: 'warning' })
      return
    }
    console.log('teamsSetToInclude', teamsSetToInclude)

    const formatList = teamsSetToInclude.map((item: any) => {
      return {
        teamId: item.id,
        userId: userSelectToShowGroups.id
      }
    })

    console.log('formatList', formatList)

    try {
      if (!userSelectToShowGroups?.id) {
        throw new Error('Não foi possível identificar usuário')
      }
      const res: any = await trackPromise(services.api.vicularUsuarioComEquipeMult(formatList))
      if (res.fail) {
        throw new Error(res.error || 'Erro ao Vincular usuários')
      }

      notify(`Usuários vinculados com Sucesso`, { variant: 'success' })
      setTeamsSetToInclude([])
      handleOpenGrupos(userSelectToShowGroups)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const handleOpenGrupos = async (user: any, reloadUsers = false) => {

    try {
      const res: any = await trackPromise(services.api.getTeamsByUser(user.id))
      if (res.fail) {
        throw new Error(res.error || 'Erro ao Remover usuários')
      }
      if (res.data?.value?.length > 0) {
        setGruposByUser(res.data.value)
        setGruposByUserFilter(res.data.value)
      } else {
        setGruposByUser([])
        setGruposByUserFilter([])
      }
      if (reloadUsers) {
        handleRemoverRegistro()
      }
      setUserSelectToShowGroups(user)
      setTeamsSetToInclude([])
      setModalGrupos(true)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const handleGruposFilter = (searchGrupo: any) => {
    setGruposByUserFilter(gruposByUser.filter((item: any) => item.name.toUpperCase().includes(searchGrupo[1].value.toUpperCase())))
  }

  const handleOrderbyGrupo = (column: string, direction: string) => {
    const nfilter = services.utils.orderbyArrayOfObjects(column, gruposByUser, direction)
    setGruposByUserFilter([...nfilter])
  }

  const getRoleLabel = (role: string) => {
    // ['ADMINISTRATOR', 'CUSTOMER_ADMINISTRATOR', 'READ_ONLY']
    if (role === 'ADMINISTRATOR') {
      return 'Admin'
    } else if (role === 'CUSTOMER_ADMINISTRATOR') {
      return 'Cliente'
    } else if (role === 'READ_ONLY') {
      return 'Ler'
    } else {
      return '-'
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounce = useCallback(debounce((busca) => {
    handleBusca(busca, pageSize, '', '', idParentAtivo)
  }, 800), [idParentAtivo])

  const handleChangeBusca = (busca: string) => {
    handleBuscaDebounce(busca)
  }

  const ordeby = (direction: boolean, column?: string) => {
    handleCloseOrderby()
    if (column) {
      handleBusca(objBusca, pageSize, column, direction, idParentAtivo)
    } else {
      handleBusca(objBusca, pageSize, colunaAtiva, direction, idParentAtivo)
    }
  }

  const handleChangeVisibleColumn = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
    let indexItem = visibleColumnsControl.indexOf(item)
    if (!event.target.checked && visibleColumnsControl.length <= 1) {
      notify('Pelo menos uma coluna deve ficar visivel', { variant: 'warning' })
      return
    }
    if (event.target.checked) {
      setVisibleColumnsControl((prevState: any) => ([
          ...prevState,
          item
        ]
      ))
    } else {
      setVisibleColumnsControl((prevState: any) => ([
          ...prevState.slice(0, indexItem),
          ...prevState.slice(indexItem + 1)
        ]
      ))
    }
  }

  const confirmVisibilityColumns = () => {
    Cookies.set(`Table_UsersMain_${user?.id}`, JSON.stringify(visibleColumnsControl))
    setVisibleColumns([...visibleColumnsControl])
    setModalShowHideColumns(false)
  }

  const handleOpenModalVisibleColumns = () => {
    setModalShowHideColumns(true)
    setVisibleColumnsControl([...visibleColumns])
  }

  useEffect(() => {
    setFilterList(mainList?.map((item: IUser) => {
      item.selecionado = false
      return item
    }))
  }, [mainList])

  useEffect(() => {
    //setSearch('')
  }, [idParentAtivo])

  useEffect(() => {
    if (user?.id) {
      const tableCookieString: any = Cookies.get(`Table_UsersMain_${user?.id}`)
      if (!tableCookieString) {
        Cookies.set(`Table_UsersMain_${user?.id}`, JSON.stringify(tablesVisibles))
        setVisibleColumns([...tablesVisibles])
        setVisibleColumnsControl([...tablesVisibles])
      } else {
        const tableCookieObj = JSON.parse(tableCookieString)
        setVisibleColumns([...tableCookieObj])
        setVisibleColumnsControl([...tableCookieObj])
      }
    }
  }, [])

  useEffect(() => {
    const itensSelecionados = filterList?.filter((item: IUser) => item.selecionado)
    if (itensSelecionados?.length) {
      setShowActions(true)
    } else {
      setShowActions(false)
    }
    if (itensSelecionados?.length > 0 && itensSelecionados.length === filterList.length) {
      setAllSelected(true)
    } else {
      setAllSelected(false)
    }
  }, [filterList])

  useEffect(() => {
    setTeamsFilter(teams)
  }, [teams])


  return (
    <>
      <div className="wrapper-page-content-interna">
        <div className="flex items-center justify-between gap-10">
          <div className="flex gap-10">
            {showActions &&
              <div>
                <Button
                  variant="contained" type="button" color="secondary"
                  endIcon={<KeyboardArrowDownOutlinedIcon fontSize="inherit" />}
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  disabled={promiseInProgress}
                >
                  Ações
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                >
                  <MenuItem onClick={removerUsuario} className="fontSmall" disabled={!idParentAtivo}>
                    <ListItemIcon>
                      <DeleteOutlineOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    remover da equipe
                  </MenuItem>
                </Menu>
              </div>
            }
          </div>

          <div className="flex items-center gap-10">
            <div className="flex items-center gap-6">
              <Tooltip title="Visualização Colunas">
                <span>
                  <IconButton
                    className="btn-purple"
                    onClick={handleOpenModalVisibleColumns}
                    disabled={promiseInProgress}
                  >
                    <SettingsIcon fontSize="small" />
                  </IconButton>
                  </span>
              </Tooltip>
            </div>
            {/* {userRoles === 'ADMINISTRATOR' &&
              <Button 
                variant="contained" type="button" color="secondary" 
                onClick={newRegister} 
                startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
                disabled={promiseInProgress}
              >
                <span className='md-hidden'>{idParentAtivo ? 'criar usuário na equipe' : 'criar usuário'}</span>
              </Button>
            } */}
          </div>
        </div>
        <div className="divider" style={{ opacity: 0 }}></div>
        <div className="">
          <table className="table_styled table_selectable">
            <thead>
            {/* <tr>
                {(userRoles === 'ADMINISTRATOR' || userRoles === 'CUSTOMER_ADMINISTRATOR') && idParentAtivo ?
                  <th>
                    <div>
                      <span className='th-title'>
                        <input type="checkbox" 
                          checked={allSelected}
                          onChange={e =>
                            handleSelectAll(e.target.checked)
                          }
                        />
                      </span>
                    </div>
                  </th> :
                  ''
                }
              </tr> */}
            <tr>
              {
                objBusca?.map((item: any, index: number) => (
                  <TabelaSearchColumn
                    key={index}
                    visibleColumn={visibleColumns.includes(item.label)}
                    indexRef={index}
                    paramns={item}
                    objBusca={objBusca}
                    setSearchChange={setObjBusca}
                    handleChangeBusca={handleChangeBusca}
                  />
                ))
              }
            </tr>
            <tr>
              {
                objBusca?.map((item: any, index: number) => (
                  <TabelaHeaderTh
                    key={index}
                    ordeby={ordeby}
                    visibleColumn={visibleColumns.includes(item.label)}
                    indexRef={index}
                    paramns={item}
                    setSearchChange={setObjBusca}
                    idParentAtivo={idParentAtivo}
                    allSelected={allSelected}
                    handleSelectAll={handleSelectAll}
                    // setListItensSelected={setListItensSelected}
                    // itemsSelecionadosLength={listItensSelected.length}
                    // rows={filterList}
                  />
                ))
              }
            </tr>
            </thead>
            <tbody>
            {!filterList &&
              <tr>
                <td colSpan={visibleColumns.length + 1}>
                  <div className="justify-center">loading...</div>
                </td>
              </tr>
            }
            {filterList?.length === 0 &&
              <tr>
                <td colSpan={visibleColumns.length + 1}>
                  <div className="justify-center">Nenhum registro encontrado</div>
                </td>
              </tr>
            }
            {filterList?.map((item: IUser, index: number) => (
              <tr key={item.id}>
                {visibleColumns.includes('checkbox') &&
                  <td>
                    {idParentAtivo ?
                      <div>
                        <input type="checkbox"
                               checked={item.selecionado}
                               onChange={(e) => setFilterList((prevState: any) => [
                                 ...prevState.slice(0, index),
                                 { ...prevState[index], selecionado: e.target.checked },
                                 ...prevState.slice(index + 1)
                               ])}
                        />
                      </div> :
                      ''
                    }
                  </td>
                }
                {visibleColumns.includes('Login') &&
                  <td>
                    <div>{item.username}</div>
                  </td>
                }
                {visibleColumns.includes('Nome') &&
                  <td>
                    <div>{item.name}</div>
                  </td>
                }
                {visibleColumns.includes('Email') &&
                  <td>
                    <div>{item.email}</div>
                  </td>
                }
                {visibleColumns.includes('Status') &&
                  <td>
                    <div>{item.active ? <span className="badge badge-success">Ativo</span> :
                      <span className="badge badge-error">Inativo</span>}</div>
                  </td>
                }
                {visibleColumns.includes('Interno') &&
                  <td>
                    <div><span className="badge">{item.internal ? 'Interno' : 'Externo'}</span></div>
                  </td>
                }
                {userRoles.includes('bi_teams_read_any') ?
                  <td>
                    <div className="flex justify-end">
                      <IconButton
                        size="small" aria-label="ver grupos" component="label"
                        onClick={() => handleOpenGrupos(item)}
                      >
                        <GroupWorkIcon fontSize="small" />
                      </IconButton>
                      {/* <IconButton  
                          size="small" aria-label="editar senha" component="label" 
                          onClick={() => handleChangePassword(item.id)}
                        >
                          <KeyIcon fontSize="small" />
                        </IconButton>
                        <IconButton  
                          size="small" aria-label="editar usuário" component="label" 
                          onClick={() => editRegistro(item)}
                          disabled={promiseInProgress}
                        >
                          <ModeEditOutlineOutlinedIcon fontSize="small" />
                        </IconButton> */}
                    </div>
                  </td>
                  : ''
                }

              </tr>
            ))}
            </tbody>
          </table>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            itensLength={filterList?.length}
            totalCount={totalCount}
            handlePagination={handlePagination}
            colunaBusca={''}
            search={''}
            idRef={idParentAtivo}
          />

        </div>
      </div>

      {/* show hide columns */}
      <Modal
        size="sm"
        open={modalShowHideColumns}
        close={() => setModalShowHideColumns(false)}
        titulo={'Selecionar Colunas'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12} className="flex gap-6">
            <FormGroup>
              {tablesVisibles.filter(item => item !== 'Ações' && item !== 'checkbox').map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Switch
                      checked={visibleColumnsControl.includes(item)}
                      onChange={(e) => handleChangeVisibleColumn(e, item)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={item}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              color="secondary"
              type="button"
              size="small"
              onClick={confirmVisibilityColumns}
            >
              Aplicar
            </Button>
          </Grid>
        </Grid>
      </Modal>

      {/* modal usuário remover */}
      <Modal
        size="sm"
        open={modalDeletarUsuario}
        close={() => setModalDeletarUsuario(false)}
        titulo={'Remover Usuário'}
      >
        <form onSubmit={deletarUsuario}>
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              <p className="center">
                Deseja mesmo desativar os usuários selecionados? <br />
              </p>
            </Grid>
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size="small"
                color="inherit"
                onClick={() => setModalDeletarUsuario(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size="small"
                color="error"
                disabled={promiseInProgress}
              >
                desativar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

      {/* modal change password */}
      <Modal
        size="sm"
        open={modalPassword}
        close={() => setModalPassword(false)}
        titulo={'Alterar senha do usuário'}
      >
        <form onSubmit={deletarUsuario}>
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Nova Senha</span>
              </label>
              <TextField
                fullWidth
                required
                size="small"
                // label="Senha"
                placeholder="Senha"
                type={`${typePassword ? 'password' : 'text'}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setTypePassword(!typePassword)}
                        edge="end"
                      >
                        {typePassword ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                inputProps={{ maxLength: 20 }}
                value={userPassword}
                onChange={e => setUserPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Confirmar Nova Senha</span>
              </label>
              <TextField
                fullWidth
                required
                placeholder="Confirmar Nova Senha"
                size="small"
                inputProps={{ maxLength: 20 }}
                value={confirmSenha}
                onChange={e => setConfirmSenha(e.target.value)}
                type={`${typePasswordConfirm ? 'password' : 'text'}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setTypePasswordConfirm(!typePasswordConfirm)}
                        edge="end"
                      >
                        {typePasswordConfirm ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size="small"
                color="inherit"
                onClick={() => setModalDeletarUsuario(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size="small"
                disabled={promiseInProgress}
                onClick={alterarSenhaUsuario}
              >
                alterar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

      {/* grupos modeal */}
      <Modal
        size="sm"
        open={modalGrupos}
        close={() => setModalGrupos(false)}
        titulo={`Visualizar Equipes de: ${userSelectToShowGroups?.name}`}
      >
        <Grid container spacing={{ xs: 2 }}>
          {/* <Grid item xs={12}>
            Usuário: {userSelectToShowGroups?.name} <br />
            E-mail: {userSelectToShowGroups?.email}
          </Grid> */}
          <Grid item xs={12} sm={12}>
            {/* <label className="labelAllis">
              <span>Equipe</span>
            </label> */}
            {/* <Select
              fullWidth
              required
              size="small"
              value={teamSetToInclude || ''}
              onChange={e =>
                setTeamSetToInclude(e.target.value)
              }
            >
              <MenuItem value="" >
                Selecione
              </MenuItem>
              {teams?.map((item: any, index: number) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select> */}
            {/* <Select
              fullWidth
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={teamsSetToInclude}
              onChange={(e) => handleChange(e.target.value)}
              renderValue={(selected) => selected.map((item: any) => (item.name)).join(', ')}
              MenuProps={MenuProps}
            >
              {teamsFilter.map((itemT: any, index: number) => (
                <MenuItem key={itemT.id} value={itemT.id}>
                  <Checkbox 
                    checked={teamsSetToInclude ? teamsSetToInclude.filter((item: any) => item.id === itemT.id).length > 0 : false} 
                  />
                  <ListItemText primary={itemT.name} />
                </MenuItem>
              ))}
            </Select> */}
            <Autocomplete
              multiple
              id="tags-outlined"
              options={teamsFilter}
              getOptionLabel={(option) => option.name}
              value={teamsSetToInclude}
              onChange={(event, newValue) => {
                setTeamsSetToInclude(newValue)
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Equipes"
                  placeholder="Equipes"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Button
              onClick={vincularEquipeUsuarioMult2}
              variant="contained"
              type="button"
              size="small"
              color="secondary"
              disabled={promiseInProgress}
            >
              Vincular
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TabelaGrupo
              mainList={gruposByUserFilter}
              user={userSelectToShowGroups}
              reload={handleOpenGrupos}
              objBusca={objBuscaGrupo}
              setObjBusca={setObjBuscaGrupo}
              filter={handleGruposFilter}
              handleOrderby={handleOrderbyGrupo}
            />
          </Grid>

          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              type="button"
              size="small"
              color="inherit"
              onClick={() => setModalGrupos(false)}
            >
              Fechar
            </Button>
          </Grid>
        </Grid>
      </Modal>

      {/* menu opções */}
      <Menu
        anchorEl={anchorElOrderby}
        open={openOrderby}
        onClose={handleCloseOrderby}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))'
          }
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <div style={{ padding: '2px 8px', fontSize: '14px' }}>
            ORDENAR
          </div>
          <MenuItem
            onClick={() => ordeby(false)}
          >
            <ListItemIcon>
              <ArrowUpwardIcon fontSize="small" />
            </ListItemIcon>
            Ordem crescente
          </MenuItem>
          <MenuItem
            onClick={() => ordeby(true)}
          >
            <ListItemIcon>
              <ArrowDownwardIcon fontSize="small" />
            </ListItemIcon>
            Ordem decrescente
          </MenuItem>
        </div>

      </Menu>

    </>
  )
}

export default TabelaUsuarios
