import React from 'react'
import { Table, TableColumnsType } from 'antd'
import { ITrctLog } from '../../interfaces'

interface ITableTrctLog {
  sortFunction: any,
  data: ITrctLog[];
  loading: boolean;
  pageSize: number;
  totalCount: number;
  currentPage: number;
  handlePagination: (page: number, pageSize?: number) => void;
}

const TableTrctLog: React.FC<ITableTrctLog> = ({
                                                 sortFunction,
                                                 data,
                                                 loading,
                                                 currentPage,
                                                 pageSize,
                                                 totalCount,
                                                 handlePagination
                                               }) => {
  const columns: TableColumnsType<ITrctLog> = [
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf'
    },
    {
      title: 'PIS',
      dataIndex: 'pis',
      key: 'pis'
    },
    {
      title: 'Telefone',
      dataIndex: 'telephone',
      key: 'telephone'
    },
    {
      title: 'Data de Solicitação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleString(),
      sorter: true,
      defaultSortOrder: 'ascend'
    },
    {
      title: 'Sucesso',
      dataIndex: 'success',
      key: 'success',
      render: (success) => (
        <span className={`badge ${success ? 'badge-success' : 'badge-error'}`}>
                  {success ? 'Sucesso' : 'Erro'}
                </span>
      ),
      defaultSortOrder: 'ascend'
    }
  ]

  // const handleChange = (pagination: any, filters: any, sorter: any) => {
  //     if (sorter.order) {
  //         sortFunction(sorter);
  //     }
  //     handlePagination(pagination.current, pagination.pageSize);
  // };

  return (
    <Table
      locale={{
        triggerDesc: 'Ordenação descendente',
        triggerAsc: 'Ordenação ascendente',
        cancelSort: 'Cancelar ordenação',
        emptyText: 'Nenhum log encontrado'
      }}
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        total: totalCount,
        showSizeChanger: true,
        onChange: handlePagination
      }}
      columns={columns}
      rowKey={(record, index) => record.cpf + record + index}
      onChange={sortFunction}
      dataSource={data}
      loading={loading}

    />
  )
}

export default TableTrctLog
