import React, { useEffect, useState } from 'react'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useParams } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'
import services from '../../services'
import { Divider } from 'antd'
import { number } from '../../services/mask'
import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification'
import {
  IDashboardEnvOverview,
  IDashboardEnvTeam,
  IDashboardEnvUser,
  IDashboardFilter,
  IDashboardRule,
  IDropdownOptions,
  IEnvironment,
  ITeam,
  IUser
} from '../../interfaces'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Modal from '../../components/Modal'
import TableEnvFilters from './TableEnvFilters'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Tabs from '../../components/Tabs'
import TableEnvUsers from './TableEnvUsers'
import UserSelectList from './UserSelectList'
import TableEnvTeams from './TableEnvTeams'
import TeamSelectList from './TeamSelectList'

function DashboardEdit() {
  const [dashboardName, setDashboardName] = useState('')
  const [dashboardOrder, setDashboardOrder] = useState<number | null>(null)
  const [dashboardActive, setDashboardActive] = useState<boolean | undefined>(undefined)
  const [dashboardEnableFilters, setDashboardEnableFilters] = useState<boolean | undefined>(undefined)
  const [dashboardEnableNavigationBar, setDashboardEnableNavigationBar] = useState<boolean | undefined>(undefined)
  const [dashboardFilters, setDashboardFilters] = useState<IDashboardFilter[] | undefined>(undefined)
  const [dashboardReportPath, setDashboardReportPath] = useState('')
  const [dashboardRefreshTime, setDashboardRefreshTime] = useState<number | null>(null)
  const [dashboardChanged, setDashboardChanged] = useState<boolean>(false)
  const [changes, setDashboardChanges] = useState<number>(0)
  const [envName, setEnvName] = useState<string | undefined>(undefined)

  //DASH ENVIRONMENTS VARS
  const [dashboardEnvOverview, setDashboardEnvOverview] = useState<IDashboardEnvOverview[] | undefined>(undefined)
  const [dashEnvModal, setDashEnvModal] = useState<boolean>(false)
  const [envsList, setEnvsList] = useState<IDropdownOptions[]>([])
  const [groupsList, setGroupsList] = useState<IDropdownOptions[]>([])
  const [rulesList, setRulesList] = useState<IDropdownOptions[]>([])
  const [envSelected, setEnvSelected] = useState<number | undefined>(undefined)
  const [groupSelected, setGroupSelected] = useState<number | undefined>(undefined)
  const [resetKey, setResetKey] = useState<number>(0)
  const [dashEnvButton, setDashEnvButton] = useState<boolean>(false)

  //TABS BARS
  const [tabActive, setTabActive] = useState(0)
  const [tabsList, setTabsList] = useState<any[]>([{ id: 0, label: 'Usuários' }, { id: 1, label: 'Equipes' }])
  const [step, setStep] = useState(0)
  const [dashboardEnvUsers, setDashboardEnvUsers] = useState<IDashboardEnvUser[] | undefined>(undefined)
  const [dashboardEnvTeams, setDashboardEnvTeams] = useState<IDashboardEnvTeam[] | undefined>(undefined)
  const [selectedUsersToAdd, setSelectedUsersToAdd] = useState<IUser[] | undefined>([])
  const [selectedTeamsToAdd, setSelectedTeamsToAdd] = useState<ITeam[] | undefined>([])
  const [modalAccessUserAdd, setModalAccessUserAdd] = useState<boolean>(false)
  const [modalAccessTeamAdd, setModalAccessTeamAdd] = useState<boolean>(false)


  //FILTERS VARS
  const [dashFilterTable, setDashFilterTable] = useState('')
  const [dashFilterColumn, setDashFilterColumn] = useState('')
  const [dashFilterOperator, setDashFilterOperator] = useState('EQ')
  const [dashFilterValueType, setDashFilterValueType] = useState('STATIC')
  const [dashFilterValue, setDashFilterValue] = useState('')
  const [dashFilterAcumulator, setDashFilterAcumulator] = useState('AND')
  const [dashFilterModal, setDashFilterModal] = useState<boolean>(false)
  const [dashGroupId, setDashGroupId] = useState<number>(0)
  const [envGroupId, setEnvGroupId] = useState<number>(0)

  const [dashboardRuleId, setDashboardRuleId] = useState<number>(0)

  //FILTERS EDIT VARS
  const [dashFilterIdEdit, setDashFilterIdEdit] = useState('')
  const [dashFilterTableEdit, setDashFilterTableEdit] = useState('')
  const [dashFilterColumnEdit, setDashFilterColumnEdit] = useState('')
  const [dashFilterOperatorEdit, setDashFilterOperatorEdit] = useState('AND')
  const [dashFilterValueTypeEdit, setDashFilterValueTypeEdit] = useState('STATIC')
  const [dashFilterValueEdit, setDashFilterValueEdit] = useState('')
  const [dashFilterAcumulatorEdit, setDashFilterAcumulatorEdit] = useState('AND')
  const [dashFilterModalEdit, setDashFilterModalEdit] = useState<boolean>(false)


  const { id } = useParams()
  const { envId } = useParams()
  const { notify } = useNotification()

  async function AddDashboardFilter() {


    let filterData = {
      table: dashFilterTable,
      colunm: dashFilterColumn,
      operator: dashFilterOperator,
      acumulator: dashFilterAcumulator,
      order: null,
      dashboardId: id,
      environmentId: envId,
      type: dashFilterValueType,
      value: dashFilterValue
    }

    let addedData = await trackPromise(services.api.addFilterToDashboard(filterData))

    if (addedData.fail || addedData.status !== 200) {
      notify('Não foi possivel carregar os dados do dashboard', { variant: 'error' })
      return
    }

    notify('Filtro adicionado com sucesso!', { variant: 'success' })
    setDashFilterModal(false)
    setDashFilterValue('')
    setDashFilterColumn('')
    setDashFilterTable('')
    await GetDashboardData(number(id))
  }

  async function UpdateDashboardFilter() {

    let filterData = {
      id: dashFilterIdEdit,
      table: dashFilterTableEdit,
      colunm: dashFilterColumnEdit,
      operator: dashFilterOperatorEdit,
      acumulator: dashFilterAcumulatorEdit,
      environmentId: envId,
      type: dashFilterValueTypeEdit,
      value: dashFilterValueEdit
    }

    let update = await trackPromise(services.api.updateFilterToDashboard(filterData))

    if (update.fail || update.status !== 200) {
      notify('Não foi possivel atualizar o filtro', { variant: 'error' })
      return
    }
    notify('Filtro atualizado com sucesso', { variant: 'success' })
    setDashFilterModalEdit(false)
    setDashFilterValueEdit('')
    setDashFilterColumnEdit('')
    setDashFilterTableEdit('')
    await GetDashboardData(number(id))
  }

  const HandleDashboardEdit = async (filterData: IDashboardFilter) => {
    setDashFilterTableEdit(filterData.table)
    setDashFilterColumnEdit(filterData.colunm)
    setDashFilterOperatorEdit(filterData.operator)
    setDashFilterAcumulatorEdit(filterData.acumulator)
    setDashFilterValueEdit(filterData.value)
    setDashFilterValueTypeEdit(filterData.type)
    setDashFilterIdEdit(number(filterData.id))
    setDashFilterModalEdit(true)
  }

  const handleEnvGroupChanged = async (groupId: string) => {
    if (groupId !== envGroupId.toString()) {
      setEnvGroupId(number(groupId))
      let promisse = await trackPromise(services.api.updateDashboardGroupGroup(dashGroupId, groupId))
      if (promisse.status !== 200) {
        notify('Não foi possivel alterar o grupo do dashboard', { variant: 'error' })
        return
      }
      notify('Grupo do ambiente alterado com sucesso!', { variant: 'success' })
    }
  }

  const handleRuleChanged = async (ruleId: string) => {
    setDashboardRuleId(number(ruleId))

    let promisse = await trackPromise(services.api.updateDashboardGroupRule(dashGroupId, ruleId))
    if (promisse.status !== 200) {
      notify('Não foi possivel alterar o grupo do dashboard', { variant: 'error' })
      return
    }
    notify('Regra alterada com sucesso!', { variant: 'success' })
  }

  const setSelectedUsers = async (selectedData: any[]) => {
    setSelectedUsersToAdd(selectedData)
  }

  const DeleteDashboardFilter = async (filterId: number) => {
    let remove = await trackPromise(services.api.removeFilterFromDashboard(filterId))
    if (remove.status !== 200) {
      notify('Não foi possivel deletar este filtro', { variant: 'error' })
      return
    }
    notify('Filtro removido com sucesso!', { variant: 'success' })
    await GetDashboardData(number(id))
  }

  const DeleteDashboardUser = async (accessUserId: number) => {
    let remove = await trackPromise(services.api.removeUserFromDashboardGroup(accessUserId))
    if (remove.status !== 200) {
      notify('Não foi possivel remover este usuário', { variant: 'error' })
      return
    }
    notify('Usuário removido com sucesso!', { variant: 'success' })
    await GetDashboardData(number(id))
  }

  const DeleteDashboardTeam = async (accessUserId: number) => {
    let remove = await trackPromise(services.api.removeTeamFromDashboardGroup(accessUserId))
    if (remove.status !== 200) {
      notify('Não foi possivel remover esta equipe.', { variant: 'error' })
      return
    }
    notify('Equipe removida com sucesso!', { variant: 'success' })
    await GetDashboardData(number(id))
  }

  const AddDashboardUsers = async () => {
    let ids = selectedUsersToAdd!.map(it => it.id)
    setSelectedUsersToAdd([])
    let added = await trackPromise(services.api.addDashboardUsers(ids, dashGroupId))
    if (added.status !== 200) {
      notify('Não foi possivel adicionar os usuários', { variant: 'error' })
      return
    }
    notify('Usuários adicionados com sucesso!', { variant: 'success' })
    setModalAccessUserAdd(false)
    await GetDashboardData(number(id))
  }

  const AddDashboardTeams = async () => {
    let ids = selectedTeamsToAdd!.map(it => it.id)
    setSelectedTeamsToAdd([])
    let added = await trackPromise(services.api.addDashboardTeams(ids, dashGroupId))
    if (added.status !== 200) {
      notify('Não foi possivel adicionar os equipes', { variant: 'error' })
      return
    }
    notify('Equipes adicionadas com sucesso!', { variant: 'success' })
    setModalAccessTeamAdd(false)
    await GetDashboardData(number(id))
  }

  async function GetDashboardData(id: number) {
    let dashboardData = await trackPromise(services.api.getDashboardById(id))
    let dashboardEnvOverviewData = await trackPromise(services.api.getDashboardEnvOverview(id))
    let environmentData = await trackPromise(services.api.getEnvironmentById(envId))
    setDashboardEnvOverview(dashboardEnvOverviewData.data)

    if (dashboardData.fail) {
      notify('Não foi possivel carregar os dados do dashboard', { variant: 'error' })
      return
    } else if (dashboardData.status === 204) {
      notify('Dashboard inexistente', { variant: 'error' })
      return
    }
    setDashboardName(dashboardData.data.name)
    setDashboardOrder(dashboardData.data.order)
    setDashboardActive(dashboardData.data.active)
    setDashboardReportPath(dashboardData.data.reportPath)
    setDashboardRefreshTime(dashboardData.data.autoRefreshTime)
    setDashboardEnableFilters(dashboardData.data.enableFilters)
    setDashboardEnableNavigationBar(dashboardData.data.enableNavigationBar)
    setDashboardFilters(dashboardData.data.dashboardFilters)
    setEnvName(environmentData.data.name)
    let dashGroupId = dashboardData.data.dashboardGroups.filter((it: {
      group: { environmentId: { toString: () => string; }; };
    }) => it.group.environmentId.toString() === envId!.toString())[0].id
    let dashRuleId = dashboardData.data.dashboardGroups.filter((it: {
      group: { environmentId: { toString: () => string; }; };
    }) => it.group.environmentId.toString() === envId!.toString())[0].dashboardRuleId
    setDashboardRuleId(dashRuleId)
    setDashGroupId(dashGroupId)

    let envGroupId = dashboardData.data.dashboardGroups.filter((it: {
      group: { environmentId: { toString: () => string; }; };
    }) => it.group.environmentId.toString() === envId!.toString())[0].groupId
    setEnvGroupId(number(envGroupId))

    let accessTeams = await trackPromise(services.api.getAllTeamsFromDashGroupId(dashGroupId))
    let accessUsers = await trackPromise(services.api.getAllUsersFromDashGroupId(dashGroupId))
    setDashboardEnvTeams(accessTeams.data)
    setDashboardEnvUsers(accessUsers.data)

    let groups = await trackPromise(services.api.getAllEnvGroups(envId))
    setGroupsList(groups.data.map((env: IEnvironment) => ({ value: env.id, label: env.name })))

    let rulesData = await trackPromise(services.api.getAllDashboardRules())
    let list = rulesData.data.map((dt: IDashboardRule) => ({ value: dt.id, label: dt.name }))
    list.unshift({ value: 0, label: 'Nenhuma regra' })
    setRulesList(list)
  }

  async function SetDashboardFilterOrder(ids: number[]) {
    let result = await trackPromise(services.api.setDashboardFilterOrder(ids))
    if (result.error) {
      notify(result.error, { variant: 'error' })
      return
    }
    notify('Ordem dos filtros salva com sucesso.', { variant: 'success' })
  }

  useEffect(() => {
    if (dashFilterValueType !== 'STATIC')
      setDashFilterValue('ENVIRONMENT_NAME')
    else
      setDashFilterValue('')
  }, [dashFilterValueType])

  useEffect(() => {
    if (dashFilterValueTypeEdit !== 'STATIC')
      setDashFilterValueEdit('ENVIRONMENT_NAME')
    else
      setDashFilterValueEdit('')
  }, [dashFilterValueTypeEdit])

  useEffect(() => {
    const init = async () => {
      await GetDashboardData(number(id))
    }
    init().then()
  }, [id])

  return (
    <div className="wrapper-container-content bgwhite">
      <div className="wrapper-page-content" id="wrapper-page-content">
        <PageTitle subText={`Editando o dashboard #${id}`}>
          <div>{dashboardName ? `Dashboards > ${dashboardName}` : 'Dashboards > ...'}</div>
        </PageTitle>
        <Grid container justifyContent="center" spacing={{ xs: 3 }}>
          <Grid item md={11} xs={12}>
            <div className="panel">
              <div className="panel-body">


                <Grid container spacing={{ xs: 3 }}>
                  <Grid item xs={12} sm={9} md={9} lg={9}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <ArrowBackIcon sx={{ fontSize: 40, marginRight: '10px', cursor: 'pointer' }}
                                     onClick={() => window.location.href = `/app/dash_management/dashboard/${id}`} />
                      <h2>Ambiente: {envName}</h2>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel>Grupo do ambiente</InputLabel>
                      <Select
                        fullWidth
                        required
                        size="small"
                        label="Grupo do ambiente"
                        value={envGroupId === undefined ? 0 : envGroupId}
                        onChange={(e) => handleEnvGroupChanged(String(Number(e.target.value)))}
                      >
                        {groupsList.map((group) => (
                          <MenuItem key={group.value} value={group.value}>
                            {group.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel>Regra do ambiente</InputLabel>
                      <Select
                        fullWidth
                        required
                        size="small"
                        label="Regra do ambiente"
                        value={dashboardRuleId === null ? 0 : dashboardRuleId}
                        onChange={(e) => handleRuleChanged(String(Number(e.target.value)))}
                      >
                        {rulesList.map((rule) => (
                          <MenuItem key={rule.value} value={rule.value}>
                            {rule.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                </Grid>


                <Grid container spacing={{ xs: 3 }}>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <h3>Filtros do ambiente</h3>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div style={{ textAlign: 'right', marginTop: '18px' }}>
                      <Button
                        variant="contained"
                        className="btn-purple mr-6 content-end"
                        type="button"
                        size="small"
                        color="inherit"
                        onClick={() => setDashFilterModal(true)}
                      >
                        <AddCircleOutlineIcon style={{ marginRight: '5px' }} fontSize="small" /> Novo filtro
                      </Button>
                    </div>
                  </Grid>

                </Grid>


                <TableEnvFilters
                  environmentId={number(envId)}
                  data={dashboardFilters || []}
                  deleteDashboardFilter={DeleteDashboardFilter}
                  editDashboardFilter={HandleDashboardEdit}
                  setDashboardFilterOrder={SetDashboardFilterOrder}
                ></TableEnvFilters>


                <Divider></Divider>
                <h3>Acessos</h3>


                <Grid container justifyContent="center" spacing={{ xs: 3 }}>
                  <Grid item md={12} xs={12}>
                    <Tabs tabs={tabsList} active={tabActive} onChange={setTabActive} disabled={step === 1} />
                    {tabActive === 0 &&

                      <div>
                        <div className={'mb-12'} style={{ textAlign: 'right' }}>
                          <Button
                            variant="contained"
                            className="btn-purple mr-6 content-end"
                            type="button"
                            size="small"
                            color="inherit"
                            onClick={() => setModalAccessUserAdd(true)}
                          >
                            <AddCircleOutlineIcon style={{ marginRight: '5px' }} fontSize="small" /> Adicionar usuário
                          </Button>
                        </div>

                        <TableEnvUsers dataSource={dashboardEnvUsers!} deleteDashboardUser={DeleteDashboardUser}
                                       dashboardId={number(id)} environmentId={number(envId)}></TableEnvUsers>
                      </div>

                    }

                    {tabActive === 1 &&
                      <div>
                        <div className={'mb-12'} style={{ textAlign: 'right' }}>
                          <Button
                            variant="contained"
                            className="btn-purple mr-6 content-end"
                            type="button"
                            size="small"
                            color="inherit"
                            onClick={() => setModalAccessTeamAdd(true)}
                          >
                            <AddCircleOutlineIcon style={{ marginRight: '5px' }} fontSize="small" /> Adicionar equipe
                          </Button>
                        </div>

                        <TableEnvTeams dataSource={dashboardEnvTeams!} deleteDashboardTeam={DeleteDashboardTeam}
                                       dashboardId={number(id)} environmentId={number(envId)}></TableEnvTeams>
                      </div>
                    }
                  </Grid>
                </Grid>


              </div>
            </div>
          </Grid>
        </Grid>


        <Modal size="sm" open={dashFilterModalEdit} close={() => setDashFilterModalEdit(false)}
               titulo={'Editar filtro'}>
          <Grid container spacing={{ xs: 3 }}>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                required
                size="small"
                label="Tabela"
                inputProps={{ maxLength: 120 }}
                InputLabelProps={{ shrink: true }}
                value={dashFilterTableEdit}
                onChange={e =>
                  setDashFilterTableEdit(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                required
                size="small"
                label="Coluna"
                inputProps={{ maxLength: 120 }}
                InputLabelProps={{ shrink: true }}
                value={dashFilterColumnEdit}
                onChange={e =>
                  setDashFilterColumnEdit(e.target.value)}
              />
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl>
                <InputLabel>Operador</InputLabel>
                <Select
                  fullWidth
                  required
                  notched
                  size="small"
                  label="Barra de navegação PowerBI"
                  value={dashFilterOperatorEdit}
                  onChange={e => setDashFilterOperatorEdit(e.target.value)}
                >
                  <MenuItem value={'EQ'}>
                    EQ (Igual a)
                  </MenuItem>
                  <MenuItem value={'NE'}>
                    NE (Diferente de)
                  </MenuItem>
                  <MenuItem value={'GE'}>
                    GE (Maior ou igual)
                  </MenuItem>
                  <MenuItem value={'GT'}>
                    GT (Maior que)
                  </MenuItem>
                  <MenuItem value={'LE'}>
                    LE (Menor ou igual)
                  </MenuItem>
                  <MenuItem value={'LT'}>
                    LT (Menor que)
                  </MenuItem>
                  <MenuItem value={'IN'}>
                    IN (Incluindo)
                  </MenuItem
                  >
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl>
                <InputLabel>Tipo de valor</InputLabel>
                <Select
                  fullWidth
                  required
                  notched
                  size="small"
                  label="Tipo de valor"
                  value={dashFilterValueTypeEdit}
                  onChange={e => setDashFilterValueTypeEdit(e.target.value)}
                >
                  <MenuItem value={'VARIABLE'}>
                    Variavel do sistema
                  </MenuItem>
                  <MenuItem value={'STATIC'}>
                    Valor estatico
                  </MenuItem>

                </Select>
              </FormControl>
            </Grid>

            {dashFilterValueTypeEdit === 'STATIC' &&
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  label="Valor"
                  inputProps={{ maxLength: 120 }}
                  InputLabelProps={{ shrink: true }}
                  value={dashFilterValueEdit}
                  onChange={e =>
                    setDashFilterValueEdit(e.target.value)}
                />
              </Grid>
            }

            {dashFilterValueTypeEdit === 'VARIABLE' &&
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl>
                  <InputLabel>Valor</InputLabel>
                  <Select
                    fullWidth
                    required
                    notched
                    size="small"
                    label="Valor"
                    value={dashFilterValueEdit}
                    onChange={e => setDashFilterValueEdit(e.target.value)}
                  >
                    <MenuItem value={'ENVIRONMENT_NAME'}>
                      Nome do ambiente
                    </MenuItem>
                    <MenuItem value={'USER_NAME'}>
                      Nome do usuário
                    </MenuItem>
                    <MenuItem value={'USER_CPF'}>
                      CPF do usuário
                    </MenuItem>
                    <MenuItem value={'USER_EMAIL'}>
                      Email do usuário
                    </MenuItem>
                    <MenuItem value={'USER_COMPANY'}>
                      Empresa do usuário
                    </MenuItem>


                  </Select>
                </FormControl>
              </Grid>
            }

            {/*<Grid item xs={12} sm={12} md={12} lg={12}>*/}
            {/*  <FormControl>*/}
            {/*    <InputLabel>Acumulador</InputLabel>*/}
            {/*    <Select*/}
            {/*      fullWidth*/}
            {/*      required*/}
            {/*      notched*/}
            {/*      size="small"*/}
            {/*      label="Acumulador"*/}
            {/*      value={dashFilterAcumulatorEdit}*/}
            {/*      onChange={e => setDashFilterAcumulatorEdit(e.target.value)}*/}
            {/*    >*/}
            {/*      <MenuItem value={"AND"} >*/}
            {/*        AND (E)*/}
            {/*      </MenuItem>*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}

            <Grid item xs={12} className="flex justify-end gap-6">

              <Button
                variant="contained"
                type="button"
                size="small"
                color="inherit"
                onClick={() => setDashFilterModalEdit(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="button"
                size="small"
                color="secondary"
                onClick={UpdateDashboardFilter}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Modal>


        <Modal size="md" open={modalAccessUserAdd} close={() => setModalAccessUserAdd(false)}
               titulo={'Adicionar usuários'}>
          <div className={'m-20'}>
            <Grid container spacing={{ xs: 3 }}>
              <Grid item xs={12} className="flex justify-center gap-6">

                <UserSelectList
                  setSelectedUsers={setSelectedUsers}
                ></UserSelectList>
              </Grid>

              <Grid item xs={12} style={{ marginRight: '30px' }} className="flex justify-end gap-6">
                <div style={{ textAlign: 'right', marginTop: '18px' }}>
                  <Button
                    variant="contained"
                    className="btn-purple mr-6 content-end"
                    type="button"
                    disabled={selectedUsersToAdd!.length === 0}
                    size="small"
                    color="inherit"
                    onClick={AddDashboardUsers}
                  >
                    <AddCircleOutlineIcon style={{ marginRight: '5px' }} fontSize="small" /> Adicionar
                  </Button>
                </div>
              </Grid>


            </Grid>
          </div>
        </Modal>


        <Modal size="md" open={modalAccessTeamAdd} close={() => setModalAccessTeamAdd(false)}
               titulo={'Adicionar times'}>
          <div className={'m-20'}>
            <Grid container spacing={{ xs: 3 }}>
              <Grid item xs={12} className="flex justify-center gap-6">
                <TeamSelectList
                  setSelectedTeams={setSelectedTeamsToAdd}
                ></TeamSelectList>
              </Grid>

              <Grid item xs={12} style={{ marginRight: '30px' }} className="flex justify-end gap-6">
                <div style={{ textAlign: 'right', marginTop: '18px' }}>
                  <Button
                    variant="contained"
                    className="btn-purple mr-6 content-end"
                    type="button"
                    disabled={selectedTeamsToAdd!.length === 0}
                    size="small"
                    color="inherit"
                    onClick={AddDashboardTeams}
                  >
                    <AddCircleOutlineIcon style={{ marginRight: '5px' }} fontSize="small" /> Adicionar
                  </Button>
                </div>
              </Grid>


            </Grid>
          </div>
        </Modal>


        <Modal size="sm" open={dashFilterModal} close={() => setDashFilterModal(false)} titulo={'Novo filtro'}>
          <Grid container spacing={{ xs: 3 }}>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                required
                size="small"
                label="Tabela"
                inputProps={{ maxLength: 120 }}
                InputLabelProps={{ shrink: true }}
                value={dashFilterTable}
                onChange={e =>
                  setDashFilterTable(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                required
                size="small"
                label="Coluna"
                inputProps={{ maxLength: 120 }}
                InputLabelProps={{ shrink: true }}
                value={dashFilterColumn}
                onChange={e =>
                  setDashFilterColumn(e.target.value)}
              />
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl>
                <InputLabel>Operador</InputLabel>
                <Select
                  fullWidth
                  required
                  notched
                  size="small"
                  label="Barra de navegação PowerBI"
                  value={dashFilterOperator}
                  onChange={e => setDashFilterOperator(e.target.value)}
                >
                  <MenuItem value={'EQ'}>
                    EQ (Igual a)
                  </MenuItem>
                  <MenuItem value={'NE'}>
                    NE (Diferente de)
                  </MenuItem>
                  <MenuItem value={'GE'}>
                    GE (Maior ou igual)
                  </MenuItem>
                  <MenuItem value={'GT'}>
                    GT (Maior que)
                  </MenuItem>
                  <MenuItem value={'LE'}>
                    LE (Menor ou igual)
                  </MenuItem>
                  <MenuItem value={'LT'}>
                    LT (Menor que)
                  </MenuItem>
                  <MenuItem value={'IN'}>
                    IN (Incluindo)
                  </MenuItem
                  >
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl>
                <InputLabel>Tipo de valor</InputLabel>
                <Select
                  fullWidth
                  required
                  notched
                  size="small"
                  label="Tipo de valor"
                  value={dashFilterValueType}
                  onChange={e => setDashFilterValueType(e.target.value)}
                >
                  <MenuItem value={'VARIABLE'}>
                    Variavel do sistema
                  </MenuItem>
                  <MenuItem value={'STATIC'}>
                    Valor estatico
                  </MenuItem>

                </Select>
              </FormControl>
            </Grid>

            {dashFilterValueType === 'STATIC' &&
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  label="Valor"
                  inputProps={{ maxLength: 120 }}
                  InputLabelProps={{ shrink: true }}
                  value={dashFilterValue}
                  onChange={e =>
                    setDashFilterValue(e.target.value)}
                />
              </Grid>
            }

            {dashFilterValueType === 'VARIABLE' &&
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl>
                  <InputLabel>Valor</InputLabel>
                  <Select
                    fullWidth
                    required
                    notched
                    size="small"
                    label="Valor"
                    value={dashFilterValue}
                    onChange={e => setDashFilterValue(e.target.value)}
                  >
                    <MenuItem value={'ENVIRONMENT_NAME'}>
                      Nome do ambiente
                    </MenuItem>
                    <MenuItem value={'USER_NAME'}>
                      Nome do usuário
                    </MenuItem>
                    <MenuItem value={'USER_CPF'}>
                      CPF do usuário
                    </MenuItem>
                    <MenuItem value={'USER_EMAIL'}>
                      Email do usuário
                    </MenuItem>
                    <MenuItem value={'USER_COMPANY'}>
                      Empresa do usuário
                    </MenuItem>

                  </Select>
                </FormControl>
              </Grid>
            }

            {/*<Grid item xs={12} sm={12} md={12} lg={12}>*/}
            {/*  <FormControl>*/}
            {/*    <InputLabel>Acumulador</InputLabel>*/}
            {/*    <Select*/}
            {/*      fullWidth*/}
            {/*      required*/}
            {/*      notched*/}
            {/*      size="small"*/}
            {/*      label="Acumulador"*/}
            {/*      value={dashFilterAcumulator}*/}
            {/*      onChange={e => setDashFilterAcumulator(e.target.value)}*/}
            {/*    >*/}
            {/*      <MenuItem value={"AND"} >*/}
            {/*        AND (E)*/}
            {/*      </MenuItem>*/}
            {/*    </Select>*/}
            {/*  </FormControl>*/}
            {/*</Grid>*/}

            <Grid item xs={12} className="flex justify-end gap-6">

              <Button
                variant="contained"
                type="button"
                size="small"
                color="inherit"
                onClick={() => setDashFilterModal(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="button"
                size="small"
                color="secondary"
                //disabled={promiseInProgress}
                onClick={AddDashboardFilter}
              >
                Adicionar
              </Button>
            </Grid>
          </Grid>
        </Modal>

      </div>
    </div>
  )
}

export default DashboardEdit
