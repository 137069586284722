import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import { Button, Grid } from '@mui/material'

import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification.js'
import { IBeneficio, IChamado, IEmpresa, IRazaoContratacao, IRecruitmentReasonData } from '../../interfaces/index'
import services from '../../services/index.js'

import Beneficios from './components/Beneficios'
import Employees from './components/Employees'
import DadosContratante from './components/DadosContratante'
import DadosVaga from './components/DadosVaga'

import './style.scss'

function ChamadoNew() {
  const navigate = useNavigate()
  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()

  const [empresas, setEmpresas] = useState<IEmpresa[] | []>([])

  const [razoesContratacao, setRazoesContratacao] = useState<IRazaoContratacao[] | []>([])
  const [beneficios, setBeneficios] = useState<IBeneficio[] | []>([])
  const [employees, setEmployees] = useState<IRecruitmentReasonData[] | []>([])

  const [chamado, setChamado] = useState<IChamado>({})

  const [listaChamados, setListaChamados] = useState<any>([
    { open: true }, { open: false }
  ])

  const handleToggleChamado = (index: number) => {
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, index),
      { ...prevState[index], open: !prevState[index].open },
      ...prevState.slice(index + 1)
    ])
  }

  const handleCopyChamado = (index: number) => {
    const newChanadoItem = { ...listaChamados[index] }
    setListaChamados((prevState: any) => [
      ...prevState,
      newChanadoItem
    ])
  }

  const handleTrashChamado = (index: number) => {
    if (listaChamados.length > 1) {
      setListaChamados((prevState: any) => ([
          ...prevState.slice(0, index),
          ...prevState.slice(index + 1)
        ]
      ))
    }
  }

  const openChamado = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (chamado?.functionName && chamado?.functionName?.length <= 5) {
      notify('Campo função deve ter mais que 5 caracteres', { variant: 'warning' })
      return false
    }
    if (Number(chamado.quantity) === 0) {
      notify('Quantidade de vagas deve ser maior que zero', { variant: 'warning' })
      return false
    }
    if (chamado.isEmployeeReplace) {
      if (Number(chamado.quantity) !== employees.length) {
        notify('A quantidade de Funcionarios a serem substituidos deve ser igual a quantidade de vagas', { variant: 'warning' })
        return false
      }
    }

    try {
      const chamatoFormat = { ...chamado }
      chamatoFormat.benefits = beneficios
      chamatoFormat.employeesToReplace = chamado.isEmployeeReplace ? employees : []
      chamatoFormat.recruitmentDemandHiringReasonId = chamatoFormat.recruitmentDemandHiringReasonId === '' || chamatoFormat.recruitmentDemandHiringReasonId === 'null' ? null : chamatoFormat.recruitmentDemandHiringReasonId
      chamatoFormat.duration = chamatoFormat.duration ? chamatoFormat.duration : null
      chamatoFormat.fastJobId = chamatoFormat.fastJobId ? chamatoFormat.fastJobId : null

      const resTicketData = await trackPromise(services.api.newResChamadoData(chamatoFormat))
      if (resTicketData.fail) {
        throw new Error(
          resTicketData.error || 'Não foi possível criar o chamado'
        )
      }

      notify('chamado criado com sucesso', { variant: 'success' })
      navigate(`/app/chamados/meus-chamados`)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  /// add beneficio
  const addBeneficio = async (beneficioNome: string, beneficioValue: string, baneficioId?: number) => {
    setBeneficios(prevState => [...prevState, { benefitName: beneficioNome, benefitValue: beneficioValue }])
  }

  const removeBeneficio = async (index: number) => {
    setBeneficios((prevState: any) => ([
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1)
      ]
    ))
  }

  /// add recruitment
  const addRecruitment = async (recruitmentCpf: string, recruitmentNome: string) => {
    setEmployees(prevState => [...prevState,
      { cpf: recruitmentCpf, fullName: recruitmentNome }
    ])
  }

  const removeRecruitment = async (index: number) => {
    setEmployees((prevState: any) => ([
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1)
      ]
    ))
  }

  useEffect(() => {
    const init = async () => {
      try {
        const res = await trackPromise(services.api.getEmpresasMe())
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível consultar Usuários'
          )
        }
        setEmpresas(res.data)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }

      try {
        const res = await trackPromise(services.api.getDemandreasons())
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível consultar Demand reasons'
          )
        }
        setRazoesContratacao(res.data)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (chamado.fastRhContratoId) {
      console.log('dadosContratante.fastRhContratoId', chamado.fastRhContratoId)
    }
  }, [chamado.fastRhContratoId])

  return (
    <div className="wrapper-container-content bgwhite">
      <div className="wrapper-page-content" id="wrapper-page-content">
        <PageTitle>
          <div>Novo Chamado</div>
        </PageTitle>
        <Grid container justifyContent="center" spacing={{ xs: 3 }}>
          <Grid item md={10} xs={12}>
            <div className="panel">
              <div className="panel-body">
                <form onSubmit={openChamado}>
                  <div>
                    <DadosContratante
                      empresas={empresas}
                      chamado={chamado}
                      setChamado={setChamado}
                    />

                    <DadosVaga
                      chamado={chamado}
                      setChamado={setChamado}
                      razoesContratacao={razoesContratacao}
                      disabledSubstituicao={false}
                    />
                  </div>

                  <Grid container spacing={{ xs: 3 }}>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <Beneficios
                        beneficios={beneficios}
                        removeBeneficio={removeBeneficio}
                        addBeneficio={addBeneficio}
                      />
                      {
                        chamado.isEmployeeReplace ?
                          <Employees
                            employees={employees}
                            remove={removeRecruitment}
                            add={addRecruitment}
                          />
                          : ''
                      }
                    </Grid>
                  </Grid>

                  <Grid container spacing={{ xs: 3 }}>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      {listaChamados.map((item: any, index: number) => (
                        <div className="chamadoTicketItem">
                          <div className="chamadoTicketItem_Title">
                            <div className="chamadoTicketItem_Title_text">2 - Auxiliar de faxina</div>
                            <div className="chamadoTicketItem_Title_actions">
                              <div>
                                <span onClick={() => handleToggleChamado(index)}>
                                  Expandir dados de vaga
                                  {item.open ? <span>A</span> : <span>V</span>}
                                </span>
                              </div>
                              <div>
                                <button onClick={() => handleCopyChamado(index)}>copy</button>
                              </div>
                              <div>
                                <button onClick={() => handleTrashChamado(index)}>trash</button>
                              </div>
                            </div>
                          </div>
                          <div className={`chamadoTicketItem_Content ${item.open ? 'open' : ''}`}>
                            {item.open &&
                              <div>
                                content
                              </div>
                            }
                          </div>
                        </div>
                      ))}

                    </Grid>
                  </Grid>

                  <div className="flex justify-end mt-30 mb-30">
                    <Button
                      variant="contained"
                      type="submit"
                      size="small"
                      color="secondary"
                      disabled={promiseInProgress}
                    >
                      Abrir Chamado
                    </Button>
                  </div>

                </form>
              </div>
            </div>
          </Grid>
        </Grid>

      </div>

    </div>
  )
}

export default ChamadoNew
