import { useEffect, useState } from 'react'

import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { useNotification } from '../../../hooks/useNotification.js'
import { useAuth } from '../../../hooks/useAuth'
import { IChamado, IClienteFilial, IContrato, IEmpresa } from '../../../interfaces/index'
import services from '../../../services/index.js'

interface IDadosContratante {
  empresas: IEmpresa[] | []
  chamado: IChamado
  setChamado: any
  ticketStatus?: string
}

function DadosContratante(props: IDadosContratante) {
  const {
    empresas, chamado, setChamado, ticketStatus
  } = props
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [clientes, setClientes] = useState<IClienteFilial[] | []>([])
  const [contratos, setContratos] = useState<IContrato[] | []>([])
  const [blockEdicao, setBlockEdicao] = useState<boolean>(true)
  const [cnpjFilial, setCnpjFilial] = useState<string | undefined>('')

  const handleSelectEmpresa = async (empresaId: string | number) => {
    let isTemporary = false
    let isEmployeeReplace = false
    setChamado((prevState: any) => ({
      ...prevState,
      fastRhEmpresaId: empresaId,
      fastRhEmpresaFilialId: '',
      fastRhContratoId: '',
      isTemporary: isTemporary,
      isEmployeeReplace: isEmployeeReplace
    }))
  }

  const copyText = () => {
    navigator.clipboard.writeText(cnpjFilial ? cnpjFilial : '')
    notify('CNPJ Copiado', { variant: 'success' })
  }

  const handleSelectContrato = async (contratoId: string | number) => {
    let isTemporary = false
    const contratoObjSelectd = contratos.filter(item => item.id === contratoId)
    if (contratoObjSelectd.length) {
      let contratoDescricao = contratoObjSelectd[0].servico?.nome || ''
      if (
        contratoDescricao.toUpperCase().includes('TEMPORARIO') ||
        contratoDescricao.toUpperCase().includes('TEMPORÁRIO')
      ) {
        isTemporary = true
      }
    }
    setChamado((prevState: any) => ({
      ...prevState,
      fastRhContratoId: contratoId,
      isTemporary: isTemporary,
      duration: isTemporary ? prevState.duration : null,
      recruitmentDemandHiringReasonId: isTemporary ? prevState.recruitmentDemandHiringReasonId : null
    }))
  }

  useEffect(() => {
    if (chamado?.fastRhEmpresaId && empresas?.length) {
      const indexItem = services.utils.findIndexInArray(chamado.fastRhEmpresaId, empresas, 'id')
      setClientes(empresas[indexItem]?.filiais)
      setContratos(empresas[indexItem]?.contratos)
    }
  }, [empresas, chamado.fastRhEmpresaId])

  useEffect(() => {
    if (chamado?.fastRhEmpresaFilialId && clientes?.length) {
      const indexItem = services.utils.findIndexInArray(chamado.fastRhEmpresaFilialId, clientes, 'id')
      setCnpjFilial(clientes[indexItem]?.cnpj)
    } else {
      setCnpjFilial('')
    }
  }, [clientes, chamado.fastRhEmpresaFilialId])

  useEffect(() => {
    if (!ticketStatus) {
      setBlockEdicao(false)
      return
    }
    if (
      (
        userRoles.includes('ticket_res_update') ||
        userRoles.includes('ticket_res_update_any')
      ) &&
      (ticketStatus === 'IN_PROGRESS' || ticketStatus === 'IN_REVISION')
    ) {
      setBlockEdicao(false)
      return
    }
    setBlockEdicao(true)
  }, [ticketStatus])

  return (
    <Grid container spacing={{ xs: 3 }}>
      <Grid item xs={12}>
        <p className="m-0 ">
          Dados do contratante
        </p>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <FormControl>
          <InputLabel shrink>Empresa</InputLabel>
          <Select
            fullWidth
            required
            notched
            size="small"
            label="Empresa"
            value={chamado.fastRhEmpresaId ? Number(chamado.fastRhEmpresaId) : ''}
            onChange={e =>
              handleSelectEmpresa(e.target.value)
            }
            disabled={!!ticketStatus}
          >
            <MenuItem value={''}>
              Selecione
            </MenuItem>
            {
              empresas.map(empresa => (
                <MenuItem value={empresa.id} key={empresa.id}>
                  {empresa.nome}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6}>
        <FormControl>
          <InputLabel shrink>Filial</InputLabel>
          <Select
            fullWidth
            required
            notched
            size="small"
            label="Filial"
            value={chamado.fastRhEmpresaFilialId ? Number(chamado.fastRhEmpresaFilialId) : ''}
            onChange={e =>
              setChamado((prevState: any) => ({ ...prevState, fastRhEmpresaFilialId: e.target.value }))
            }
            disabled={!!ticketStatus || !chamado.fastRhEmpresaId}
          >
            <MenuItem value="">
              Selecione
            </MenuItem>
            {
              clientes.map(clienteFilial => (
                <MenuItem value={clienteFilial.id} key={clienteFilial.id}>
                  {clienteFilial.nomeFantasia}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6}>
        <TextField
          fullWidth
          size="small"
          label="CNPJ Filial"
          value={cnpjFilial ? cnpjFilial : ''}
          disabled={true}
          InputProps={{
            endAdornment: (
              <Tooltip title="Copiar CNPJ">
                <InputAdornment position="end">
                  <IconButton
                    style={{ padding: '2px', marginRight: '-8px' }}
                    className="btn-purple"
                    onClick={copyText}
                    edge="end"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              </Tooltip>
            )
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={6}>
        <TextField
          fullWidth
          required
          size="small"
          label="Gestor Responsavel"
          inputProps={{ maxLength: 120 }}
          InputLabelProps={{ shrink: true }}
          value={chamado.customerManager ? chamado.customerManager : ''}
          onChange={e =>
            setChamado((prevState: any) => ({ ...prevState, customerManager: e.target.value }))
          }
          disabled={blockEdicao}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <FormControl>
          <InputLabel shrink>Contrato</InputLabel>
          <Select
            fullWidth
            required
            notched
            size="small"
            label="Contrato"
            value={chamado.fastRhContratoId ? Number(chamado.fastRhContratoId) : ''}
            onChange={e =>
              handleSelectContrato(e.target.value)
            }
            disabled={!!ticketStatus || !chamado.fastRhEmpresaId}
          >
            <MenuItem value="">
              Selecione
            </MenuItem>
            {/* {
              contratos.map(contrato => (
                <MenuItem value={contrato.id} key={contrato.id} >
                  {contrato.servico?.nome}
                </MenuItem>
              ))
            } */}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <TextField
          fullWidth
          size="small"
          label="Centro de custo"
          inputProps={{ maxLength: 120 }}
          InputLabelProps={{ shrink: true }}
          value={chamado.costCenter ? chamado.costCenter : ''}
          onChange={e =>
            setChamado((prevState: any) => ({ ...prevState, costCenter: e.target.value }))
          }
          disabled={blockEdicao}
        />
      </Grid>
    </Grid>
  )
}

export default DadosContratante
