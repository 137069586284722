import React, { useCallback, useEffect, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import { Button, FormControlLabel, Grid, MenuItem, Select, Switch, TextField, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import TabelaUsuarios from './TabelaUsuarios'
import { IBusca, ITeam, IUser } from '../../interfaces/index'
import { PageTitle } from '../../components/PageElements'
import Modal from '../../components/Modal'
import { useNotification } from '../../hooks/useNotification.js'
import SubMenuAdmin from '../../components/SubMenuAdmin/SubMenuUsuarios'
import { useAuth } from '../../hooks/useAuth'
import services from '../../services/index.js'

import './style.scss'

enum EquipeMode {
  novo,
  edicao
}

enum UserMode {
  novo,
  edicao
}

function Usuarios() {
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [modalUsuario, setModalUsuario] = useState(false)
  const [modalNovaEquipe, setModalNovaEquipe] = useState(false)
  const [teams, setTeams] = useState<ITeam[] | []>([])
  const [usuarios, setUsuarios] = useState<IUser[] | []>([])
  const [confirmarSenha, setConfirmarSenha] = useState('')
  const [userActive, setUserActive] = useState<IUser>(
    {
      name: '',
      phone: '',
      username: '',
      email: '',
      company: '',
      internal: true,
      role: '',
      password: ''
    }
  )
  const [nomeEquipe, setNomeEquipe] = useState('')
  const [equipeMode, setEquipeMode] = useState<EquipeMode>(EquipeMode.novo)
  const [idEquipeEdicao, setIdEquipeEdicao] = useState<any>()
  const [nameEquipeAtiva, setNameEquipeAtiva] = useState<string>('')
  const [idEquipeAtiva, setIdEquipeAtiva] = useState<any>()
  const [modalRemoverEquipe, setModalRemoverEquipe] = useState<boolean>(false)
  const [userMode, setUserMode] = useState<UserMode>(UserMode.novo)
  const [totalRegistros, setTotalRegistros] = useState(0)

  // paginacao
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(0)

  const [objBusca, setObjBusca] = useState<any>(
    [
      {
        label: 'checkbox',
        value: false,
        searchRef: 'res',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'boolean'
      },
      {
        label: 'Login',
        value: '',
        searchRef: 'username',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Nome',
        value: '',
        searchRef: 'FullName',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Email',
        value: '',
        searchRef: 'Email',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: null,
        type: 'string'
      },
      {
        label: 'Status',
        value: '',
        searchRef: 'Active',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: [
          { id: 'true', descrition: 'Ativo' },
          { id: 'false', descrition: 'Inativo' }
        ],
        type: 'string'
      },
      {
        label: 'Interno',
        value: '',
        searchRef: 'Internal',
        inputVisible: false,
        showOrderby: true,
        showSearch: true,
        selectOptions: [
          { id: 'true', descrition: 'Interno' },
          { id: 'false', descrition: 'Externo' }
        ],
        type: 'string'
      },
      {
        label: 'Ações',
        value: '',
        searchRef: 'acao',
        inputVisible: false,
        showOrderby: false,
        showSearch: false,
        selectOptions: null,
        type: 'string'
      }
    ]
  )

  const getTeams = useCallback(async () => {
    try {
      let res: any
      if (userRoles.includes('bi_teams_read_any')) {
        res = await trackPromise(services.api.getTeamsAll())
      } else {
        res = await trackPromise(services.api.getTeamsAllRelated())
      }
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Equipes'
        )
      }

      setTeams(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserActive(prevState => ({
      ...prevState,
      active: e.target.checked
    }))
  }

  const handleChangeOnLogin = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserActive(prevState => ({
      ...prevState,
      updatePasswordOnLogin: e.target.checked
    }))
  }

  const getUsers = useCallback(async (_objBusca?: IBusca) => {
    try {
      const pagina = _objBusca?.page ? _objBusca?.page : currentPage
      const tamanhoPagina = _objBusca?.pageSize ? _objBusca?.pageSize : pageSize

      let paramns: any = services.utils.formatObjBusca(_objBusca?.busca ? _objBusca.busca : objBusca)
      paramns.orderByColumn = _objBusca?.orderByColumn ? _objBusca?.orderByColumn : ''
      paramns.desc = _objBusca?.desc ? _objBusca?.desc : ''

      let res: any
      if (userRoles.includes('common_users_read_any')) {
        res = await trackPromise(services.api.getUsersAll(paramns, pagina, tamanhoPagina))
      } else {
        res = await trackPromise(services.api.getUsersAllRelated(paramns, pagina, tamanhoPagina))
      }
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Usuários'
        )
      }
      if (userRoles.includes('common_users_read_any')) {
        setUsuarios(res.data.data)
      } else {
        setUsuarios(res.data)
      }
      setTotalRegistros(res.data.totalItems)
      setCurrentPage(res.data.pageIndex)
      setTotalPages(res.data.totalPages)
      setPageSize(res.data.pageSize)
      setTotalCount(res.data.totalCount)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getUsersByEquipe = useCallback(async (_objBusca?: IBusca, idEquipe?: number) => {
    try {
      const pagina = _objBusca?.page ? _objBusca?.page : currentPage
      const tamanhoPagina = _objBusca?.pageSize ? _objBusca?.pageSize : pageSize

      let paramns: any = services.utils.formatObjBusca(_objBusca?.busca ? _objBusca.busca : objBusca)
      paramns.orderByColumn = _objBusca?.orderByColumn ? _objBusca?.orderByColumn : ''
      paramns.desc = _objBusca?.desc ? _objBusca?.desc : ''

      const res = await trackPromise(services.api.getUsersByEquipe(paramns, pagina, idEquipe, tamanhoPagina))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Usuários dessa equipe'
        )
      }
      setIdEquipeAtiva(idEquipe)
      setUsuarios(res.data.data)
      setTotalRegistros(res.data.totalItems)
      setCurrentPage(res.data.pageIndex)
      setTotalPages(res.data.totalPages)
      setPageSize(res.data.pageSize)
      setTotalCount(res.data.totalCount)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePagination = useCallback(async (newPage: number, busca: string, itensPerPage: number, idParent?: number) => {
    if (idParent) {
      getUsersByEquipe({ page: newPage, pageSize: itensPerPage, busca: objBusca }, idParent)
    } else {
      getUsers({ page: newPage, pageSize: itensPerPage, busca: objBusca })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBusca = useCallback(async (busca: string, sizePage: number, coluna?: string, desc?: boolean, idParent?: number) => {
    if (idParent) {
      getUsersByEquipe({ page: 1, pageSize: sizePage, busca: busca, orderByColumn: coluna, desc: desc }, idParent)
    } else {
      getUsers({ page: 1, pageSize: sizePage, busca: busca, orderByColumn: coluna, desc: desc })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNewUser = useCallback(async () => {
    setUserActive(
      {
        id: 0,
        name: '',
        phone: '',
        username: '',
        email: '',
        company: '',
        internal: true,
        role: '',
        expirationDays: '',
        updatePasswordOnLogin: false,
        password: ''
      }
    )
    setUserMode(UserMode.novo)
    setConfirmarSenha('')
    setModalUsuario(true)
  }, [])

  const handleChangeInternal = (value: string | boolean) => {
    let newValue: any = ''
    if (value === 'true' || value === true) {
      newValue = true
    }
    if (value === 'false' || value === false) {
      newValue = false
    }
    setUserActive(prevState => ({
      ...prevState,
      internal: newValue
    }))
  }

  const handleEditUser = useCallback(async (user: IUser) => {
    setUserActive(
      {
        id: user.id,
        name: user.name,
        phone: user.phone,
        active: user.active,
        username: user.username,
        email: user.email,
        company: user.company,
        internal: user.internal,
        role: user.role,
        expirationDays: user.expirationDays,
        updatePasswordOnLogin: user.updatePasswordOnLogin,
        password: ''
      }
    )
    setUserMode(UserMode.edicao)
    setModalUsuario(true)
  }, [])

  const handleNewEquipe = useCallback(async () => {
    setNomeEquipe('')
    setIdEquipeEdicao('')
    setEquipeMode(EquipeMode.novo)
    setModalNovaEquipe(true)
  }, [])

  const handleSelectEquipe = useCallback(async (equipe: ITeam, updateTimes?: boolean | undefined) => {
    setNameEquipeAtiva(equipe.name)
    if (equipe?.id) {
      getUsersByEquipe({}, equipe.id)
    } else {
      setIdEquipeAtiva(0)
      getUsers()
    }
    if (updateTimes) {
      getTeams()
    }
  }, [getUsersByEquipe, getTeams, getUsers])


  const saveUpdateUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    function reloadNewUser(idNewUser: any) {
      setModalUsuario(false)
      setConfirmarSenha('')
      if (idEquipeAtiva && idNewUser) {
        vicularUsuarioComEquipe(idNewUser, idEquipeAtiva)
      } else {
        getUsers()
      }
    }

    async function criarUsuario() {
      try {
        const res = await trackPromise(services.api.criarUsuario(userActive))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível salvar Usuário'
          )
        }
        notify('Usuário salvo com sucesso', { variant: 'success' })
        reloadNewUser(res.data.id)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

    async function editarUsuario() {
      try {
        const res = await trackPromise(services.api.editUsuario(userActive))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível editar Usuário'
          )
        }
        notify('Usuário editado com sucesso', { variant: 'success' })

        setModalUsuario(false)
        setUsuarios(
          usuarios.map(item =>
            item.id === userActive.id
              ? {
                ...item,
                username: userActive.username,
                name: userActive.name,
                phone: userActive.phone,
                email: userActive.email,
                company: userActive.company,
                active: userActive.active,
                internal: userActive.internal,
                role: userActive.role,
                expirationDays: userActive.expirationDays,
                updatePasswordOnLogin: userActive.updatePasswordOnLogin
              }
              : item
          ))
        setConfirmarSenha('')
        getTeams()
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

    if (userActive.id) {
      // edição
      editarUsuario()
    } else {
      // novo
      criarUsuario()
    }

  }

  const vicularUsuarioComEquipe = useCallback(async (userId: any, equipeId: any) => {
    try {
      const res = await trackPromise(services.api.vicularUsuarioComEquipe(userId, equipeId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível vincular usuário'
        )
      }
      getUsersByEquipe({}, equipeId)
      getTeams()
      // notify('Usuário vinculado com sucesso', { variant: 'success' })
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const criarEquipe = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (equipeMode === EquipeMode.edicao) {
      try {
        if (!idEquipeEdicao) {
          throw new Error(
            'Não foi possível Editar a Equipe, id não localizado'
          )
        }
        const res = await trackPromise(services.api.editarEquipe(nomeEquipe, idEquipeEdicao))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível Editar a Equipe'
          )
        }
        notify('Equipe Alterada com Sucesso', { variant: 'success' })
        setModalNovaEquipe(false)
        setNomeEquipe('')
        setIdEquipeEdicao('')
        setEquipeMode(EquipeMode.novo)
        getTeams()
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    } else {
      try {
        const res = await trackPromise(services.api.criarEquipe(nomeEquipe))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível Criar Equipe'
          )
        }
        notify('Equipe Criada com Sucesso', { variant: 'success' })
        setModalNovaEquipe(false)
        setNomeEquipe('')
        setIdEquipeEdicao('')
        getTeams()
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }
  }

  const editEquipe = useCallback(async (equipe: ITeam) => {
    setModalNovaEquipe(true)
    setNomeEquipe(equipe.name)
    setIdEquipeEdicao(equipe.id)
    setEquipeMode(EquipeMode.edicao)
  }, [])

  const removeEquipe = useCallback(async (equipe: ITeam) => {
    setModalRemoverEquipe(true)
    setNomeEquipe(equipe.name)
    setIdEquipeEdicao(equipe.id)
  }, [])

  const removerEquipe = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      if (!idEquipeEdicao) {
        throw new Error(
          'Id não localizado'
        )
      }
      const res = await trackPromise(services.api.deleteEquipe(idEquipeEdicao))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível Deletar a Equipe'
        )
      }
      notify('Equipe Deletada com Sucesso', { variant: 'success' })
      setModalRemoverEquipe(false)
      setIdEquipeEdicao('')
      getTeams()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const handleRemoverUsuario = async () => {
    if (idEquipeAtiva) {
      getUsersByEquipe({}, idEquipeAtiva)
    } else {
      getUsers()
    }
    getTeams()
  }

  useEffect(() => {
    getTeams()
    getUsers()
  }, [getTeams, getUsers])

  //style={{maxWidth: 'calc(100% - 300px)'}}

  return (
    <div className="wrapper-container-content flex flex-nowrap">
      <SubMenuAdmin
        mainList={teams}
        totalRegistros={totalRegistros}
        actionEdit={editEquipe}
        actionRemove={removeEquipe}
        actionSelect={handleSelectEquipe}
        title={'Equipes'}
        subTitle={'EQUIPES'}
        actionAdd={handleNewEquipe}
        idItemAtivo={idEquipeAtiva}
        classNameContainer={'float-left'}
      />
      <div className="wrapper-page-content wrapper-page-divided" id="wrapper-page-content">
        <PageTitle subText={'Crie e gerencie usuários da sua equipe'}>
          <div>{`Usuários > ${nameEquipeAtiva ? nameEquipeAtiva : 'Todos'}`}</div>
        </PageTitle>

        <Grid container justifyContent="center" spacing={{ xs: 3 }}>
          <Grid item md={12} xs={12}>
            <div className="panel">
              <div className="panel-body">
                <TabelaUsuarios
                  teams={teams}
                  mainList={usuarios}
                  idParentAtivo={idEquipeAtiva}
                  newRegister={handleNewUser}
                  editRegistro={handleEditUser}
                  handleRemoverRegistro={handleRemoverUsuario}
                  handlePagination={handlePagination}
                  handleBusca={handleBusca}
                  objBusca={objBusca}
                  setObjBusca={setObjBusca}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  pageSize={pageSize}
                  totalCount={totalCount}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* modal usuário */}
      <Modal
        size="sm"
        open={modalUsuario}
        close={() => setModalUsuario(false)}
        titulo={userMode === UserMode.edicao ? 'Edição de usuário' : 'Novo usuário para a equipe'}
      >
        <form onSubmit={saveUpdateUser} autoComplete="off">
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              <p className="m-0">
                Dados
              </p>
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Nome</span>
                <Tooltip title="O nome do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                required
                size="small"
                inputProps={{ maxLength: 400 }}
                value={userActive.name}
                onChange={e =>
                  setUserActive(prevState => ({
                    ...prevState,
                    name: e.target.value
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>E-mail</span>
                <Tooltip title="O e-mail do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                required
                size="small"
                type="email"
                inputProps={{ maxLength: 400 }}
                value={userActive.email}
                onChange={e =>
                  setUserActive(prevState => ({
                    ...prevState,
                    email: e.target.value
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Telefone</span>
                <Tooltip title="O Telefone do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                size="small"
                inputProps={{ maxLength: 400 }}
                value={services.mask.phone(userActive.phone)}
                onChange={e =>
                  setUserActive(prevState => ({
                    ...prevState,
                    phone: services.mask.unMaskPhone(e.target.value)
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Login</span>
                <Tooltip title="O UserName do usuário">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                required
                size="small"
                inputProps={{ maxLength: 400 }}
                value={userActive.username}
                onChange={e =>
                  setUserActive(prevState => ({
                    ...prevState,
                    username: e.target.value
                  }))
                }
              />
            </Grid>

            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Internal</span>
                <Tooltip title="Empresa que o grupo pertence">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <Select
                fullWidth
                required
                size="small"
                value={userActive.internal}
                onChange={e => handleChangeInternal(e.target.value)}
              >
                <MenuItem value="">
                  Selecione
                </MenuItem>
                <MenuItem value="true">
                  Interno
                </MenuItem>
                <MenuItem value="false">
                  Externo
                </MenuItem>
              </Select>
            </Grid>

            {/* { === 'ADMINISTRATOR' &&
              <Grid item xs={12}>
                <label className="labelAllis">
                  <span>Empresa</span>
                  <Tooltip title="O Empresa do usuário">
                    <InfoOutlinedIcon />
                  </Tooltip>
                </label>
                <TextField
                  fullWidth
                  required
                  size='small'
                  inputProps={{ maxLength: 400 }}
                  value={userActive.company}
                  onChange={e => 
                    setUserActive(prevState => ({
                      ...prevState,
                      company: e.target.value
                    }))
                  }
                />
              </Grid>
            }
            { === 'ADMINISTRATOR' &&
              <Grid item xs={12}>
                <label className="labelAllis">
                  <span>Permissão de Usuário</span>
                  <Tooltip title="Permições que o usuário terá">
                    <InfoOutlinedIcon />
                  </Tooltip>
                </label>
                <Select
                  fullWidth
                  required
                  size="small"
                  value={userActive.role}
                  onChange={e =>
                    setUserActive(prevState => ({
                      ...prevState,
                      role: e.target.value
                    }))
                  }
                >
                  <MenuItem value="READ_ONLY">
                    Ler
                  </MenuItem>
                  <MenuItem value="CUSTOMER_ADMINISTRATOR">
                    Admin_Cliente
                  </MenuItem>
                  <MenuItem value="ADMINISTRATOR">
                    Admin
                  </MenuItem>
                </Select>
              </Grid>
            } */}
            {userMode === UserMode.edicao &&
              <Grid item xs={12}>
                <FormControlLabel control={
                  <Switch
                    checked={userActive.active}
                    onChange={handleChangeActive}
                  />
                } label={userActive.active ? 'Ativo' : 'Desativado'} />
              </Grid>
            }

            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Tempo de Reativação de Acesso</span>
                <Tooltip title="Tempo de Reativação de Acesso em dias">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                size="small"
                inputProps={{ maxLength: 3 }}
                value={services.mask.number(userActive.expirationDays)}
                onChange={e =>
                  setUserActive(prevState => ({
                    ...prevState,
                    expirationDays: e.target.value
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel control={
                <Switch
                  checked={userActive.updatePasswordOnLogin}
                  onChange={handleChangeOnLogin}
                />
              } label={'Mudança de senha no próximo acesso'} />
            </Grid>

            {userMode === UserMode.novo &&
              <>
                <Grid item xs={12}>
                  <label className="labelAllis">
                    <span>Senha</span>
                    <Tooltip title="O Senha do usuário">
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </label>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    type="password"
                    inputProps={{
                      maxLength: 60, autoComplete: 'new-password', form: {
                        autocomplete: 'off'
                      }
                    }}
                    value={userActive.password}
                    onChange={e =>
                      setUserActive(prevState => ({
                        ...prevState,
                        password: e.target.value
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <label className="labelAllis">
                    <span>Confirmar Senha</span>
                  </label>
                  <TextField
                    fullWidth
                    required
                    size="small"
                    type="password"
                    inputProps={{
                      maxLength: 60, autoComplete: 'new-password', form: {
                        autocomplete: 'off'
                      }
                    }}
                    value={confirmarSenha}
                    onChange={e =>
                      setConfirmarSenha(prevState => e.target.value)
                    }
                  />
                </Grid>
              </>
            }

            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size="small"
                color="inherit"
                onClick={() => setModalUsuario(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size="small"
                color="secondary"
                disabled={promiseInProgress}
              >
                salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

      {/* modal equipe criar / edição */}
      <Modal
        size="sm"
        open={modalNovaEquipe}
        close={() => setModalNovaEquipe(false)}
        titulo={equipeMode === EquipeMode.edicao ? 'Edição da Equipe' : 'Nova Equipe'}
      >
        <form onSubmit={criarEquipe}>
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              <p className="m-0">
                Dados
              </p>
            </Grid>
            <Grid item xs={12}>
              <label className="labelAllis">
                <span>Nome</span>
                <Tooltip title="Nome do time">
                  <InfoOutlinedIcon />
                </Tooltip>
              </label>
              <TextField
                fullWidth
                required
                size="small"
                inputProps={{ maxLength: 120 }}
                value={nomeEquipe}
                onChange={e =>
                  setNomeEquipe(prevState => e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size="small"
                color="inherit"
                onClick={() => setModalNovaEquipe(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size="small"
                color="secondary"
                disabled={promiseInProgress}
              >
                salvar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>

      {/* modal equipe remover */}
      <Modal
        size="sm"
        open={modalRemoverEquipe}
        close={() => setModalRemoverEquipe(false)}
        titulo={'Remover Equipe'}
      >
        <form onSubmit={removerEquipe}>
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              <p className="center">
                Deseja mesmo remover os itens selecionados? <br />
                Esta ação não poderá ser desfeita
              </p>
            </Grid>
            <Grid item xs={12} className="flex justify-end gap-6">
              <Button
                variant="contained"
                type="button"
                size="small"
                color="inherit"
                onClick={() => setModalRemoverEquipe(false)}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                size="small"
                color="error"
                disabled={promiseInProgress}
              >
                remover
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>


    </div>
  )
}

export default Usuarios
