import { useState, useEffect } from 'react'
import {
  FormControl,
  TextField,
  InputAdornment 
} from '@mui/material'

import './style.scss'

const formatarValorToString = v => {
  if (v) {
    v = Number(v).toFixed(2)
    v = formatarValor(v)
  }
  return v
}

const formatarValor = v => {
  v = v.replace(/[^\d]/g, '') // Remove tudo o que não é dígito
  v = v.toString().replace(/\./g, '') // Remove todos os .
  v = v.toString().replace(/\,/g, '') // Remove todos as .

  // trata valores com 0 a esquerda
  if (v.length === 1) return `0,0${v}`
  if (parseInt(v) === 0) return '0,00'
  if (v.length === 4) {
    v = parseInt(v).toString()
    if (v.length === 1) v = `00${v}`
    if (v.length === 2) v = `0${v}`
  }
  if (v.length === 5) {
    v = parseInt(v).toString()
    if (v.length === 2) v = `0${v}`
  }

  v = v.replace(/(\d)(\d{14})$/, '$1.$2') // coloca o pornto dos trilhoes
  v = v.replace(/(\d)(\d{11})$/, '$1.$2') // coloca o ponto dos bilhoes
  v = v.replace(/(\d)(\d{8})$/, '$1.$2') // coloca o ponto dos milhoes
  v = v.replace(/(\d)(\d{5})$/, '$1.$2') // coloca o ponto dos milhares
  v = v.replace(/(\d)(\d{2})$/, '$1,$2') // coloca , nos centavos antes dos 2 últimos dígitos

  return v
}

const InputCurrency = ({
  value,
  index,
  id,
  label,
  change,
  classInput,
  classContainer,
  maxlengh = 11,
  disabled = false
}) => {
  const [inputValue, setInputValue] = useState(formatarValorToString(value))

  const formatarValorToNumber = v => {
    if (v) {
      v = v.toString().replace(/\./g, '')
      v = v.toString().replace(',', '.')

      const splitCentavos = v.split('.')
      if (splitCentavos.length > 1) {
        if (parseInt(splitCentavos[1]) === 0) {
          return splitCentavos[0]
        }
      }
    }
    return v
  }

  const changeValor = v => {
    change(formatarValorToNumber(v))
  }

  useEffect(() => {
    setInputValue(formatarValorToString(value))
  }, [value])

  return (
    <FormControl
      className={`inputCurrency_notNative_container ${classContainer || ''}`}
    >
      <TextField
        id={`${id}${index ? `_${index}` : ''}`}
        data-testid={`${id}${index ? `_${index}` : ''}`}
        className={classInput || ''}
        size="small"
        label={label || ''}
        value={inputValue}
        inputProps={{ maxLength: maxlengh }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>
        }}
        onChange={event =>
          setInputValue(formatarValor(event.target.value.toString()))
        }
        onBlur={e => changeValor(e.target.value)}
        disabled={disabled}
      />
    </FormControl>
  )
}

export default InputCurrency
