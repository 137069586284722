import { useCallback, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { debounce } from 'lodash'

import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

import Modal from '../../../components/Modal'
import { useNotification } from '../../../hooks/useNotification.js'
import services from '../../../services/index.js'
import { useAuth } from '../../../hooks/useAuth'

interface IModelComponent {
  ticketId: number | undefined
  status: string | undefined
}

function Observer(props: IModelComponent) {
  const { ticketId, status } = props
  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  const [anchorElBuscaUserDisponivel, setAnchorElBuscaUserDisponivel] = useState<null | HTMLElement>(null)
  const openBuscaUserDisponivel = Boolean(anchorElBuscaUserDisponivel)
  const handleCloseBuscaUserDisponivel = () => {
    setAnchorElBuscaUserDisponivel(null)
  }

  const [searchUserDisponivel, setSearchUserDisponivel] = useState('')
  const [colunaBuscaUserDisponivel, setColunaBuscaUserDisponivel] = useState<string>('fullname')

  const [modal, setModal] = useState<boolean>(false)
  const [availableObservers, setAvailableObservers] = useState<any>(null)
  const [usuariosLista, setUsuariosLista] = useState<any>([])

  const handleClickBuscaUserDisponivel = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBuscaUserDisponivel(event.currentTarget)
  }

  const handleopen = async () => {
    setModal(true)
    carrgarObservadores()
    if (
      userRoles.includes('ticket_res_observers_create') ||
      userRoles.includes('ticket_res_observers_create_any')
    ) {
      handleBuscaUserDisponivel('')
    }
  }

  const handleChangeBuscaUserDisponivel = (busca: string) => {
    setSearchUserDisponivel(busca)
    handleBuscaDebounceUserDisponivel(busca)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounceUserDisponivel = useCallback(debounce((busca) => {
    handleBuscaUserDisponivel(busca)
  }, 800), [colunaBuscaUserDisponivel])

  const handleBuscaUserDisponivel = useCallback(async (busca: string) => {
    let paramns: any = {}
    paramns.filterBy = colunaBuscaUserDisponivel
    paramns.filterValue = busca

    try {
      const res = await trackPromise(services.api.getUsersAll(paramns, 1, 10))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar usuários disponiveis'
        )
      }
      setUsuariosLista(res.data.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colunaBuscaUserDisponivel])

  const carrgarObservadores = async () => {
    try {
      const res = await trackPromise(services.api.getObservers(ticketId))
      if (res.fail) {
        setAvailableObservers([])
        throw new Error(
          res.error || 'Não foi possível consultar os observadores'
        )
      }
      setAvailableObservers(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const addObserver = async (userItem: any, index: number) => {
    const findUser = availableObservers.filter((item: any) => item?.user?.id === userItem?.id)
    if (findUser.length) {
      notify('Este usuário já foi incluido', { variant: 'warning' })
      return
    }

    try {
      const res = await trackPromise(services.api.postObserver(ticketId, userItem.id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi atribuir atendente'
        )
      }
      notify('Observador adicionado com sucesso', { variant: 'success' })
      setUsuariosLista((prevState: any) => (
        [
          ...prevState.slice(0, index),
          ...prevState.slice(index + 1)
        ]
      ))
      carrgarObservadores()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const deleteObserver = async (item: any, index: number) => {
    try {
      const res = await trackPromise(services.api.deleteObserver(ticketId, item.userId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi remover atendente'
        )
      }
      notify('Observador removido com sucesso', { variant: 'success' })
      carrgarObservadores()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  return (
    <>
      {ticketId &&
        <Button
          variant="contained"
          type="button"
          size="small"
          disabled={promiseInProgress}
          color="secondary"
          onClick={handleopen}
        >
          Ver Observadores
        </Button>
      }

      <Modal
        size="md"
        open={modal}
        close={() => setModal(false)}
        titulo={'Observadores'}
      >
        <div className="chamados_box">
          <Grid container spacing={{ xs: 3 }} className="sm-min-500">
            {
              (
                userRoles.includes('ticket_res_observers_create') ||
                userRoles.includes('ticket_res_observers_create_any')
              ) &&
              <Grid item xs={12}>
                {
                  status !== 'FINISHIED' && status !== 'CANCELED' &&
                  <TextField
                    size="small"
                    className="w-200"
                    label="Pesquisa"
                    placeholder="Pesquisa"
                    inputProps={{ maxLength: 50 }}
                    value={searchUserDisponivel}
                    onChange={e => handleChangeBuscaUserDisponivel(e.target.value)}
                    disabled={promiseInProgress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{
                              backgroundColor: '$cor-primary',
                              color: 'white',
                              padding: '2px',
                              marginRight: '-8px',
                              borderRadius: '4px'
                            }}
                            aria-label="toggle password visibility"
                            onClick={(e) => handleClickBuscaUserDisponivel(e)}
                            edge="end"
                          >
                            <FilterAltIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                }
              </Grid>
            }
            {
              (
                userRoles.includes('ticket_res_observers_create') ||
                userRoles.includes('ticket_res_observers_create_any')
              ) &&
              <>
                {
                  status !== 'FINISHIED' && status !== 'CANCELED' &&
                  <Grid item xs={12} sm={6}>
                    <div className="table-container">
                      <table className="table_styled">
                        <thead>
                        <tr>
                          <th>
                            <div>
                              <span className="th-title">Observadores disponíveis</span>
                            </div>
                          </th>
                          <th>
                            <div><span className="th-title"></span></div>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        {!usuariosLista &&
                          <tr>
                            <td colSpan={8}>
                              <div className="justify-center">loading...</div>
                            </td>
                          </tr>
                        }
                        {usuariosLista?.length === 0 &&
                          <tr>
                            <td colSpan={8}>
                              <div className="justify-center">Nenhum registro encontrado</div>
                            </td>
                          </tr>
                        }
                        {usuariosLista?.map((item: any, index: number) => (
                          <tr key={item.id}>
                            <td>
                              <div style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <span>{item?.name}</span>
                                <span style={{ fontSize: '12px' }}>{item?.email}</span>
                                <span style={{ fontSize: '12px' }}>{item?.company}</span>
                              </div>
                            </td>
                            <td>
                              <div className="justify-end">
                                <Button
                                  variant="contained" type="button" color="secondary"
                                  onClick={() => addObserver(item, index)}
                                  size="small"
                                  disabled={promiseInProgress}
                                >
                                  <span>Adicionar</span>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </Grid>
                }
              </>
            }
            <Grid item xs={12}
                  sm={
                    (
                      userRoles.includes('ticket_res_observers_create') ||
                      userRoles.includes('ticket_res_observers_create_any')
                    ) ? 6 : 12
                  }
            >
              <div className="table-container">
                <table className="table_styled">
                  <thead>
                  <tr>
                    <th>
                      <div>
                        <span className="th-title">Observadores vinculados</span>
                      </div>
                    </th>
                    {
                      (
                        userRoles.includes('ticket_res_observers_delete') ||
                        userRoles.includes('ticket_res_observers_delete_any')
                      ) &&
                      <th>
                        <div><span className="th-title"></span></div>
                      </th>
                    }
                  </tr>
                  </thead>
                  <tbody>
                  {!availableObservers &&
                    <tr>
                      <td colSpan={8}>
                        <div className="justify-center">loading...</div>
                      </td>
                    </tr>
                  }
                  {availableObservers?.length === 0 &&
                    <tr>
                      <td colSpan={8}>
                        <div className="justify-center">Nenhum registro encontrado</div>
                      </td>
                    </tr>
                  }
                  {availableObservers?.map((item: any, index: number) => (
                    <tr key={item.id}>
                      <td>
                        <div style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <span>{item?.user?.name}</span>
                          <span style={{ fontSize: '12px' }}>{item?.user?.email}</span>
                          <span style={{ fontSize: '12px' }}>{item?.user?.company}</span>
                        </div>
                      </td>
                      {
                        (
                          userRoles.includes('ticket_res_observers_delete') ||
                          userRoles.includes('ticket_res_observers_delete_any')
                        ) &&
                        <td>
                          {
                            status !== 'FINISHIED' && status !== 'CANCELED' &&
                            <div className="justify-end">
                              <Button
                                variant="contained" type="button" color="secondary"
                                onClick={() => deleteObserver(item, index)}
                                size="small"
                                disabled={promiseInProgress}
                              >
                                <span>Remover</span>
                              </Button>
                            </div>
                          }
                        </td>
                      }
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </Grid>


          </Grid>

        </div>
      </Modal>


      {/* menu opções busca user disponivel*/}
      <Menu
        anchorEl={anchorElBuscaUserDisponivel}
        open={openBuscaUserDisponivel}
        onClose={handleCloseBuscaUserDisponivel}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))'
          }
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <div style={{ padding: '2px 8px', fontSize: '14px' }}>
            Buscar por
          </div>
          <div style={{ padding: '2px 8px' }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="tipoBuscaColunaBusca-label"
                name="tipoBuscaColunaBusca"
                onChange={e => setColunaBuscaUserDisponivel(e.target.value)}
                value={colunaBuscaUserDisponivel}
              >
                <FormControlLabel value="fullname" control={<Radio />} label="Nome" />
                <FormControlLabel value="username" control={<Radio />} label="Login" />
                <FormControlLabel value="company" control={<Radio />} label="Empresa" />
                <FormControlLabel value="email" control={<Radio />} label="Email" />
                <FormControlLabel value="id" control={<Radio />} label="Id" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

      </Menu>
    </>
  )
}

export default Observer
