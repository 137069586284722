import { usePromiseTracker } from 'react-promise-tracker'
import './style.scss'

interface IEstOverview {
  onSelect: any
  type: string
  value?: number
  active?: string
}

const EstOverview = (props: IEstOverview) => {
  const { value, onSelect, active, type } = props
  const { promiseInProgress } = usePromiseTracker()

  const handleSelect = (type: string) => {
    if (!promiseInProgress) {
      onSelect(type)
    }
  }

  return (
    <div className={`est_totais_item ${type} ${active === type ? 'active' : ''}`} onClick={() => handleSelect(type)}>
      <div className="est_totais_value">{value}</div>
      <div className="est_totais_label">
        {type === 'ALL' && 'Todos'}
        {type === 'CPF' && 'CPFs'}
        {type === 'CNPJ' && 'CNPJs'}
        {type === 'BAIXADOS' && 'CNPJs baixados'}
        {type === 'HIGIENIZADOS' && 'Higienizados'}
        {type === 'PARA_ATUALIZAR' && 'Para atualizar'}
      </div>
    </div>
  )
}

export default EstOverview
