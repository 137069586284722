import React, { useCallback, useEffect, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import Cookies from 'js-cookie'

import { debounce } from 'lodash'
import moment from 'moment'

import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Tooltip
} from '@mui/material'


import MoodBadIcon from '@mui/icons-material/MoodBad'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import CancelIcon from '@mui/icons-material/Cancel'
import SearchIcon from '@mui/icons-material/Search'
import SettingsIcon from '@mui/icons-material/Settings'
import RefreshIcon from '@mui/icons-material/Refresh'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import { ConfigProvider, DatePicker } from 'antd'
import type { Dayjs } from 'dayjs'
import 'dayjs/locale/pt-br'
import locale from 'antd/locale/pt_BR'

import { useNotification } from '../../hooks/useNotification.js'
import Modal from '../../components/Modal'
import { Pagination, TabelaSearchColumn } from '../../components/Table'
import { ITicket } from '../../interfaces/index'
import services from '../../services/index.js'
import { useAuth } from '../../hooks/useAuth'

interface ITableaUsuario {
  mainList: ITicket[] | [] | null | undefined
  editRegistro: any
  handlePagination: any
  handleBusca: any
  newRegister: any
  currentPage: number
  totalPages: number
  pageSize: number
  totalCount: number
  overviewActive: string
  objBusca: any
  setObjBusca: any
  reloadOverview: any
}

const dateFormat = 'DD/MM/YYYY'
const { RangePicker } = DatePicker
type RangeValue = [Dayjs | null, Dayjs | null] | null

const tablesVisibles = [
  'Id', 'Local', 'CNPJ do cliente', 'CR',
  'Cliente', 'E-mail Cliente', 'Nome Atendente', 'E-mail Atendente',
  'Filial', 'Função', 'Quantidade', 'Aberto em', 'Última atualização',
  'Data de início', 'Nota', 'Status', 'Ações'
]

function TabelaHeaderTh(props: any) {
  const { indexRef, visibleColumn, paramns, setSearchChange, ordeby } = props
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  return (
    <>
      {visibleColumn &&
        <>
          {
            (
              paramns.label === 'Ações' &&
              (
                userRoles.includes('ticket_res_update') ||
                userRoles.includes('ticket_res_update_any') ||
                userRoles.includes('ticket_res_read') ||
                userRoles.includes('ticket_res_read_any') ||
                userRoles.includes('ticket_update') ||
                userRoles.includes('ticket_update_any')
              )
            ) || paramns.label !== 'Ações' ?
              <th>
                <div>
                  <div>
                    {
                      paramns?.showOrderby &&
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <IconButton
                          size="small"
                          onClick={() => ordeby(false, paramns.searchRef)}
                          style={{ padding: '2px' }}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{ fontSize: '12px' }} />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => ordeby(true, paramns.searchRef)}
                          style={{ padding: '2px' }}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{ fontSize: '12px' }} />
                        </IconButton>
                      </div>
                    }
                    <span className="th-title">{paramns.label}</span>
                  </div>
                  {
                    paramns?.showSearch &&
                    <div>
                      <SearchIcon fontSize="small" className="tableSeachIccon"
                                  onClick={() =>
                                    setSearchChange((prevState: any) => [
                                      ...prevState.slice(0, indexRef),
                                      { ...prevState[indexRef], inputVisible: true },
                                      ...prevState.slice(indexRef + 1)
                                    ])
                                  }
                      />
                    </div>
                  }
                </div>
              </th>
              :
              ''
          }
        </>
      }
    </>
  )
}

function TabelaChamados(props: ITableaUsuario) {
  const {
    pageSize, totalCount, currentPage, totalPages, handlePagination,
    mainList, newRegister, editRegistro, handleBusca,
    overviewActive, objBusca, setObjBusca, reloadOverview
  } = props

  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()
  const { getUserRoles, getUser } = useAuth()
  const userRoles = getUserRoles()
  const user = getUser()

  const [disableAprovLote, setDisableAprovLote] = useState<boolean>(true)
  const [listItensSelected, setListItensSelected] = useState<any>([])
  const [listItensSelectedDetalhada, setListItensSelectedDetalhada] = useState<any>([])
  const [modalAprovacao, setModalAprovacao] = useState<boolean>(false)

  const [modalShowHideColumns, setModalShowHideColumns] = useState<boolean>(false)
  const [visibleColumns, setVisibleColumns] = useState<any>([])
  const [visibleColumnsControl, setVisibleColumnsControl] = useState<any>([])

  const [dates, setDates] = useState<RangeValue>(null)
  const [filterList, setFilterList] = useState<any>(null)
  const [colunaAtiva] = useState<string>('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounce = useCallback(debounce((busca) => {
    handleBusca(busca, pageSize)
  }, 800), [pageSize])

  const handleChangeBusca = (busca: string) => {
    handleBuscaDebounce(busca)
  }

  const ordeby = (direction: boolean, column?: string) => {
    if (column) {
      handleBusca(objBusca, pageSize, column, direction)
    } else {
      handleBusca(objBusca, pageSize, colunaAtiva, direction)
    }
  }

  const handleChangeVisibleColumn = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
    let indexItem = visibleColumnsControl.indexOf(item)

    if (!event.target.checked && visibleColumnsControl.length <= 1) {
      notify('Pelo menos uma coluna deve ficar visivel', { variant: 'warning' })
      return
    }
    if (event.target.checked) {
      setVisibleColumnsControl((prevState: any) => ([
          ...prevState,
          item
        ]
      ))
    } else {
      setVisibleColumnsControl((prevState: any) => ([
          ...prevState.slice(0, indexItem),
          ...prevState.slice(indexItem + 1)
        ]
      ))
    }
  }

  const confirmVisibilityColumns = () => {
    Cookies.set(`Table_Chamados_${user?.id}`, JSON.stringify(visibleColumnsControl))
    setVisibleColumns([...visibleColumnsControl])
    setModalShowHideColumns(false)
  }

  const handleOpenModalVisibleColumns = () => {
    setModalShowHideColumns(true)
    setVisibleColumnsControl([...visibleColumns])
  }

  const startAprovacaoEmLote = () => {
    if (!listItensSelected.length) {
      notify('Selecione pelo menos um chamado da tabela', { variant: 'warning' })
      return false
    }
    let itensFormat: any = []
    for (let i = 0; i < filterList.length; i++) {
      for (let x = 0; x < listItensSelected.length; x++) {
        if (filterList[i].id === listItensSelected[x]) {
          if (filterList[i].status === 'AWAITING_FINISH_APROVEMENT') {
            itensFormat.push(filterList[i])
          }
          break
        }
      }
    }
    setListItensSelectedDetalhada(itensFormat)
    setModalAprovacao(true)
  }

  const handleSelectChamado = (itemId: any) => {
    if (listItensSelected.includes(itemId)) {
      setListItensSelected(listItensSelected.filter((item: any) => item !== itemId))
    } else {
      setListItensSelected((prevState: any) => ([
        ...prevState,
        itemId
      ]))
    }
  }

  const aprovarChamadosEmLote = async () => {
    try {
      const res = await trackPromise(services.api.finishTicketAproveEmLote(listItensSelected))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível aprovar os chamados'
        )
      }
      setModalAprovacao(false)
      setListItensSelected([])
      handleChangeBusca(objBusca)
      reloadOverview()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const cancelAprovacaoItem = (id: any) => {
    setListItensSelected(listItensSelected.filter((item: any) => item !== id))
    setListItensSelectedDetalhada(listItensSelectedDetalhada.filter((item: any) => item.id !== id))
  }

  // reseta seleção da linha
  useEffect(() => {
    setFilterList(mainList?.map((item: ITicket) => {
      item.selecionado = false
      return item
    }))
    setListItensSelected([])

    const itensAguardandoAprovacao = mainList?.filter(item => item.status === 'AWAITING_FINISH_APROVEMENT')
    if (itensAguardandoAprovacao?.length) {
      setDisableAprovLote(false)
    } else {
      setDisableAprovLote(true)
    }
  }, [mainList])

  // get configs cookie to show/hide columns
  useEffect(() => {
    if (user?.id) {
      const tableCookieString: any = Cookies.get(`Table_Chamados_${user?.id}`)
      if (!tableCookieString) {
        Cookies.set(`Table_Chamados_${user?.id}`, JSON.stringify(tablesVisibles))
        setVisibleColumns([...tablesVisibles])
        setVisibleColumnsControl([...tablesVisibles])
      } else {
        const tableCookieObj = JSON.parse(tableCookieString)
        setVisibleColumns([...tableCookieObj])
        setVisibleColumnsControl([...tableCookieObj])
      }
    }
  }, [])

  return (
    <>
      <div className="wrapper-page-content-interna">
        <div className="flex items-center justify-between gap-10">
          <div className="flex gap-10" style={{ fontWeight: 'bold' }}>
            {overviewActive === 'ALL' && 'Todos os Chamados'}
            {overviewActive === 'IN_PROGRESS' && 'Em Andamento'}
            {overviewActive === 'FINISHIED' && 'Chamados Finalizados'}
            {overviewActive === 'CANCELED' && 'Chamados Cancelados'}
            {overviewActive === 'AWAITING_CANCELATION_APROVEMENT' && 'Aguardando aprovação de cancelamento'}
            {overviewActive === 'AWAITING_FINISH_APROVEMENT' && 'Aguardando aprovação de conclusão'}
            {overviewActive === 'AWAITING_START' && 'Aguardando Início'}
            {overviewActive === 'AWAITING_ATTENDANT' && 'Aguardando Atendente'}
            {overviewActive === 'AWAITING_REVISION' && 'Aguardando revisão'}
            {overviewActive === 'IN_REVISION' && 'Em revisão'}
            {overviewActive === 'CANCELED_PARTIAL' && 'Cancelado parcial'}
          </div>

          <div className="flex items-center gap-10">
            <div className="flex items-center gap-6" id="ref_table_chamados">
              <div id="input_chamado_data_type">
                <TextField
                  select
                  size="small"
                  className="w-200"
                  label="Tipo de Data"
                  placeholder="Tipo de Data"
                  defaultValue="Created"
                  disabled={promiseInProgress}
                >
                  <MenuItem value={'Created'}>
                    Data Criação
                  </MenuItem>
                  <MenuItem value={'Finished'}>
                    Data Finalização
                  </MenuItem>
                  <MenuItem value={'Updated'}>
                    Data Alteração
                  </MenuItem>
                </TextField>
              </div>
              <ConfigProvider locale={locale}>
                <div id="datePicker_input">
                  <RangePicker
                    size="large"
                    format={dateFormat}
                    value={dates}
                    onCalendarChange={val => {
                      setDates(val)
                    }}
                    disabled={promiseInProgress}
                  />
                </div>
              </ConfigProvider>

              <Tooltip title="Recarregar busca">
                <span>
                  <IconButton
                    className="btn-purple"
                    onClick={() => handleChangeBusca(objBusca)}
                    disabled={promiseInProgress}
                  >
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="Visualização Colunas">
                <span>
                  <IconButton
                    className="btn-purple"
                    onClick={handleOpenModalVisibleColumns}
                    disabled={promiseInProgress}
                  >
                    <SettingsIcon fontSize="small" />
                  </IconButton>
                  </span>
              </Tooltip>
            </div>

            {
              userRoles.includes('ticket_res_create') &&
              <Button
                variant="contained" type="button" color="secondary"
                onClick={newRegister}
                startIcon={<AddCircleOutlineIcon fontSize="small" />}
                disabled={promiseInProgress}
              >
                <span>novo chamado</span>
              </Button>
            }
            {
              userRoles.includes('ticket_res_create') &&
              <Button
                variant="contained" type="button" color="success"
                onClick={startAprovacaoEmLote}
                startIcon={<AddCircleOutlineIcon fontSize="small" />}
                disabled={promiseInProgress || disableAprovLote}
              >
                <span>Aprovar chamados selecionados</span>
              </Button>
            }
          </div>
        </div>
        <div className="divider" style={{ opacity: 0 }}></div>
        <div className="table-container">
          <table className="table_styled table_selectable">
            <thead>
            <tr>
              {
                objBusca?.map((item: any, index: number) => (
                  <TabelaSearchColumn
                    key={index}
                    visibleColumn={visibleColumns.includes(item.label)}
                    indexRef={index}
                    paramns={item}
                    objBusca={objBusca}
                    setSearchChange={setObjBusca}
                    handleChangeBusca={handleChangeBusca}
                  />
                ))
              }
            </tr>
            <tr>
              {
                objBusca?.map((item: any, index: number) => (
                  <TabelaHeaderTh
                    key={index}
                    ordeby={ordeby}
                    visibleColumn={visibleColumns.includes(item.label)}
                    indexRef={index}
                    paramns={item}
                    setSearchChange={setObjBusca}
                  />
                ))
              }
            </tr>
            </thead>
            <tbody>
            {!filterList &&
              <tr>
                <td colSpan={visibleColumns.length + 1}>
                  <div className="justify-center">loading...</div>
                </td>
              </tr>
            }
            {filterList?.length === 0 &&
              <tr>
                <td colSpan={visibleColumns.length + 1}>
                  <div className="justify-center">Nenhum registro encontrado</div>
                </td>
              </tr>
            }
            {filterList?.map((item: ITicket, index: number) => (
              <tr key={item.id}>
                {visibleColumns.includes('Id') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div>{item.id}</div>
                  </td>
                }
                {visibleColumns.includes('Local') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div>{item.recruitmentTicketData?.protheusCtt?.ctT_RES} {item.recruitmentTicketData?.protheusCtt?.ctT_XNGRUP} {item.recruitmentTicketData?.protheusCtt?.ctT_DESC01}</div>
                  </td>
                }
                {visibleColumns.includes('CNPJ do cliente') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div>{item.recruitmentTicketData?.protheusSa1?.a1_CGC}</div>
                  </td>
                }
                {visibleColumns.includes('CR') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div>{item.recruitmentTicketData?.protheusCtt?.ctT_RES}</div>
                  </td>
                }
                {visibleColumns.includes('Cliente') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div>{item.customerUser?.name}</div>
                  </td>
                }
                {visibleColumns.includes('E-mail Cliente') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div>{item.customerUser?.email}</div>
                  </td>
                }
                {visibleColumns.includes('Nome Atendente') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div>{item.attendant?.name}</div>
                  </td>
                }
                {visibleColumns.includes('E-mail Atendente') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div>{item.attendant?.email}</div>
                  </td>
                }

                {visibleColumns.includes('Filial') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div>{item.recruitmentTicketData ? item.recruitmentTicketData.branch?.razaoSocial : ''}</div>
                  </td>
                }
                {visibleColumns.includes('Função') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div>{item.recruitmentTicketData ? item.recruitmentTicketData.functionName : ''}</div>
                  </td>
                }
                {visibleColumns.includes('Quantidade') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div>{item.recruitmentTicketData ? item.recruitmentTicketData.quantity : ''}</div>
                  </td>
                }
                {visibleColumns.includes('Aberto em') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div
                      className="justify-center">{item.recruitmentTicketData?.createdAt ? moment(item.recruitmentTicketData?.createdAt).format('DD/MM/YYYY') : '-'}</div>
                  </td>
                }
                {visibleColumns.includes('Última atualização') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div
                      className="justify-center">{item.updatedAt ? moment(item.updatedAt).format('DD/MM/YYYY') : '-'}</div>
                  </td>
                }
                {visibleColumns.includes('Data de início') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div
                      className="justify-center">{item.recruitmentTicketData?.startDate ? moment(item.recruitmentTicketData?.startDate).format('DD/MM/YYYY') : ''}</div>
                  </td>
                }
                {visibleColumns.includes('Nota') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div className="justify-center">
                      {item.satisfaction === 1 &&
                        <span className={`badge`} style={{ backgroundColor: `#f44336`, lineHeight: '0' }}>
                            <MoodBadIcon fontSize="small" style={{ color: `white`, fontSize: '16px' }} />
                          </span>
                      }
                      {item.satisfaction === 2 &&
                        <span className={`badge`} style={{ backgroundColor: `#ff9e22`, lineHeight: '0' }}>
                            <SentimentVeryDissatisfiedIcon style={{ color: `white`, fontSize: '16px' }} />
                          </span>
                      }
                      {item.satisfaction === 3 &&
                        <span className={`badge`} style={{ backgroundColor: `#ffc107`, lineHeight: '0' }}>
                            <SentimentNeutralIcon style={{ color: `white`, fontSize: '16px' }} />
                          </span>
                      }
                      {item.satisfaction === 4 &&
                        <span className={`badge`} style={{ backgroundColor: `#cddc39`, lineHeight: '0' }}>
                            <SentimentSatisfiedAltIcon style={{ color: `white`, fontSize: '16px' }} />
                          </span>
                      }
                      {item.satisfaction === 5 &&
                        <span className={`badge`} style={{ backgroundColor: `#4caf50`, lineHeight: '0' }}>
                            <SentimentVerySatisfiedIcon style={{ color: `white`, fontSize: '16px' }} />
                          </span>
                      }
                    </div>
                  </td>
                }
                {visibleColumns.includes('Status') &&
                  <td onClick={() => handleSelectChamado(item.id)}
                      className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                    <div>
                        <span className={`badge ${services.utils.getStatusCor(item.status)}`}>
                          {services.utils.getStatusText(item.status)}
                        </span>
                    </div>
                  </td>
                }
                <td className={`${listItensSelected.includes(item.id) ? 'active' : ''}`}>
                  {
                    (
                      userRoles.includes('ticket_res_update') ||
                      userRoles.includes('ticket_res_update_any') ||
                      userRoles.includes('ticket_res_read') ||
                      userRoles.includes('ticket_res_read_any') ||
                      userRoles.includes('ticket_update') ||
                      userRoles.includes('ticket_update_any')
                    ) &&
                    <div className="justify-end">
                      <IconButton
                        size="small" aria-label="editar usuário" component="label"
                        onClick={() => editRegistro(item)}
                        disabled={promiseInProgress}
                      >
                        <RemoveRedEyeIcon fontSize="small" />
                      </IconButton>
                    </div>
                  }
                </td>
              </tr>
            ))}
            </tbody>
          </table>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            itensLength={filterList?.length}
            totalCount={totalCount}
            handlePagination={handlePagination}
            colunaBusca={''}
            search={''}
          />

        </div>
      </div>

      {/* show hide columns */}
      <Modal
        size="sm"
        open={modalShowHideColumns}
        close={() => setModalShowHideColumns(false)}
        titulo={'Selecionar Colunas'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12} className="flex gap-6">
            <FormGroup>
              {tablesVisibles.filter(item => item !== 'Ações').map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Switch
                      checked={visibleColumnsControl.includes(item)}
                      onChange={(e) => handleChangeVisibleColumn(e, item)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={item}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              color="secondary"
              type="button"
              size="small"
              onClick={confirmVisibilityColumns}
            >
              Aplicar
            </Button>
          </Grid>
        </Grid>
      </Modal>

      {/* aprovação em lote */}
      <Modal
        size="sm"
        open={modalAprovacao}
        close={() => setModalAprovacao(false)}
        titulo={'Aprovar Chamados'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12} className="center">
            Apenas itens com stauts 'AGUARDANDO APROVAÇÃO DE CONCLUSÃO' poderão ser enviados para aprovação.
          </Grid>
          <Grid item xs={12}>
            {listItensSelectedDetalhada?.map((item: any, index: number) => (
              <div key={item.id} className="chamado_to_aprove">
                <div className="chamado_to_aprove_title">
                  <div>Chamado {item.id}</div>
                  <div>
                    <CancelIcon onClick={() => cancelAprovacaoItem(item.id)} style={{ color: 'red' }}
                                className="pointer" />
                  </div>
                </div>
                <div>Mensagem: {item.finishReason}</div>
                <div>Aprovação solicitada em: {item.updatedAt ? moment(item.updatedAt).format('DD/MM/YYYY') : '-'}</div>
              </div>
            ))}
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              color="success"
              type="button"
              size="small"
              onClick={aprovarChamadosEmLote}
              disabled={!listItensSelectedDetalhada.length || promiseInProgress}
            >
              Aprovar Chamados
            </Button>
          </Grid>
        </Grid>
      </Modal>

    </>
  )
}

export default TabelaChamados
