import HeaderLateral from '../components/HeaderLateral'
import Notifications from '../components/Notifications'

import './style.scss'

const LateralHeader = props => {
  return (
    <>
      <HeaderLateral />
      <div id="wrapper">
        <div className="page-container">{props.children}</div>
      </div>
      <Notifications />
    </>
  )
}

export default LateralHeader
