import { Grid, TextField } from '@mui/material'

interface IDadosSolicitante {
  name?: string
  email?: string
  local?: string
  empresa?: string
  contrato?: string
  filial?: string
}

function DadosSolicitanteDetalhado(props: IDadosSolicitante) {
  const {
    name, email, local, empresa, contrato, filial
  } = props

  return (
    <Grid container spacing={{ xs: 3 }} className="mb-30">
      <Grid item xs={12}>
        <p className="m-0 ">
          Dados do solicitante
        </p>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          fullWidth
          size="small"
          label="Nome"
          value={name || ''}
          disabled={true}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          fullWidth
          size="small"
          label="E-mail"
          value={email || ''}
          disabled={true}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          fullWidth
          size="small"
          label="Local"
          value={local || ''}
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <TextField
          fullWidth
          size="small"
          label="Empresa"
          value={empresa || ''}
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <TextField
          fullWidth
          size="small"
          label="Contrato"
          value={contrato || ''}
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextField
          fullWidth
          size="small"
          label="Filial"
          value={filial || ''}
          disabled={true}
        />
      </Grid>
    </Grid>
  )
}

export default DadosSolicitanteDetalhado
