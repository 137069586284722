import React, { memo } from 'react'
import { useNavigate } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/material/IconButton'

import './pageTitle.scss'

interface IPageTitle {
  className?: string
  children?: any
  text?: string
  subText?: string
  goBack?: () => void
}

const PageTitle = (props: IPageTitle) => {
  const { className, children, text, subText, goBack } = props
  const navigate = useNavigate()
  const goBackRoute = () => {
    navigate(-1)
  }

  return (
    <div className={`${className} page-title-element`}>
      {goBack && (
        <IconButton
          size="small"
          className="go-back-button"
          onClick={goBackRoute}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      <h1>
        {children || text}
      </h1>
      {subText && <p>{subText}</p>}
    </div>
  )
}

export default memo(PageTitle)
