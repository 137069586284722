import { useEffect, useState } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'

import { Autocomplete, TextField } from '@mui/material'

interface ILocaisAutoComplete {
  action: any
  mainList: any
  index: number
  indexSecondary?: number
  required?: boolean
}

function LocaisAutoComplete(props: ILocaisAutoComplete) {
  const { action, mainList, index, indexSecondary, required } = props
  const { promiseInProgress } = usePromiseTracker()

  const [itemSelecionado, setItemSelecionado] = useState({})

  const [clientesLista, setClientesLista] = useState<any>([])
  const statusRetorno = 'Busque por Usuários'

  const retornaItem = (item: any) => {
    console.log('item s', item.cttId, index, action)
    if (action) {
      if (indexSecondary !== undefined && indexSecondary >= 0) {
        action(index, indexSecondary, 'cttId', item.cttId)
      } else {
        action(index, 'cttId', item.cttId)
      }
    }
    setItemSelecionado(item)
  }

  const buscarCliente = (value: string) => {
    if (value.length > 2) {
      setClientesLista(mainList?.filter((item: any) => item.label.toUpperCase().includes(value.toUpperCase())))
    }
  }

  useEffect(() => {
    setClientesLista(mainList)
  }, [mainList])

  return (
    <Autocomplete

      style={{ minWidth: '200px' }}
      value={itemSelecionado}
      onChange={(event, newValue) => {
        retornaItem(newValue)
      }}
      isOptionEqualToValue={(option: any, value: any) =>
        option.label === value.label
      }
      getOptionLabel={(option: any) => option?.label || ''}
      disableClearable
      options={clientesLista}
      disabled={promiseInProgress}
      noOptionsText={statusRetorno}
      loading={false}
      renderInput={params => (
        <TextField
          label="local"
          required={required ? true : false}
          {...params}
          size="small"
          onChange={e => buscarCliente(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

export default LocaisAutoComplete
