import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { useNotification } from '../../hooks/useNotification.js'
import { debounce } from 'lodash'
import Modal from '../../components/Modal'

import { IconButton, ListItemIcon, Menu, MenuItem, TextField, Tooltip } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

import services from '../../services/index.js'
import { IEnvironment } from '../../interfaces/index'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { useAuth } from '../../hooks/useAuth'
// import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import ApartmentIcon from '@mui/icons-material/Apartment'

import BuscaUsersAdmins from './BuscaUsersAdmins'

import './style.scss'

interface ISubMenuAdminComponent {
  mainList?: Array<IEnvironment> | [] | undefined
  listEnvironments?: Array<IEnvironment> | []
  actionEdit?: any
  actionRemove?: any
  title: string
  subTitle?: string
  actionAdd?: any
  actionSelect?: any
  idItemAtivo?: number | undefined
  updateDashUsers?: any
  classNameContainer?: string
}

const ContentItens = (props: any) => {
  const {
    subTitle, userRoles, actionAdd, promiseInProgress, search,
    setSearch, idItemAtivo, clickItem, filterList, handleClick, open
  } = props
  return (
    <>

      {subTitle &&
        <label className="labelAllis big" style={{ marginTop: '20px' }}>
          <span>{subTitle}</span>
          {
            actionAdd && userRoles.includes('bi_teams_create') &&
            <Tooltip title={`Criar ${subTitle.toLowerCase()}`} className="mr-6">
              <span>
                <IconButton size="small" aria-label="Criar Equipe"
                            onClick={actionAdd} disabled={promiseInProgress}
                >
                  <AddCircleOutlineIcon fontSize="inherit" />
                </IconButton>
              </span>
            </Tooltip>
          }
        </label>
      }

      <div className="submenu-content">
        <div className="mb-14 mr-6">
          <TextField
            size="small"
            className="btn-rounded"
            placeholder="Busca"
            inputProps={{ maxLength: 100 }}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>

        <ul>
          <li className={`item-group ${!idItemAtivo ? 'active' : ''}`}>
            <div className="submenu-title-group">
              <div
                onClick={() => clickItem()}
              >
                <ApartmentIcon fontSize="small" /> Todos
              </div>
            </div>
          </li>
          {filterList?.map((item: IEnvironment, index: number) => (
            <li className={`item-group item-id-${item.id} ${idItemAtivo === item.id ? 'active' : ''}`} key={item.id}>
              <div className="submenu-title-group">
                <div onClick={() => clickItem(item)}>
                  <ApartmentIcon fontSize="small" /> {item.name}
                </div>
                {(userRoles.includes('bi_environments_update') || userRoles.includes('bi_environments_delete')) &&
                  <span>
                    <Tooltip title={`Opções`}>
                      <span>
                        <IconButton
                          size="small" aria-label="opções"
                          onClick={(e) => handleClick(e, item)}
                          aria-controls={open ? 'account-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          disabled={promiseInProgress}
                        >
                          <MoreHorizIcon fontSize="inherit" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </span>
                }
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

const SubMenuAdmin = (props: ISubMenuAdminComponent) => {
  const {
    mainList, actionRemove, actionEdit, updateDashUsers, classNameContainer,
    actionSelect, idItemAtivo, subTitle, actionAdd
  } = props
  const { promiseInProgress } = usePromiseTracker()

  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()
  const { notify } = useNotification()

  const [filterList, setFilterList] = useState<any>([])
  const [search, setSearch] = useState('')

  // const [searchUser, setSearchUser] = useState('')
  const [filterUsers, setFilterUsers] = useState<any>([])
  const [users, setUsers] = useState<any>([])

  const [modificouUsers, setModificouUsers] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState(false)

  const [itemSelecionado, setItemSelecionado] = useState<any>({})

  const [enableAddUser, setEnableAddUser] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>, item: any) => {
    setAnchorEl(event.currentTarget)
    setItemSelecionado(item)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setEnableAddUser(false)
    if (modificouUsers && itemSelecionado) {
      updateDashUsers(itemSelecionado)
      setModificouUsers(false)
    }
  }

  const handleClickUser = () => {
    setEnableAddUser(true)
    actionSelect(itemSelecionado)

    if (userRoles.includes('common_users_read') || userRoles.includes('common_users_read_any')) {
      getUsers()
    }
  }

  const clickItem = (subMenu?: IEnvironment | undefined) => {
    if (subMenu) {
      actionSelect(subMenu)
    } else {
      actionSelect({ id: 0, name: 'Todos' })
    }
  }

  const toggleContainerMenu = () => {
    const menuContainer: any = document.getElementById('wrapper-container-submenuAdmin')
    if (menuContainer) {
      menuContainer.classList.toggle('hide')
      menuContainer.style = ''
    }

    if (document.body.clientWidth < 768) {
      setOpenModal(true)
    }
  }

  const editarItem = () => {
    handleClose()
    window.location.href = `/app/dash_management/environment/${itemSelecionado.id}`
  }

  const removerItem = () => {
    handleClose()
    actionRemove(itemSelecionado)
  }

  const getUsers = useCallback(async (busca?: string) => {
    try {
      let termoBuscado = busca ? busca : busca
      let res: any
      let paramns = { adicionalQuery: termoBuscado ? `?fullname=${termoBuscado}` : '' }
      if (userRoles.includes('common_users_read_any')) {
        res = await trackPromise(services.api.getUsersAll(paramns, 1))
      } else {
        res = await trackPromise(services.api.getUsersAll(paramns, 1))
      }
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar Usuários'
        )
      }
      let usersOrdeby = []
      if (userRoles.includes('common_users_read_any')) {
        usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data.data, false)
      } else {
        usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data.data, false)
        //usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data, false)
      }
      setUsers(usersOrdeby)
      setFilterUsers(usersOrdeby)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buscarClienteAdmin = (value: string) => {
    handle(value)
  }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handle = useCallback(
    debounce(async busca => {
      try {
        let termoBuscado = busca ? busca : busca
        let res: any
        let paramns = { adicionalQuery: termoBuscado ? `?fullname=${termoBuscado}` : '' }
        if (userRoles.includes('common_users_read_any')) {
          res = await trackPromise(services.api.getUsersAll(paramns, 1))
        } else {
          res = await trackPromise(services.api.getUsersAll(paramns, 1))
        }
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível consultar Usuários'
          )
        }
        let usersOrdeby = []
        if (userRoles.includes('common_users_read_any')) {
          usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data.data, false)
        } else {
          usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data.data, false)
          // usersOrdeby = services.utils.orderbyArrayOfObjects('name', res.data, false)
        }
        setFilterUsers(usersOrdeby)
      } catch (error: any) {
        notify(error.message, { variant: 'warning' })
      }
    }, 700),
    []
  )

  const vicularUsuarioAdminComDash = useCallback(async (user: any, equipe: IEnvironment) => {
    try {
      const res = await trackPromise(services.api.vicularUsuarioAdminComDash(user.id, equipe.id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível vincular usuário'
        )
      }
      notify('Usuário vinculado com sucesso', { variant: 'success' })
      setItemSelecionado((prevState: any) => ({
        ...prevState,
        environmentAdmins: [
          ...prevState.environmentAdmins,
          user
        ]
      }))
      setModificouUsers(true)
      // user
      const indexItemAdicionado = services.utils.findIndexInArray(user.id, users, 'id')
      setUsers((prevState: any) => ([
          ...prevState.slice(0, indexItemAdicionado),
          ...prevState.slice(indexItemAdicionado + 1)
        ]
      ))
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  const handleClickAddUser = (user: any) => {
    vicularUsuarioAdminComDash(user, itemSelecionado)
  }

  const desvincularUser = async (idUser: any, index: number) => {
    try {
      const res = await trackPromise(services.api.desvicularUsuarioAdminComDash(idUser, itemSelecionado.id))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi desvincular Usuário'
        )
      }
      notify('Usuário desvinculado', { variant: 'success' })
      setItemSelecionado((prevState: any) => ({
        ...prevState,
        environmentAdmins: [
          ...prevState.environmentAdmins.slice(0, index),
          ...prevState.environmentAdmins.slice(index + 1)
        ]
      }))
      setModificouUsers(true)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    setFilterList(mainList)
  }, [mainList])

  useEffect(() => {
    if (search && mainList?.length) {
      const newBusca = mainList?.filter((item: any) => item.name.toUpperCase().includes(search.toUpperCase()))
      setFilterList(newBusca)
    } else {
      setFilterList(mainList)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    const handleDragBar = (e: any) => {
      e.preventDefault()
      let subMenu: any = document.getElementById('wrapper-container-submenuAdmin')

      let larguraHeaderLateral = 64
      let somaPaddingSubmenu = 36
      let largTotalElement = 220 + somaPaddingSubmenu + larguraHeaderLateral
      let limit = e.clientX - largTotalElement

      if (limit > 0 && limit < 400) {
        subMenu.style.cssText = `width: ${e.clientX - (larguraHeaderLateral + somaPaddingSubmenu)}px`
      }
    }
    let dragbar: any = document.getElementById('dragbar')
    dragbar.addEventListener('drag', handleDragBar)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      let subMenu: any = document.getElementById('wrapper-container-submenuAdmin')
      if (subMenu) {
        subMenu.style = ''
      }
    }
    window.addEventListener('resize', handleResize, false)
  }, [])

  return (
    <>
      <div className={`wrapper-container-submenuAdmin ${classNameContainer}`} id="wrapper-container-submenuAdmin">
        <div id="dragbar" draggable="true"></div>
        <div className="btn-toggle-submenuAdmin" onClick={toggleContainerMenu}>
          <KeyboardDoubleArrowLeftIcon />
        </div>
        <div className="wrapper-content-submenuAdmin">
          {!openModal &&
            <ContentItens
              toggleContainerMenu={toggleContainerMenu}
              subTitle={subTitle}
              userRoles={userRoles}
              actionAdd={actionAdd}
              promiseInProgress={promiseInProgress}
              search={search}
              setSearch={setSearch}
              idItemAtivo={idItemAtivo}
              clickItem={clickItem}
              filterList={filterList}
              handleClick={handleClick}
              open={open}
            />
          }


        </div>
      </div>

      <Modal
        size="sm"
        open={openModal}
        close={() => setOpenModal(false)}
        titulo={'Ambientes'}
      >
        <div className="conteiner-submenu-modal">
          {openModal &&
            <ContentItens
              toggleContainerMenu={toggleContainerMenu}
              subTitle={subTitle}
              userRoles={userRoles}
              actionAdd={actionAdd}
              promiseInProgress={promiseInProgress}
              search={search}
              setSearch={setSearch}
              idItemAtivo={idItemAtivo}
              clickItem={clickItem}
              filterList={filterList}
              handleClick={handleClick}
              open={open}
              setOpenModal={setOpenModal}
            />
          }
        </div>
      </Modal>

      {/* menu opções */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))'
          }
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {enableAddUser ?
          <div>
            <MenuItem>
              <BuscaUsersAdmins buscarClienteAdmin={buscarClienteAdmin} mainList={filterUsers}
                                action={handleClickAddUser} />
              {/* <TextField
                size='small'
                className='btn-rounded'
                placeholder='Busca'
                inputProps={{ maxLength: 100 }}
                value={searchUser}
                onChange={e => setSearchUser(e.target.value)}
              /> */}
            </MenuItem>
            <div className="subMenuAdmin-filter-list">
              <div className="lista-entidades-vinculadas-dash" style={{ margin: '10px', maxWidth: '500px' }}>
                {
                  itemSelecionado?.environmentAdmins?.map((item: any, index: number) => (
                    <div className="entidade-item tipo-user" key={item.id}>
                      {item.name}
                      <IconButton
                        size="small"
                        disabled={promiseInProgress}
                        onClick={() => desvincularUser(item.id, index)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </div>
                  ))
                }
              </div>

              {/* {filterUsers.map((item: IUser, index: number) => (
                <MenuItem key={item.id} className="fontSmall" onClick={() => handleClickAddUser(item)} disabled={promiseInProgress}>
                  <ListItemIcon>
                    <PersonAddOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  {item.name}
                </MenuItem>
              ))} */}
            </div>
          </div>
          :
          <div>
            {userRoles.includes('bi_environments_update') &&
              <>
                <MenuItem onClick={editarItem}>
                  <ListItemIcon>
                    <ModeEditOutlineOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  Editar
                </MenuItem>
                <MenuItem onClick={removerItem}>
                  <ListItemIcon>
                    <DeleteOutlineOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  Remover
                </MenuItem>
              </>
            }

          </div>
        }
      </Menu>

    </>
  )
}

export default SubMenuAdmin