import { createContext, useContext } from 'react'

import Cookies from 'js-cookie'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const getToken = () => {
  }

  const getUserRoles = () => {
    const userObj = Cookies.get('TradeTalentosUserPAT')
    if (!userObj) return null

    const myDecodedToken = JSON.parse(userObj)
    if (myDecodedToken?.role) {
      return myDecodedToken.role
    }
  }

  const getUser = () => {
    const userObj = Cookies.get('TradeTalentosUserPAT')
    if (!userObj) return null

    const user = JSON.parse(userObj)
    if (user?.user?.id) {
      return {
        userName: user.user?.username,
        id: user.user?.id,
        fullName: user.user?.fullName,
        email: user.user?.email
      }
    }
  }

  const getPortalRole = () => {
    const userObj = Cookies.get('TradeTalentosUserPAT')
    if (!userObj) return null

    const user = JSON.parse(userObj)
    if (user?.user?.portalRole) {
      return user.user.portalRole
    }
  }


  return (
    <AuthContext.Provider
      value={{
        getToken,
        getUserRoles,
        getUser,
        getPortalRole
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuth() {
  const context = useContext(AuthContext)
  return context
}

export { AuthProvider, useAuth }
