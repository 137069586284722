export const cpf = v => {
  if (v) {
    v = v.toString()
    // if (v.slice(0, 1) === '0') v = v.slice(1)
    return v
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }
  return v
}

export const cnpj = v => {
  if (v) {
    v = v.toString()
    if (v.slice(0, 1) === '0') v = v.slice(1)
    return v
      .replace(/\D/g, '') // Remove tudo o que não é dígito
      .replace(/^(\d{2})(\d)/, '$1.$2') // Coloca ponto entre o segundo e o terceiro dígitos
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Coloca ponto entre o quinto e o sexto dígitos
      .replace(/\.(\d{3})(\d)/, '.$1/$2') // Coloca uma barra entre o oitavo e o nono dígitos
      .replace(/(\d{4})(\d)/, '$1-$2') // Coloca um hífen depois do bloco de quatro dígitos
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }
  return v
}

export const cpfCnpj = v => {
  // Remove tudo o que não é dígito
  if (v) {
    v = v.toString()
    v = v.replace(/\D/g, '')

    if (v.slice(0, 1) === '0') v = v.slice(1)

    if (v.length <= 11) {
      // CPF
      v = v.padStart(11, 0)
      // Coloca um ponto entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d)/, '$1.$2')

      // Coloca um ponto entre o terceiro e o quarto dígitos
      // de novo (para o segundo bloco de números)
      v = v.replace(/(\d{3})(\d)/, '$1.$2')

      // Coloca um hífen entre o terceiro e o quarto dígitos
      v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
    } else {
      // CNPJ
      v = v.padStart(14, 0)
      // Coloca ponto entre o segundo e o terceiro dígitos
      v = v.replace(/^(\d{2})(\d)/, '$1.$2')

      // Coloca ponto entre o quinto e o sexto dígitos
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')

      // Coloca uma barra entre o oitavo e o nono dígitos
      v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')

      // Coloca um hífen depois do bloco de quatro dígitos
      v = v
        .replace(/(\d{4})(\d)/, '$1-$2')

        // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
        .replace(/(-\d{2})\d+?$/, '$1')
    }
  }

  return v
}

export const cep = v => {
  if (v) {
    return v
      .replace(/\D/g, '')
      .replace(/^(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  }
  return v
}
export const diaMes = v => {
  if (v) {
    if (v.length > 5) {
      const data = v.substring(0, 10).split('-')
      v = data[2] + '/' + data[1]
    } else {
      v = v.replace(/\D/g, '')
      if (v.length === 1 && v > 3) v = '0' + v
      if (v.length === 2 && v > 31) v = '3'
      if (v.length === 4 && v.substring(2, 4) > 12) v = v.substring(0, 3)
      v = v.replace(/^(\d{2})(\d)/, '$1/$2')
      v = v.replace(/(\/\d{2})\d+?$/, '$1')
    }
  }
  return v
}

export const phone = v => {
  if (v) {
    return v
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, '($1) $2') // coloca () entre os dois primeiro caracteres
      .replace(/(\d{4})(\d)/, '$1-$2') // coloca - apos o 4 digito
      .replace(/(-\d{5})\d+?$/, '$1') // captura 5 numeros seguidos de um traço e não deixa ser digitado mais nada
  }
  return v
}

export const cellphone = v => {
  if (v) {
    return v
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, '($1) $2') // coloca () entre os dois primeiro caracteres
      .replace(/(\d{5})(\d)/, '$1-$2') // coloca - apos o 4 digito
      .replace(/(-\d{4})\d+?$/, '$1') // captura 5 numeros seguidos de um traço e não deixa ser digitado mais nada
  }
  return v
}

export const number = v => {
  if (v) {
    if (!v) v = 0
    v = v.toString()
    return v.replace(/[^\d]/g, '') // permite apenas numeros
  }
  return v
}

export const noEspecialCaracter = v => {
  if (v) {
    return v.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '') // remove totos os caracteres especiais
  }
  return v
}
export const noEspecialCaracterPermiteAcento = v => {
  if (v) {
    return v.replace(/([^ \nA-Za-z0-9À-ÖØ-öø-ÿ,])/g, '')
  }
  return v
}

export const noEspecialChars = v => {
  if (v) {
    return v
      .replace(/([/'"!@#$%¨&*,_+`´(){}[]?;:,<>\\~-=\|ˆ^])/g, ' ')
      .replace(/\s\./g, '')
      .replace(/\.\.+/g, '')
  }
  return v
}
export const removeMultipleSpaces = v => {
  if (v) {
    return v.replace(/\s\s+/g, ' ')
  }
  return v
}

export const valor = v => {
  if (v) {
    v = v.toString()
    v = v.replace(/\D/g, '') // Remove tudo o que não é dígito
    v = v.replace(/(\d)(\d{8})$/, '$1.$2') // coloca o ponto dos milhões
    v = v.replace(/(\d)(\d{5})$/, '$1.$2') // coloca o ponto dos milhares
    v = v.replace(/(\d)(\d{2})$/, '$1,$2') // coloca a virgula antes dos 2 últimos dígitos
  }
  return v
}

export const currency = v => {
  if (!v) v = 0
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(v)
}

export const valorReal = v => {
  if (v) {
    v = v.toString()
    v = v.replace(/(?!-)\D/g, '') // Remove tudo o que não é dígito
    v = v.replace(/(\d)(\d{8})$/, '$1.$2') // coloca o ponto dos milhões
    v = v.replace(/(\d)(\d{5})$/, '$1.$2') // coloca o ponto dos milhares
    v = v.replace(/(\d)(\d{2})$/, '$1,$2') // coloca a virgula antes dos 2 últimos dígitos
  }

  return v
}

export const unMask = v => {
  if (v) {
    return v
      .replace(/\./g, '') // remove todos os .
      .replace(/-/g, '') // remove todos os -
      .replace(/\(/g, '') // remove todos os (
      .replace(/\)/g, '') // remove todos os )
      .replace(/\//g, '') // remove todos os /
      .replace(/\s/g, '') // remove todos os " "
  }
  return v
}

export const unMaskCep = v => {
  if (v) {
    v = unMask(v)
    return v.substring(0, 8)
  }
  return v
}

export const unMaskCnpj = v => {
  if (v) {
    v = unMask(v)
    return v.substring(0, 14)
  }
  return v
}

export const unMaskCpf = v => {
  if (v) {
    v = unMask(v)
    return v.substring(0, 11)
  }
  return v
}


export const unMaskPhone = v => {
  if (v) {
    v = unMask(v)
    return v.substring(0, 11)
  }
  return v
}


export const milhar = v => {
  if (v) {
    if (!v) v = 0
    v = v.toString()
    return v
      .replace(/[^\d]/g, '') // permite apenas numeros
      .replace(/(\d)(\d{6})$/, '$1.$2') // coloca o ponto dos milhões
      .replace(/(\d)(\d{3})$/, '$1.$2') // coloca o ponto dos milhares
  }
  return v
}

export const quantidadeProduto = v => {
  if (v) {
    // caso seja uma quantidade fracionada
    if (v.toString().includes('.')) {
      const splitValue = v.split('.')
      return splitValue[0].padStart(4, '0') + '.' + splitValue[1]
    }
    return v
  }
  return v
}
