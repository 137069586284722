import { PageTitle } from '../../components/PageElements'

function NotFound() {
  return (
    <div>
      <PageTitle text={'Acesso negado'} />
    </div>
  )
}

export default NotFound
