import './style.scss'

interface ITab {
  id: number
  label: string
}

interface ITabs {
  tabs: ITab[]
  active: number
  onChange: any
  className?: string
  disabled?: boolean
}

function Tabs(props: ITabs) {
  const { tabs, active, onChange, className, disabled } = props

  return (
    <div className={`tabs-container ${className}`}>
      {tabs?.map((tab, index) => {
        if (disabled) {
          return (
            <div
              className={`tabs-item disabled ${active === tab?.id ? 'active' : ''}`}
              id={`tabs_${tab?.id || '0'}-${index}`}
              key={index}
            >
              {tab?.label}
            </div>
          )
        } else {
          return (
            <div
              className={`tabs-item ${active === tab?.id ? 'active' : ''}`}
              id={`tabs_${tab?.id || '0'}-${index}`}
              key={index}
              onClick={() => onChange(tab?.id)}
            >
              {tab?.label}
            </div>
          )
        }
      })}
    </div>
  )
}

export default Tabs
