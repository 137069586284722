import React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { IDropdownOptions } from '../../interfaces'

interface DropdownWithSearchProps {
  options: IDropdownOptions[];
  onChange: (value: string) => void;
  label: string
  disabled: boolean
  key: number
}

const DropdownSearch: React.FC<DropdownWithSearchProps> = ({ options, onChange, label, disabled, key }) => {
  return (
    <Autocomplete
      key={key}
      fullWidth
      disabled={disabled}
      noOptionsText={'Nada encontrado'}
      size="small"
      options={options}
      onChange={(event, value) => onChange(value?.value || '')}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  )
}

export default DropdownSearch
