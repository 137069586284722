import React, { useCallback, useEffect, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { debounce } from 'lodash'
import Cookies from 'js-cookie'

import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
  Tooltip
} from '@mui/material'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import SearchIcon from '@mui/icons-material/Search'
import Modal from '../../components/Modal'
import { useAuth } from '../../hooks/useAuth'
import { IDashboard } from '../../interfaces/index'
import { useNotification } from '../../hooks/useNotification.js'
import services from '../../services/index.js'
import { Pagination, TabelaSearchColumn } from '../../components/Table'

interface ITablelaDashboard {
  mainList: Array<IDashboard>
  newRegister: any
  handleReloadDashboards: any
  idParentAtivo: number | undefined
  currentPage: number
  totalPages: number
  handlePagination: any
  handleBusca: any
  podeAtivarDesativarEditar: boolean
  pageSize: number
  totalCount: number
  objBusca: any
  setObjBusca: any
}

enum ActiveMode {
  ativo,
  desativo
}

const tablesVisibles = ['Nome', 'Status', 'Ambientes', 'Filtros', 'Ações']

function TabelaHeaderTh(props: any) {
  const {
    indexRef, visibleColumn, paramns, setSearchChange, ordeby, idParentAtivo,
    podeAtivarDesativarEditar, allSelected, handleSelectAll
  } = props
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  return (
    <>
      {visibleColumn &&
        <>
          {
            (
              paramns.label === 'Ações' &&
              (
                userRoles.includes('bi_dashboards_edit') ||
                userRoles.includes('bi_dashboards_create')
              )
            ) || paramns.label !== 'Ações' ?
              <>
                {(paramns.label === 'Ambiente' && idParentAtivo) ? '' :
                  <th>
                    <div>
                      <div>
                        {
                          paramns?.showOrderby &&
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <IconButton
                              size="small"
                              onClick={() => ordeby(false, paramns.searchRef)}
                              style={{ padding: '2px' }}
                              disabled={promiseInProgress}
                            >
                              <ArrowUpwardIcon style={{ fontSize: '12px' }} />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => ordeby(true, paramns.searchRef)}
                              style={{ padding: '2px' }}
                              disabled={promiseInProgress}
                            >
                              <ArrowDownwardIcon style={{ fontSize: '12px' }} />
                            </IconButton>
                          </div>
                        }


                        {paramns.label !== 'checkbox' &&
                          <span className="th-title">{paramns.label}</span>
                        }

                      </div>
                      {
                        paramns?.showSearch &&
                        <div>
                          <SearchIcon fontSize="small" className="tableSeachIccon"
                                      onClick={() =>
                                        setSearchChange((prevState: any) => [
                                          ...prevState.slice(0, indexRef),
                                          { ...prevState[indexRef], inputVisible: true },
                                          ...prevState.slice(indexRef + 1)
                                        ])
                                      }
                          />
                        </div>
                      }
                    </div>
                  </th>
                }
              </>
              :
              ''
          }
        </>
      }
    </>
  )
}

function TabelaDashboards(props: ITablelaDashboard) {
  const {
    newRegister, mainList, idParentAtivo, handleBusca, podeAtivarDesativarEditar, pageSize,
    handleReloadDashboards, currentPage, totalPages, handlePagination, totalCount, objBusca, setObjBusca
  } = props
  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()
  const { getUserRoles, getUser } = useAuth()
  const userRoles = getUserRoles()
  const user = getUser()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [anchorElOrderby, setAnchorElOrderby] = useState<null | HTMLElement>(null)
  const openOrderby = Boolean(anchorElOrderby)

  const handleCloseOrderby = () => {
    setAnchorElOrderby(null)
  }

  const [filterList, setFilterList] = useState<any>([])

  const [allSelected, setAllSelected] = useState<boolean>(false)
  const [showActions, setShowActions] = useState<boolean>(true)

  const [modalDeletar, setModalDeletar] = useState<boolean>(false)
  const [modalAtivarDesativar, setModalAtivarDesativar] = useState<boolean>(false)
  const [colunaAtiva] = useState<string>('')
  const [activeMode, setActiveMode] = useState<ActiveMode>(ActiveMode.ativo)

  const [modalShowHideColumns, setModalShowHideColumns] = useState<boolean>(false)
  const [visibleColumns, setVisibleColumns] = useState<any>([])
  const [visibleColumnsControl, setVisibleColumnsControl] = useState<any>([])


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounce = useCallback(debounce((busca, idParentAtivoAtual) => {
    handleBusca(busca, pageSize, '', '', idParentAtivoAtual)
  }, 800), [pageSize])

  const handleChangeBusca = (busca: string) => {
    handleBuscaDebounce(busca, idParentAtivo)
  }

  const ordeby = (direction: boolean, column?: string) => {
    handleCloseOrderby()
    if (column) {
      handleBusca(objBusca, pageSize, column, direction, idParentAtivo)
    } else {
      handleBusca(objBusca, pageSize, colunaAtiva, direction, idParentAtivo)
    }
  }

  const handleChangeVisibleColumn = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
    let indexItem = visibleColumnsControl.indexOf(item)
    if (!event.target.checked && visibleColumnsControl.length <= 1) {
      notify('Pelo menos uma coluna deve ficar visivel', { variant: 'warning' })
      return
    }
    if (event.target.checked) {
      setVisibleColumnsControl((prevState: any) => ([
          ...prevState,
          item
        ]
      ))
    } else {
      setVisibleColumnsControl((prevState: any) => ([
          ...prevState.slice(0, indexItem),
          ...prevState.slice(indexItem + 1)
        ]
      ))
    }
  }

  const confirmVisibilityColumns = () => {
    Cookies.set(`Table_DashboardsMain_${user?.id}`, JSON.stringify(visibleColumnsControl))
    setVisibleColumns([...visibleColumnsControl])
    setModalShowHideColumns(false)
  }

  const handleOpenModalVisibleColumns = () => {
    setModalShowHideColumns(true)
    setVisibleColumnsControl([...visibleColumns])
  }

  useEffect(() => {
    setFilterList(mainList?.map((item: IDashboard, index: number) => {
      item.selecionado = false
      return item
    }))
  }, [mainList])

  useEffect(() => {
    const getPermissaoAtivar = async () => {
      try {
        const res: any = await trackPromise(services.api.isadmin(idParentAtivo))
        if (res.fail) {
          throw new Error(res.error || 'Não foi possível consultar permissão empresa')
        }
        setShowActions(true)
      } catch (error: any) {
        setShowActions(false)
      }
    }
    if (idParentAtivo && userRoles.includes('bi_dashboards_create')) {
      getPermissaoAtivar()
    } else if (!idParentAtivo && userRoles.includes('bi_dashboards_create')) {
      setShowActions(false)
    } else if (userRoles.includes('bi_dashboards_create_any')) {
      setShowActions(true)
    }
  }, [idParentAtivo])

  useEffect(() => {
    if (user?.id) {
      const tableCookieString: any = Cookies.get(`Table_DashboardsMain_${user?.id}`)
      if (!tableCookieString) {
        Cookies.set(`Table_DashboardsMain_${user?.id}`, JSON.stringify(tablesVisibles))
        setVisibleColumns([...tablesVisibles])
        setVisibleColumnsControl([...tablesVisibles])
      } else {
        const tableCookieObj = JSON.parse(tableCookieString)
        setVisibleColumns([...tableCookieObj])
        setVisibleColumnsControl([...tableCookieObj])
      }
    }
  }, [])

  return (
    <>
      <div className="wrapper-page-content-interna">
        <div className="flex items-center justify-between gap-10">
          <div className="flex gap-10"></div>
          <div className="flex items-center gap-10">
            <div className="flex items-center gap-6">
              <Tooltip title="Visualização Colunas">
                <span>
                  <IconButton
                    className="btn-purple"
                    onClick={handleOpenModalVisibleColumns}
                    disabled={promiseInProgress}
                  >
                    <SettingsIcon fontSize="small" />
                  </IconButton>
                  </span>
              </Tooltip>
            </div>
            {userRoles.includes('bi_dashboards_create_any') &&
              <Button
                variant="contained"
                className="btn-purple mr-6 content-end"
                type="button"
                color="inherit"
                onClick={() => window.location.href = '/app/dash_management/dashboard/new'}
              >
                <AddCircleOutlineIcon style={{ marginRight: '5px' }} fontSize="small" /> Novo dashboard
              </Button>
            }
          </div>
        </div>
        <div className="divider" style={{ opacity: 0 }}></div>

        <div className="">
          <table className="table_styled table_selectable">
            <thead>
            <tr>
              {
                objBusca?.map((item: any, index: number) => (
                  <TabelaSearchColumn
                    key={index}
                    visibleColumn={visibleColumns.includes(item.label)}
                    indexRef={index}
                    paramns={item}
                    objBusca={objBusca}
                    setSearchChange={setObjBusca}
                    handleChangeBusca={handleChangeBusca}
                  />
                ))
              }
            </tr>
            <tr>
              {
                objBusca?.map((item: any, index: number) => (
                  <TabelaHeaderTh
                    key={index}
                    ordeby={ordeby}
                    visibleColumn={visibleColumns.includes(item.label)}
                    indexRef={index}
                    paramns={item}
                    setSearchChange={setObjBusca}
                    idParentAtivo={idParentAtivo}
                    podeAtivarDesativarEditar={false}
                    allSelected={null}
                    handleSelectAll={null}
                  />
                ))
              }
            </tr>
            </thead>
            <tbody>
            {!filterList &&
              <tr>
                <td colSpan={visibleColumns.length + 1}>
                  <div className="justify-center">loading...</div>
                </td>
              </tr>
            }
            {filterList?.length === 0 &&
              <tr>
                <td colSpan={visibleColumns.length + 1}>
                  <div className="justify-center">Nenhum registro encontrado</div>
                </td>
              </tr>
            }
            {filterList.map((item: IDashboard, index: number) => (
              <tr key={item.id}>

                {visibleColumns.includes('Nome') &&
                  <td>
                    <div>{item.name}</div>
                  </td>
                }

                {visibleColumns.includes('Status') &&
                  <td>
                    <div>
                      {item.active ? <span className="badge badge-success">Ativo</span> :
                        <span className="badge badge-error">Inativo</span>}
                    </div>
                  </td>
                }

                {visibleColumns.includes('Ambientes') &&
                  <td>
                    <div>
                      {item.dashboardGroups?.length === 0 ? (
                        <span>Nenhum ambiente</span>
                      ) : item.dashboardGroups?.length === 1 ? (
                        <span>1 ambiente</span>
                      ) : (
                        <span>{item.dashboardGroups?.length} ambientes</span>
                      )}
                    </div>
                  </td>
                }

                {visibleColumns.includes('Filtros') &&
                  <td>
                    <div>
                      {item.dashboardFilters?.length === 0 ? (
                        <span>Nenhum filtro</span>
                      ) : item.dashboardFilters?.length === 1 ? (
                        <span>Um filtro</span>
                      ) : (
                        <span>{item.dashboardFilters?.length} filtros</span>
                      )}
                    </div>
                  </td>
                }


                {userRoles.includes('bi_dashboards_edit') || userRoles.includes('bi_dashboards_create') ?
                  <td>
                    <div className="justify-end">
                      <IconButton
                        size="small" aria-label="editar usuário"
                        component="label"
                        disabled={promiseInProgress}
                        onClick={() => window.location.href = `dashboard/${item.id}`}
                      >
                        <ModeEditOutlineOutlinedIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </td> :
                  ''
                }
              </tr>
            ))}
            </tbody>
          </table>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            itensLength={filterList?.length}
            totalCount={totalCount}
            handlePagination={handlePagination}
            colunaBusca={''}
            search={''}
            idRef={idParentAtivo}
          />

        </div>
      </div>

      {/* show hide columns */}
      <Modal
        size="sm"
        open={modalShowHideColumns}
        close={() => setModalShowHideColumns(false)}
        titulo={'Selecionar Colunas'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12} className="flex gap-6">
            <FormGroup>
              {tablesVisibles.filter(item => item !== 'Ações' && item !== 'checkbox').map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Switch
                      checked={visibleColumnsControl.includes(item)}
                      onChange={(e) => handleChangeVisibleColumn(e, item)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={item}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              color="secondary"
              type="button"
              size="small"
              onClick={confirmVisibilityColumns}
            >
              Aplicar
            </Button>
          </Grid>
        </Grid>
      </Modal>


      {/* menu opções */}
      <Menu
        anchorEl={anchorElOrderby}
        open={openOrderby}
        onClose={handleCloseOrderby}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))'
          }
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <div style={{ padding: '2px 8px', fontSize: '14px' }}>
            ORDENAR
          </div>
          <MenuItem
            onClick={() => ordeby(false)}
          >
            <ListItemIcon>
              <ArrowUpwardIcon fontSize="small" />
            </ListItemIcon>
            Ordem crescente
          </MenuItem>
          <MenuItem
            onClick={() => ordeby(true)}
          >
            <ListItemIcon>
              <ArrowDownwardIcon fontSize="small" />
            </ListItemIcon>
            Ordem decrescente
          </MenuItem>
        </div>
      </Menu>
    </>
  )
}

export default TabelaDashboards
