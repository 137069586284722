import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Button, IconButton, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import Modal from '../../components/Modal'
import { PageTitle } from '../../components/PageElements'
import { useAuth } from '../../hooks/useAuth'
import TableTrct from './TableTrctPackage'
import services from '../../services'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { ITrctPackage } from '../../interfaces'
import { trackPromise } from 'react-promise-tracker'
import { useNotification } from '../../hooks/useNotification.js'
import { deleteFile, listUploads } from '../../services/api'
import moment from 'moment'
import SearchIcon from '@mui/icons-material/Search'

function TrctInclude() {
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  const [searchTerm, setsearchTerm] = useState('')
  const [modalNewItem, setModalNewItem] = useState(false)


  const [orderDesc, setOrderDesc] = useState<boolean>(false)
  const [data, setData] = useState<ITrctPackage[]>([])
  const [loading, setLoading] = useState(true)
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const { notify } = useNotification()


  const openNewItemModal = () => {
    setModalNewItem(true)
  }

  const closeNewItemModal = () => {
    setModalNewItem(false)
  }

  const handleFileUpload = async (file: File) => {
    if (file && file.type === 'application/pdf') {
      const res = await trackPromise(services.api.uploadTrct(file, 1))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível enviar anexo'
        )
      }
      const newNamefile = `${res?.fileName}`
      const newFile = new File([file], newNamefile)
      console.log('file newFile', newFile)
      await trackPromise(services.api.uploadS3(newFile, res.url))
      services.api.triggerWorker(newFile)
      notify('O processamento do seu arquivo foi iniciado', { variant: 'success' })

      console.log(file)
    } else {
      console.error('Please upload a PDF file.')
      notify('Tipo de arquivo inválido', { variant: 'error' })

    }
  }
  const handleSortOrder = (data: any) => {
    try {
      setOrderDesc(data.order != 'ascend')
    } catch {
    }
    fetchFiles(currentPage, pageSize)
  }
  const handleSearch = () => {
    fetchFiles(currentPage, pageSize)
  }
  const fetchFiles = async (page: number, pageSize: number) => {
    setLoading(true)

    try {
      const folderName = 'Uploads/'
      const response = await listUploads(page, pageSize, orderDesc, searchTerm)

      const { data, totalCount } = response

      const formattedFiles = data
        .filter((file: any) => file.fileName !== 'Uploads/')
        .map((file: any) => {
          let fileName = file.fileName.replace('Uploads/', '')
          const createdAt = moment(file.createdAt).format('DD/MM/YYYY HH:mm')
          let sucesso
          switch (file.statusId) {
            case 1:
              sucesso = 'Em fila'
              break
            case 2:
              sucesso = 'Sucesso'
              break
            case 3:
              sucesso = 'Erro'
              break
          }

          return {
            fileName,
            createdAt,
            success: sucesso // Exemplo de como você pode usar o uploadStatus
          }
        })

      setData(formattedFiles)
      setTotalCount(totalCount)
    } catch (error) {
      console.error('Error fetching files:', error)
    } finally {
      setLoading(false)
    }
  }
  const onDelete = (fileName: string) => {
    deleteFile('Uploads/', fileName)
    console.log(`Deleting file: ${fileName}`)
    notify('Arquivo Deletado', { variant: 'success' })
    fetchFiles(currentPage, pageSize)

  }


// Função de Paginação
  const handlePagination = (page: number, pageSize?: number) => {
    setCurrentPage(page)
    if (pageSize) setPageSize(pageSize)
    fetchFiles(page, pageSize || 10)
  }

  useEffect(() => {
    fetchFiles(currentPage, pageSize)
  }, [currentPage, pageSize, searchTerm, orderDesc])


  return (
    <div className="wrapper-container-content bgwhite flex flex-col">
      <div className="wrapper-page-content" id="wrapper-page-content">
        <PageTitle subText={'Gerenciar Trct'}>
          <div>Termos de recisão contratual de trabalho</div>
        </PageTitle>
        <div className="panel">
          <div className="panel-body">

            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '15px' }}>

              {
                userRoles.includes('trct_proccess_any') &&

                <Button
                  variant="contained" type="button" color="secondary"
                  onClick={openNewItemModal}
                  startIcon={<AddCircleOutlineIcon fontSize="small" />}
                >
                  <span>Upload</span>
                </Button>
              }

              <TextField
                size="small"
                label="Buscar arquivo"
                placeholder="Buscar arquivo"
                value={searchTerm}
                onChange={(e) => setsearchTerm(e.target.value)}
                defaultValue=""
              />
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </div>

            <TableTrct
              data={data}
              currentPage={currentPage}
              handlePagination={handlePagination}
              loading={loading}
              sortFunction={handleSortOrder}
              pageSize={pageSize}
              totalCount={totalCount}
              handleDelete={onDelete}
            ></TableTrct>

          </div>
        </div>

      </div>

      <Modal open={modalNewItem} close={closeNewItemModal} titulo={'Upload TRCT'}>
        <form
          className="flex flex-col"
          id="uploadTrct"
          onSubmit={(e) => {
            e.preventDefault()
            const fileInput = document.querySelector<HTMLInputElement>('input[name="trctFile"]')
            if (fileInput && fileInput.files) {
              handleFileUpload(fileInput.files[0])
            }
            setModalNewItem(false)
          }}
        >
          <input type="file" name="trctFile" accept="application/pdf"></input>
          <Button className="mt-16" variant="contained" type="submit" color="secondary"
                  startIcon={<UploadFileIcon fontSize="small" />}>Upload</Button>
        </form>
      </Modal>
    </div>
  )
}

export default TrctInclude
