import React, { useCallback, useEffect, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import Cookies from 'js-cookie'
import { debounce } from 'lodash'
import moment from 'moment'

import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Tooltip
} from '@mui/material'

import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import SettingsIcon from '@mui/icons-material/Settings'
import SearchIcon from '@mui/icons-material/Search'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import Modal from '../../components/Modal'
import services from '../../services'
import { Pagination, TabelaSearchColumn } from '../../components/Table'
import { useAuth } from '../../hooks/useAuth'
import { useNotification } from '../../hooks/useNotification.js'

interface ITableaUsuario {
  mainList: any[] | [] | null | undefined
  type: string
  editRegistro?: any
  handlePagination: any
  handleBusca: any
  titleTable: string
  removeRegistro?: any
  currentPage: number
  totalPages: number
  pageSize: number
  totalCount: number
  setAsPrincipal?: any
  setAsSupervisor?: any
  setAsManager?: any
  setAsAddedbyjob?: any
  objBusca: any
  setObjBusca: any
}

const tablesVisibles = ['ID do usuário', 'Nome', 'E-mail', 'Adicionado em', 'Empresa', 'Adicionado automaticamente', 'Manager', 'Supervisor', 'Principal', 'Ações']

function TabelaHeaderTh(props: any) {
  const { indexRef, visibleColumn, paramns, setSearchChange, ordeby, type } = props
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  return (
    <>
      {visibleColumn &&
        <>
          <th className={paramns.label === 'Principal' && type === 'UserRegular' ? 'hide' : ''}>
            <div>
              <div>
                {
                  paramns?.showOrderby &&
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <IconButton
                      size="small"
                      onClick={() => ordeby(false, paramns.searchRef)}
                      style={{ padding: '2px' }}
                      disabled={promiseInProgress}
                    >
                      <ArrowUpwardIcon style={{ fontSize: '12px' }} />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => ordeby(true, paramns.searchRef)}
                      style={{ padding: '2px' }}
                      disabled={promiseInProgress}
                    >
                      <ArrowDownwardIcon style={{ fontSize: '12px' }} />
                    </IconButton>
                  </div>
                }
                <span className="th-title">{paramns.label}</span>
              </div>
              {
                paramns?.showSearch &&
                <div>
                  <SearchIcon fontSize="small" className="tableSeachIccon"
                              onClick={() =>
                                setSearchChange((prevState: any) => [
                                  ...prevState.slice(0, indexRef),
                                  { ...prevState[indexRef], inputVisible: true },
                                  ...prevState.slice(indexRef + 1)
                                ])
                              }
                  />
                </div>
              }
            </div>
          </th>
        </>
      }
    </>
  )
}

function TabelaUsuarios(props: ITableaUsuario) {
  const {
    mainList, editRegistro, handleBusca, pageSize, titleTable, removeRegistro,
    setAsPrincipal, setAsSupervisor, setAsManager, setAsAddedbyjob,
    currentPage, totalPages, handlePagination, type, totalCount, objBusca, setObjBusca
  } = props

  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()
  const { getUserRoles, getUser } = useAuth()
  const userRoles = getUserRoles()
  const user = getUser()

  const [modalDeletarUsuario, setModalDeletarUsuario] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<any>({})

  const [modalShowHideColumns, setModalShowHideColumns] = useState<boolean>(false)
  const [visibleColumns, setVisibleColumns] = useState<any>([])
  const [visibleColumnsControl, setVisibleColumnsControl] = useState<any>([])

  const [filterList, setFilterList] = useState<any>(null)
  const [search, setSearch] = useState('')

  const [colunaAtiva] = useState<string>('')
  const [colunaBusca, setColunaBusca] = useState<string>('nome&&&')

  const [anchorElBuscaUserDisponivel, setAnchorElBuscaUserDisponivel] = useState<null | HTMLElement>(null)
  const openBuscaUserDisponivel = Boolean(anchorElBuscaUserDisponivel)
  const handleClickBuscaUserDisponivel = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBuscaUserDisponivel(event.currentTarget)
  }
  const handleCloseBuscaUserDisponivel = () => {
    setAnchorElBuscaUserDisponivel(null)
  }

  const [userDisponiveis, setUserDisponiveis] = useState<any>(null)
  const [searchUserDisponivel, setSearchUserDisponivel] = useState('')

  const [colunaBuscaUserDisponivel, setColunaBuscaUserDisponivel] = useState<string>('fullname')

  const [modalPerfil, setModalPerfil] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounce = useCallback(debounce((busca) => {
    handleBusca(busca, pageSize)
  }, 800), [pageSize])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounceUserDisponivel = useCallback(debounce((busca) => {
    handleBuscaUserDisponivel(busca)
  }, 800), [colunaBuscaUserDisponivel])

  const handleBuscaUserDisponivel = useCallback(async (busca: string) => {
    let paramns: any = {}
    paramns.filterBy = colunaBuscaUserDisponivel
    paramns.filterValue = busca

    try {
      const res = await trackPromise(services.api.getUsersAll(paramns, 1, 20))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar usuários disponiveis'
        )
      }
      setUserDisponiveis(res.data.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colunaBuscaUserDisponivel])


  const handleChangeBusca = (busca: string) => {
    setSearch(busca)
    handleBuscaDebounce(busca)
  }

  const handleChangeBuscaUserDisponivel = (busca: string) => {
    setSearchUserDisponivel(busca)
    handleBuscaDebounceUserDisponivel(busca)
  }

  const ordeby = (direction: boolean, column?: string) => {
    if (column) {
      handleBusca(objBusca, pageSize, column, direction)
    } else {
      handleBusca(objBusca, pageSize, colunaAtiva, direction)
    }
  }

  const handleChangeVisibleColumn = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
    let indexItem = visibleColumnsControl.indexOf(item)
    // console.log(event.target.checked, item, indexItem, visibleColumnsControl)
    if (!event.target.checked && visibleColumnsControl.length <= 1) {
      notify('Pelo menos uma coluna deve ficar visivel', { variant: 'warning' })
      return
    }
    if (event.target.checked) {
      setVisibleColumnsControl((prevState: any) => ([
          ...prevState,
          item
        ]
      ))
    } else {
      setVisibleColumnsControl((prevState: any) => ([
          ...prevState.slice(0, indexItem),
          ...prevState.slice(indexItem + 1)
        ]
      ))
    }
  }

  const confirmVisibilityColumns = () => {
    Cookies.set(`Table_Usuario_${type}_${user?.id}`, JSON.stringify(visibleColumnsControl))
    setVisibleColumns([...visibleColumnsControl])
    setModalShowHideColumns(false)
  }

  const handleOpenModalVisibleColumns = () => {
    setModalShowHideColumns(true)
    setVisibleColumnsControl([...visibleColumns])
  }


  const handleDeletUser = async (user: any) => {
    setSelectedItem(user)
    setModalDeletarUsuario(true)
  }

  const deletarUsuario = async () => {
    removeRegistro(selectedItem, type)
    setModalDeletarUsuario(false)
  }

  const handleAddModalInclude = async () => {
    let paramns: any = {}
    paramns.filterBy = 'name'
    paramns.filterValue = ''
    setModalPerfil(true)
    try {
      const res = await trackPromise(services.api.getUsersAll(paramns, 1, 20))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar usuários disponiveis'
        )
      }
      setUserDisponiveis(res.data.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const vincularUsuario = async (item: any, index: number) => {
    editRegistro(item, type)
    setUserDisponiveis((prevState: any) => ([
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1)
      ]
    ))
  }


  useEffect(() => {
    setFilterList(mainList?.map((item: any) => {
      item.selecionado = false
      return item
    }))
  }, [mainList])

  useEffect(() => {
    if (user?.id) {
      const tableCookieString: any = Cookies.get(`Table_Usuario_${type}_${user?.id}`)
      if (!tableCookieString) {
        Cookies.set(`Table_Usuario_${type}_${user?.id}`, JSON.stringify(tablesVisibles))
        setVisibleColumns([...tablesVisibles])
        setVisibleColumnsControl([...tablesVisibles])
      } else {
        const tableCookieObj = JSON.parse(tableCookieString)
        setVisibleColumns([...tableCookieObj])
        setVisibleColumnsControl([...tableCookieObj])
      }
    }
  }, [])

  return (
    <>
      <div className="wrapper-page-content-interna">
        <div className="flex items-center justify-between gap-10">
          <div className="flex gap-10"><h3>{titleTable}</h3></div>

          <div className="flex items-center gap-10">
            <div className="flex items-center gap-6" id={`ref_table_usuario_${type}`}>
              <Tooltip title="Visualização Colunas">
                <IconButton
                  className="btn-purple"
                  size="small" aria-label="upload picture"
                  onClick={handleOpenModalVisibleColumns}
                  disabled={promiseInProgress}
                >
                  <SettingsIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              {
                type === 'UserReponsavel' ?
                  <>
                    {
                      (
                        userRoles.includes('recruitermaps_create') ||
                        userRoles.includes('recruitermaps_create_any')
                      ) &&
                      <Button
                        variant="contained" type="button" color="secondary"
                        onClick={handleAddModalInclude}
                        size="small"
                        startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
                        disabled={promiseInProgress}
                      >
                        <span>Vincular usuário</span>
                      </Button>
                    }
                  </>
                  :
                  <>
                    {
                      (
                        userRoles.includes('crusermap_create') ||
                        userRoles.includes('crusermap_create_any')
                      ) &&
                      <Button
                        variant="contained" type="button" color="secondary"
                        onClick={handleAddModalInclude}
                        size="small"
                        startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
                        disabled={promiseInProgress}
                      >
                        <span>Vincular usuário</span>
                      </Button>
                    }
                  </>
              }
            </div>


          </div>
        </div>
        <div className="divider" style={{ opacity: 0 }}></div>
        <div className="table-container">
          <table className="table_styled">
            <thead>
            <tr>
              {
                objBusca?.map((item: any, index: number) => (
                  <TabelaSearchColumn
                    key={index}
                    visibleColumn={visibleColumns.includes(item.label)}
                    indexRef={index}
                    paramns={item}
                    objBusca={objBusca}
                    setSearchChange={setObjBusca}
                    handleChangeBusca={handleChangeBusca}
                  />
                ))
              }
            </tr>
            <tr>
              {
                objBusca?.map((item: any, index: number) => (
                  <TabelaHeaderTh
                    key={index}
                    ordeby={ordeby}
                    visibleColumn={visibleColumns.includes(item.label)}
                    indexRef={index}
                    paramns={item}
                    setSearchChange={setObjBusca}
                    type={type}
                  />
                ))
              }
            </tr>

            </thead>
            <tbody>
            {!filterList &&
              <tr>
                <td colSpan={visibleColumns.length + 1}>
                  <div className="justify-center">loading...</div>
                </td>
              </tr>
            }
            {filterList?.length === 0 &&
              <tr>
                <td colSpan={visibleColumns.length + 1}>
                  <div className="justify-center">Nenhum registro encontrado</div>
                </td>
              </tr>
            }
            {filterList?.map((item: any, index: number) => (
              <tr key={item.id}>
                {visibleColumns.includes('ID do usuário') && <td>
                  <div>{item.user.id}</div>
                </td>}
                {visibleColumns.includes('Nome') && <td>
                  <div>{item.user.name}</div>
                </td>}
                {visibleColumns.includes('E-mail') && <td>
                  <div>{item.user.email}</div>
                </td>}
                {visibleColumns.includes('Adicionado em') && <td>
                  <div>{moment(item.createdAt).format('DD/MM/YYYY - HH:mm')}</div>
                </td>}
                {visibleColumns.includes('Empresa') && <td>
                  <div>{item.user.company}</div>
                </td>}

                {visibleColumns.includes('Adicionado automaticamente') && <>
                  {
                    type === 'UserReponsavel' &&
                    <td>
                      <div><span className="th-title">{item.addedByJob ? <span style={{
                        background: 'green',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        color: 'white'
                      }}>Sim</span> : <span style={{
                        background: 'red',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        color: 'white'
                      }}>Não</span>}</span></div>
                    </td>
                  }
                </>
                }
                {visibleColumns.includes('Gerente') && <>
                  {
                    type === 'UserReponsavel' &&
                    <td>
                      <div><span className="th-title">{item.manager ? <span style={{
                        background: 'green',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        color: 'white'
                      }}>Sim</span> : <span style={{
                        background: 'red',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        color: 'white'
                      }}>Não</span>}</span></div>
                    </td>
                  }
                </>
                }
                {visibleColumns.includes('Supervisor') && <>
                  {
                    type === 'UserReponsavel' &&
                    <td>
                      <div><span className="th-title">{item.supervisor ? <span style={{
                        background: 'green',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        color: 'white'
                      }}>Sim</span> : <span style={{
                        background: 'red',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        color: 'white'
                      }}>Não</span>}</span></div>
                    </td>
                  }
                </>
                }

                {visibleColumns.includes('Principal') && <>
                  {
                    type === 'UserReponsavel' &&
                    <td>
                      <div><span className="th-title">{item.principal ? <span style={{
                        background: 'green',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        color: 'white'
                      }}>Sim</span> : <span style={{
                        background: 'red',
                        borderRadius: '8px',
                        padding: '4px 8px',
                        color: 'white'
                      }}>Não</span>}</span></div>
                    </td>
                  }
                </>
                }

                <td>
                  <div className="justify-end flex gap-8 flex-nowrap">
                    {
                      type === 'UserReponsavel' &&
                      !item.principal &&
                      <>
                        {
                          (
                            userRoles.includes('recruitermaps_update') ||
                            userRoles.includes('recruitermaps_update_any')
                          ) &&
                          <Button
                            style={{ fontSize: '12px', width: '120px' }}
                            variant="contained" type="button" color="secondary"
                            onClick={() => setAsPrincipal(item)}
                            size="small"
                            disabled={promiseInProgress}
                          >
                            <span>Tornar Principal</span>
                          </Button>
                        }
                      </>
                    }
                    {
                      type === 'UserReponsavel' &&
                      !item.supervisor &&
                      <>
                        {
                          (
                            userRoles.includes('recruitermaps_update') ||
                            userRoles.includes('recruitermaps_update_any')
                          ) &&
                          <Button
                            style={{ fontSize: '12px', width: '130px' }}
                            variant="contained" type="button" color="secondary"
                            onClick={() => setAsSupervisor(item)}
                            size="small"
                            disabled={promiseInProgress}
                          >
                            <span>Tornar Supervisor</span>
                          </Button>
                        }
                      </>
                    }
                    {
                      type === 'UserReponsavel' &&
                      !item.manager &&
                      <>
                        {
                          (
                            userRoles.includes('recruitermaps_update') ||
                            userRoles.includes('recruitermaps_update_any')
                          ) &&
                          <Button
                            style={{ fontSize: '12px', width: '120px' }}
                            variant="contained" type="button" color="secondary"
                            onClick={() => setAsManager(item)}
                            size="small"
                            disabled={promiseInProgress}
                          >
                            <span>Tornar Gerente</span>
                          </Button>
                        }
                      </>
                    }
                    {/* {
                        type === 'UserReponsavel' &&
                        !item.addedbyjob &&
                        <>
                          {
                            (
                              userRoles.includes("recruitermaps_update") || 
                              userRoles.includes("recruitermaps_update_any")
                            ) && 
                            <Button 
                              style={{fontSize: '12px', width: '120px'}}
                              variant="contained" type="button" color="secondary"
                              onClick={() => setAsAddedbyjob(item)}
                              size="small"
                              disabled={promiseInProgress}
                            >
                              <span>Tornar Addedbyjob</span>
                            </Button>
                          }
                        </>
                      } */}

                    {
                      type === 'UserReponsavel' ?
                        <>
                          {
                            (
                              userRoles.includes('recruitermaps_delete') ||
                              userRoles.includes('recruitermaps_delete_any')
                            ) &&
                            <Tooltip title="Desvincular">
                              <span>
                                <IconButton
                                  size="small"
                                  style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    marginRight: '0px',
                                    borderRadius: '4px'
                                  }}
                                  onClick={() => handleDeletUser(item)}
                                  edge="end"
                                  disabled={promiseInProgress}
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          }
                        </>
                        :
                        <>
                          {
                            (
                              userRoles.includes('crusermap_delete') ||
                              userRoles.includes('crusermap_delete_any')
                            ) &&
                            <Tooltip title="Desvincular">
                              <span>
                                <IconButton
                                  size="small"
                                  style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    marginRight: '0px',
                                    borderRadius: '4px'
                                  }}
                                  onClick={() => handleDeletUser(item)}
                                  edge="end"
                                  disabled={promiseInProgress}
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          }
                        </>
                    }
                  </div>
                </td>

              </tr>
            ))}
            </tbody>
          </table>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            handlePagination={handlePagination}
            colunaBusca={colunaBusca}
            search={search}
            itensLength={filterList?.length}
            totalCount={totalCount}
          />

        </div>
      </div>

      {/* vincular */}
      <Modal
        size="sm"
        open={modalPerfil}
        close={() => setModalPerfil(false)}
        titulo={'Adicionar Usuários'}
      >
        <Grid container spacing={{ xs: 3 }} id="Modal_seach_user">
          <Grid item md={12} xs={12}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <Grid item md={12}>
                <TextField
                  size="small"
                  className="w-200"
                  label="Pesquisa"
                  placeholder="Pesquisa"
                  inputProps={{ maxLength: 50 }}
                  value={searchUserDisponivel}
                  onChange={e => handleChangeBuscaUserDisponivel(e.target.value)}
                  disabled={promiseInProgress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{
                            backgroundColor: '$cor-primary',
                            color: 'white',
                            padding: '2px',
                            marginRight: '-8px',
                            borderRadius: '4px'
                          }}
                          aria-label="toggle password visibility"
                          onClick={(e) => handleClickBuscaUserDisponivel(e)}
                          edge="end"
                        >
                          <FilterAltIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <div className="table-container">
                  <table className="table_styled">
                    <thead>
                    <tr>
                      <th>
                        <div>
                          <span className="th-title">Usuário</span>
                        </div>
                      </th>
                      <th>
                        <div><span className="th-title"></span></div>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {!userDisponiveis &&
                      <tr>
                        <td colSpan={8}>
                          <div className="justify-center">loading...</div>
                        </td>
                      </tr>
                    }
                    {userDisponiveis?.length === 0 &&
                      <tr>
                        <td colSpan={8}>
                          <div className="justify-center">Nenhum registro encontrado</div>
                        </td>
                      </tr>
                    }
                    {userDisponiveis?.map((item: any, index: number) => (
                      <tr key={item.id}>
                        <td>
                          <div style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <span>{item.name}</span>
                            <span style={{ fontSize: '12px' }}>{item.email}</span>
                            <span style={{ fontSize: '12px' }}>{item.company}</span>
                          </div>
                        </td>
                        <td>
                          <div className="justify-end">
                            <Button
                              variant="contained" type="button" color="secondary"
                              onClick={() => vincularUsuario(item, index)}
                              size="small"
                              disabled={promiseInProgress}
                            >
                              <span>Adicionar</span>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              type="button"
              size="small"
              color="inherit"
              onClick={() => setModalPerfil(false)}
            >
              cancelar
            </Button>
          </Grid>
        </Grid>

      </Modal>

      {/* modal usuário remover */}
      <Modal
        size="sm"
        open={modalDeletarUsuario}
        close={() => setModalDeletarUsuario(false)}
        titulo={'Remover Usuário'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12}>
            <p className="center">
              Deseja mesmo remover o usuário selecionado?
            </p>
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              type="button"
              size="small"
              color="inherit"
              onClick={() => setModalDeletarUsuario(false)}
            >
              cancelar
            </Button>
            <Button
              variant="contained"
              type="button"
              size="small"
              color="error"
              disabled={promiseInProgress}
              onClick={deletarUsuario}
            >
              remover
            </Button>
          </Grid>
        </Grid>
      </Modal>

      {/* show hide columns */}
      <Modal
        size="sm"
        open={modalShowHideColumns}
        close={() => setModalShowHideColumns(false)}
        titulo={'Selecionar Colunas'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12} className="flex gap-6">
            <FormGroup>
              {tablesVisibles.filter(item => item !== 'Ações').map((item, index) => (
                <FormControlLabel
                  className={item === 'Principal' && type === 'UserRegular' ? 'hide' : ''}
                  key={index}
                  control={
                    <Switch
                      checked={visibleColumnsControl.includes(item)}
                      onChange={(e) => handleChangeVisibleColumn(e, item)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={item}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              color="secondary"
              type="button"
              size="small"
              onClick={confirmVisibilityColumns}
            >
              Aplicar
            </Button>
          </Grid>
        </Grid>
      </Modal>


      {/* menu opções busca user disponivel*/}
      <Menu
        anchorEl={anchorElBuscaUserDisponivel}
        open={openBuscaUserDisponivel}
        onClose={handleCloseBuscaUserDisponivel}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))'
          }
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <div style={{ padding: '2px 8px', fontSize: '14px' }}>
            Buscar por
          </div>
          <div style={{ padding: '2px 8px' }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="tipoBuscaColunaBusca-label"
                name="tipoBuscaColunaBusca"
                onChange={e => setColunaBuscaUserDisponivel(e.target.value)}
                value={colunaBuscaUserDisponivel}
              >
                <FormControlLabel value="fullname" control={<Radio />} label="Nome" />
                <FormControlLabel value="username" control={<Radio />} label="Login" />
                <FormControlLabel value="company" control={<Radio />} label="Empresa" />
                <FormControlLabel value="email" control={<Radio />} label="Email" />
                <FormControlLabel value="id" control={<Radio />} label="Id" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

      </Menu>
    </>
  )
}

export default TabelaUsuarios
