import trade from '../company/trade/content.json'


export const getCompany = (): CompanyProps => {
  const company = process.env.NEXT_PUBLIC_COMPANY
  return trade
}


interface CompanyProps {
  companyName: string
  slug: string
  legalName: string
  imageUrl: string
}