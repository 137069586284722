import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { trackPromise } from 'react-promise-tracker'

import { MenuItem, Select } from '@mui/material'

import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification.js'

import EnviriomentsList from '../../components/EnviriomentsList/index'
import SubMenuDash from '../../components/SubMenuDash/index'
import PowerBi from '../../components/PowerBi/index'

import services from '../../services/index.js'
import { IDashboard, IDashboardGroup, IEnvironment, IPowerBiData } from '../../interfaces/index'
import './style.scss'

import { toNumber } from 'lodash'
import { number } from '../../services/mask'

function Dashboard() {
  const navigate = useNavigate()
  // const { promiseInProgress } = usePromiseTracker()
  const { id } = useParams()
  const { envId } = useParams()
  const { notify } = useNotification()
  const [environments, setEnvironments] = useState<IEnvironment[] | []>([])
  const [activeEnvironment, setActiveEnvironment] = useState<IEnvironment>()
  const [dashboardGroups, setDashboardGroups] = useState<IDashboardGroup[] | []>([])
  const [powerBiData, setPowerBiData] = useState<IPowerBiData>({
    enbedUrl: '',
    expiration: '',
    token: '',
    tokenId: ''
  })
  const [pageTitle, setPageTitle] = useState<string>('')
  const [reloadDash, setReloadDash] = useState<number>(0)
  const [autoRefreshTime, setAutoRefreshTime] = useState<any>(null)
  const [refreshPowerBi, setRefreshPowerBi] = useState<number>(1)

  const [enableFilters, setEnableFilters] = useState<boolean | undefined>(false)
  const [enableNavigationBar, setEnableNavigationBar] = useState<boolean | undefined>(false)

  const onUnmount: any = React.useRef()

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)

  const [reprocessMonth, setReprocessMonth] = useState('Mês')
  const [reprocessYear, setReprocessYear] = useState(getLastFiveYears()[0])
  const [reprocessEnabled, setReprocessEnabled] = useState(false)

  function getLastFiveYears() {
    let date = new Date()
    let thisYear = date.getFullYear()
    return [thisYear, thisYear - 1, thisYear - 2, thisYear - 3, thisYear - 4, thisYear - 5]
  }

  const handleFullScreenOn = useCallback(() => {
    let item: any = document.getElementById('container-power-dash')
    item.requestFullscreen()
  }, [])
  const handleFullScreenOff = useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    }
  }, [])

  const selectDashBoard = useCallback((dashId: number) => {
    navigate(`/app/dashboard/${dashId}/${activeEnvironment?.id!}`)

  }, [navigate, activeEnvironment])

  const loadDashBoardGroups = useCallback(async (environmentId: number) => {
    try {
      const res = await trackPromise(services.api.dashboardgroupsRelatedByEnvironment(environmentId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar os grupos '
        )
      }
      setDashboardGroups(res.data)
      let possuiDashValido = false
      if (res.data.length) {
        for (let x = 0; x < res.data.length; x++) {
          if (res.data[x].dashboards.length) {

            possuiDashValido = true

            if (envId === undefined || id === undefined) {
              navigate(`/app/dashboard/${res.data[x].dashboards[0].id}/${environmentId}`)
            } else {
              navigate(`/app/dashboard/${id}/${envId}`)
            }
            break
          }
        }
      }
      if (!possuiDashValido) {
        notify('Esta Empresa não possui dashboard cadastrado', { variant: 'warning' })
      }
    } catch (error: any) {
      notify(error.message, {
        variant: 'error'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notify])

  const selectEnvironment = (environment: IEnvironment) => {
    if (environment) {
      setActiveEnvironment(environment)
      loadDashBoardGroups(environment.id)
    }
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', (event) => {
      if (document.fullscreenElement === null) {
        setIsFullScreen(false)
      } else {
        setIsFullScreen(true)
      }
    })
  }, [])

  useEffect(() => {
    const getEnvironments = async () => {
      try {
        const res = await trackPromise(services.api.environmentsRelatedAll())

        if (res.fail) {
          throw new Error(
            res.error || 'Erro ao carregar os ambientes.'
          )
        }
        if (!res.data.length) {
          let noDataEnv: IEnvironment = {
            id: 0,
            name: 'Nenhum ambiente'
          }

          setActiveEnvironment(noDataEnv)
          throw new Error(
            res.error || 'Você não tem acesso a nenhum ambiente. Contate o administrador.'
          )
        }
        setEnvironments(res.data)
        if (envId !== undefined) {
          let targetEnv = res.data.filter((it: IEnvironment) => it.id == number(envId))[0]
          setActiveEnvironment(targetEnv)
          loadDashBoardGroups(number(envId))

        } else {
          setEnvironments(res.data)
          setActiveEnvironment(res.data[0])
          loadDashBoardGroups(res.data[0].id)
        }


      } catch (error: any) {
        notify(error.message, {
          variant: 'error'
        })
      }
    }
    getEnvironments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadDashBoardGroups])

  useEffect(() => {
    const powerBi = async () => {
      try {
        const res = await trackPromise(services.api.powerbiKey(id, envId))
        if (res.fail) {


          if (res.status == 403) {
            throw new Error(
              res.error || 'Você não tem permissão para acessar este dashboard'
            )
          }
          throw new Error(
            res.error || 'Erro ao carregar PowerBi'
          )
        }
        setPowerBiData(res.data)
      } catch (error: any) {
        notify(error.message, {
          variant: 'error'
        })
      }
    }
    if (id && dashboardGroups.length) {
      powerBi()
      dashboardGroups.forEach((item: IDashboardGroup) => {
        item.dashboards.forEach((subItem: IDashboard) => {
          if (subItem.id === Number(id)) {
            setPageTitle(subItem.name)
            setAutoRefreshTime(subItem.autoRefreshTime)
            setEnableFilters(subItem.enableFilters)
            setEnableNavigationBar(subItem.enableNavigationBar)
          }
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dashboardGroups])

  useEffect(() => {
    const powerBi = async () => {
      try {
        const res = await trackPromise(services.api.powerbiKey(id, envId))
        if (res.fail) {
          throw new Error(
            res.error || 'Erro ao carregar PowerBi'
          )
        }
        setReloadDash(prev => prev + 1)
        setPowerBiData(res.data)
      } catch (error: any) {
        notify(error.message, {
          variant: 'error'
        })
      }
    }

    if (id && autoRefreshTime) {
      onUnmount.current = setInterval(function() {
        powerBi()
        setRefreshPowerBi(prevState => prevState + 1)
      }, autoRefreshTime * 1000)
    } else {
      clearInterval(onUnmount.current)
    }

    return () => clearInterval(onUnmount.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, autoRefreshTime, envId])

  return (

    <div className="wrapper-container-content flex">
      <SubMenuDash listMenu={dashboardGroups} action={selectDashBoard} activeDash={id} />
      <div className="wrapper-page-content page-dash">
        {dashboardGroups.length > 0 && <PageTitle text={pageTitle} />}
        {/*<DashboardAction reportName="Reprocessar"></DashboardAction>*/}

        {reprocessEnabled &&
          <Select
            required
            size="small"
            value={reprocessMonth}
            onChange={e => setReprocessMonth(e.target.value)}
          >
            <MenuItem value="Mês" disabled>
              Mês
            </MenuItem>
            <MenuItem value="01">
              Janeiro
            </MenuItem>
            <MenuItem value="02">
              Fevereiro
            </MenuItem>
            <MenuItem value="03">
              Março
            </MenuItem>
            <MenuItem value="04">
              Abril
            </MenuItem>
            <MenuItem value="05">
              Maio
            </MenuItem>
            <MenuItem value="06">
              Junho
            </MenuItem>
            <MenuItem value="07">
              Julho
            </MenuItem>
            <MenuItem value="08">
              Agosto
            </MenuItem>
            <MenuItem value="09">
              Setembro
            </MenuItem>
            <MenuItem value="10">
              Outubro
            </MenuItem>
            <MenuItem value="11">
              Novembro
            </MenuItem>
            <MenuItem value="12">
              Dezembro
            </MenuItem>
          </Select>
        }

        {reprocessEnabled &&
          <Select
            required
            size="small"
            value={reprocessYear}
            onChange={e => setReprocessYear(toNumber(e.target.value))}
          >
            {getLastFiveYears().map((item, index) => {
              return <MenuItem value={item}>
                {item}
              </MenuItem>
            })}

          </Select>
        }

        <EnviriomentsList environments={environments} activeEnvironmentId={activeEnvironment?.id}
                          activeEnvironmentName={activeEnvironment?.name} changeActiveEnvironment={selectEnvironment} />
        <div id="container-power-dash">
          {dashboardGroups.length > 0 &&
            <PowerBi
              key={refreshPowerBi}
              powerBiData={powerBiData}
              reload={reloadDash}
              enableFilters={enableFilters}
              enableNavigationBar={enableNavigationBar}
              isfullscreen={isFullScreen}
              fullscreenOff={handleFullScreenOff}
              fullscreenOn={handleFullScreenOn}
            />
          }
        </div>

      </div>
    </div>
  )
}

export default Dashboard
