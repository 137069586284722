import React, { useEffect, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import { Badge, Button, Drawer, FormControlLabel, FormGroup, Grid, IconButton, Switch, Tooltip } from '@mui/material'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import NotificationsIcon from '@mui/icons-material/Notifications'
import CancelIcon from '@mui/icons-material/Cancel'

import { useNotification } from '../../hooks/useNotification.js'
import services from '../../services/index.js'

import './style.scss'

function Notifications() {
  const navigate = useNavigate()
  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()
  const [modal, setModal] = useState<boolean>(false)
  const [notificacoes, setNotificacoes] = useState<any>(null)
  const [reading, setReading] = useState<boolean>(true)
  const [numberOfnot, setNumberOfnot] = useState<number>(0)
  const onUnmount: any = React.useRef()

  const handleopen = async () => {
    setModal(true)
    setReading(true)
    try {
      const res = await trackPromise(services.api.getNotificationsUnread())
      if (res.fail) {
        setNotificacoes([])

        throw new Error(
          res.error || 'Não foi possível consultar Notificações'
        )
      }
      setNumberOfnot(res.data.length)
      setNotificacoes(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const consultarAtuais = async () => {
    setReading(true)
    try {
      const res = await trackPromise(services.api.getNotificationsUnread())
      if (res.fail) {
        setNotificacoes([])

        throw new Error(
          res.error || 'Não foi possível consultar Notificações'
        )
      }
      setNumberOfnot(res.data.length)
      setNotificacoes(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const consultarHistorico = async () => {
    setReading(false)
    try {
      const res = await trackPromise(services.api.getNotificationsRead())
      if (res.fail) {
        setNotificacoes([])

        throw new Error(
          res.error || 'Não foi possível consultar Notificações'
        )
      }
      setNotificacoes(res.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const setRead = async (itemId: number) => {
    try {
      const res = await trackPromise(services.api.readNotifications([itemId]))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível marcar item como lido'
        )
      }
      setNumberOfnot((prevState: number) => prevState - 1)
      setNotificacoes(notificacoes.filter((itens: any) => itens.id !== itemId))
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const marcarTodosComoLido = async () => {
    const listIds = notificacoes.map((Item: any) => Item.id)
    try {
      const res = await trackPromise(services.api.readNotifications(listIds))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível marcar item como lido'
        )
      }
      setNumberOfnot(0)
      setNotificacoes([])
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const handleHistorico = (value: boolean) => {
    if (value) {
      consultarHistorico()
    } else {
      consultarAtuais()
    }
  }

  const gotoTicket = (idticket: any) => {
    navigate(`/app/chamados/detalhe/${idticket}`)
    setModal(false)
  }

  useEffect(() => {
    const updateNotificacoes = async () => {
      try {
        const res = await services.api.getNotificationsUnread()
        if (res.fail) {
          setNotificacoes([])
          throw new Error(
            res.error || 'Não foi possível consultar Notificações'
          )
        }
        setNumberOfnot(res.data.length)
        setNotificacoes(res.data)
      } catch (error: any) {
        console.log('erro nas notivicações', error.message)
        // notify(error.message, {variant: 'error'})
      }
    }

    if (!modal) {
      onUnmount.current = setInterval(function() {
        updateNotificacoes()
      }, 5000)
    } else {
      clearInterval(onUnmount.current)
    }

    return () => clearInterval(onUnmount.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal])

  return (
    <div className={`notifications_container`}>
      <div className={`notifications_icon`}>
        <Tooltip title="Notificações">
          <IconButton
            type="button" color="secondary" aria-label="notificações" onClick={handleopen}
          >
            <Badge badgeContent={numberOfnot > 0 ? numberOfnot : null} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </div>

      <Drawer
        anchor={'right'}
        open={modal}
        onClose={() => setModal(false)}
      >
        <div style={{ maxWidth: '500px' }}>
          <div className="flex gap-10 items-center"
               style={{ borderBottom: '2px solid $cor-primary', paddingBottom: '10px', margin: '20px 20px 0 20px' }}>
            <span>
              <div><strong>Notificações</strong></div>
              <div>
                {reading &&
                  <Button
                    variant="text"
                    type="button"
                    size="small"
                    style={{ padding: '4px 0px 4px 0px', background: 'white' }}
                    onClick={marcarTodosComoLido}
                    disabled={promiseInProgress}
                  >
                    Marcar todas como lida
                  </Button>
                }
              </div>
            </span>
            <IconButton
              aria-label="close"
              onClick={() => setModal(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8
              }}
            >
              <CancelIcon />
            </IconButton>
          </div>
          <div className="chamados_box m-20">
            <Grid container spacing={{ xs: 2 }}>
              <Grid item xs={12}>
                <div className="flex justify-start">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!reading}
                          onChange={(e) => handleHistorico(e.target.checked)}
                          disabled={promiseInProgress}
                        />
                      }
                      label="Consultar notificações lidas"
                    />
                  </FormGroup>
                  {/* {reading ? 
                    <Button
                      variant="contained"
                      type="button"
                      size='small'
                      style={{minWidth: '90px'}}
                      color="secondary" 
                      onClick={consultarHistorico}
                      disabled={promiseInProgress}
                    >
                      Consultar notificações antigas
                    </Button>
                    : 
                    <Button
                      variant="contained"
                      type="button"
                      size='small'
                      style={{minWidth: '90px'}}
                      color="secondary" 
                      onClick={consultarAtuais}
                      disabled={promiseInProgress}
                    >
                      Consultar notificações atuais
                    </Button>
                  } */}
                </div>
              </Grid>
              <Grid item xs={12}>
                {!notificacoes &&
                  <div className="justify-center">loading...</div>
                }
                {notificacoes?.length === 0 &&
                  <div className="justify-center">Nenhum registro encontrado</div>
                }
                {notificacoes?.map((item: any, index: number) => (
                  <div className={`notificationItem`} key={item.id}>
                    <div>
                      <div className="notificationItem_text mb-10">{services.utils.parseLines(item.message)}</div>
                      <div
                        className="notificationItem_date">{item?.createdAt ? `${moment(item.createdAt).format('DD/MM/YYYY HH:mm')}` : ''} </div>
                    </div>
                    <div className="flex justify-end flex-col gap-0 items-center">
                      {
                        item.type === 'TICKET' &&
                        <Tooltip title="Ver detalhe do ticket">
                          <IconButton
                            type="button" color="secondary" aria-label="ticket"
                            disabled={promiseInProgress}
                            onClick={() => gotoTicket(item.notificationData)}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                      }
                      {reading &&
                        <Tooltip title="Marcar como lido">
                          <IconButton
                            type="button" color="secondary" aria-label="notificações"
                            disabled={promiseInProgress}
                            onClick={() => setRead(item.id)}
                          >
                            <CheckBoxOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      }
                      {!reading &&
                        <Tooltip title="Lido">
                          <CheckBoxIcon style={{ color: 'green' }} />
                        </Tooltip>
                      }
                    </div>
                  </div>
                ))}

              </Grid>
            </Grid>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default Notifications
