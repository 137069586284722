import { useEffect, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import base64 from 'react-native-base64'
import Cookies from 'js-cookie'
import { useLocation, useNavigate } from 'react-router-dom'
import QRCode from 'react-qr-code'
import mfaImage from '../../assets/img/mfa.png'


import { Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import FingerprintIcon from '@mui/icons-material/Fingerprint'

import { useNotification } from '../../hooks/useNotification.js'
import services from '../../services/index.js'
import logoTrade from '../../assets/img/trade/logo.png'
import logoPop from '../../assets/img/pop/logo.png'
import logoTradeMark from '../../assets/img/trademark/logo.png'
import logoSeven from '../../assets/img/seven/logo.png'
import './style.scss'
import { getCompany } from '../../configs/company'

const company = getCompany()
let logo: string
if (company.slug == 'TRADE') {
  logo = logoTrade
} else if (company.slug == 'POP') {
  logo = logoPop
} else if (company.slug == 'SEVEN') {
  logo = logoSeven
} else if (company.slug == 'TRADEMARK') {
  logo = logoTradeMark
}

function Login() {
  const navigate = useNavigate()
  const location = useLocation()
  const { promiseInProgress } = usePromiseTracker()

  const { notify } = useNotification()

  const [userLogin, setUserLogin] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [newSenha, setNewSenha] = useState('')
  const [totpEnabled, setTotpEnabled] = useState(false)
  const [mfaPassword, setMfaPassword] = useState('')

  const [otpauthLink, setOtpauthLink] = useState('')
  const [totpKey, setTotpKey] = useState('')

  const [mfaCode, setMfaCode] = useState('')
  const [mfaType, setMfaType] = useState('')
  const [oldSenha, setOldSenha] = useState('')
  const [confirmSenha, setConfirmSenha] = useState('')
  const [objRecovery, setObjRecovery] = useState<any>({})

  const [typePassword, setTypePassword] = useState(true)
  const [typePasswordNew, setTypePasswordNew] = useState(true)
  const [typePasswordConfirm, setTypePasswordConfirm] = useState(true)
  const [typeOldPassword, setTypeOldPassword] = useState(true)

  const [step, setStep] = useState(0) // 0 = login; 1 = esqueci senha; 2 = renovar senha; 3 = escolher MFA, 4 = TOTP Login

  const [userToken, setUserToken] = useState('')
  const [usarToken] = useState(false)

  const [cronometro, setCronometro] = useState(0)
  const [cronometroMfa, setCronometroMfa] = useState(0)

  const redirectToValidRoute = (roles: any, user: any) => {
    if (roles.includes('bi_dashboards_read_any') || roles.includes('bi_dashboards_read')) {
      navigate('/app/dashboard')
    } else if (roles.includes('showmenu_ticket_res')) {
      navigate('/app/chamados/meus-chamados')
    } else if (roles.includes('showmenu_recruitermaps')) {
      navigate('/app/clientes/listagem')
    } else {
      navigate('/nao-autorizado')
    }
  }

  useEffect(() => {
    const token = Cookies.get('TradeTalentosTokenPAT')
    if (token) {
      const userObj: any = Cookies.get('TradeTalentosUserPAT')
      const decodedUser: any = JSON.parse(userObj)
      const userRoles = decodedUser?.role
        ? decodedUser.role.map((x: any) => x)
        : []

      if (userRoles.length) {
        if (userRoles.includes('bi_dashboards_read_any') || userRoles.includes('bi_dashboards_read')) {
          navigate('/app/dashboard')
        } else if (userRoles.includes('showmenu_ticket_res')) {
          navigate('/app/chamados/meus-chamados')
        } else if (userRoles.includes('showmenu_recruitermaps')) {
          navigate('/app/clientes/listagem')
        } else {
          navigate('/nao-autorizado')
        }
      } else {
        Cookies.remove('TradeTalentosTokenPAT')
        Cookies.remove('TradeTalentosUserPAT')
      }
    }
  }, [navigate])

  useEffect(() => {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const myParam = urlParams.get('recovery')
      if (myParam) {
        const decodeObj: any = base64.decode(myParam ? myParam : '')
        const decodeParse: any = JSON.parse(decodeObj)
        // console.log('value', myParam)
        // console.log('decodeObj', decodeObj);
        if (!decodeParse?.secret || !decodeParse?.secretId || !decodeParse?.Expiration) {
          throw new Error('Erro ao tentar recuperar senha')
        }

        const dateExpire = decodeParse?.Expiration.split('T')[0].replace(/-/g, '/') + ' ' + decodeParse?.Expiration.split('T')[1].substring(0, 5)
        if (!dateExpire) {
          throw new Error('Erro ao tentar recuperar senha')
        }
        const expireDateFormat = new Date(dateExpire)
        const dataAtual = new Date()
        if (expireDateFormat < dataAtual) {
          throw new Error('Código de recuperaçao expirado')
        }

        setNewSenha('')
        setConfirmSenha('')
        setObjRecovery(decodeParse)
        setStep(2)

      }
      //notify('Enviamos no e-mail cadastrado o código de verificação', { variant: 'success' })
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    const handleCronometro = () => {
      setCronometro(prev => prev - 1)
    }
    let timer: any = null
    if (cronometro > 0) {
      timer = setTimeout(() => {
        handleCronometro()
      }, 1000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [cronometro])


  useEffect(() => {
    const handleCronometroMfa = () => {
      console.log('TIMER')
      setCronometroMfa(prev => prev - 1)
    }
    let timerMfa: any = null
    if (cronometroMfa > 0) {
      timerMfa = setTimeout(() => {
        handleCronometroMfa()
      }, 1000)
    }
    return () => {
      clearTimeout(timerMfa)
    }
  }, [cronometroMfa])


  const execAuth = async (retUser: any) => {
    Cookies.set('TradeTalentosTokenPAT', retUser.token)
    const user = {
      user: retUser.userData,
      role: retUser.claims
    }
    Cookies.set('TradeTalentosUserPAT', JSON.stringify(user), {
      sameSite: 'strict'
    })
    redirectToValidRoute(retUser.claims, retUser.userData)
  }

  const logarUser = async (userParam: string, senhaParam: string) => {
    try {
      const res: any = await trackPromise(
        services.api.login(userParam, senhaParam)
      )
      if (res.status === 428) {
        notify(res.error, {
          variant: 'warning'
        })
        setOldSenha(userPassword)
        setStep(3)
        return
      }
      if (res.status === 202) {
        setStep(4)
        setMfaPassword(userPassword)
        setTotpEnabled(res.data.extra.totp)
        return
      }
      if (res.status === 401) {
        if (res.data) {
          setStep(4)
          setMfaPassword(userPassword)
          setTotpEnabled(res.data.extra.totp)
        } else {
          setStep(0)
          throw new Error(res.error || 'Erro ao logar usuário')
        }
        throw new Error(res.error || 'Erro ao logar usuário')
        return
      }
      execAuth(res.data)
    } catch (error: any) {
      Cookies.remove('TradeTalentosTokenPAT')
      Cookies.remove('TradeTalentosUserPAT')
      notify(error.message, {
        variant: 'error'
      })
    }
  }

  const logarUserMfa = async (userParam: string, senhaParam: string, mfaType: string, mfaCode: string) => {
    try {
      const res: any = await trackPromise(
        services.api.loginWithMfa(userParam, senhaParam, mfaType, mfaCode)
      )
      if (res.fail) {
        throw new Error('Código invalido')
      }
      execAuth(res.data)
    } catch (error: any) {
      Cookies.remove('TradeTalentosTokenPAT')
      Cookies.remove('TradeTalentosUserPAT')
      notify(error.message, {
        variant: 'error'
      })
    }
  }

  const finishTotpMfaConfig = async (userParam: string, senhaParam: string, mfaType: string, mfaCode: string) => {
    try {
      const res: any = await trackPromise(
        services.api.finishTotpConfiguration(userParam, senhaParam, totpKey, mfaCode)
      )
      if (res.fail) {
        throw new Error('Código invalido')
      }
      setStep(7)
    } catch (error: any) {
      Cookies.remove('TradeTalentosTokenPAT')
      Cookies.remove('TradeTalentosUserPAT')
      notify(error.message, {
        variant: 'error'
      })
    }
  }

  const validarLogin = async (e: any) => {
    e.preventDefault()

    if (!usarToken) {
      if (!userLogin) {
        notify('Informe um usuário', { variant: 'warning' })
        return
      }
      if (!userPassword || userPassword.length < 6) {
        notify('Informe um senha válida a partir de 6 digitos', {
          variant: 'warning'
        })
        return
      }
    } else {
      if (!userToken) {
        notify('Informe um token válido', { variant: 'warning' })
        return
      }
    }

    logarUser(userLogin.trim(), userPassword)
  }


  const validarLoginMfa = async (e: any) => {
    e.preventDefault()

    if (!mfaCode || mfaCode.length != 6) {
      notify('Informe um código valido', { variant: 'warning' })
      return
    }
    logarUserMfa(userLogin.trim(), userPassword, mfaType, mfaCode)
  }

  const validarTotpConfiguration = async (e: any) => {
    e.preventDefault()

    if (!mfaCode || mfaCode.length != 6) {
      notify('Informe um código valido', { variant: 'warning' })
      return
    }
    finishTotpMfaConfig(userLogin.trim(), userPassword, mfaType, mfaCode)
  }


  const passwordRecover = async (e: any) => {
    e.preventDefault()

    if (!userEmail) {
      notify('Informe um usuário ou e-mail válido', { variant: 'warning' })
      return
    }

    try {
      //const isEmail = userEmail.includes('@') && userEmail.includes('.com')
      const res = await trackPromise(services.api.recoveryRequest(userEmail.trim()))
      if (res.fail) {
        throw new Error(
          res.error || 'Erro ao tentar recuperar senha'
        )
      }
      notify('Enviamos no e-mail cadastrado o link de verificação', { variant: 'success' })
      setStep(1)
      setUserEmail('')
      setCronometro(30)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const showAuthenticatorForm = async (e: any) => {
    e.preventDefault()
    setMfaType('TOTP')
    setStep(5)
  }

  const showEmailMfaForm = async (e: any) => {
    e.preventDefault()
    setMfaType('EMAIL')

    try {
      const res = await trackPromise(services.api.requestMfaEmailCode(userLogin))
      if (res.fail) {
        throw new Error(
          res.error || 'Erro ao solicitar o código'
        )
      }
      setStep(8)
      setCronometroMfa(30)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const resendEmail = async (e: any) => {
    setCronometroMfa(30)
    e.preventDefault()
    setMfaType('EMAIL')

    try {
      const res = await trackPromise(services.api.requestMfaEmailCode(userLogin))
      if (res.fail) {
        throw new Error(
          res.error || 'Erro ao solicitar o código'
        )
      }
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }


  const showAuthenticatorConfigureForm = async (e: any) => {
    e.preventDefault()

    try {
      //const isEmail = userEmail.includes('@') && userEmail.includes('.com')
      const res = await trackPromise(services.api.getTotpConfiguration(userLogin, userPassword))
      if (res.fail) {
        throw new Error(
          'Erro ao configurar o MFA'
        )
      } else {
        setOtpauthLink(res.data.otpauth)
        setTotpKey(res.data.key)
        setMfaType('TOTP')
        setStep(6)
      }
    } catch (error: any) {
      setStep(0)
    }


  }

  const showLoginForm = async (e: any) => {
    e.preventDefault()
    setStep(0)
  }

  const redefinirSenha = async (e: any) => {
    e.preventDefault()

    if (!newSenha) {
      notify('Informe uma senha válido', { variant: 'warning' })
      return
    }

    if (newSenha.length < 6) {
      notify('Informe uma senha maior que 6 caracteres', { variant: 'warning' })
      return
    }

    if (newSenha !== confirmSenha) {
      notify('Senha informada é igual a confirmação', { variant: 'warning' })
      return
    }

    if (!objRecovery?.UserEmail || !objRecovery?.secret || !objRecovery?.secretId) {
      notify('Código de recuperação não localizado', { variant: 'warning' })
      return
    }

    // recuperação se senha por email
    try {
      const res: any = await trackPromise(
        services.api.changePasswordByEmail(objRecovery.UserEmail, objRecovery.secret, objRecovery.secretId, newSenha)
      )
      if (res.fail) {
        throw new Error(res.error || 'Erro ao redefinir senha')
      }
      notify('Senha alterada com sucesso!', { variant: 'success' })
      setStep(0)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const redefinirSenhaExpirada = async (e: any) => {
    e.preventDefault()

    if (!oldSenha) {
      notify('Informe sua senha antiga', { variant: 'warning' })
      return
    }

    if (!newSenha) {
      notify('Informe uma senha válido', { variant: 'warning' })
      return
    }

    if (newSenha.length < 6) {
      notify('Informe uma senha maior que 6 caracteres', { variant: 'warning' })
      return
    }

    if (newSenha !== confirmSenha) {
      notify('Senha informada é igual a confirmação', { variant: 'warning' })
      return
    }

    // redefinição de senha expirada
    try {
      const res: any = await trackPromise(
        services.api.changePasswordExpired(userLogin, newSenha, oldSenha)
      )
      if (res.fail) {
        throw new Error(res.error || 'Erro ao redefinir senha')
      }
      setStep(0)
      notify('Senha alterada com sucesso!', { variant: 'success' })
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }

  }

  return (
    <div className="login-page">

      {/* FORM LOGIN */}
      <div className="login-page-container fadein">
        <div className="login-page-content">
          <div>
            <img src={logo} alt="TradeTalentos" className="login-page-logo" />
          </div>
          <div className="login-divider-container"></div>
          <div className="login-form">
            {/* Login */}
            {step === 0 &&
              <form onSubmit={validarLogin}>
                <Grid container spacing={{ xs: 2 }}>
                  {!usarToken && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          autoFocus
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          id="input_user_login"
                          label="Usuário"
                          placeholder="Email ou usuário"
                          inputProps={{ maxLength: 240 }}
                          value={userLogin}
                          onChange={e => setUserLogin(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}
                          label="Senha"
                          placeholder="Senha"
                          type={`${typePassword ? 'password' : 'text'}`}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setTypePassword(!typePassword)}
                                  edge="end"
                                >
                                  {typePassword ? (
                                    <VisibilityOutlinedIcon />
                                  ) : (
                                    <VisibilityOffOutlinedIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          inputProps={{ maxLength: 60 }}
                          value={userPassword}
                          onChange={e => setUserPassword(e.target.value)}
                        />
                      </Grid>
                    </>
                  )}

                  {usarToken && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        id="input_user_token"
                        label="Token"
                        inputProps={{ maxLength: 400 }}
                        value={userToken}
                        onChange={e => setUserToken(e.target.value)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} className="center">
                    <Button
                      fullWidth
                      variant="contained"
                      className="btn-main btn-left"
                      // color="warning"
                      type="submit"
                      id="btn_login_submit"
                      disabled={promiseInProgress}
                    >
                      <FingerprintIcon />
                      ENTRAR
                    </Button>
                  </Grid>
                  <Grid item xs={12} className="center">
                    {cronometro > 0 ?
                      <Button
                        variant="text"
                        type="button"
                        disabled={true}
                      >
                        {cronometro} Esqueci minha senha
                      </Button> :
                      <Button
                        variant="text"
                        type="button"
                        onClick={() => setStep(1)}
                        disabled={promiseInProgress}
                      >
                        Esqueci minha senha
                      </Button>
                    }
                  </Grid>
                </Grid>
              </form>
            }

            {/* Recuperação de senha - solicitar recuperação via email */}
            {step === 1 &&
              <form onSubmit={passwordRecover}>
                <Grid container spacing={{ xs: 3 }}>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      <strong>Recuperação de senha</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      Informe o endereço de email ou login utilizado por você ao acessar sua conta.
                      Em seguida, clique em 'Confirmar' para receber em sua caixa de entrada um código de validação.
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      label="Usuário ou E-mail"
                      placeholder="Usuário ou E-mail"
                      size="small"
                      inputProps={{ maxLength: 240 }}
                      value={userEmail}
                      onChange={e => setUserEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} className="center">
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      className="btn-main mb-10"
                      id="btn_send_new_password"
                      disabled={!userEmail || promiseInProgress}
                    >
                      confirmar
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      type="button"
                      className="mb-10"
                      onClick={() => setStep(0)}
                    >
                      voltar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            }

            {/* redefinir senha esquecida - tratar recuperação via token */}
            {step === 2 &&
              <form onSubmit={redefinirSenha} autoComplete="off">
                <Grid container spacing={{ xs: 3 }}>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      Recuperação de senha
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      No campo abaixo, informe a nova senha.
                      Em seguida clique em 'confirmar' para prosseguir com a redefinição de senha.
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      placeholder="Nova Senha"
                      size="small"
                      inputProps={{ maxLength: 60 }}
                      value={newSenha}
                      onChange={e => setNewSenha(e.target.value)}
                      type={`${typePasswordNew ? 'password' : 'text'}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setTypePasswordNew(!typePasswordNew)}
                              edge="end"
                            >
                              {typePasswordNew ? (
                                <VisibilityOutlinedIcon />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      placeholder="Confirmar Senha"
                      size="small"
                      inputProps={{ maxLength: 60 }}
                      value={confirmSenha}
                      onChange={e => setConfirmSenha(e.target.value)}
                      type={`${typePasswordConfirm ? 'password' : 'text'}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setTypePasswordConfirm(!typePasswordConfirm)}
                              edge="end"
                            >
                              {typePasswordConfirm ? (
                                <VisibilityOutlinedIcon />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className="center">
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      className="btn-main mb-10"
                      disabled={!newSenha || !confirmSenha || promiseInProgress}
                    >
                      confirmar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            }

            {/* redefinir senha expirada - redefinir senha */}
            {step === 3 &&
              <form onSubmit={redefinirSenhaExpirada} autoComplete="off">
                <Grid container spacing={{ xs: 3 }}>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      Redefinir Senha
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      No campo abaixo, informe a nova senha.
                      Em seguida clique em 'confirmar' para prosseguir com a redefinição de senha.
                    </p>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      placeholder='Senha Antiga'
                      size='small'
                      inputProps={{ maxLength: 60 }}
                      value={oldSenha}
                      onChange={e => setOldSenha(e.target.value)}
                      type={`${typeOldPassword ? 'password' : 'text'}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setTypeOldPassword(!typeOldPassword)}
                              edge="end"
                            >
                              {typeOldPassword ? (
                                <VisibilityOutlinedIcon />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      placeholder="Nova Senha"
                      size="small"
                      inputProps={{ maxLength: 60 }}
                      value={newSenha}
                      onChange={e => setNewSenha(e.target.value)}
                      type={`${typePasswordNew ? 'password' : 'text'}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setTypePasswordNew(!typePasswordNew)}
                              edge="end"
                            >
                              {typePasswordNew ? (
                                <VisibilityOutlinedIcon />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      placeholder="Confirmar Senha"
                      size="small"
                      inputProps={{ maxLength: 60 }}
                      value={confirmSenha}
                      onChange={e => setConfirmSenha(e.target.value)}
                      type={`${typePasswordConfirm ? 'password' : 'text'}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setTypePasswordConfirm(!typePasswordConfirm)}
                              edge="end"
                            >
                              {typePasswordConfirm ? (
                                <VisibilityOutlinedIcon />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className="center">
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      className="btn-main mb-10"
                      disabled={!newSenha || !confirmSenha || promiseInProgress}
                    >
                      confirmar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            }


            {/* escolher forma de autenticação 2fa */}
            {step === 4 &&
              <form autoComplete="off">
                <Grid container spacing={{ xs: 3 }}>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      <strong>Autenticação de dois fatores</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      Como deseja receber o código de autenticação?
                    </p>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={showEmailMfaForm}
                      className="btn-main mb-10"
                    >
                      E-mail
                    </Button>
                  </Grid>

                  {totpEnabled &&
                    <Grid item xs={12} sx={{ marginTop: '-20px' }}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={showAuthenticatorForm}
                        className="btn-main mb-10"
                      >
                        Authenticator
                      </Button>
                    </Grid>
                  }

                  {!totpEnabled &&

                    <Grid item xs={12} sx={{ marginTop: '-20px' }}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={showAuthenticatorConfigureForm}
                        className="btn-main mb-10"
                      >
                        Configurar Authenticator
                      </Button>
                    </Grid>
                  }

                </Grid>
              </form>
            }

            {/* 2fa - Authenticator */}
            {step === 5 &&
              <form onSubmit={validarLoginMfa} autoComplete="off">
                <Grid container spacing={{ xs: 3 }}>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      <strong>Autenticação de dois fatores</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      Utilize seu aplicativo autenticador para gerar um código de acesso.
                    </p>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      label="Código de 6 digitos"
                      autoComplete={'false'}
                      placeholder="XXXXXX"
                      type={`${typePassword ? 'password' : 'text'}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setTypePassword(!typePassword)}
                              edge="end"
                            >
                              {typePassword ? (
                                <VisibilityOutlinedIcon />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      inputProps={{ maxLength: 6 }}
                      onChange={e => setMfaCode(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={8}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      className="btn-main mb-10"
                      disabled={!(mfaCode.length === 6)}
                    >
                      Fazer login
                    </Button>
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={showLoginForm}
                      className="btn-secondary mb-10"
                    >
                      Voltar
                    </Button>
                  </Grid>


                </Grid>
              </form>
            }


            {/* 2fa - Configurar Authenticator */}
            {step === 6 &&
              <form onSubmit={validarTotpConfiguration} autoComplete="off">
                <Grid container spacing={{ xs: 3 }}>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      <strong>Autenticação de dois fatores</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      Aponte a camera do aplicativo autenticador para o QR Code abaixo.
                    </p>
                  </Grid>
                  <QRCode
                    size={256}
                    style={{
                      marginTop: '15px',
                      paddingLeft: '24px',
                      maxHeight: '200px',
                      maxWidth: '100%',
                      width: '100%'
                    }}
                    value={otpauthLink}
                    viewBox={`0 0 256 256`}
                  />
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      label="Código de 6 digitos"
                      autoComplete={'false'}
                      placeholder="XXXXXX"
                      type={`${typePassword ? 'password' : 'text'}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setTypePassword(!typePassword)}
                              edge="end"
                            >
                              {typePassword ? (
                                <VisibilityOutlinedIcon />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      inputProps={{ maxLength: 6 }}
                      onChange={e => setMfaCode(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={8}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      className="btn-main mb-10"
                    >
                      Salvar
                    </Button>
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={showLoginForm}
                      className="btn-secondary mb-10"
                    >
                      Voltar
                    </Button>
                  </Grid>


                </Grid>
              </form>
            }

            {/* 2fa - Configurar Authenticator */}
            {step === 7 &&
              <form autoComplete="off">
                <Grid container spacing={{ xs: 3 }}>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      <strong>Autenticação de dois fatores</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      A configuração do Authenticator foi finalizada. Sua conta está segura.
                    </p>
                  </Grid>

                  <Grid item xs={12}>

                    <img className={'center'} src={mfaImage}
                         style={{ maxWidth: '220px', margin: 'auto', display: 'block' }} />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={showLoginForm}
                      className="btn-secondary mb-10"
                    >
                      Voltar ao login
                    </Button>
                  </Grid>


                </Grid>
              </form>
            }


            {/* 2fa - Authenticator */}
            {step === 8 &&
              <form onSubmit={validarLoginMfa} autoComplete="off">
                <Grid container spacing={{ xs: 3 }}>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      <strong>Autenticação de dois fatores</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="m-0 center">
                      Enviamos um código de 6 digitos com validade de 15 minutos para seu e-mail de cadastro.
                    </p>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      label="Código de 6 digitos"
                      autoComplete={'false'}
                      placeholder="XXXXXX"
                      type={`${typePassword ? 'password' : 'text'}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setTypePassword(!typePassword)}
                              edge="end"
                            >
                              {typePassword ? (
                                <VisibilityOutlinedIcon />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      inputProps={{ maxLength: 6 }}
                      onChange={e => setMfaCode(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={8}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      className="btn-main mb-10"
                      disabled={!(mfaCode.length === 6)}
                    >
                      Fazer login
                    </Button>
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={showLoginForm}
                      className="btn-secondary mb-10"
                    >
                      Voltar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} className="center">
                  {cronometroMfa > 0 ?
                    <Button
                      variant="text"
                      type="button"
                      disabled={true}
                    >
                      {cronometroMfa} Enviar código novamente
                    </Button> :
                    <Button
                      variant="text"
                      type="button"
                      onClick={resendEmail}
                    >
                      Enviar código novamente
                    </Button>
                  }
                </Grid>

              </form>
            }


          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
