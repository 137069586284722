export function validarCpfCnpj(cpfCnpj: string): boolean {
  // Remove caracteres não numéricos
  cpfCnpj = cpfCnpj.replace(/[^\d]+/g, '')

  if (cpfCnpj.length === 11) {
    // Se tem 11 dígitos, é um CPF
    let soma = 0
    let resto: number
    if (cpfCnpj === '00000000000' ||
      cpfCnpj === '11111111111' ||
      cpfCnpj === '22222222222' ||
      cpfCnpj === '33333333333' ||
      cpfCnpj === '44444444444' ||
      cpfCnpj === '55555555555' ||
      cpfCnpj === '66666666666' ||
      cpfCnpj === '77777777777' ||
      cpfCnpj === '88888888888' ||
      cpfCnpj === '99999999999') {
      return false
    }

    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpfCnpj.substring(i - 1, i)) * (11 - i)
    }
    resto = (soma * 10) % 11

    if ((resto === 10) || (resto === 11)) {
      resto = 0
    }
    if (resto !== parseInt(cpfCnpj.substring(9, 10))) {
      return false
    }

    soma = 0
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpfCnpj.substring(i - 1, i)) * (12 - i)
    }
    resto = (soma * 10) % 11

    if ((resto === 10) || (resto === 11)) {
      resto = 0
    }
    if (resto !== parseInt(cpfCnpj.substring(10, 11))) {
      return false
    }

    return true // CPF válido
  } else if (cpfCnpj.length === 14) {
    // Se tem 14 dígitos, é um CNPJ
    let tamanho = cpfCnpj.length - 2
    let numeros = cpfCnpj.substring(0, tamanho)
    let digitos = cpfCnpj.substring(tamanho)
    let soma = 0
    let pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--
      if (pos < 2) {
        pos = 9
      }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado !== parseInt(digitos.charAt(0))) {
      return false
    }

    tamanho += 1
    numeros = cpfCnpj.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--
      if (pos < 2) {
        pos = 9
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado !== parseInt(digitos.charAt(1))) {
      return false
    }

    return true // CNPJ válido
  } else {
    // Se não tem 11 ou 14 dígitos, não é válido
    return false
  }
}