import { useEffect, useState } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'

import CancelIcon from '@mui/icons-material/Cancel'
import MoodBadIcon from '@mui/icons-material/MoodBad'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled'
import BlockIcon from '@mui/icons-material/Block'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Modal from '../../components/Modal'
import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification.js'
import { useAuth } from '../../hooks/useAuth'
import {
  IBeneficio,
  IChamado,
  IEmpresa,
  IFastJob,
  IRazaoContratacao,
  IRecruitmentReasonData
} from '../../interfaces/index'
import services from '../../services/index.js'

import Perfis from './components/Perfis'
import Beneficios from './components/Beneficios'
import Employees from './components/Employees'
import DadosSolicitanteDetalhado from './components/DadosSolicitanteDetalhado'
import DadosVaga from './components/DadosVaga'
import ResumoVaga from './components/ResumoVaga'

import './style.scss'

function Chamado() {
  const { idChamado } = useParams()
  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()
  const { getUserRoles, getUser } = useAuth()
  const userRoles = getUserRoles()
  const user = getUser()

  const [revisaoContrato, setRevisaoContrato] = useState<boolean>(false)
  const [revisaoSalario, setRevisaoSalario] = useState<boolean>(false)
  const [revisaoPerfil, setRevisaoPerfil] = useState<boolean>(false)

  const [typeEncerramento, setTypeEncerramento] = useState<string>('')

  const [escalasList, setEscalasList] = useState<any>([])
  // const [tipoRevisao, setTipoRevisao] = useState<any>('')
  const [modalConfirmarAprovCancel, setModalConfirmarAprovCancel] = useState<boolean>(false)
  const [modalEtapaConclusao, setModalEtapaConclusao] = useState<boolean>(false)

  const [modalValidarFastId, setModalValidarFastId] = useState<boolean>(false)
  const [modalCancelChamado, setModalCancelChamado] = useState<boolean>(false)
  const [modalAprovarChamado, setModalAprovarChamado] = useState<boolean>(false)

  const [originalFastId, setOriginalFastId] = useState<string | number | null | undefined>('')
  const [reload, setReload] = useState<number>(1)
  const [motivo, setMotivo] = useState<string>('')
  const [posicoesAtendidas, setPosicoesAtendidas] = useState<any>('')
  const [notaAproval, setNotaAproval] = useState<number>(5)
  const [commentAproval, setCommentAproval] = useState<string>('')
  const [motivoReview, setMotivoReview] = useState<string>('')
  const [empresas, setEmpresas] = useState<IEmpresa[] | []>([])
  const [tiposContratacao, setTiposContratacao] = useState<any[] | []>([])

  const [razoesContratacao, setRazoesContratacao] = useState<IRazaoContratacao[] | []>([])
  const [beneficios, setBeneficios] = useState<IBeneficio[] | []>([])
  const [employees, setEmployees] = useState<IRecruitmentReasonData[] | []>([])
  const [perfis, setPerfis] = useState<any[] | []>([])

  const [detalheFastId, setDetalheFastId] = useState<IFastJob>({})

  const [resumoTicket, setResumoTicket] = useState<any>({})
  const [chamado, setChamado] = useState<IChamado>({})

  /// add perfis
  const addPerfil = async (nomeCurso: string, descricaoCurso: string, obritatorio: string, perfilId: number) => {
    setPerfis(prevState => [...prevState, {
      name: nomeCurso,
      description: descricaoCurso,
      mandatory: obritatorio,
      id: perfilId
    }])
  }

  const removePerfil = async (index: number) => {
    setPerfis((prevState: any) => ([
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1)
      ]
    ))
  }

  const handleSetChamado = (indexLocal: number, indexFuncao: number, campo: string, value: any) => {
    setChamado((prevState: any) => ({ ...prevState, [campo]: value }))
  }

  /// add beneficio
  const addBeneficio = async (beneficioNome: string, beneficioValue: string, baneficioId?: number) => {
    setBeneficios(prevState => [...prevState, {
      benefitName: beneficioNome,
      benefitValue: beneficioValue,
      id: baneficioId
    }])
  }

  const removeBeneficio = async (index: number) => {
    setBeneficios((prevState: any) => ([
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1)
      ]
    ))
  }

  /// add recruitment
  const addRecruitment = async (recruitmentCpf: string, recruitmentNome: string, recruitmentId?: number) => {
    setEmployees(prevState => [...prevState,
      { cpf: recruitmentCpf, fullName: recruitmentNome, id: recruitmentId }
    ])
  }

  const removeRecruitment = async (index: number) => {
    setEmployees((prevState: any) => ([
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1)
      ]
    ))
  }

  const confirmarFastId = async () => {
    setOriginalFastId(chamado.fastJobId)
    setModalValidarFastId(false)
    editChamado(true)
  }

  const handleCloseSolicitarCancelamento = async () => {
    setModalCancelChamado(false)
    setPosicoesAtendidas('')
    setNotaAproval(5)
    setMotivo('')
  }

  const handleCloseModalConclusao = async () => {
    setModalEtapaConclusao(false)
    setPosicoesAtendidas('')
    setNotaAproval(5)
    setMotivo('')
  }

  const editChamado = async (validarFastId: boolean = false) => {
    if (!chamado.customerManager) {
      notify('Informe o Gestor responsavel', { variant: 'warning' })
      return false
    }
    if (!chamado.quantity) {
      notify('Informe a quantidade de vagas', { variant: 'warning' })
      return false
    }
    if (!chamado.startDate) {
      notify('Informe a data de inicio', { variant: 'warning' })
      return false
    }
    if (!chamado.functionName) {
      notify('Informe a função', { variant: 'warning' })
      return false
    }
    if (!chamado.remuneration) {
      notify('Informe a remuneração', { variant: 'warning' })
      return false
    }
    if (!chamado.scale) {
      notify('Informe a escala', { variant: 'warning' })
      return false
    }
    if (!chamado.jobStartHour) {
      notify('Informe o horário de trabalho inicial', { variant: 'warning' })
      return false
    }
    if (!chamado.jobFinishHour) {
      notify('Informe o horário de trabalho final', { variant: 'warning' })
      return false
    }
    if (!chamado.recruitmentDemandHiringReasonId) {
      notify('Informe a razão da contratação', { variant: 'warning' })
      return false
    }
    if (chamado?.functionName && chamado?.functionName?.length <= 5) {
      notify('Campo função deve ter mais que 5 caracteres', { variant: 'warning' })
      return false
    }
    if (Number(chamado.quantity) === 0) {
      notify('Quantidade de vagas deve ser maior que zero', { variant: 'warning' })
      return false
    }

    if (originalFastId !== chamado.fastJobId && !validarFastId) {
      if (!chamado.fastJobId) {
        notify('Informe um Cód da vaga no FastRH válido', { variant: 'warning' })
        return false
      }

      try {
        const res = await trackPromise(services.api.getJobData(chamado.fastJobId))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível verificar o Id Fast'
          )
        }
        if (res.status === 200) {
          setDetalheFastId(res.data)
          setModalValidarFastId(true)
        } else {
          setDetalheFastId({})
          setModalValidarFastId(true)
        }
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
        return false
      }
      return false
    }


    try {
      const chamadoEditObj = {
        id: chamado.id,
        customerManager: chamado.customerManager,
        costCenter: chamado.costCenter,
        quantity: chamado.quantity,
        startDate: chamado.startDate,
        optionalData: chamado.optionalData,
        functionName: chamado.functionName,
        remuneration: chamado.remuneration,
        scale: chamado.scale,
        jobStartHour: chamado.jobStartHour,
        jobFinishHour: chamado.jobFinishHour,
        duration: chamado.duration ? chamado.duration : null,
        recruitmentDemandHiringReasonId: chamado.recruitmentDemandHiringReasonId === 'null' ? null : chamado.recruitmentDemandHiringReasonId,
        pcd: chamado.pcd,
        pcdRestriction: chamado.pcdRestriction,
        dangerousness: chamado.dangerousness,
        unhealthy: chamado.unhealthy,
        unhealthyRate: chamado.unhealthyRate,
        fastJobId: chamado.fastJobId ? chamado.fastJobId : null
      }

      const resTicketData = await trackPromise(services.api.editChamadoData(chamadoEditObj))
      if (resTicketData.fail) {
        throw new Error(
          resTicketData.error || 'Não foi possível editar o chamado'
        )
      }

      notify('Chamado alterado com sucesso', { variant: 'success' })
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  // fluxo de cancelamento
  const handleSolicitarCancelamento = async () => {
    if (resumoTicket?.attendant?.id === user.id) {
      if (!chamado.fastJobId) {
        notify('Informe o Cód da vaga no FastRH e Salve o Chamado antes de prosseguir', { variant: 'warning' })
        return false
      }
      if (originalFastId !== chamado.fastJobId) {
        notify('Salve a Alteração do Cód da vaga no FastRH antes de prosseguir', { variant: 'warning' })
        return false
      }
    }
    setMotivo('')
    setModalCancelChamado(true)
  }

  const solicitarCancelamento = async () => {
    if (notaAproval === 0) {
      notify('Informe uma avaliação', { variant: 'warning' })
      return false
    }
    if (!motivo) {
      notify('Informe o motivo do cancelamento', { variant: 'warning' })
      return false
    }
    if (posicoesAtendidas === '') {
      notify('Informe o quantidade de posições atendidas', { variant: 'warning' })
      return false
    }
    if (Number(posicoesAtendidas) > Number(chamado?.quantity)) {
      notify('Posições atendidas não pode ser maior que a quantidade máxima de vagas do chamado', { variant: 'warning' })
      return false
    }

    let partialCancelation = false
    if (posicoesAtendidas > 0) {
      partialCancelation = true
    }

    const motivoConcat = `Posições atendidas ${posicoesAtendidas}/${chamado.quantity} \\n ${motivo}`

    try {
      const resTicketData = await trackPromise(services.api.cancelTicket(resumoTicket.ticketId, motivoConcat, partialCancelation, notaAproval))
      if (resTicketData.fail) {
        throw new Error(
          resTicketData.error || 'Não foi possível efetuar a solicitação'
        )
      }
      notify('Solicitação de cancelamento enviada com sucesso', { variant: 'success' })
      setResumoTicket((prevState: any) => ({ ...prevState, status: 'AWAITING_CANCELATION_APROVEMENT' }))
      handleCloseSolicitarCancelamento()
      recarregarPage()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
      return false
    }
  }
  const aprovarCancelamento = async () => {
    try {
      const resTicketData = await trackPromise(services.api.cancelTicketAprove(resumoTicket.ticketId))
      if (resTicketData.fail) {
        throw new Error(
          resTicketData.error || 'Não foi possível cancelar o chamado'
        )
      }
      notify('Chamado cancelado com sucesso', { variant: 'success' })
      setResumoTicket((prevState: any) => ({ ...prevState, status: 'CANCELED' }))
      handleCloseSolicitarCancelamento()
      setModalConfirmarAprovCancel(false)
      recarregarPage()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
      return false
    }
  }
  const cancelarSolicitacaoCancelamento = async () => {
    try {
      const resTicketData = await trackPromise(services.api.cancelTicketCancel(resumoTicket.ticketId))
      if (resTicketData.fail) {
        throw new Error(
          resTicketData.error || 'Não foi possível efetuar esta ação'
        )
      }
      notify('Solicitação de cancelamento cancelada', { variant: 'success' })
      setResumoTicket((prevState: any) => ({ ...prevState, status: 'IN_PROGRESS' }))
      handleCloseSolicitarCancelamento()
      recarregarPage()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
      return false
    }

  }

  const solicitarRevisao = async () => {
    if (!motivoReview) {
      notify('Informe o motivo da revisão', { variant: 'warning' })
      return
    }
    if (posicoesAtendidas === '') {
      notify('Informe o quantidade de posições atendidas', { variant: 'warning' })
      return false
    }
    if (Number(posicoesAtendidas) > Number(chamado?.quantity)) {
      notify('Posições atendidas não pode ser maior que a quantidade máxima de vagas do chamado', { variant: 'warning' })
      return false
    }
    if (!revisaoContrato && !revisaoSalario && !revisaoPerfil) {
      notify('Informe pelo menos um tipo de revisão', { variant: 'warning' })
      return false
    }

    const motivoConcat = `Posições atendidas ${posicoesAtendidas}/${chamado.quantity}${revisaoContrato && '\\n Revisão das contratações'}${revisaoSalario && '\\n Revisão do salário'}${revisaoPerfil && '\\n Revisão do perfil'}\\n ${motivoReview}`

    try {
      const resTicketData = await trackPromise(services.api.requestReview(resumoTicket.ticketId, motivoConcat))
      if (resTicketData.fail) {
        throw new Error(
          resTicketData.error || 'Não foi possível solicitar revisão'
        )
      }
      notify('Revisão solicitada com sucesso', { variant: 'success' })
      setResumoTicket((prevState: any) => ({ ...prevState, status: 'IN_REVISION' }))
      handleCloseModalConclusao()
      recarregarPage()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
      return false
    }
  }

  const solicitarCancelamentoInsatisfacao = async () => {
    if (notaAproval === 0) {
      notify('Informe uma avaliação', { variant: 'warning' })
      return false
    }
    if (!motivo) {
      notify('Informe o motivo do cancelamento', { variant: 'warning' })
      return false
    }
    if (posicoesAtendidas === '') {
      notify('Informe o quantidade de posições atendidas', { variant: 'warning' })
      return false
    }
    if (Number(posicoesAtendidas) > Number(chamado?.quantity)) {
      notify('Posições atendidas não pode ser maior que a quantidade máxima de vagas do chamado', { variant: 'warning' })
      return false
    }

    let partialCancelation = false
    if (posicoesAtendidas > 0) {
      partialCancelation = true
    }

    const motivoConcat = `Posições atendidas ${posicoesAtendidas}/${chamado.quantity} \\n ${motivo}`

    try {
      const resTicketData = await trackPromise(services.api.cancelTicket(resumoTicket.ticketId, motivoConcat, partialCancelation, notaAproval))
      if (resTicketData.fail) {
        throw new Error(
          resTicketData.error || 'Não foi possível efetuar a solicitação'
        )
      }
      notify('Cancelamento enviado com sucesso', { variant: 'success' })
      setResumoTicket((prevState: any) => ({ ...prevState, status: 'CANCELED_PARTIAL' }))
      handleCloseModalConclusao()
      recarregarPage()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
      return false
    }
  }

  const openEtapaComclusao = () => {
    setNotaAproval(5)
    setCommentAproval('')
    setMotivoReview('')
    setMotivo('')
    setTypeEncerramento('')
    setModalEtapaConclusao(true)
  }

  const handleConclusao = () => {
    if (typeEncerramento === 'aprove') {
      aprovarAprovacao()
    }
    if (typeEncerramento === 'review') {
      solicitarRevisao()
    }
    if (typeEncerramento === 'cancel') {
      solicitarCancelamentoInsatisfacao()
    }
  }

  // fluxo de aprovação
  const handleSolicitarAprovacao = async () => {
    if (resumoTicket?.attendant?.id === user.id) {
      if (!chamado.fastJobId) {
        notify('Informe o Cód da vaga no FastRH e Salve o Chamado antes de prosseguir', { variant: 'warning' })
        return false
      }
      if (originalFastId !== chamado.fastJobId) {
        notify('Salve a Alteração do Cód da vaga no FastRH antes de prosseguir', { variant: 'warning' })
        return false
      }
    }
    setMotivo('')
    setModalAprovarChamado(true)
  }
  const solicitarAprovacao = async () => {
    if (!motivo) {
      notify('Informe o motivo da aprovação', { variant: 'warning' })
      return false
    }

    try {
      const resTicketData = await trackPromise(services.api.aproveTicket(resumoTicket.ticketId, motivo))
      if (resTicketData.fail) {
        throw new Error(
          resTicketData.error || 'Não foi possível efetuar a aprovação'
        )
      }
      notify('Solicitação de aprovação enviada com sucesso', { variant: 'success' })
      setResumoTicket((prevState: any) => ({ ...prevState, status: 'AWAITING_FINISH_APROVEMENT' }))
      setModalAprovarChamado(false)
      recarregarPage()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
      return false
    }

  }

  const aprovarAprovacao = async () => {
    if (notaAproval === 0) {
      notify('Informe uma avaliação', { variant: 'warning' })
      return false
    }
    if (notaAproval <= 3 && !commentAproval) {
      notify('Informe um comentário', { variant: 'warning' })
      return false
    }
    try {
      const resTicketData = await trackPromise(services.api.aproveTicketAprove(resumoTicket.ticketId, notaAproval, commentAproval))
      if (resTicketData.fail) {
        throw new Error(
          resTicketData.error || 'Não foi possível aprovar o chamado'
        )
      }
      notify('Chamado aprovado com sucesso', { variant: 'success' })
      setResumoTicket((prevState: any) => ({ ...prevState, status: 'FINISHIED' }))
      handleCloseModalConclusao()
      recarregarPage()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
      return false
    }

  }

  const cancelarSolicitacaoAprovacao = async () => {
    try {
      const resTicketData = await trackPromise(services.api.aproveTicketCancel(resumoTicket.ticketId))
      if (resTicketData.fail) {
        throw new Error(
          resTicketData.error || 'Não foi possível efetuar esta ação'
        )
      }
      notify('Solicitação de aprovação cancelada', { variant: 'success' })
      setResumoTicket((prevState: any) => ({ ...prevState, status: 'IN_PROGRESS' }))
      setModalAprovarChamado(false)
      recarregarPage()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
      return false
    }

  }

  const recarregarPage = () => {
    setReload(prevState => prevState + 1)
  }

  useEffect(() => {
    const init = async () => {
      try {
        const [resRazoesContratacao, resTicket, resEscalas] = await trackPromise(
          Promise.all(
            [
              services.api.getDemandreasons(),
              services.api.getChamadoById(idChamado),
              services.api.getEscalasList()
            ]
          )
        )

        if (resRazoesContratacao.fail) {
          throw new Error(resRazoesContratacao.error || 'Não foi possível consultar Demand reasons')
        }


        if (resTicket.fail) {
          throw new Error(resTicket.error || 'Não foi possível consultar Usuários')
        }

        const resChamado = resTicket.data.recruitmentTicketData ? resTicket.data.recruitmentTicketData : {}

        let tiposlistmotivoRef: any = []
        let tiposlistmotivo: any = []
        let motivosFormat = resRazoesContratacao.data.map((item: IRazaoContratacao) => {
          // let typeString: any = item?.type ? item.type.split(' ') : ''
          // typeString = typeString[typeString.length - 1]
          // item.typeMotivo = typeString
          // if (item.typeMotivo === 'Previsível' || item.typeMotivo === 'Imprevisível' || item.typeMotivo ===  'Transitória') {
          //   if (!tiposlistmotivoRef.includes(item.typeMotivo)) {
          //     tiposlistmotivoRef.push(item.typeMotivo)
          //     tiposlistmotivo.push(item.type)
          //   } 
          // }
          if (!tiposlistmotivoRef.includes(item.type)) {
            tiposlistmotivoRef.push(item.type)
            tiposlistmotivo.push(item.type)
          }

          return item
        })
        let findReazonSelected = motivosFormat.find((item: any) => item.id === resChamado.recruitmentDemandHiringReasonId)
        let stringTipoMotivo = findReazonSelected?.type || ''
        // // if (findReazonSelected?.type) {
        // //   stringTipoMotivo = findReazonSelected.type.split(' ')
        // //   stringTipoMotivo = stringTipoMotivo[stringTipoMotivo.length - 1]
        // // }
        setRazoesContratacao([...motivosFormat])
        setTiposContratacao(tiposlistmotivo)

        const listaEmpresas = [
          {
            ...resChamado?.customer,
            filiais: [resChamado?.branch],
            contratos: [resChamado?.contract]
          }
        ]
        setEmpresas(listaEmpresas)

        // objeto exclusivo para seção de resumo e mensagens
        setResumoTicket({
          attendantLeader: resTicket.data.attendantLeader,
          status: resTicket.data.status,
          createdAt: resTicket.data.createdAt,
          finishedAt: resTicket.data.finishedAt,
          ticketId: resTicket.data.id,
          customerUser: resTicket.data.customerUser,
          customerUserId: resTicket.data.customerUserId,
          recruitmentTicketData: resTicket.data.recruitmentTicketData,
          finishReason: resTicket.data.finishReason,
          attendant: resTicket.data.attendant,
          customerManager: resChamado.customerManager,
          satisfaction: resTicket.data.satisfaction,
          satisfactionComment: resTicket.data.satisfactionComment,
          revisionComment: resTicket.data.revisionComment
        })
        let isTemporary = false
        let isEmployeeReplace = false
        if (
          resChamado?.protheusAfk?.akF_DESCRI?.toUpperCase().includes('TEMPORARIO') ||
          resChamado?.protheusAfk?.akF_DESCRI?.toUpperCase().includes('TEMPORARIA') ||
          resChamado?.protheusAfk?.akF_DESCRI?.toUpperCase().includes('TEMPORÁRIO')
        ) {
          isTemporary = true
        }
        if (
          resChamado?.recruitmentDemandHiringReason?.type?.toUpperCase().includes('SUBSTITUIÇÃO TRANSITÓRIA')
        ) {
          isEmployeeReplace = true
        }
        setChamado({
          fastRhEmpresaId: resChamado.fastRhEmpresaId,
          fastRhEmpresaFilialId: resChamado.fastRhEmpresaFilialId,
          customerManager: resChamado.customerManager,
          fastRhContratoId: resChamado.fastRhContratoId,
          costCenter: resChamado.costCenter,
          ticketId: resTicket.data.id,
          functionName: resChamado.functionName,
          id: resChamado.id,
          pcd: resChamado.pcd,
          pcdRestriction: resChamado.pcdRestriction,
          remuneration: resChamado.remuneration,
          quantity: resChamado.quantity,
          typeHiringReason: stringTipoMotivo,
          recruitmentDemandHiringReasonId: resChamado.recruitmentDemandHiringReasonId === null ? 'null' : resChamado.recruitmentDemandHiringReasonId,
          scale: resChamado.scale,
          jobStartHour: resChamado.jobStartHour,
          jobFinishHour: resChamado.jobFinishHour,
          startDate: resChamado.startDate,
          unhealthy: resChamado.unhealthy,
          unhealthyRate: resChamado.unhealthyRate,
          dangerousness: resChamado.dangerousness,
          duration: resChamado.duration,
          optionalData: resChamado.optionalData,
          fastJobId: resChamado.fastJobId,
          isTemporary: isTemporary,
          isEmployeeReplace: isEmployeeReplace
        })
        setOriginalFastId(resChamado.fastJobId)
        setBeneficios(resChamado.benefits)
        setEmployees(resChamado.employeesToReplace)
        setPerfis(resChamado.profileItems)

        if (!resEscalas.fail) {
          setEscalasList(resEscalas.data)
        }
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }

    }

    if (idChamado) {
      init()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idChamado, reload])

  useEffect(() => {
    console.log('chamado.fastRhContratoId', chamado.fastRhContratoId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chamado.fastRhContratoId])

  return (
    <>
      <div className="wrapper-container-content bgwhite">
        <div className="wrapper-page-content" id="wrapper-page-content">
          <PageTitle>
            <div>{`Chamado #${idChamado ? idChamado : ''}`}</div>
          </PageTitle>
          <Grid container justifyContent="center" spacing={{ xs: 3 }}>
            <Grid item md={10} xs={12}>
              <div className="panel">
                <div className="panel-body">
                  <ResumoVaga
                    reload={recarregarPage}
                    chamado={resumoTicket}
                    setResumoTicket={setResumoTicket}
                    filialId={resumoTicket?.recruitmentTicketData?.protheusCtt?.r_E_C_N_O_}
                    ticketStatus={resumoTicket.status} ticketDataId={chamado.ticketId}
                  />

                  <div>
                    {/* <DetalhesConclusao 
                      ticketStatus={resumoTicket.status} 
                      textConclusao={resumoTicket.finishReason} 
                    /> */}

                    <DadosSolicitanteDetalhado
                      name={resumoTicket?.customerUser?.name || ''}
                      email={resumoTicket?.customerUser?.email || ''}
                      local={resumoTicket?.recruitmentTicketData?.protheusCtt ? `${resumoTicket.recruitmentTicketData.protheusCtt.ctT_RES} - ${resumoTicket.recruitmentTicketData.protheusCtt.ctT_XNGRUP} - ${resumoTicket.recruitmentTicketData.protheusCtt.ctT_DESC01} - ${services.mask.cnpj(resumoTicket.recruitmentTicketData.protheusSa1.a1_CGC)}` : ''}
                      empresa={resumoTicket?.recruitmentTicketData?.customer?.nome || ''}
                      contrato={resumoTicket?.recruitmentTicketData?.protheusAfk?.akF_DESCRI || ''}
                      filial={resumoTicket?.recruitmentTicketData?.branch?.razaoSocial || ''}
                    />

                    {/* <DadosContratante
                      empresas={empresas}
                      chamado={chamado}
                      setChamado={setChamado}
                      ticketStatus={resumoTicket.status}
                    /> */}

                    <DadosVaga
                      chamado={chamado}
                      setChamado={handleSetChamado}
                      razoesContratacao={razoesContratacao}
                      edicaoMode={true}
                      ticketStatus={resumoTicket.status}
                      tiposContratacao={tiposContratacao}
                      escalasList={escalasList}
                      disabledSubstituicao={false}
                    />
                  </div>

                  <Grid container spacing={{ xs: 3 }}>
                    <Grid item xs={12} className="mt-30">
                      <Perfis
                        edicaoMode={true}
                        list={perfis}
                        remove={removePerfil}
                        add={addPerfil}
                        ticketDataId={chamado.id}
                        ticketStatus={resumoTicket.status}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Beneficios
                        edicaoMode={true}
                        beneficios={beneficios}
                        removeBeneficio={removeBeneficio}
                        addBeneficio={addBeneficio}
                        ticketDataId={chamado.id}
                        ticketStatus={resumoTicket.status}
                      />
                    </Grid>
                    {
                      chamado.isEmployeeReplace ?
                        <Grid item xs={12}>
                          <Employees
                            edicaoMode={true}
                            employees={employees}
                            remove={removeRecruitment}
                            add={addRecruitment}
                            ticketDataId={chamado.id}
                            ticketStatus={resumoTicket.status}
                            quantidade={chamado.quantity}
                          />
                        </Grid>
                        : ''
                    }
                  </Grid>

                  {/* <DescricaoSolicitacao ticketStatus={resumoTicket.status} ticketDataId={chamado.ticketId} /> */}

                  <Grid container spacing={{ xs: 3 }}>
                    <Grid item xs={12} style={{ marginTop: '20px' }} className="flex gap-10 justify-end item-center">
                      {
                        (
                          userRoles.includes('ticket_res_update') ||
                          userRoles.includes('ticket_res_update_any')
                        ) &&
                        (resumoTicket.status === 'IN_PROGRESS' || resumoTicket.status === 'IN_REVISION') &&
                        <Button
                          variant="contained"
                          type="button"
                          size="small"
                          color="inherit"
                          onClick={() => editChamado(false)}
                          disabled={promiseInProgress}
                        >
                          Salvar Alterações
                        </Button>
                      }
                      {
                        userRoles.includes('ticket_cancelrequest_create') &&
                        (resumoTicket.status === 'IN_PROGRESS' || resumoTicket.status === 'IN_REVISION') &&
                        <Button
                          variant="contained"
                          type="button"
                          size="small"
                          color="error"
                          onClick={handleSolicitarCancelamento}
                          disabled={promiseInProgress}
                        >
                          Solicitar Cancelamento
                        </Button>
                      }
                      {
                        (
                          userRoles.includes('ticket_cancelrequest_archive') ||
                          userRoles.includes('ticket_cancelrequest_archive_any')
                        ) &&
                        resumoTicket.status === 'AWAITING_CANCELATION_APROVEMENT' &&
                        <Button
                          variant="contained"
                          type="button"
                          size="small"
                          color="inherit"
                          onClick={cancelarSolicitacaoCancelamento}
                          disabled={promiseInProgress}
                        >
                          Cancelar Solicitação de Cancelamento
                        </Button>
                      }
                      {
                        (
                          userRoles.includes('ticket_cancelrequest_approve') ||
                          userRoles.includes('ticket_cancelrequest_approve_any')
                        ) &&
                        resumoTicket.status === 'AWAITING_CANCELATION_APROVEMENT' &&
                        <Button
                          variant="contained"
                          type="button"
                          size="small"
                          color="secondary"
                          onClick={() => setModalConfirmarAprovCancel(true)}
                          disabled={promiseInProgress}
                        >
                          Aprovar Cancelamento
                        </Button>
                      }
                      {
                        (
                          userRoles.includes('ticket_res_update') ||
                          userRoles.includes('ticket_res_update_any')
                        ) &&
                        (resumoTicket.status === 'IN_PROGRESS' || resumoTicket.status === 'IN_REVISION') &&
                        <Button
                          variant="contained"
                          type="button"
                          size="small"
                          color="secondary"
                          onClick={handleSolicitarAprovacao}
                          disabled={promiseInProgress}
                        >
                          Solicitar Aprovação
                        </Button>
                      }
                      {
                        (
                          userRoles.includes('ticket_res_update') ||
                          userRoles.includes('ticket_res_update_any')
                        ) &&
                        resumoTicket.status === 'AWAITING_FINISH_APROVEMENT' &&
                        <Button
                          variant="contained"
                          type="button"
                          size="small"
                          color="error"
                          onClick={cancelarSolicitacaoAprovacao}
                          disabled={promiseInProgress}
                        >
                          Cancelar Solicitação de Aprovação
                        </Button>
                      }
                      {/* {  
                        user?.id === resumoTicket?.customerUserId &&
                        resumoTicket.status === 'AWAITING_FINISH_APROVEMENT' && 
                        <Button
                          variant="contained"
                          type="button"
                          size='small'
                          color="secondary" 
                          onClick={() => setModalConfirmarAprovConclusao(true)}
                          disabled={promiseInProgress}
                        >
                          Aprovar conclusão 
                        </Button>
                      } */}
                      {
                        user?.id === resumoTicket?.customerUserId &&
                        resumoTicket.status === 'AWAITING_FINISH_APROVEMENT' &&
                        <Button
                          variant="contained"
                          type="button"
                          size="small"
                          color="secondary"
                          onClick={openEtapaComclusao}
                          disabled={promiseInProgress}
                        >
                          Opções de conclusão
                        </Button>
                      }
                    </Grid>
                  </Grid>

                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>


      {/* solicitar aprovação */}
      <Modal
        size="sm"
        open={modalAprovarChamado}
        close={() => setModalAprovarChamado(false)}
        titulo={'Aprovar Chamado'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12}>
            Solicitação de aprovação do chamado #{resumoTicket.ticketId}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              size="small"
              label="Mensagem de conclusão"
              multiline
              maxRows={10}
              inputProps={{ maxLength: 400 }}
              value={motivo}
              onChange={e =>
                setMotivo(e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              type="button"
              size="small"
              color="inherit"
              onClick={() => setModalAprovarChamado(false)}
            >
              cancelar
            </Button>
            <Button
              variant="contained"
              type="button"
              size="small"
              color="secondary"
              disabled={promiseInProgress}
              onClick={solicitarAprovacao}
            >
              Enviar Solicitação
            </Button>
          </Grid>
        </Grid>
      </Modal>

      {/* solicitar aprovação */}
      <Modal
        size="sm"
        open={modalValidarFastId}
        close={() => setModalValidarFastId(false)}
        titulo={'Confirmar vaga do FastRH'}
      >
        <Grid container spacing={{ xs: 3 }} style={{ maxWidth: '300px' }}>
          {
            detalheFastId?.id ?
              <>
                <Grid item xs={12} sm={12}>
                  <label className="labelAllis">
                    <span>Cargo:</span>
                  </label>
                  <div>{detalheFastId?.cargo}</div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label className="labelAllis">
                    <span>Posições:</span>
                  </label>
                  <div>{detalheFastId?.numeroVagas}</div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label className="labelAllis">
                    <span>Salário:</span>
                  </label>
                  <div>{services.mask.currency(detalheFastId?.salario)}</div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label className="labelAllis">
                    <span>Data de abertura:</span>
                  </label>
                  <div>{detalheFastId?.dataCriacao ? moment(detalheFastId?.dataCriacao).format('DD/MM/YYYY - HH:mm') : '-'}</div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <label className="labelAllis">
                    <span>Status:</span>
                  </label>
                  <div>{detalheFastId?.statusDescricao}</div>
                </Grid>
                <Grid item xs={12} className="flex justify-end gap-6">
                  <Button
                    variant="contained"
                    type="button"
                    size="small"
                    color="inherit"
                    onClick={() => setModalValidarFastId(false)}
                  >
                    cancelar
                  </Button>
                  <Button
                    variant="contained"
                    type="button"
                    size="small"
                    color="secondary"
                    disabled={promiseInProgress}
                    onClick={confirmarFastId}
                  >
                    Confirmar e Salvar
                  </Button>
                </Grid>
              </>
              :
              <>
                <Grid item xs={12} sm={12} className="flex justify-center">
                  <Alert severity="error">Não foi possível localizar a vaga informada no FastRH</Alert>
                </Grid>
                <Grid item xs={12} className="flex justify-center">
                  <Button
                    variant="contained"
                    type="button"
                    size="small"
                    color="inherit"
                    onClick={() => setModalValidarFastId(false)}
                  >
                    OK
                  </Button>
                </Grid>
              </>
          }
        </Grid>
      </Modal>

      {/* confirmar aprovação de cancelamento */}
      <Modal
        size="sm"
        open={modalConfirmarAprovCancel}
        close={() => setModalConfirmarAprovCancel(false)}
        titulo={'Confirmar Aprovação de Cancelamento'}
      >
        <div style={{ maxWidth: '300px', margin: 'auto' }}>
          <div className="center mb-10">
            <Alert severity="warning">Tem certeza que deseja aprovar o cancelamento #{idChamado}?</Alert>
          </div>
          <div className="flex gap-10 justify-center">
            <Button
              variant="contained"
              type="button"
              size="small"
              color="inherit"
              onClick={() => setModalConfirmarAprovCancel(false)}
              disabled={promiseInProgress}
            >
              Fechar
            </Button>
            <Button
              variant="contained"
              type="button"
              size="small"
              color="secondary"
              onClick={aprovarCancelamento}
              disabled={promiseInProgress}
            >
              Aprovar Cancelamento
            </Button>
          </div>
        </div>
      </Modal>

      {/* confirmar conclusão */}
      {/* <Modal
        size='sm'
        open={modalConfirmarAprovConclusao}
        close={() => setModalConfirmarAprovConclusao(false)}
        titulo={'Confirmar Conclusão'}
      >
        <div style={{maxWidth: '300px', margin: 'auto'}}>
          <div className="center mb-10">
            <Alert severity="warning">Tem certeza que concluir o chamado #{idChamado}?</Alert>
          </div>
          <div className="flex gap-10 justify-center">
            <Button
              variant="contained"
              type="button"
              size='small'
              color="inherit" 
              onClick={() => setModalConfirmarAprovConclusao(false)}
              disabled={promiseInProgress}
            >
              Fechar
            </Button>
            <Button
              variant="contained"
              type="button"
              size='small'
              color="secondary" 
              onClick={aprovarAprovacao}
              disabled={promiseInProgress}
            >
              Concluir
            </Button>
          </div>
        </div>
      </Modal> */}

      {/* solicitar cancelamento */}
      <Modal
        size="sm"
        open={modalCancelChamado}
        close={handleCloseSolicitarCancelamento}
        titulo={`Cancelar Chamado ${chamado.id}`}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12}>
            <div>
              <div className="mt-10 mb-20 center">
                Qual seu nivel de satisfação com nosso atendimento?
              </div>
              <div className="flex justify-center mb-20">
                <IconButton
                  className="btn-open-envirioments_icon"
                  size="small" aria-label="upload picture"
                  component="label"
                  onClick={() => setNotaAproval(5)}
                  disabled={promiseInProgress}
                  style={{ backgroundColor: `${notaAproval === 5 ? '#4caf50' : ''}` }}
                >
                  <SentimentVerySatisfiedIcon fontSize="large"
                                              style={{ color: `${notaAproval === 5 ? '#ffffff' : ''}` }} />
                </IconButton>
                <IconButton
                  className="btn-open-envirioments_icon"
                  size="small" aria-label="upload picture"
                  component="label"
                  onClick={() => setNotaAproval(4)}
                  disabled={promiseInProgress}
                  style={{ backgroundColor: `${notaAproval === 4 ? '#cddc39' : ''}` }}
                >
                  <SentimentSatisfiedAltIcon fontSize="large"
                                             style={{ color: `${notaAproval === 4 ? '#ffffff' : ''}` }} />
                </IconButton>
                <IconButton
                  className="btn-open-envirioments_icon"
                  size="small" aria-label="upload picture"
                  component="label"
                  onClick={() => setNotaAproval(3)}
                  disabled={promiseInProgress}
                  style={{ backgroundColor: `${notaAproval === 3 ? '#ffc107' : ''}` }}
                >
                  <SentimentNeutralIcon fontSize="large" style={{ color: `${notaAproval === 3 ? '#ffffff' : ''}` }} />
                </IconButton>
                <IconButton
                  className="btn-open-envirioments_icon"
                  size="small" aria-label="upload picture"
                  component="label"
                  onClick={() => setNotaAproval(2)}
                  disabled={promiseInProgress}
                  style={{ backgroundColor: `${notaAproval === 2 ? '#ff9e22' : ''}` }}
                >
                  <SentimentVeryDissatisfiedIcon fontSize="large"
                                                 style={{ color: `${notaAproval === 2 ? '#ffffff' : ''}` }} />
                </IconButton>
                <IconButton
                  className="btn-open-envirioments_icon"
                  size="small" aria-label="upload picture"
                  component="label"
                  onClick={() => setNotaAproval(1)}
                  disabled={promiseInProgress}
                  style={{ backgroundColor: `${notaAproval === 1 ? '#f44336' : ''}` }}
                >
                  <MoodBadIcon fontSize="large" style={{ color: `${notaAproval === 1 ? '#ffffff' : ''}` }} />
                </IconButton>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            Seu chamado
            possui {chamado.quantity} {chamado?.quantity === 1 ? 'posição, ela foi atendida?' : 'posições, quantas foram atendidas?'}
          </Grid>
          <Grid item xs={12}>
            {chamado.quantity === 1 &&
              <Select
                fullWidth
                required
                size="small"
                value={posicoesAtendidas}
                onChange={e =>
                  setPosicoesAtendidas(e.target.value)
                }
              >
                <MenuItem value="">Selecione</MenuItem>
                <MenuItem value="1">Sim</MenuItem>
                <MenuItem value="0">Não</MenuItem>
              </Select>
            }
            {chamado.quantity !== 1 &&
              <TextField
                fullWidth
                size="small"
                label="Vagas atendidas"
                inputProps={{ maxLength: 5 }}
                value={posicoesAtendidas}
                onChange={e =>
                  setPosicoesAtendidas(services.mask.number(e.target.value))
                }
              />
            }
          </Grid>
          <Grid item xs={12}>
            Fornceça um detalhamento sobre o motivo do cancelamento
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              size="small"
              label="Motivo"
              multiline
              maxRows={10}
              inputProps={{ maxLength: 400 }}
              value={motivo}
              onChange={e =>
                setMotivo(e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              type="button"
              size="small"
              color="inherit"
              onClick={handleCloseSolicitarCancelamento}
            >
              cancelar
            </Button>
            <Button
              variant="contained"
              type="button"
              size="small"
              color="error"
              disabled={promiseInProgress}
              onClick={solicitarCancelamento}
            >
              Enviar Solicitação
            </Button>
          </Grid>
        </Grid>
      </Modal>

      {/* etapa conclusão */}
      <Dialog
        open={modalEtapaConclusao}
        onClose={handleCloseModalConclusao}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle style={{ padding: '10px 10px' }}>
          <div className="flex justify-between items-center">
            {typeEncerramento === '' &&
              <>
                <span style={{ width: '30px' }}></span>
                <Typography>Opções de conclusão</Typography>
              </>
            }
            {typeEncerramento === 'review' &&
              <>
                <IconButton
                  className="btn-open-envirioments_icon"
                  size="small" aria-label="upload picture"
                  component="label"
                  onClick={() => setTypeEncerramento('')}
                  disabled={promiseInProgress}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Typography>Pedir revisão do chamado</Typography>
              </>
            }
            {typeEncerramento === 'aprove' &&
              <>
                <IconButton
                  className="btn-open-envirioments_icon"
                  size="small" aria-label="upload picture"
                  component="label"
                  onClick={() => setTypeEncerramento('')}
                  disabled={promiseInProgress}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Typography>Aprovar Conclusão</Typography>
              </>
            }

            {typeEncerramento === 'cancel' &&
              <>
                <IconButton
                  className="btn-open-envirioments_icon"
                  size="small" aria-label="upload picture"
                  component="label"
                  onClick={() => setTypeEncerramento('')}
                  disabled={promiseInProgress}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Typography>Cancelar o chamado</Typography>
              </>
            }
            <IconButton
              aria-label="close"
              onClick={handleCloseModalConclusao}
              disabled={promiseInProgress}
            >
              <CancelIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <div className="sm-min-500">
            {
              typeEncerramento === '' &&
              <div className="flex justify-center mb-20" style={{ marginTop: '20px' }}>
                <div className="box_type_chamado center"
                     onClick={() => setTypeEncerramento('review')}
                >
                  <ReplayCircleFilledIcon fontSize="large" style={{ color: '$cor-primary' }} />
                  <span>Pedir revisão <br />do chamado</span>
                </div>
                <div className="box_type_chamado center"
                     onClick={() => setTypeEncerramento('aprove')}
                >
                  <CheckCircleIcon fontSize="large" style={{ color: 'green' }} />
                  <span>Aprovar <br />Conclusão</span>
                </div>

                <div className="box_type_chamado center"
                     onClick={() => setTypeEncerramento('cancel')}
                >
                  <BlockIcon fontSize="large" style={{ color: 'red' }} />
                  <span>Cancelar o <br />chamado</span>
                </div>
              </div>
            }

            {
              (typeEncerramento === 'aprove' || typeEncerramento === 'cancel') &&
              <div>
                <div className="mt-10 mb-20 center">
                  Qual seu nivel de satisfação com nosso atendimento?
                </div>
                <div className="flex justify-center mb-20">
                  <IconButton
                    className="btn-open-envirioments_icon"
                    size="small" aria-label="upload picture"
                    component="label"
                    onClick={() => setNotaAproval(5)}
                    disabled={promiseInProgress}
                    style={{ backgroundColor: `${notaAproval === 5 ? '#4caf50' : ''}` }}
                  >
                    <SentimentVerySatisfiedIcon fontSize="large"
                                                style={{ color: `${notaAproval === 5 ? '#ffffff' : ''}` }} />
                  </IconButton>
                  <IconButton
                    className="btn-open-envirioments_icon"
                    size="small" aria-label="upload picture"
                    component="label"
                    onClick={() => setNotaAproval(4)}
                    disabled={promiseInProgress}
                    style={{ backgroundColor: `${notaAproval === 4 ? '#cddc39' : ''}` }}
                  >
                    <SentimentSatisfiedAltIcon fontSize="large"
                                               style={{ color: `${notaAproval === 4 ? '#ffffff' : ''}` }} />
                  </IconButton>
                  <IconButton
                    className="btn-open-envirioments_icon"
                    size="small" aria-label="upload picture"
                    component="label"
                    onClick={() => setNotaAproval(3)}
                    disabled={promiseInProgress}
                    style={{ backgroundColor: `${notaAproval === 3 ? '#ffc107' : ''}` }}
                  >
                    <SentimentNeutralIcon fontSize="large" style={{ color: `${notaAproval === 3 ? '#ffffff' : ''}` }} />
                  </IconButton>
                  <IconButton
                    className="btn-open-envirioments_icon"
                    size="small" aria-label="upload picture"
                    component="label"
                    onClick={() => setNotaAproval(2)}
                    disabled={promiseInProgress}
                    style={{ backgroundColor: `${notaAproval === 2 ? '#ff9e22' : ''}` }}
                  >
                    <SentimentVeryDissatisfiedIcon fontSize="large"
                                                   style={{ color: `${notaAproval === 2 ? '#ffffff' : ''}` }} />
                  </IconButton>
                  <IconButton
                    className="btn-open-envirioments_icon"
                    size="small" aria-label="upload picture"
                    component="label"
                    onClick={() => setNotaAproval(1)}
                    disabled={promiseInProgress}
                    style={{ backgroundColor: `${notaAproval === 1 ? '#f44336' : ''}` }}
                  >
                    <MoodBadIcon fontSize="large" style={{ color: `${notaAproval === 1 ? '#ffffff' : ''}` }} />
                  </IconButton>
                </div>
              </div>
            }
            {
              typeEncerramento === 'aprove' &&
              <div className="mb-20">
                <TextField
                  fullWidth
                  required
                  size="small"
                  label="Comentário"
                  multiline
                  maxRows={10}
                  inputProps={{ maxLength: 400 }}
                  value={commentAproval}
                  onChange={e =>
                    setCommentAproval(e.target.value)
                  }
                />
              </div>
            }

            {
              (typeEncerramento === 'cancel' || typeEncerramento === 'review') &&
              <>
                <div className="mt-10 mb-20 center">
                  Seu chamado
                  possui {chamado.quantity} {chamado?.quantity === 1 ? 'posição, ela foi atendida?' : 'posições, quantas foram atendidas?'}
                </div>
                <div className="mb-20">
                  {chamado.quantity === 1 &&
                    <Select
                      fullWidth
                      required
                      size="small"
                      value={posicoesAtendidas}
                      onChange={e =>
                        setPosicoesAtendidas(e.target.value)
                      }
                    >
                      <MenuItem value="">Selecione</MenuItem>
                      <MenuItem value="1">Sim</MenuItem>
                      <MenuItem value="0">Não</MenuItem>
                    </Select>
                  }
                  {chamado.quantity !== 1 &&
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label="Vagas atendidas"
                      inputProps={{ maxLength: 5 }}
                      value={posicoesAtendidas}
                      onChange={e =>
                        setPosicoesAtendidas(services.mask.number(e.target.value))
                      }
                    />
                  }
                </div>
              </>
            }
            {
              typeEncerramento === 'cancel' &&
              <div>
                <div className="mt-10 mb-20 center">Informe o motivo do cancelamento</div>
                <div className="mb-20">
                  <TextField
                    fullWidth
                    required
                    size="small"
                    label="Motivo"
                    multiline
                    maxRows={10}
                    inputProps={{ maxLength: 400 }}
                    value={motivo}
                    onChange={e =>
                      setMotivo(e.target.value)
                    }
                  />
                </div>
              </div>
            }

            {
              typeEncerramento === 'review' &&
              <div>
                <div className="mb-10 mt-10">
                  Tipo de revisão
                </div>
                <div>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={revisaoContrato}
                                                         onChange={(e) => setRevisaoContrato(e.target.checked)} />}
                                      label="Revisão das contratações" />
                    <FormControlLabel control={<Checkbox checked={revisaoSalario}
                                                         onChange={(e) => setRevisaoSalario(e.target.checked)} />}
                                      label="Revisão do salário" />
                    <FormControlLabel control={<Checkbox checked={revisaoPerfil}
                                                         onChange={(e) => setRevisaoPerfil(e.target.checked)} />}
                                      label="Revisão do perfil" />
                  </FormGroup>
                  {/* <Select
                    fullWidth
                    required
                    size="small"
                    value={tipoRevisao}
                    onChange={e =>
                      setTipoRevisao(e.target.value)
                    }
                  >
                    <MenuItem value="">Selecione</MenuItem>
                    <MenuItem value="Revisar as contratações realizadas">Revisão das contratações</MenuItem>
                    <MenuItem value="Revisar o salário desta vaga">Revisão do salário</MenuItem>
                    <MenuItem value="Revisar o perfil desta vaga">Revisão do perfil</MenuItem>
                  </Select>  */}
                </div>
                <div className="mb-10 mt-20 center">
                  Forneça o detalhamento das mudanças necessárias no seu chamado.
                </div>
                <div className="mb-20">
                  <TextField
                    fullWidth
                    required
                    size="small"
                    label="Motivo"
                    multiline
                    maxRows={10}
                    inputProps={{ maxLength: 400 }}
                    value={motivoReview}
                    onChange={e =>
                      setMotivoReview(e.target.value)
                    }
                  />
                </div>
              </div>
            }

            {typeEncerramento !== '' &&
              <div className="flex justify-end">
                <Button
                  variant="contained"
                  type="button"
                  size="small"
                  color="secondary"
                  onClick={handleConclusao}
                  disabled={promiseInProgress}
                >
                  Confirmar
                </Button>
              </div>
            }

          </div>
        </DialogContent>
      </Dialog>

    </>
  )
}

export default Chamado
