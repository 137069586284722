// multi funcoes > locais
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import moment from 'moment'

import { Button, Grid, IconButton, TextField } from '@mui/material'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification.js'
import { IRazaoContratacao } from '../../interfaces/index'
import services from '../../services/index.js'
import { useAuth } from '../../hooks/useAuth'

import Beneficios from './components/Beneficios'
import Employees from './components/Employees'
import Perfis from './components/Perfis'
import DadosSolicitante from './components/DadosSolicitante'
import ResumoSolicitacao from './components/ResumoSolicitacao'
import DadosVaga from './components/DadosVaga'
import LocaisAutoComplete from './components/LocaisAutoComplete'

import './style.scss'

const dataAtual = new Date().toISOString()

function ChamadoNewMultLocais() {
  const { idTypeContrato } = useParams()
  const { getUser } = useAuth()
  const user = getUser()
  const navigate = useNavigate()
  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()

  const [listaChamados, setListaChamados] = useState<any>([
    {
      open: true,
      isTemporary: idTypeContrato?.toUpperCase() === 'TEMPORARIA' ? true : false,
      pcd: false,
      dangerousness: false,
      unhealthy: false,
      benefits: [],
      employeesToReplace: [],
      profileItems: [],
      locais: [{ cttId: '', startDate: '', quantity: '' }]
    }
  ])

  const minDateInicial = moment().add(7, 'days').format('YYYY-MM-DD')

  //
  const [empresas, setEmpresas] = useState<any[] | []>([])
  const [razoesContratacao, setRazoesContratacao] = useState<IRazaoContratacao[] | []>([])
  const [tiposContratacao, setTiposContratacao] = useState<any[] | []>([])
  const [escalasList, setEscalasList] = useState<any>([])

  // gerenciamento de local
  const addNewGroup = () => {
    setListaChamados((prevState: any) => ([...prevState, {
      open: true,
      isTemporary: idTypeContrato?.toUpperCase() === 'TEMPORARIA' ? true : false,
      pcd: false,
      dangerousness: false,
      unhealthy: false,
      benefits: [],
      employeesToReplace: [],
      profileItems: [],
      locais: [{ cttId: '', startDate: '', quantity: '' }]
    }]))
  }

  const handleToggleChamado = (index: number) => {
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, index),
      { ...prevState[index], open: !prevState[index].open },
      ...prevState.slice(index + 1)
    ])
  }

  const handleCopyChamado = (index: number) => {
    const newChanadoItem = { ...listaChamados[index] }
    setListaChamados((prevState: any) => [
      ...prevState,
      newChanadoItem
    ])
  }

  const handleTrashChamado = (index: number) => {
    if (listaChamados.length === 1) {
      notify('A solicitação deve possuir pelo menos um local', { variant: 'warning' })
      return false
    }
    setListaChamados((prevState: any) => ([
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1)
      ]
    ))
  }

  // gerenciamento de locais
  const addNewLocal = (index: number) => {
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, index),
      {
        ...prevState[index],
        locais: [
          ...prevState[index].locais,
          { cttId: '', startDate: '', quantity: '' }
        ]
      },
      ...prevState.slice(index + 1)
    ])
  }
  const removeLocal = (index: number, indexLocal: number) => {
    if (listaChamados[index].locais.length === 1) {
      notify(`A solicitação deve possuir pelo menos um local`, { variant: 'warning' })
      return
    }
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, index),
      {
        ...prevState[index],
        locais: [
          ...prevState[index].locais.slice(0, indexLocal),
          ...prevState[index].locais.slice(indexLocal + 1)
        ]
      },
      ...prevState.slice(index + 1)
    ])
  }
  const setLocal = (index: number, indexL: number, chave: string, valeu: any) => {
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, index),
      {
        ...prevState[index],
        locais: [
          ...prevState[index].locais.slice(0, indexL),
          { ...prevState[index].locais[indexL], [chave]: valeu },
          ...prevState[index].locais.slice(indexL + 1)
        ]
      },
      ...prevState.slice(index + 1)
    ])
  }

  const setFuncao = (indexLocal: number, indexFuncao: number, chave: string, value: any) => {
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        [chave]: value
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }

  //


  /// add beneficio
  const addBeneficio = async (beneficioNome: string, beneficioValue: string, baneficioId: number, indexLocal: number, indexFuncao: number) => {
    // setBeneficios(prevState => [
    //   ...prevState, 
    //   {
    //     benefitName: beneficioNome, 
    //     benefitValue: beneficioValue
    //   }
    // ])

    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        benefits: [
          ...prevState[indexLocal].benefits,
          {
            benefitName: beneficioNome,
            benefitValue: beneficioValue
          }
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])

  }

  const removeBeneficio = async (index: number, indexLocal: number, indexFuncao: number) => {
    // setBeneficios((prevState:any) => ([
    //     ...prevState.slice(0, index),
    //     ...prevState.slice(index + 1)
    //   ]
    // ))

    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        benefits: [
          ...prevState[indexLocal].benefits.slice(0, index),
          ...prevState[indexLocal].benefits.slice(index + 1)
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }

  /// add recruitment
  const addRecruitment = async (recruitmentCpf: string, recruitmentNome: string, employeeId: number, indexLocal: number, indexFuncao: number) => {
    // setEmployees(prevState => [...prevState, 
    //   {cpf: recruitmentCpf, fullName:recruitmentNome } 
    // ])

    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        employeesToReplace: [
          ...prevState[indexLocal].employeesToReplace,
          { cpf: recruitmentCpf, fullName: recruitmentNome }
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }

  const removeRecruitment = async (index: number, indexLocal: number, indexFuncao: number) => {
    // setEmployees((prevState:any) => ([
    //     ...prevState.slice(0, index),
    //     ...prevState.slice(index + 1)
    //   ]
    // ))

    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        employeesToReplace: [
          ...prevState[indexLocal].employeesToReplace.slice(0, index),
          ...prevState[indexLocal].employeesToReplace.slice(index + 1)
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }

  /// add perfis
  const addPerfil = async (nomeCurso: string, descricaoCurso: string, obritatorio: string, perfilId: number, indexLocal: number, indexFuncao: number) => {
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        profileItems: [
          ...prevState[indexLocal].profileItems,
          { name: nomeCurso, description: descricaoCurso, mandatory: obritatorio }
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }

  const removePerfil = async (index: number, indexLocal: number, indexFuncao: number) => {
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        profileItems: [
          ...prevState[indexLocal].profileItems.slice(0, index),
          ...prevState[indexLocal].profileItems.slice(index + 1)
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }
  ///////

  const openChamado = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    function validarListChamados() {
      let valid = true
      for (let x = 0; x < listaChamados.length; x++) {

        if (!listaChamados[x].customerManager) {
          notify(`Função: ${x + 1} - Campo Gestor Responsavel deve ser informado`, { variant: 'warning' })
          valid = false
          break
        }

        if (!listaChamados[x].functionName) {
          notify(`Função: ${x + 1} - Campo função deve ser informado`, { variant: 'warning' })
          valid = false
          break
        }
        if (listaChamados[x].functionName && listaChamados[x].functionName?.length <= 5) {
          notify(`Função: ${x + 1} - Campo função deve ter mais que 5 caracteres`, { variant: 'warning' })
          valid = false
          break
        }
        if (!listaChamados[x].remuneration) {
          notify(`Função: ${x + 1} - Campo remuneração deve ser informado`, { variant: 'warning' })
          valid = false
          break
        }
        if (!listaChamados[x].scale) {
          notify(`Função: ${x + 1} - Campo Escala deve ser informado`, { variant: 'warning' })
          valid = false
          break
        }
        if (listaChamados[x].scale.length < 3) {
          notify(`Função: ${x + 1} - Campo Escala deve ser maior ou igual a 3 caracteres`, { variant: 'warning' })
          valid = false
          break
        }
        if (!listaChamados[x].jobStartHour) {
          notify(`Função: ${x + 1} - Campo Horário trabalho inicial deve ser informado`, { variant: 'warning' })
          valid = false
          break
        }
        if (!listaChamados[x].jobFinishHour) {
          notify(`Função: ${x + 1} - Campo Horário trabalho final deve ser informado`, { variant: 'warning' })
          valid = false
          break
        }
        if (idTypeContrato?.toUpperCase() === 'TEMPORARIA') {
          if (!listaChamados[x].recruitmentDemandHiringReasonId) {
            notify(`Função: ${x + 1} - Campo Motivo da contratação deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if (!listaChamados[x].duration) {
            notify(`Função: ${x + 1} - Campo Duração em dias deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if (listaChamados[x].duration < 7) {
            notify(`Função: ${x + 1} - Deve ser superior ou igual a 7`, { variant: 'warning' })
            valid = false
            break
          }
        }

        if (listaChamados[x].pcd === null || listaChamados[x].pcd === undefined || listaChamados[x].pcd === '') {
          notify(`Função: ${x + 1} - Campo PCD deve ser informado`, { variant: 'warning' })
          valid = false
          break
        }
        if ((listaChamados[x].pcd === true || listaChamados[x].pcd === 'true') && (listaChamados[x].pcdRestriction === null || listaChamados[x].pcdRestriction === undefined || listaChamados[x].pcdRestriction === '')) {
          notify(`Função: ${x + 1} - Campo Critério PCD deve ser informado`, { variant: 'warning' })
          valid = false
          break
        }
        if (listaChamados[x].pcdRestriction && listaChamados[x].pcdRestriction.length < 5) {
          notify(`Função: ${x + 1} - Campo Critério PCD deve ter no minimo 5 caracteres`, { variant: 'warning' })
          valid = false
          break
        }
        if (listaChamados[x].dangerousness === null || listaChamados[x].dangerousness === undefined || listaChamados[x].dangerousness === '') {
          notify(`Função: ${x + 1} - Campo Periculosidade deve ser informado`, { variant: 'warning' })
          valid = false
          break
        }
        if (listaChamados[x].unhealthy === null || listaChamados[x].unhealthy === undefined || listaChamados[x].unhealthy === '') {
          notify(`Função: ${x + 1} - Campo Insalubridade deve ser informado`, { variant: 'warning' })
          valid = false
          break
        }


        for (let f = 0; f < listaChamados[x].locais.length; f++) {
          if (!listaChamados[x].locais[f].quantity) {
            notify(`Função: ${x + 1} | Local: ${f + 1} - Campo Quantidade deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if (Number(listaChamados[x].locais[f].quantity) === 0) {
            notify(`Função: ${x + 1} | Local: ${f + 1} - Campo Quantidade de vagas deve ser maior que zero`, { variant: 'warning' })
            valid = false
            break
          }
          if (!listaChamados[x].locais[f].startDate) {
            notify(`Função: ${x + 1} | Local: ${f + 1} - Campo Data de início deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }

          if (listaChamados[x].isEmployeeReplace) {
            if (Number(listaChamados[x].locais[f].quantity) !== listaChamados[x].employeesToReplace.length) {
              notify(`Função: ${x + 1} | Local: ${f + 1} - A quantidade de Funcionarios a serem substituidos deve ser igual a quantidade de vagas`, { variant: 'warning' })
              return false
            }
          }
        }
        if (!valid) {
          break
        }
      }
      return valid
    }

    function formatObjToSend() {
      let objAllFuncoesList = []

      for (let x = 0; x < listaChamados.length; x++) {
        for (let f = 0; f < listaChamados[x].locais.length; f++) {
          let chamadoUnitario: any = {}

          chamadoUnitario.cttId = listaChamados[x].locais[f].cttId

          chamadoUnitario.customerManager = listaChamados[x].customerManager
          chamadoUnitario.costCenter = listaChamados[x].costCenter
          chamadoUnitario.quantity = listaChamados[x].locais[f].quantity
          chamadoUnitario.startDate = listaChamados[x].locais[f].startDate
          chamadoUnitario.optionalData = listaChamados[x].optionalData
          chamadoUnitario.functionName = listaChamados[x].functionName
          chamadoUnitario.remuneration = listaChamados[x].remuneration
          chamadoUnitario.scale = listaChamados[x].scale
          chamadoUnitario.jobStartHour = listaChamados[x].jobStartHour
          chamadoUnitario.jobFinishHour = listaChamados[x].jobFinishHour
          chamadoUnitario.duration = listaChamados[x].duration ? listaChamados[x].duration : null
          chamadoUnitario.recruitmentDemandHiringReasonId = listaChamados[x].recruitmentDemandHiringReasonId === '' || listaChamados[x].recruitmentDemandHiringReasonId === 'null' ? null : listaChamados[x].recruitmentDemandHiringReasonId
          chamadoUnitario.pcd = listaChamados[x].pcd
          chamadoUnitario.pcdRestriction = listaChamados[x].pcdRestriction || null
          chamadoUnitario.dangerousness = listaChamados[x].dangerousness
          chamadoUnitario.unhealthy = listaChamados[x].unhealthy
          chamadoUnitario.unhealthyRate = listaChamados[x].unhealthyRate
          chamadoUnitario.fastJobId = listaChamados[x].fastJobId ? listaChamados[x].fastJobId : null

          chamadoUnitario.employeesToReplace = []
          chamadoUnitario.benefits = listaChamados[x].benefits
          chamadoUnitario.profileItems = listaChamados[x].profileItems

          if (listaChamados[x].isEmployeeReplace) {
            chamadoUnitario.employeesToReplace = listaChamados[x].employeesToReplace
          }


          objAllFuncoesList.push(chamadoUnitario)
        }
      }

      return objAllFuncoesList
    }

    if (validarListChamados()) {
      let objAllFuncoes = formatObjToSend()

      try {
        const resTicketData = await trackPromise(services.api.newResChamadoData(objAllFuncoes))
        if (resTicketData.fail) {
          throw new Error(
            resTicketData.error || 'Não foi possível criar o chamado'
          )
        }

        notify('chamado criado com sucesso', { variant: 'success' })
        navigate(`/app/chamados/meus-chamados`)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

  }

  useEffect(() => {
    const init = async () => {
      try {
        const res = await trackPromise(services.api.getLocais(idTypeContrato?.toUpperCase() === 'TEMPORARIA' ? true : false))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível Locais'
          )
        }
        setEmpresas(res.data.map((item: any) => {
          return {
            ...item,
            label: `${item.cttId} - ${item.res} - ${item.xnGrup} - ${item.desc01} - ${services.mask.cnpj(item.cgc)}`
          }
        }))
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }

      try {
        const res = await trackPromise(services.api.getEscalasList())
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível Carregar Escalas'
          )
        }
        setEscalasList(res.data)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }

      try {
        const res = await trackPromise(services.api.getDemandreasons())
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível consultar Demand reasons'
          )
        }
        let tiposlistmotivoRef: any = []
        let tiposlistmotivo: any = []
        let motivosFormat = res.data.map((item: IRazaoContratacao) => {
          // let typeString: any = item?.type ? item.type.split(' ') : ''
          // typeString = typeString[typeString.length - 1]
          // item.typeMotivo = typeString
          // if (item.typeMotivo === 'Previsível' || item.typeMotivo === 'Imprevisível' || item.typeMotivo ===  'Transitória') {
          //   if (!tiposlistmotivoRef.includes(item.typeMotivo)) {
          //     tiposlistmotivoRef.push(item.typeMotivo)
          //     tiposlistmotivo.push(item.type)
          //   } 
          // }
          if (!tiposlistmotivoRef.includes(item.type)) {
            tiposlistmotivoRef.push(item.type)
            tiposlistmotivo.push(item.type)
          }
          return item
        })
        setRazoesContratacao([...motivosFormat])
        setTiposContratacao(tiposlistmotivo)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="wrapper-container-content bgwhite">
      <div className="wrapper-page-content" id="wrapper-page-content">
        <PageTitle>
          <div>Solicitação de vagas</div>
        </PageTitle>
        <Grid container justifyContent="center" spacing={{ xs: 3 }}>
          <Grid item md={10} xs={12}>
            <div className="panel">
              <div className="panel-header">
                Solicitação de vagas - {idTypeContrato === 'TEMPORARIA' ? 'Temporário' : 'R&S'} - Um local com múlplas
                funções
              </div>
              <div className="panel-body">
                {/* CABEÇARIO  */}
                <DadosSolicitante
                  name={user?.fullName || ''}
                  email={user?.email || ''}
                  data={dataAtual}
                />
                <ResumoSolicitacao
                  refObj={'byFuncao'}
                  list={listaChamados}
                />

                <form onSubmit={openChamado}>
                  {/* LOCAIS  */}
                  <Grid container spacing={{ xs: 3 }}>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <div className="flex gap-10 justify-between items-center mb-10"
                           style={{ borderBottom: '1px solid rgb(234 234 234)', paddingBottom: '10px' }}>
                        <div>Cadastro de funções</div>
                        <Button
                          variant="contained"
                          type="button"
                          size="small"
                          color="secondary"
                          onClick={addNewGroup}
                          startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
                        >
                          Adicionar função
                        </Button>
                      </div>
                      {listaChamados.map((item: any, index: number) => (
                        <div className="chamadoTicketItem" key={index}>
                          <div className="chamadoTicketItem_Title main">
                            <div className="chamadoTicketItem_Title_text">#{index + 1} {item.functionName}</div>
                            <div className="chamadoTicketItem_Title_actions">
                              <div>
                                <span onClick={() => handleToggleChamado(index)} className="flex items-center pointer">
                                  {item.open ? 'Recolher Função' : ' Expandir Função'}
                                  {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </span>
                              </div>
                              <div className="flex gap-6">
                                <IconButton
                                  style={{
                                    backgroundColor: '#f4f4f4',
                                    color: '#cccccc',
                                    marginRight: '0px',
                                    borderRadius: '4px'
                                  }}
                                  onClick={() => handleCopyChamado(index)}
                                  edge="end"
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                                <IconButton
                                  style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    marginRight: '0px',
                                    borderRadius: '4px'
                                  }}
                                  onClick={() => handleTrashChamado(index)}
                                  edge="end"
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                          <div className={`chamadoTicketItem_Content ${item.open ? 'open' : ''}`}>
                            {item.open &&
                              <>
                                <div>
                                  <DadosVaga
                                    chamado={item}
                                    setChamado={setFuncao}
                                    indexLocal={index}
                                    razoesContratacao={razoesContratacao}
                                    tiposContratacao={tiposContratacao}
                                    ocultarCamposDataQuantidade={true}
                                    escalasList={escalasList}
                                    disabledSubstituicao={idTypeContrato?.toUpperCase() === 'TEMPORARIA'}
                                  />

                                  <div>
                                    <div className="mt-30">Locais</div>
                                    <div>
                                      {
                                        item.locais.map((local: any, indexL: number) => (
                                          <Grid container spacing={{ xs: 3 }} className="mt-10" key={indexL}>
                                            <Grid item xs={12} sm={12} md={4} lg={6}>
                                              <LocaisAutoComplete
                                                action={setLocal}
                                                mainList={empresas}
                                                index={index}
                                                indexSecondary={indexL}
                                                required={true}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                              <TextField
                                                fullWidth
                                                required
                                                size="small"
                                                type="date"
                                                label="Data de início"
                                                inputProps={{ maxLength: 120, min: minDateInicial }}
                                                InputLabelProps={{ shrink: true }}
                                                value={local.startDate ? moment(local.startDate).format('YYYY-MM-DD') : ''}
                                                onChange={e =>
                                                  setLocal(index, indexL, 'startDate', e.target.value)
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3}
                                                  className="flex items-center gap-10 flex-nowrap">
                                              <TextField
                                                fullWidth
                                                required
                                                size="small"
                                                label="Quantidade de vagas"
                                                inputProps={{ maxLength: 5 }}
                                                InputLabelProps={{ shrink: true }}
                                                value={local.quantity || ''}
                                                onChange={e =>
                                                  setLocal(index, indexL, 'quantity', e.target.value)
                                                }
                                              />
                                              <IconButton
                                                style={{
                                                  backgroundColor: 'red',
                                                  color: 'white',
                                                  marginRight: '0px',
                                                  borderRadius: '4px'
                                                }}
                                                size="small"
                                                onClick={() => removeLocal(index, indexL)}
                                                edge="end"
                                              >
                                                <DeleteForeverIcon />
                                              </IconButton>
                                            </Grid>
                                          </Grid>
                                        ))
                                      }

                                    </div>
                                    <div className="flex justify-end mt-20">
                                      <Button
                                        variant="contained"
                                        type="button"
                                        size="small"
                                        color="secondary"
                                        onClick={() => addNewLocal(index)}
                                        startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
                                      >
                                        Adicionar local
                                      </Button>
                                    </div>
                                  </div>
                                  <Grid container spacing={{ xs: 3 }}>
                                    <Grid item xs={12} className="mt-30">
                                      <Perfis
                                        list={item.profileItems}
                                        remove={removePerfil}
                                        add={addPerfil}
                                        indexLocal={index}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Beneficios
                                        beneficios={item.benefits}
                                        removeBeneficio={removeBeneficio}
                                        addBeneficio={addBeneficio}
                                        indexLocal={index}
                                      />
                                    </Grid>
                                    {
                                      item.isEmployeeReplace ?
                                        <Grid item xs={12}>
                                          <Employees
                                            employees={item.employeesToReplace}
                                            remove={removeRecruitment}
                                            add={addRecruitment}
                                            indexLocal={index}
                                            quantidade={item.quantity}
                                          />
                                        </Grid>
                                        : ''
                                    }
                                  </Grid>

                                  <Grid item xs={12} className="mt-30">
                                    <TextField
                                      fullWidth
                                      multiline
                                      maxRows={10}
                                      label="Dados Opcionais"
                                      inputProps={{ maxLength: 400 }}
                                      value={item.optionalData ? item.optionalData : ''}
                                      InputLabelProps={{ shrink: true }}
                                      onChange={e =>
                                        setFuncao(index, 0, 'optionalData', e.target.value)
                                      }
                                    />
                                  </Grid>
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      ))}

                    </Grid>
                  </Grid>

                  <div className="flex justify-end mt-30 mb-30">
                    <Button
                      variant="contained"
                      type="submit"
                      size="small"
                      color="success"
                      disabled={promiseInProgress}
                    >
                      Abrir Solicitação
                    </Button>
                  </div>

                </form>
              </div>
            </div>
          </Grid>
        </Grid>

      </div>

    </div>
  )
}

export default ChamadoNewMultLocais
