import React, { useCallback, useEffect, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { debounce } from 'lodash'
import Cookies from 'js-cookie'

import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip
} from '@mui/material'

import RemoveIcon from '@mui/icons-material/Remove'
import CheckIcon from '@mui/icons-material/Check'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import SettingsIcon from '@mui/icons-material/Settings'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import SearchIcon from '@mui/icons-material/Search'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import { useNotification } from '../../hooks/useNotification.js'
import Modal from '../../components/Modal'
import { Pagination, TabelaSearchColumn } from '../../components/Table'
import { IEmpresa } from '../../interfaces/index'
import services from '../../services/index.js'
import { useAuth } from '../../hooks/useAuth'

interface ITableaUsuario {
  mainList: IEmpresa[] | [] | null | undefined
  editRegistro: any
  handlePagination: any
  handleBusca: any
  currentPage: number
  totalPages: number
  pageSize: number
  totalCount: number
  objBusca: any
  setObjBusca: any
}

const indexColumnBloqueado = 11
const tablesVisibles = ['checkbox', 'CR', 'Descrição do CR', 'Serviço', 'Razão Social', 'Endereço', 'Bairro', 'CEP', 'Estado', 'CNPJ', 'Grupo do cliente', 'Bloqueado', 'Ações']

function not(a: readonly any[], b: readonly any[]) {
  // return a.filter((value) => b.indexOf(value) === -1);
  return a.filter((value) => services.utils.findIndexInArray(value.id, b, 'id') === -1)
}

function intersection(a: readonly any[], b: readonly any[]) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function TabelaHeaderTh(props: any) {
  const {
    indexRef, visibleColumn, paramns, setSearchChange, ordeby,
    setListItensSelected, rows, itemsSelecionadosLength
  } = props
  const { promiseInProgress } = usePromiseTracker()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  const selectAll = () => {
    setListItensSelected(rows.map((item: IEmpresa) => item.indexRef))
  }

  const unselectAll = () => {
    setListItensSelected([])
  }

  return (
    <>
      {visibleColumn &&
        <>
          {
            (
              paramns.label === 'Ações' &&
              (
                userRoles.includes('recruitermaps_read') ||
                userRoles.includes('recruitermaps_read_any') ||
                userRoles.includes('crusermap_read') ||
                userRoles.includes('crusermap_read_any')
              )
            ) || paramns.label !== 'Ações' ?
              <th>
                <div>
                  <div>
                    {
                      paramns?.showOrderby &&
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <IconButton
                          size="small"
                          onClick={() => ordeby(false, paramns.searchRef)}
                          style={{ padding: '2px' }}
                          disabled={promiseInProgress}
                        >
                          <ArrowUpwardIcon style={{ fontSize: '12px' }} />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => ordeby(true, paramns.searchRef)}
                          style={{ padding: '2px' }}
                          disabled={promiseInProgress}
                        >
                          <ArrowDownwardIcon style={{ fontSize: '12px' }} />
                        </IconButton>
                      </div>
                    }
                    <span className="th-title">
                    {paramns.label === 'checkbox' &&
                      <>
                        {itemsSelecionadosLength === rows?.length && itemsSelecionadosLength > 0 &&
                          <span className="fakeCheckbox active" onClick={unselectAll}><CheckIcon
                            fontSize="small" /></span>
                        }
                        {itemsSelecionadosLength !== rows?.length && itemsSelecionadosLength > 0 &&
                          <span className="fakeCheckbox active" onClick={selectAll}><RemoveIcon
                            fontSize="small" /></span>
                        }
                        {itemsSelecionadosLength === 0 &&
                          <span className="fakeCheckbox" onClick={selectAll}></span>
                        }
                      </>
                    }
                      {paramns.label !== 'checkbox' &&
                        paramns.label
                      }
                  </span>
                  </div>
                  {
                    paramns?.showSearch &&
                    <div>
                      <SearchIcon fontSize="small" className="tableSeachIccon"
                                  onClick={() =>
                                    setSearchChange((prevState: any) => [
                                      ...prevState.slice(0, indexRef),
                                      { ...prevState[indexRef], inputVisible: true },
                                      ...prevState.slice(indexRef + 1)
                                    ])
                                  }
                      />
                    </div>
                  }
                </div>
              </th>
              :
              ''
          }
        </>
      }
    </>
  )
}

function TabelaEmpresas(props: ITableaUsuario) {
  const {
    mainList, editRegistro, handleBusca, pageSize, totalCount,
    currentPage, totalPages, handlePagination, objBusca, setObjBusca
  } = props

  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()
  const { getUserRoles, getUser } = useAuth()
  const userRoles = getUserRoles()
  const user = getUser()

  const [listItensSelected, setListItensSelected] = useState<any>([])
  const [listItensSelectedDetalhada, setListItensSelectedDetalhada] = useState<any>([])
  const [modalMapeamento, setModalMapeamento] = useState<boolean>(false)
  const [mapementoStep, setMapeamentoStep] = useState<number>(0)

  const [modalShowHideColumns, setModalShowHideColumns] = useState<boolean>(false)
  const [visibleColumns, setVisibleColumns] = useState<any>([])
  const [visibleColumnsControl, setVisibleColumnsControl] = useState<any>([])
  const [colunaBuscaUserDisponivel, setColunaBuscaUserDisponivel] = useState<string>('fullname')

  const [destinoMap, setDestinoMap] = useState<any>('')
  const [checked, setChecked] = useState<readonly any[]>([])
  const [left, setLeft] = useState<readonly any[]>([])
  const [right, setRight] = useState<readonly any[]>([])
  const [searchUserDisponivel, setSearchUserDisponivel] = useState('')

  const handleCloseBuscaUserDisponivel = () => {
    setAnchorElBuscaUserDisponivel(null)
  }

  const handleToggleBloqueado = (value: boolean) => {
    setObjBusca((prevState: any) => [
      ...prevState.slice(0, indexColumnBloqueado),
      { ...prevState[indexColumnBloqueado], value: value ? '1' : '' },
      ...prevState.slice(indexColumnBloqueado + 1)
    ])

    let newBusca = [...objBusca]
    newBusca[indexColumnBloqueado].value = value ? '1' : ''

    handleChangeBusca(newBusca)
  }

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleChangeBuscaUserDisponivel = (busca: string) => {
    setSearchUserDisponivel(busca)
    handleBuscaDebounceUserDisponivel(busca)
  }
  const [anchorElBuscaUserDisponivel, setAnchorElBuscaUserDisponivel] = useState<null | HTMLElement>(null)
  const openBuscaUserDisponivel = Boolean(anchorElBuscaUserDisponivel)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounceUserDisponivel = useCallback(debounce((busca) => {
    handleBuscaUserDisponivel(busca)
  }, 800), [colunaBuscaUserDisponivel])

  const handleBuscaUserDisponivel = useCallback(async (busca: string) => {
    let paramns: any = {}
    paramns.filterBy = colunaBuscaUserDisponivel
    paramns.filterValue = busca

    try {
      const res = await trackPromise(services.api.getUsersAll(paramns, 1, 20))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível consultar usuários disponiveis'
        )
      }
      setLeft(res.data.data)
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colunaBuscaUserDisponivel])

  const handleClickBuscaUserDisponivel = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBuscaUserDisponivel(event.currentTarget)
  }

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleCheckedRight = async () => {
    let itensRepetido: any = []
    for (let r = 0; r < right.length; r++) {
      for (let l = 0; l < leftChecked.length; l++) {
        if (right[r].id === leftChecked[l].id) {
          itensRepetido.push(leftChecked[l].id)
        }
      }
    }
    let itensUnicos = leftChecked.filter(item => !itensRepetido.includes(item.id))
    setRight(right.concat(itensUnicos))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = async () => {
    let itensRepetido: any = []
    for (let r = 0; r < left.length; r++) {
      for (let l = 0; l < rightChecked.length; l++) {
        if (right[r].id === rightChecked[l].id) {
          itensRepetido.push(rightChecked[l].id)
        }
      }
    }
    let itensUnicos = rightChecked.filter(item => !itensRepetido.includes(item.id))
    setLeft(left.concat(itensUnicos))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const customList = (items: readonly any[], titulo: string) => (
    <List dense component="div" role="list">
      {items.length === 0 ?
        <div className="flex flex-nowrap gap-8 justify-center items-center"
             style={{ fontSize: '14px', margin: '22px' }}
        >
          <ErrorOutlineIcon style={{ color: 'red' }} />
          <span style={{ maxWidth: 'fit-content' }}>Não ha {titulo}</span>
        </div> : ''
      }
      {items.map((value: any) => {
        const labelId = `transfer-list-item-${value.id}-label`
        return (
          <ListItem
            className={`${checked.indexOf(value) !== -1 ? 'active' : ''}`}
            style={{ flexDirection: 'column', alignItems: 'start' }}
            key={value.id}
            role="listitem"
            button
            onClick={handleToggle(value)}
          >
            <ListItemText id={labelId} primary={`${value.name}`} style={{ margin: '0px', fontSize: '12px' }} />
            <div style={{ fontSize: '10px' }}>{value.email} <br /> {value.company}</div>
          </ListItem>
        )
      })}
    </List>
  )

  const handleStepMapeamento = () => {
    setMapeamentoStep(1)
  }
  const handleCloseMapeamento = () => {
    setModalMapeamento(false)
    setMapeamentoStep(0)
    setDestinoMap('')
    setChecked([])
    setLeft([])
    setRight([])
  }

  const aprovarMapeamentoEmLote = async () => {
    let listFormat = []
    for (let empI = 0; empI < listItensSelectedDetalhada.length; empI++) {
      for (let userI = 0; userI < right.length; userI++) {
        listFormat.push(
          {
            userId: right[userI].id,
            cttId: listItensSelectedDetalhada[empI].cttId
          }
        )
      }
    }
    if (destinoMap === 'Atendentes') {
      listFormat = listFormat.map((item: any) => {
        return { ...item, principal: false }
      })
      try {
        const res = await trackPromise(services.api.multiMapRecruiters(listFormat))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível aprovar os chamados'
          )
        }
        notify('Vínculo realizado com sucesso', { variant: 'success' })
        handleCloseMapeamento()
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    } else {
      try {
        const res = await trackPromise(services.api.multiMapRecruitersUser(listFormat))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível aprovar os chamados'
          )
        }
        notify('Vinculo realizado com sucesso', { variant: 'success' })
        handleCloseMapeamento()
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

  }

  const cancelMapItem = (id: any) => {
    setListItensSelected(listItensSelected.filter((item: any) => item !== id))
    setListItensSelectedDetalhada(listItensSelectedDetalhada.filter((item: any) => item.indexRef !== id))
  }

  const startMapeamentoEmLote = () => {
    if (!listItensSelected.length) {
      notify('Selecione pelo menos um cliente da tabela', { variant: 'warning' })
      return false
    }
    let itensFormat: any = []
    for (let i = 0; i < filterList.length; i++) {
      for (let x = 0; x < listItensSelected.length; x++) {
        if (filterList[i].indexRef === listItensSelected[x]) {
          itensFormat.push(filterList[i])
          break
        }
      }
    }
    setListItensSelectedDetalhada(itensFormat)
    setModalMapeamento(true)
    handleBuscaUserDisponivel('')
  }

  const [filterList, setFilterList] = useState<any>(null)
  const [search, setSearch] = useState('')

  const [colunaAtiva] = useState<string>('')
  const [colunaBusca, setColunaBusca] = useState<string>('nome&&&')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBuscaDebounce = useCallback(debounce((busca) => {
    handleBusca(busca, pageSize)
  }, 800), [pageSize])

  const handleChangeBusca = (busca: any) => {
    handleBuscaDebounce(busca)
  }

  const ordeby = (direction: boolean, column?: string) => {
    if (column) {
      handleBusca(objBusca, pageSize, column, direction)
    } else {
      handleBusca(objBusca, pageSize, colunaAtiva, direction)
    }
  }

  const handleChangeVisibleColumn = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
    let indexItem = visibleColumnsControl.indexOf(item)
    if (!event.target.checked && visibleColumnsControl.length <= 1) {
      notify('Pelo menos uma coluna deve ficar visivel', { variant: 'warning' })
      return
    }
    if (event.target.checked) {
      setVisibleColumnsControl((prevState: any) => ([
          ...prevState,
          item
        ]
      ))
    } else {
      setVisibleColumnsControl((prevState: any) => ([
          ...prevState.slice(0, indexItem),
          ...prevState.slice(indexItem + 1)
        ]
      ))
    }
  }

  const confirmVisibilityColumns = () => {
    Cookies.set(`Table_Clientes_${user?.id}`, JSON.stringify(visibleColumnsControl))
    setVisibleColumns([...visibleColumnsControl])
    setModalShowHideColumns(false)
  }

  const handleOpenModalVisibleColumns = () => {
    setModalShowHideColumns(true)
    setVisibleColumnsControl([...visibleColumns])
  }

  const handleSelect = (itemId: any) => {
    if (listItensSelected.includes(itemId)) {
      setListItensSelected(listItensSelected.filter((item: any) => item !== itemId))
    } else {
      setListItensSelected((prevState: any) => ([
        ...prevState,
        itemId
      ]))
    }
  }

  useEffect(() => {
    setFilterList(mainList?.map((item: IEmpresa, index: number) => {
      item.selecionado = false
      item.indexRef = index
      return item
    }))
    setListItensSelected([])
  }, [mainList])

  useEffect(() => {
    if (user?.id) {
      const tableCookieString: any = Cookies.get(`Table_Clientes_${user?.id}`)
      if (!tableCookieString) {
        Cookies.set(`Table_Clientes_${user?.id}`, JSON.stringify(tablesVisibles))
        setVisibleColumns([...tablesVisibles])
        setVisibleColumnsControl([...tablesVisibles])
      } else {
        const tableCookieObj = JSON.parse(tableCookieString)
        setVisibleColumns([...tableCookieObj])
        setVisibleColumnsControl([...tableCookieObj])
      }
    }
  }, [])

  return (
    <>
      <div className="wrapper-page-content-interna">
        <div className="flex items-center justify-between gap-10">
          <div className="flex gap-10">{listItensSelected.length > 0 ?
            <span>CRs Selecionados: {listItensSelected.length}</span> : ''}</div>

          <div className="flex items-center gap-10">
            <div className="flex items-center gap-6" id="ref_table_empresas">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={objBusca[indexColumnBloqueado].value === '1' ? true : false}
                      onChange={(e) => handleToggleBloqueado(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={promiseInProgress}
                    />
                  }
                  label="Buscar Clientes Bloquados"
                />
              </FormGroup>
              <Tooltip title="Visualização Colunas">
                <span>
                  <IconButton
                    className="btn-purple"
                    onClick={handleOpenModalVisibleColumns}
                    disabled={promiseInProgress}
                  >
                    <SettingsIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
              {
                (
                  userRoles.includes('recruitermaps_create') ||
                  userRoles.includes('recruitermaps_create_any') ||
                  userRoles.includes('crusermap_create') ||
                  userRoles.includes('crusermap_create_any')
                ) &&
                <Button
                  variant="contained" type="button" color="success"
                  onClick={startMapeamentoEmLote}
                  startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
                  disabled={promiseInProgress}
                >
                  <span>Vincular usuários</span>
                </Button>
              }
            </div>
          </div>
        </div>
        <div className="divider" style={{ opacity: 0 }}></div>
        <div className="table-container">
          <table className="table_styled table_selectable">
            <thead>
            <tr>
              {
                objBusca?.map((item: any, index: number) => (
                  <TabelaSearchColumn
                    key={index}
                    visibleColumn={visibleColumns.includes(item.label)}
                    indexRef={index}
                    paramns={item}
                    objBusca={objBusca}
                    setSearchChange={setObjBusca}
                    handleChangeBusca={handleChangeBusca}
                  />
                ))
              }
            </tr>
            <tr>
              {
                objBusca?.map((item: any, index: number) => (
                  <TabelaHeaderTh
                    key={index}
                    ordeby={ordeby}
                    visibleColumn={visibleColumns.includes(item.label)}
                    indexRef={index}
                    paramns={item}
                    setSearchChange={setObjBusca}
                    setListItensSelected={setListItensSelected}
                    itemsSelecionadosLength={listItensSelected.length}
                    rows={filterList}
                  />
                ))
              }
            </tr>
            </thead>
            <tbody>
            {!filterList &&
              <tr>
                <td colSpan={visibleColumns.length + 1}>
                  <div className="justify-center">loading...</div>
                </td>
              </tr>
            }
            {filterList?.length === 0 &&
              <tr>
                <td colSpan={visibleColumns.length + 1}>
                  <div className="justify-center">Nenhum registro encontrado</div>
                </td>
              </tr>
            }
            {filterList?.map((item: any) => (
              <tr key={item.indexRef}>
                {visibleColumns.includes('checkbox') &&
                  <td onClick={() => handleSelect(item.indexRef)}
                      className={`${listItensSelected.includes(item.indexRef) ? 'active' : ''}`}>
                    <div>
                      <input type="checkbox" checked={listItensSelected.includes(item.indexRef) ? true : false}
                             onChange={() => {
                             }} />
                    </div>
                  </td>
                }
                {visibleColumns.includes('CR') &&
                  <td onClick={() => handleSelect(item.indexRef)}
                      className={`${listItensSelected.includes(item.indexRef) ? 'active' : ''}`}>
                    <div>{item?.res}</div>
                  </td>
                }
                {visibleColumns.includes('Descrição do CR') &&
                  <td onClick={() => handleSelect(item.indexRef)}
                      className={`${listItensSelected.includes(item.indexRef) ? 'active' : ''}`}>
                    <div>{item?.desc01}</div>
                  </td>
                }
                {visibleColumns.includes('Serviço') &&
                  <td onClick={() => handleSelect(item.indexRef)}
                      className={`${listItensSelected.includes(item.indexRef) ? 'active' : ''}`}>
                    <div>{item?.afkDescri}</div>
                  </td>
                }
                {visibleColumns.includes('Razão Social') &&
                  <td onClick={() => handleSelect(item.indexRef)}
                      className={`${listItensSelected.includes(item.indexRef) ? 'active' : ''}`}>
                    <div>{item?.nome}</div>
                  </td>
                }
                {visibleColumns.includes('Endereço') &&
                  <td onClick={() => handleSelect(item.indexRef)}
                      className={`${listItensSelected.includes(item.indexRef) ? 'active' : ''}`}>
                    <div>{item?.end}</div>
                  </td>
                }
                {visibleColumns.includes('Bairro') &&
                  <td onClick={() => handleSelect(item.indexRef)}
                      className={`${listItensSelected.includes(item.indexRef) ? 'active' : ''}`}>
                    <div>{item?.bairro}</div>
                  </td>
                }
                {visibleColumns.includes('CEP') &&
                  <td onClick={() => handleSelect(item.indexRef)}
                      className={`${listItensSelected.includes(item.indexRef) ? 'active' : ''}`}>
                    <div>{item?.cep}</div>
                  </td>
                }
                {visibleColumns.includes('Estado') &&
                  <td onClick={() => handleSelect(item.indexRef)}
                      className={`${listItensSelected.includes(item.indexRef) ? 'active' : ''}`}>
                    <div>{item?.est}</div>
                  </td>
                }
                {visibleColumns.includes('CNPJ') &&
                  <td onClick={() => handleSelect(item.indexRef)}
                      className={`${listItensSelected.includes(item.indexRef) ? 'active' : ''}`}>
                    <div>{item?.cgc}</div>
                  </td>
                }
                {visibleColumns.includes('Grupo do cliente') &&
                  <td onClick={() => handleSelect(item.indexRef)}
                      className={`${listItensSelected.includes(item.indexRef) ? 'active' : ''}`}>
                    <div>{item?.xnGrup}</div>
                  </td>
                }
                {visibleColumns.includes('Bloqueado') &&
                  <td onClick={() => handleSelect(item.indexRef)}
                      className={`${listItensSelected.includes(item.indexRef) ? 'active' : ''}`}>
                    <div>{item?.bloq === '1' ? 'Bloqueado' : 'Desbloqueado'}</div>
                  </td>
                }
                <td className={`${listItensSelected.includes(item.indexRef) ? 'active' : ''}`}>
                  {
                    (
                      userRoles.includes('recruitermaps_read') ||
                      userRoles.includes('recruitermaps_read_any') ||
                      userRoles.includes('crusermap_read') ||
                      userRoles.includes('crusermap_read_any')
                    ) &&
                    <div className="justify-end">
                      <IconButton
                        size="small" aria-label="editar usuário" component="label"
                        onClick={() => editRegistro(item)}
                        disabled={promiseInProgress}
                      >
                        <RemoveRedEyeIcon fontSize="small" />
                      </IconButton>
                    </div>
                  }
                </td>
              </tr>
            ))}
            </tbody>
          </table>

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            handlePagination={handlePagination}
            colunaBusca={colunaBusca}
            search={search}
            itensLength={filterList?.length}
            totalCount={totalCount}
          />

        </div>
      </div>

      {/* show hide columns */}
      <Modal
        size="sm"
        open={modalShowHideColumns}
        close={() => setModalShowHideColumns(false)}
        titulo={'Selecionar Colunas'}
      >
        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={12} className="flex gap-6">
            <FormGroup>
              {tablesVisibles.filter(item => item !== 'Ações' && item !== 'checkbox').map((item, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Switch
                      checked={visibleColumnsControl.includes(item)}
                      onChange={(e) => handleChangeVisibleColumn(e, item)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={item}
                />
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={12} className="flex justify-end gap-6">
            <Button
              variant="contained"
              color="secondary"
              type="button"
              size="small"
              onClick={confirmVisibilityColumns}
            >
              Aplicar
            </Button>
          </Grid>
        </Grid>
      </Modal>

      {/* aprovação em lote */}
      <Modal
        open={modalMapeamento}
        close={handleCloseMapeamento}
        titulo={'Vincular em massa'}
      >
        <Grid container spacing={{ xs: 3 }} id="Modal_app_mapeamento">
          {
            mapementoStep === 0 ?
              <>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel id="input_destino-label">Destino do vínculo</InputLabel>
                    <Select
                      labelId="input_destino-label"
                      id="input_destino"
                      fullWidth
                      required
                      size="small"
                      label="Destino do vínculo"
                      value={destinoMap || ''}
                      onChange={e =>
                        setDestinoMap(e.target.value)
                      }
                    >
                      <MenuItem value="">
                        Selecione
                      </MenuItem>
                      {
                        (
                          userRoles.includes('recruitermaps_create') ||
                          userRoles.includes('recruitermaps_create_any')
                        ) &&
                        <MenuItem value="Atendentes">
                          Atendentes
                        </MenuItem>
                      }
                      {
                        (
                          userRoles.includes('crusermap_create') ||
                          userRoles.includes('crusermap_create_any')
                        ) &&
                        <MenuItem value="Clientes">
                          Clientes
                        </MenuItem>
                      }
                    </Select>
                  </FormControl>
                </Grid>
                {destinoMap &&
                  <Grid item xs={12} className="center">
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                      <Grid item xs={12} sm={5}>
                        <Paper variant="outlined" sx={{ height: 300, overflow: 'auto' }}>
                          <div style={{ textAlign: 'center', fontSize: '12px', margin: '10px 0 8px 0px' }}>Usuário
                            Disponíveis
                          </div>
                          <div className="p-10">
                            <TextField
                              size="small"
                              className="mb-10"
                              label="Pesquisa"
                              placeholder="Pesquisa"
                              inputProps={{ maxLength: 50 }}
                              value={searchUserDisponivel}
                              onChange={e => handleChangeBuscaUserDisponivel(e.target.value)}
                              disabled={promiseInProgress}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      style={{
                                        backgroundColor: '$cor-primary',
                                        color: 'white',
                                        padding: '2px',
                                        marginRight: '-8px',
                                        borderRadius: '4px'
                                      }}
                                      aria-label="toggle password visibility"
                                      onClick={(e) => handleClickBuscaUserDisponivel(e)}
                                      edge="end"
                                    >
                                      <FilterAltIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                          </div>
                          {customList(left, 'Usuário Disponíveis')}
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Grid container direction="column" alignItems="center">
                          <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0 || promiseInProgress}
                            aria-label="move selected right"
                          >
                            &gt;
                          </Button>
                          <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0 || promiseInProgress}
                            aria-label="move selected left"
                          >
                            &lt;
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <Paper variant="outlined" sx={{ height: 300, overflow: 'auto' }}>
                          <div style={{ textAlign: 'center', fontSize: '12px', margin: '10px 0 8px 0px' }}>Usuários
                            Selecionados
                          </div>
                          {customList(right, 'Usuário Selecionados')}
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                }
                <Grid item xs={12} className="flex justify-end gap-6">
                  <Button
                    variant="contained"
                    color="success"
                    type="button"
                    size="small"
                    onClick={handleStepMapeamento}
                    disabled={!listItensSelectedDetalhada.length || promiseInProgress || !destinoMap || right.length === 0}
                  >
                    Próximo
                  </Button>
                </Grid>
              </>
              : ''
          }
          {
            mapementoStep === 1 ?
              <>
                <Grid item xs={12} className="center">
                  CRs de Destino
                </Grid>
                <Grid item xs={12}>
                  {listItensSelectedDetalhada?.map((item: any, index: number) => (
                    <div key={item.indexRef} className="chamado_to_aprove">
                      <div className="chamado_to_aprove_title">
                        <div>CR {item.res}</div>
                        <div>
                          <CancelIcon onClick={() => cancelMapItem(item.indexRef)} style={{ color: 'red' }}
                                      className="pointer" />
                        </div>
                      </div>
                      <div>{item.desc01} {item.afkDescri} {item.cgc}</div>
                      <div>{item.nome}</div>
                    </div>
                  ))}
                </Grid>
                <Grid item xs={12} className="flex justify-end gap-6">
                  <Button
                    variant="contained"
                    color="success"
                    type="button"
                    size="small"
                    onClick={aprovarMapeamentoEmLote}
                    disabled={!listItensSelectedDetalhada.length || promiseInProgress}
                  >
                    Concluir vinculação
                  </Button>
                </Grid>
              </>
              : ''
          }

        </Grid>
      </Modal>

      {/* menu opções busca user disponivel*/}
      <Menu
        anchorEl={anchorElBuscaUserDisponivel}
        open={openBuscaUserDisponivel}
        onClose={handleCloseBuscaUserDisponivel}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))'
          }
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <div style={{ padding: '2px 8px', fontSize: '14px' }}>
            Buscar por
          </div>
          <div style={{ padding: '2px 8px' }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="tipoBuscaColunaBusca-label"
                name="tipoBuscaColunaBusca"
                onChange={e => setColunaBuscaUserDisponivel(e.target.value)}
                value={colunaBuscaUserDisponivel}
              >
                <FormControlLabel value="fullname" control={<Radio />} label="Nome" />
                <FormControlLabel value="username" control={<Radio />} label="Login" />
                <FormControlLabel value="company" control={<Radio />} label="Empresa" />
                <FormControlLabel value="email" control={<Radio />} label="Email" />
                <FormControlLabel value="id" control={<Radio />} label="Id" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

      </Menu>
    </>
  )
}

export default TabelaEmpresas
