import { useEffect, useState } from 'react'
import moment from 'moment'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import { Button, Grid, TextField } from '@mui/material'

import MoodBadIcon from '@mui/icons-material/MoodBad'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SendIcon from '@mui/icons-material/Send'
import CheckIcon from '@mui/icons-material/Check'
import Modal from '../../../components/Modal'
import { useNotification } from '../../../hooks/useNotification.js'
import services from '../../../services/index.js'
import { useAuth } from '../../../hooks/useAuth'
import Mensagens from './Mensagens'
import Attendant from './Attendant'
import Logs from './Logs'
import Observer from './Observer'
import { ICancelRequest } from '../../../interfaces/index'

interface IResumoVaga {
  chamado: any
  setResumoTicket: any
  filialId: any
  reload: any
  ticketDataId?: number
  ticketStatus?: string
}

function ResumoVaga(props: IResumoVaga) {
  const { chamado, setResumoTicket, filialId, reload, ticketStatus, ticketDataId } = props
  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()
  const { getUserRoles } = useAuth()
  const userRoles = getUserRoles()

  const [modalStartChamado, setModalStartChamado] = useState<boolean>(false)
  const [modalStartAtendimento, setModalStartAtendimento] = useState<boolean>(false)
  const [modalStartReview, setModalStartReview] = useState<boolean>(false)


  const [cancelRequestActive, setCancelRequestActive] = useState<ICancelRequest>({})

  const startChamado = async () => {
    try {
      const res = await trackPromise(services.api.startTicket(chamado.ticketId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível iniciar o chamado'
        )
      }
      setModalStartChamado(false)
      setResumoTicket((prevState: any) => ({ ...prevState, status: 'AWAITING_ATTENDANT' }))
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const startReview = async () => {
    try {
      const res = await trackPromise(services.api.startReview(chamado.ticketId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível iniciar o chamado'
        )
      }
      setModalStartReview(false)
      setResumoTicket((prevState: any) => ({ ...prevState, status: 'AWAITING_REVISION' }))
      reload()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const startAtendimento = async () => {
    try {
      const res = await trackPromise(services.api.startAtendimento(chamado.ticketId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível iniciar o atendimento'
        )
      }
      setModalStartAtendimento(false)
      reload()
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    const loadCancelRequests = async () => {
      try {
        const res = await trackPromise(services.api.getCancelRequests(ticketDataId))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível carregar as requisições de cancelamento'
          )
        }
        let listCancelRequests: any = res.data
        let solicitacoesAtivas = listCancelRequests.filter((item: ICancelRequest) => !item.arquieved)
        if (solicitacoesAtivas?.length) {
          setCancelRequestActive(solicitacoesAtivas[0])
        }
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
        return false
      }
    }
    if (
      ticketDataId &&
      (
        ticketStatus === 'AWAITING_CANCELATION_APROVEMENT' ||
        ticketStatus === 'CANCELED' || chamado?.status === 'CANCELED_PARTIAL'
      ) &&
      (userRoles.includes('ticket_cancelrequest_read') || userRoles.includes('ticket_cancelrequest_read_any'))
    ) {
      loadCancelRequests()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketDataId, ticketStatus])

  return (
    <div className="mb-20">
      <Grid container spacing={{ xs: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} className="flex justify-between">
          <div>Detalhes do Chamado</div>
          <div className="flex justify-end">
            <div className={`badge ${services.utils.getStatusCor(chamado?.status)}`}>
              {services.utils.getStatusText(chamado?.status)}
            </div>
            {
              chamado?.status === 'FINISHIED' || chamado?.status === 'CANCELED_PARTIAL' ?
                <>
                  {chamado.satisfaction === 1 &&
                    <span className={`badge`} style={{ backgroundColor: `#f44336`, lineHeight: '0' }}>
                    <MoodBadIcon fontSize="small" style={{ color: `white`, fontSize: '20px' }} />
                  </span>
                  }
                  {chamado.satisfaction === 2 &&
                    <span className={`badge`} style={{ backgroundColor: `#ff9e22`, lineHeight: '0' }}>
                    <SentimentVeryDissatisfiedIcon style={{ color: `white`, fontSize: '20px' }} />
                  </span>
                  }
                  {chamado.satisfaction === 3 &&
                    <span className={`badge`} style={{ backgroundColor: `#ffc107`, lineHeight: '0' }}>
                    <SentimentNeutralIcon style={{ color: `white`, fontSize: '20px' }} />
                  </span>
                  }
                  {chamado.satisfaction === 4 &&
                    <span className={`badge`} style={{ backgroundColor: `#cddc39`, lineHeight: '0' }}>
                    <SentimentSatisfiedAltIcon style={{ color: `white`, fontSize: '20px' }} />
                  </span>
                  }
                  {chamado.satisfaction === 5 &&
                    <span className={`badge`} style={{ backgroundColor: `#4caf50`, lineHeight: '0' }}>
                    <SentimentVerySatisfiedIcon style={{ color: `white`, fontSize: '20px' }} />
                  </span>
                  }
                </>
                : ''
            }
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4}>
          <TextField
            fullWidth
            size="small"
            label="Atendente"
            value={chamado?.attendant ? chamado?.attendant?.name : '-'}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <TextField
            fullWidth
            size="small"
            label="Supervisor do Atendente"
            value={chamado?.attendantLeader ? chamado?.attendantLeader?.name : '-'}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <TextField
            fullWidth
            size="small"
            label="Data de Solicitação"
            value={moment(chamado?.createdAt).format('DD/MM/YYYY - HH:mm')}
            disabled={true}
          />
        </Grid>

        {
          chamado?.status === 'AWAITING_CANCELATION_APROVEMENT' || chamado?.status === 'CANCELED' || chamado?.status === 'CANCELED_PARTIAL' ?
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  label="Motivo do Cancelamento"
                  className="error"
                  value={cancelRequestActive?.cancelationComment ? services.utils.parseLines(cancelRequestActive?.cancelationComment) : ''}
                  disabled={true}
                />
              </Grid>
            </>
            : ''
        }

        {
          chamado?.status === 'AWAITING_CANCELATION_APROVEMENT' ?
            <>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Data de Solicitação de Cancelamento"
                  value={moment(chamado?.createdAt).format('DD/MM/YYYY - HH:mm')}
                  disabled={true}
                />
              </Grid>
            </>
            : ''
        }

        {
          chamado?.status === 'CANCELED' || chamado?.status === 'CANCELED_PARTIAL' ?
            <>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Data de Cancelamento"
                  value={moment(chamado?.createdAt).format('DD/MM/YYYY - HH:mm')}
                  disabled={true}
                />
              </Grid>
            </>
            : ''
        }

        {chamado?.revisionComment && (chamado?.status === 'AWAITING_REVISION' || chamado?.status === 'IN_REVISION') &&
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              fullWidth
              multiline
              //rows={6} 
              size="small"
              label="Mensagem de Revisão"
              className="primary"
              value={chamado.revisionComment ? services.utils.parseLines(chamado.revisionComment) : ''}
              disabled={true}
            />
          </Grid>
        }
        {
          chamado?.status === 'AWAITING_FINISH_APROVEMENT' || chamado?.status === 'FINISHIED' ?
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  label="Mensagem de Conclusão"
                  className="success"
                  value={chamado?.finishReason ? chamado?.finishReason : '-'}
                  disabled={true}
                />
              </Grid>
            </>
            : ''
        }
        {
          chamado?.status === 'FINISHIED' || chamado?.status === 'CANCELED_PARTIAL' ?
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                size="small"
                label="Mensagem de Avaliação"
                className={`${chamado?.status === 'FINISHIED' ? 'success' : 'error'}`}
                value={chamado?.satisfactionComment ? chamado.satisfactionComment : 'Nenhum feedback informado pelo cliente'}
                disabled={true}
              />
            </Grid>
            : ''
        }

        {
          chamado?.status === 'AWAITING_FINISH_APROVEMENT' ?
            <>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Data de Solicitação de Conclusão"
                  value={moment(chamado?.createdAt).format('DD/MM/YYYY - HH:mm')}
                  disabled={true}
                />
              </Grid>

            </>
            : ''
        }


        {
          chamado?.status === 'FINISHIED' ?
            <>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Data de Conclusão"
                  value={chamado?.finishedAt ? moment(chamado.finishedAt).format('DD/MM/YYYY - HH:mm') : '-'}
                  disabled={true}
                />
              </Grid>
            </>
            : ''
        }

        <Grid item xs={12} sm={12} md={12} className="flex justify-end items-center">
          {
            chamado?.status === 'AWAITING_START' ?
              <>
                {
                  (
                    userRoles.includes('ticket_update') ||
                    userRoles.includes('ticket_update_any')
                  ) &&
                  <Button
                    variant="contained"
                    type="button"
                    size="small"
                    disabled={promiseInProgress}
                    color="secondary"
                    startIcon={<SendIcon fontSize="inherit" />}
                    onClick={() => setModalStartChamado(true)}
                  >
                    Iniciar Chamado
                  </Button>
                }
              </>
              : ''
          }

          {
            chamado?.status === 'AWAITING_REVISION' ?
              <>
                {
                  (
                    userRoles.includes('ticket_update') ||
                    userRoles.includes('ticket_update_any')
                  ) &&
                  <Button
                    variant="contained"
                    type="button"
                    size="small"
                    disabled={promiseInProgress}
                    color="secondary"
                    startIcon={<SendIcon fontSize="inherit" />}
                    onClick={() => setModalStartReview(true)}
                  >
                    Iniciar revisão
                  </Button>
                }
              </>
              : ''
          }

          {
            chamado?.status === 'AWAITING_ATTENDANT' ?
              <>
                {
                  (
                    userRoles.includes('ticket_update') ||
                    userRoles.includes('ticket_update_any')
                  ) &&
                  <>

                    <Button
                      variant="contained"
                      type="button"
                      size="small"
                      disabled={promiseInProgress}
                      color="secondary"
                      startIcon={<CheckIcon fontSize="inherit" />}
                      onClick={() => setModalStartAtendimento(true)}
                    >
                      Iniciar atendimento
                    </Button>
                  </>
                }
              </>
              :
              ''
          }

          {chamado?.status === 'AWAITING_ATTENDANT' || chamado?.status === 'IN_PROGRESS' || chamado?.status === 'IN_REVISION' ?
            <>
              {
                (
                  userRoles.includes('ticket_update_attendant') ||
                  userRoles.includes('ticket_update_attendant_any')
                ) &&
                <Attendant
                  ticketId={chamado?.ticketId}
                  reload={reload}
                  attendant={chamado?.attendant}
                  filialId={filialId}
                />
              }
            </>
            : ''
          }

          {
            chamado?.status !== 'AWAITING_START' || chamado?.status !== 'AWAITING_ATTENDANT' ?
              <>
                {
                  (
                    userRoles.includes('ticket_res_observers_read') ||
                    userRoles.includes('ticket_res_observers_read_any')
                  ) &&
                  <Observer ticketId={chamado?.ticketId} status={chamado?.status} />
                }
                {
                  (
                    userRoles.includes('ticket_logs_read') ||
                    userRoles.includes('ticket_logs_read_any')
                  ) &&
                  <Logs chamadoId={chamado?.ticketId} />
                }

                {
                  (
                    userRoles.includes('ticket_messages_create') ||
                    userRoles.includes('ticket_messages_create_any') ||
                    userRoles.includes('ticket_messages_read') ||
                    userRoles.includes('ticket_messages_read_any')
                  ) &&
                  <Mensagens chamadoId={chamado?.ticketId} status={chamado?.status} />
                }
              </>
              : ''
          }
        </Grid>


      </Grid>

      <Modal
        size="md"
        open={modalStartChamado}
        close={() => setModalStartChamado(false)}
        titulo={'Iniciar Chamado'}
      >
        <div>
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              Ao iniciar o chamado, o mesmo passara para status
              'Aguardando Atendente', você poderá atribuir o
              chamado a um atendente de sua hierarquia ou iniciar o
              atendimento.
            </Grid>
            <Grid item xs={12} className="flex justify-end">
              <Button
                variant="contained"
                type="button"
                size="small"
                disabled={promiseInProgress}
                color="success"
                startIcon={<CheckIcon fontSize="inherit" />}
                onClick={startChamado}
              >
                Prosseguir
              </Button>
            </Grid>
          </Grid>

        </div>
      </Modal>

      <Modal
        size="md"
        open={modalStartAtendimento}
        close={() => setModalStartAtendimento(false)}
        titulo={'Iniciar Atendimento'}
      >
        <div>
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              Ao iniciar o atendimento, o chamado passara para status
              'Em Andamento' e a solicitação deverá ser atendida o
              mais rápido possível.
            </Grid>
            <Grid item xs={12} className="flex justify-end">
              <Button
                variant="contained"
                type="button"
                size="small"
                disabled={promiseInProgress}
                color="success"
                startIcon={<CheckIcon fontSize="inherit" />}
                onClick={startAtendimento}
              >
                Prosseguir
              </Button>
            </Grid>
          </Grid>

        </div>
      </Modal>


      <Modal
        size="md"
        open={modalStartReview}
        close={() => setModalStartReview(false)}
        titulo={'Iniciar Revisão'}
      >
        <div>
          <Grid container spacing={{ xs: 3 }}>
            <Grid item xs={12}>
              Ao iniciar a revisão, o mesmo passara para status 'Em Revisão'.
            </Grid>
            <Grid item xs={12} className="flex justify-end">
              <Button
                variant="contained"
                type="button"
                size="small"
                disabled={promiseInProgress}
                color="success"
                startIcon={<CheckIcon fontSize="inherit" />}
                onClick={startReview}
              >
                Prosseguir
              </Button>
            </Grid>
          </Grid>

        </div>
      </Modal>
    </div>
  )
}

export default ResumoVaga
