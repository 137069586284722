import { Backdrop, CircularProgress } from '@mui/material'

import logoTrade from '../../assets/img/trade/logo.png'
import logoPop from '../../assets/img/pop/logo.png'
import logoTradeMark from '../../assets/img/trademark/logo.png'
import logoSeven from '../../assets/img/seven/logo.png'
import './loadingBackdrop.scss'
import { getCompany } from '../../configs/company'

interface ILoadingBackdrop {
  open: boolean
  variant?: 'logo'
}

const company = getCompany()
let logo: string
if (company.slug == 'TRADE') {
  logo = logoTrade
} else if (company.slug == 'POP') {
  logo = logoPop
} else if (company.slug == 'SEVEN') {
  logo = logoSeven
} else if (company.slug == 'TRADEMARK') {
  logo = logoTradeMark
}
export default function LoadingBackdrop(props: ILoadingBackdrop) {
  const { open, variant } = props
  return (
    <div className="loading">
      <Backdrop className={'backdrop_main'} open={open}>
        {variant === 'logo' ? (
          <img
            alt="logo"
            src={logo}
            className="circleLoading"
            data-testid="circle-loading"
          />
        ) : (
          <CircularProgress
            color="inherit"
            data-testid="circular-progress-loading"
          />
        )}
      </Backdrop>
    </div>
  )
}
