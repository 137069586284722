import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React, { useEffect, useState } from 'react'
import { Popconfirm, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import './style.scss'
import { IDashboardFilter } from '../../interfaces'
import { Button, IconButton } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Save } from '@mui/icons-material'

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key']
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
  }
  return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />
}

interface TableFiltersProps {
  data: IDashboardFilter[]
  environmentId: number
  deleteDashboardFilter: any
  editDashboardFilter: any
  setDashboardFilterOrder: any
}

const TableEnvFilters: React.FC<TableFiltersProps> = ({
                                                        data,
                                                        deleteDashboardFilter,
                                                        editDashboardFilter,
                                                        setDashboardFilterOrder,
                                                        environmentId
                                                      }) => {

  const [dataSource, setDataSource] = useState<IDashboardFilter[]>([])
  const [orderChanged, setOrderChanged] = useState<boolean>(false)

  const columns: ColumnsType<IDashboardFilter> = [
    {
      title: 'Tabela',
      dataIndex: 'table'
    },
    {
      title: 'Coluna',
      dataIndex: 'colunm'
    },
    {
      title: 'Operador',
      dataIndex: 'operator'
    },
    {
      title: 'Tipo',
      render: (_, record) => {
        if (record.type === 'VARIABLE') {
          return (
            <span className="badge" style={{ backgroundColor: 'rgba(204, 0, 227, 0.5)', lineHeight: '0' }}>
              Variavel
        </span>
          )
        } else {
          return (
            <span className="badge" style={{ backgroundColor: 'rgba(0, 204, 255, 0.5)', lineHeight: '0' }}>
              Valor fixo
        </span>
          )
        }
      }
    },
    {
      title: 'Valor',
      render: (_, record) => {
        if (record.type === 'VARIABLE') {
          if (record.value === 'USER_CPF') {
            return (
              'CPF do usuário'
            )
          } else if (record.value === 'ENVIRONMENT_NAME') {
            return (
              'Nome do ambiente'
            )
          } else if (record.value === 'USER_NAME') {
            return (
              'Nome do usuário'
            )
          } else if (record.value === 'USER_EMAIL') {
            return (
              'Email do usuário'
            )
          } else if (record.value === 'USER_COMPANY') {
            return (
              'Empresa do usuário'
            )
          }
        } else {
          return (record.value)
        }
      }
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <IconButton
            className="btn-purple mr-6 content-end"
            disabled={false}
            onClick={() => editDashboardFilter(record)}
          >
            <SettingsIcon fontSize="small" />
          </IconButton>

          <Popconfirm title="Deseja deletar o filtro?" cancelText={'Cancelar'} okText={'Deletar'}
                      onConfirm={() => deleteDashboardFilter(record.id)}>
            <IconButton className="btn-purple mr-6 content-end" disabled={false}>
              <DeleteForeverIcon fontSize="small" />
            </IconButton>
          </Popconfirm>
        </div>
      )
    }
  ]


  useEffect(() => {
    setDataSource(data.filter(it => it.environmentId?.toString() === environmentId.toString()))
  }, [data])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1
      }
    })
  )

  async function SetNewOrder() {
    setOrderChanged(false)
    let list = dataSource.map(item => item.id)
    await setDashboardFilterOrder(list)
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev!.findIndex((i) => i.id === active.id)
        const overIndex = prev!.findIndex((i) => i.id === over?.id)
        setOrderChanged(true)
        return arrayMove(prev!, activeIndex, overIndex)
      })
    }
  }


  return (
    <div style={{ textAlign: 'right' }}>
      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource?.map((i) => i.id)!}
          strategy={verticalListSortingStrategy}
        >
          <Table
            locale={{
              emptyText:
                ' Nenhum filtro'
            }}
            components={{
              body: {
                row: Row
              }
            }}
            pagination={false}
            rowKey="id"
            columns={columns}
            dataSource={dataSource?.map((item, index) => ({ ...item, key: `${item.id}-${index}` }))}
          />
        </SortableContext>
      </DndContext>

      <div style={{ textAlign: 'right', marginTop: '10px' }}>
        <Button
          variant="contained"
          className="btn-purple mr-6 content-end mb-12"
          type="button"
          size="small"
          color="inherit"
          onClick={SetNewOrder}
          disabled={!orderChanged}
        >
          <Save style={{ marginRight: '5px' }} fontSize="small" /> Salvar ordem
        </Button>
      </div>

    </div>


  )
}

export default TableEnvFilters