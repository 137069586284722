import { PowerBIEmbed } from 'powerbi-client-react'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import { IPowerBiData } from '../../interfaces/index'
// import { usePromiseTracker } from 'react-promise-tracker'
import './style.scss'

interface IPowerBiDataComponent {
  powerBiData: IPowerBiData,
  reload: number,
  enableFilters: boolean | undefined,
  enableNavigationBar: boolean | undefined
  isfullscreen: any
  fullscreenOn: any
  fullscreenOff: any
}

const PowerBi = (props: IPowerBiDataComponent) => {
  const { powerBiData, reload, enableFilters, enableNavigationBar, fullscreenOn, fullscreenOff, isfullscreen } = props
  // const { promiseInProgress } = usePromiseTracker()

  return (
    <>
      {powerBiData.enbedUrl ?
        <>
          {isfullscreen ?
            <div className="fullscrenDashBtn" onClick={fullscreenOff}><FullscreenExitIcon /></div>
            :
            <div className="fullscrenDashBtn" onClick={fullscreenOn}><FullscreenIcon /></div>
          }

          <PowerBIEmbed
            embedConfig={{
              type: 'report',   // Supported types: report, dashboard, tile, visual and qna
              // id: powerBiData.tokenId, 
              id: undefined,
              embedUrl: powerBiData.enbedUrl,
              accessToken: powerBiData.token,    // Keep as empty string, null or undefined
              tokenType: 1, //models.TokenType.Embed

              settings: {
                filterPaneEnabled: enableFilters,
                navContentPaneEnabled: enableNavigationBar
              }

            }}
            cssClassName={`report-style-class reload_${reload}`}
          />
        </> :
        ''
      }
    </>
  )
}

export default PowerBi