import { Fragment } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'

import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import services from '../../services/index.js'


const TabelaSearchColumn = (props: any) => {
  const { indexRef, visibleColumn, paramns, setSearchChange, objBusca, handleChangeBusca } = props
  const { promiseInProgress } = usePromiseTracker()

  const handleChangeFilter = (text: string) => {
    if (paramns?.type === 'number') {
      text = services.mask.number(text)
    }
    setSearchChange((prevState: any) => [
      ...prevState.slice(0, indexRef),
      { ...prevState[indexRef], value: text },
      ...prevState.slice(indexRef + 1)
    ])

    let newBusca = [...objBusca]
    newBusca[indexRef].value = text

    handleChangeBusca(newBusca)
  }

  const handleCloseFilter = () => {
    let atualizarPesquisa = false
    if (objBusca[indexRef].value) {
      atualizarPesquisa = true
    }

    setSearchChange((prevState: any) => [
      ...prevState.slice(0, indexRef),
      { ...prevState[indexRef], inputVisible: false, value: '' },
      ...prevState.slice(indexRef + 1)
    ])

    let newBusca = [...objBusca]
    newBusca[indexRef].value = ''
    newBusca[indexRef].inputVisible = false

    if (atualizarPesquisa) {
      handleChangeBusca(newBusca)
    }
  }


  return (
    <>
      {visibleColumn &&
        <th className="tr_search">
          {paramns?.inputVisible &&
            <div className="container_column_seach">
              {paramns?.selectOptions ?
                <TextField
                  select
                  size="small"
                  fullWidth
                  placeholder={`Filtrar`}
                  value={paramns?.value || ''}
                  onChange={e => handleChangeFilter(e.target.value)}
                  disabled={promiseInProgress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{
                            backgroundColor: '$cor-primary',
                            color: 'white',
                            padding: '2px',
                            marginRight: '-8px',
                            borderRadius: '4px'
                          }}
                          aria-label="toggle password visibility"
                          onClick={handleCloseFilter}
                          edge="end"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                >
                  <MenuItem value={''}>
                    Selecione
                  </MenuItem>
                  {
                    paramns.selectOptions.map((item: any) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.descrition}
                      </MenuItem>
                    ))
                  }
                </TextField>
                : ''
              }
              {!paramns?.selectOptions ?
                <>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder={`Filtrar`}
                    inputProps={{ maxLength: 20 }}
                    value={paramns?.value || ''}
                    onChange={e => handleChangeFilter(e.target.value)}
                    disabled={promiseInProgress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{
                              backgroundColor: '$cor-primary',
                              color: 'white',
                              padding: '2px',
                              marginRight: '-8px',
                              borderRadius: '4px'
                            }}
                            aria-label="toggle password visibility"
                            onClick={handleCloseFilter}
                            edge="end"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </>
                : ''
              }
            </div>
          }
        </th>
      }
    </>
  )
}

export default TabelaSearchColumn
