import { trackPromise } from 'react-promise-tracker'
// import { saveAs } from 'file-saver';
import { useAuth } from '../../hooks/useAuth'
import services from '../../services/index.js'
import { useNotification } from '../../hooks/useNotification.js'

import './style.scss'

import { Button } from '@mui/material'

import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom'
import icon_csv from '../../assets/img/icon-csv.png'
import icon_doc from '../../assets/img/icon-doc.png'
import icon_file from '../../assets/img/icon-file.png'
import icon_image from '../../assets/img/icon-image.png'
import icon_pdf from '../../assets/img/icon-pdf.png'
import icon_ppt from '../../assets/img/icon-ppt.png'
import icon_txt from '../../assets/img/icon-txt.png'
import icon_zip from '../../assets/img/icon-zip.png'

interface IChatItem {
  ownerName: string
  date: string
  msg: string
  ownerId: number,
  messageType: string
  id: number
}

const ChatItem = (props: IChatItem) => {
  const { msg, date, ownerName, ownerId, messageType, id } = props
  const { getUser } = useAuth()
  const user = getUser()
  const { notify } = useNotification()

  const downloadFile = async (msgId: number) => {
    try {
      const res = await trackPromise(services.api.downloadFileMsg(msgId))
      if (res.fail) {
        throw new Error(
          res.error || 'Não foi possível editar cadastro'
        )
      }
      window.open(res.data.url, '_blank')
    } catch (error: any) {
      notify(error.message, { variant: 'error' })
    }
  }

  const fileoption = (data: string, idMessage: number) => {
    let extensionData = data.split('.')
    let extension = extensionData[extensionData.length - 1]
    return (
      <div>
        {extension === 'csv' &&
          <img src={icon_csv} alt="" style={{ marginTop: '6px', width: '20px', cursor: 'pointer' }}
               onClick={() => downloadFile(idMessage)} />
        }
        {extension === 'doc' &&
          <img src={icon_doc} alt="" style={{ marginTop: '6px', width: '20px', cursor: 'pointer' }}
               onClick={() => downloadFile(idMessage)} />
        }
        {extension === 'pdf' &&
          <img src={icon_pdf} alt="" style={{ marginTop: '6px', width: '20px', cursor: 'pointer' }}
               onClick={() => downloadFile(idMessage)} />
        }
        {(extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'gif') &&
          <img src={icon_image} alt="" style={{ marginTop: '6px', width: '20px', cursor: 'pointer' }}
               onClick={() => downloadFile(idMessage)} />
        }
        {extension === 'zip' &&
          <img src={icon_zip} alt="" style={{ marginTop: '6px', width: '20px', cursor: 'pointer' }}
               onClick={() => downloadFile(idMessage)} />
        }
        {extension === 'txt' &&
          <img src={icon_txt} alt="" style={{ marginTop: '6px', width: '20px', cursor: 'pointer' }}
               onClick={() => downloadFile(idMessage)} />
        }
        {extension === 'ppt' &&
          <img src={icon_ppt} alt="" style={{ marginTop: '6px', width: '20px', cursor: 'pointer' }}
               onClick={() => downloadFile(idMessage)} />
        }
        {(
            extension !== 'ppt' &&
            extension !== 'txt' &&
            extension !== 'zip' &&
            extension !== 'csv' &&
            extension !== 'doc' &&
            extension !== 'pdf' &&
            extension !== 'jpg' &&
            extension !== 'png' &&
            extension !== 'jpeg' &&
            extension !== 'gif') &&
          <img src={icon_file} alt="" style={{ marginTop: '6px', width: '20px', cursor: 'pointer' }}
               onClick={() => downloadFile(idMessage)} />
        }
      </div>
    )
  }

  if (messageType === 'TEXT') {
    return (
      <div className={`chamado-chat-item ${user?.id === ownerId ? 'chamado-chat-me' : ''}`}>
        <div className="chamado-chat-box">
          <div className="chamado-chat-label">{date} - {ownerName} </div>
          <div className="chamado-chat-text">{msg}</div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`chamado-chat-item ${user?.id === ownerId ? 'chamado-chat-me' : ''}`}>
        <div className="chamado-chat-box">
          <div className="chamado-chat-label mb-4">{date} - {ownerName} </div>
          <div className="chamado-chat-text flex items-center justify-between gap-30">
            <div className="flex items-center">
              {fileoption(msg, id)} {msg.split('/')[2]?.substring(9)}
            </div>
          </div>
          <div className="backbutton">
            {/* <IconButton
              onClick={() => downloadFile(id)}
              className='btn-purple'
            >
              <VerticalAlignBottomIcon style={{ fontSize: '16px'}} />
            </IconButton> */}
            <Button
              onClick={() => downloadFile(id)}
              className="btn-purple"
            >
              <VerticalAlignBottomIcon style={{ fontSize: '16px' }} />
              Baixar Arquivo
            </Button>
          </div>
        </div>
      </div>
    )
  }

}

export default ChatItem