// // multi locais > funcoes
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'

import { Button, Grid, IconButton, TextField } from '@mui/material'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import { PageTitle } from '../../components/PageElements'
import { useNotification } from '../../hooks/useNotification.js'
import { IRazaoContratacao } from '../../interfaces/index'
import services from '../../services/index.js'
import { useAuth } from '../../hooks/useAuth'

import Beneficios from './components/Beneficios'
import Employees from './components/Employees'
import Perfis from './components/Perfis'
import DadosSolicitante from './components/DadosSolicitante'
import ResumoSolicitacao from './components/ResumoSolicitacao'
import DadosVaga from './components/DadosVaga'
import LocaisAutoComplete from './components/LocaisAutoComplete'

import './style.scss'

const dataAtual = new Date().toISOString()

function ChamadoNewMultFuncoes() {
  const { idTypeContrato } = useParams()
  const { getUser } = useAuth()
  const user = getUser()
  const navigate = useNavigate()
  const { promiseInProgress } = usePromiseTracker()
  const { notify } = useNotification()

  const [listaChamados, setListaChamados] = useState<any>([
    {
      open: true,
      funcoes: [
        {
          open: false,
          isTemporary: idTypeContrato?.toUpperCase() === 'TEMPORARIA' ? true : false,
          pcd: false,
          dangerousness: false,
          unhealthy: false,
          benefits: [],
          employeesToReplace: [],
          profileItems: []
        }
      ]
    }
  ])

  //
  const [empresas, setEmpresas] = useState<any[] | []>([])
  const [razoesContratacao, setRazoesContratacao] = useState<IRazaoContratacao[] | []>([])
  const [tiposContratacao, setTiposContratacao] = useState<any[] | []>([])
  const [escalasList, setEscalasList] = useState<any>([])

  // gerenciamento de local
  const addNewGroup = () => {
    setListaChamados((prevState: any) => ([...prevState, {
      open: true,
      funcoes: [
        {
          open: false,
          isTemporary: idTypeContrato?.toUpperCase() === 'TEMPORARIA' ? true : false,
          pcd: false,
          dangerousness: false,
          unhealthy: false,
          benefits: [],
          employeesToReplace: [],
          profileItems: []
        }
      ]
    }]))
  }

  const handleToggleChamado = (index: number) => {
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, index),
      { ...prevState[index], open: !prevState[index].open },
      ...prevState.slice(index + 1)
    ])
  }

  const handleCopyChamado = (index: number) => {
    const newChanadoItem = { ...listaChamados[index] }
    setListaChamados((prevState: any) => [
      ...prevState,
      newChanadoItem
    ])
  }

  const handleTrashChamado = (index: number) => {
    if (listaChamados.length === 1) {
      notify('A solicitação deve possuir pelo menos um local', { variant: 'warning' })
      return false
    }
    setListaChamados((prevState: any) => ([
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1)
      ]
    ))
  }

  // gerenciamento de função
  const addNewFuncao = (indexLocal: number) => {
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        funcoes: [
          ...prevState[indexLocal].funcoes,
          {
            open: false,
            pcd: false,
            dangerousness: false,
            unhealthy: false,
            isTemporary: idTypeContrato?.toUpperCase() === 'TEMPORARIA' ? true : false,
            benefits: [],
            employeesToReplace: [],
            profileItems: []
          }
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }

  const handleToggleFuncao = (indexLocal: number, indexFuncao: number) => {
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        funcoes: [
          ...prevState[indexLocal].funcoes.slice(0, indexFuncao),
          {
            ...prevState[indexLocal].funcoes[indexFuncao],
            open: !prevState[indexLocal].funcoes[indexFuncao].open
          },
          ...prevState[indexLocal].funcoes.slice(indexFuncao + 1)
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }

  const handleCopyFuncao = (indexLocal: number, indexFuncao: number) => {
    const newFuncao = { ...listaChamados[indexLocal].funcoes[indexFuncao] }
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        funcoes: [
          ...prevState[indexLocal].funcoes,
          newFuncao
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }

  const handleTrashFuncao = (indexLocal: number, indexFuncao: number) => {
    if (listaChamados[indexLocal].funcoes.length === 1) {
      notify('A solicitação deve possuir pelo menos uma função por local', { variant: 'warning' })
      return false
    }
    setListaChamados((prevState: any) => ([
        ...prevState.slice(0, indexLocal),
        {
          ...prevState[indexLocal],
          funcoes: [
            ...prevState[indexLocal].funcoes.slice(0, indexFuncao),
            ...prevState[indexLocal].funcoes.slice(indexFuncao + 1)
          ]
        },
        ...prevState.slice(indexLocal + 1)
      ]
    ))
  }

  const setChamado = (indexLocal: number, chave: string, value: any) => {
    if (chave === 'cttId') {
      let empresaAtivaIndex = services.utils.findIndexInArray(value, empresas, 'cttId')
      let descricaoLocal = empresas[empresaAtivaIndex].nome
      setListaChamados((prevState: any) => [
        ...prevState.slice(0, indexLocal),
        {
          ...prevState[indexLocal],
          [chave]: value,
          cttRecnoDescricao: descricaoLocal
        },
        ...prevState.slice(indexLocal + 1)
      ])
    } else {
      setListaChamados((prevState: any) => [
        ...prevState.slice(0, indexLocal),
        {
          ...prevState[indexLocal],
          [chave]: value
        },
        ...prevState.slice(indexLocal + 1)
      ])
    }

  }

  const setFuncao = (indexLocal: number, indexFuncao: number, chave: string, valeu: any) => {
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        funcoes: [
          ...prevState[indexLocal].funcoes.slice(0, indexFuncao),
          {
            ...prevState[indexLocal].funcoes[indexFuncao],
            [chave]: valeu
          },
          ...prevState[indexLocal].funcoes.slice(indexFuncao + 1)
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }

  //


  /// add beneficio
  const addBeneficio = async (beneficioNome: string, beneficioValue: string, baneficioId: number, indexLocal: number, indexFuncao: number) => {
    // setBeneficios(prevState => [
    //   ...prevState, 
    //   {
    //     benefitName: beneficioNome, 
    //     benefitValue: beneficioValue
    //   }
    // ])

    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        funcoes: [
          ...prevState[indexLocal].funcoes.slice(0, indexFuncao),
          {
            ...prevState[indexLocal].funcoes[indexFuncao],
            benefits: [
              ...prevState[indexLocal].funcoes[indexFuncao].benefits,
              {
                benefitName: beneficioNome,
                benefitValue: beneficioValue
              }
            ]
          },
          ...prevState[indexLocal].funcoes.slice(indexFuncao + 1)
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])

  }

  const removeBeneficio = async (index: number, indexLocal: number, indexFuncao: number) => {

    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        funcoes: [
          ...prevState[indexLocal].funcoes.slice(0, indexFuncao),
          {
            ...prevState[indexLocal].funcoes[indexFuncao],
            benefits: [
              ...prevState[indexLocal].funcoes[indexFuncao].benefits.slice(0, index),
              ...prevState[indexLocal].funcoes[indexFuncao].benefits.slice(index + 1)
            ]
          },
          ...prevState[indexLocal].funcoes.slice(indexFuncao + 1)
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }

  /// add recruitment
  const addRecruitment = async (recruitmentCpf: string, recruitmentNome: string, employeeId: number, indexLocal: number, indexFuncao: number) => {

    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        funcoes: [
          ...prevState[indexLocal].funcoes.slice(0, indexFuncao),
          {
            ...prevState[indexLocal].funcoes[indexFuncao],
            employeesToReplace: [
              ...prevState[indexLocal].funcoes[indexFuncao].employeesToReplace,
              { cpf: recruitmentCpf, fullName: recruitmentNome }
            ]
          },
          ...prevState[indexLocal].funcoes.slice(indexFuncao + 1)
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }

  const removeRecruitment = async (index: number, indexLocal: number, indexFuncao: number) => {


    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        funcoes: [
          ...prevState[indexLocal].funcoes.slice(0, indexFuncao),
          {
            ...prevState[indexLocal].funcoes[indexFuncao],
            employeesToReplace: [
              ...prevState[indexLocal].funcoes[indexFuncao].employeesToReplace.slice(0, index),
              ...prevState[indexLocal].funcoes[indexFuncao].employeesToReplace.slice(index + 1)
            ]
          },
          ...prevState[indexLocal].funcoes.slice(indexFuncao + 1)
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }

  /// add perfis
  const addPerfil = async (nomeCurso: string, descricaoCurso: string, obritatorio: string, perfilId: number, indexLocal: number, indexFuncao: number) => {
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        funcoes: [
          ...prevState[indexLocal].funcoes.slice(0, indexFuncao),
          {
            ...prevState[indexLocal].funcoes[indexFuncao],
            profileItems: [
              ...prevState[indexLocal].funcoes[indexFuncao].profileItems,
              { name: nomeCurso, description: descricaoCurso, mandatory: obritatorio }
            ]
          },
          ...prevState[indexLocal].funcoes.slice(indexFuncao + 1)
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }

  const removePerfil = async (index: number, indexLocal: number, indexFuncao: number) => {
    setListaChamados((prevState: any) => [
      ...prevState.slice(0, indexLocal),
      {
        ...prevState[indexLocal],
        funcoes: [
          ...prevState[indexLocal].funcoes.slice(0, indexFuncao),
          {
            ...prevState[indexLocal].funcoes[indexFuncao],
            profileItems: [
              ...prevState[indexLocal].funcoes[indexFuncao].profileItems.slice(0, index),
              ...prevState[indexLocal].funcoes[indexFuncao].profileItems.slice(index + 1)
            ]
          },
          ...prevState[indexLocal].funcoes.slice(indexFuncao + 1)
        ]
      },
      ...prevState.slice(indexLocal + 1)
    ])
  }
  ///////

  const openChamado = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    function validarListChamados() {
      let valid = true
      for (let x = 0; x < listaChamados.length; x++) {
        if (!listaChamados[x].cttId) {
          notify(`Local: ${x + 1} Campo Local deve ser informado`, { variant: 'warning' })
          valid = false
          break
        }
        for (let f = 0; f < listaChamados[x].funcoes.length; f++) {
          if (!listaChamados[x].funcoes[f].customerManager) {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Gestor Responsavel deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if (!listaChamados[x].funcoes[f].functionName) {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo função deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if (listaChamados[x].funcoes[f].functionName && listaChamados[x].funcoes[f].functionName?.length <= 5) {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo função deve ter mais que 5 caracteres`, { variant: 'warning' })
            valid = false
            break
          }
          if (!listaChamados[x].funcoes[f].remuneration) {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo remuneração deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if (!listaChamados[x].funcoes[f].scale) {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Escala deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if (listaChamados[x].funcoes[f].scale.length < 3) {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Escala deve ser maior ou igual a 3 caracteres`, { variant: 'warning' })
            valid = false
            break
          }
          if (!listaChamados[x].funcoes[f].jobStartHour) {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Horário trabalho inicial deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if (!listaChamados[x].funcoes[f].jobFinishHour) {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Horário trabalho final deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if (idTypeContrato?.toUpperCase() === 'TEMPORARIA') {
            if (!listaChamados[x].funcoes[f].recruitmentDemandHiringReasonId) {
              notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Motivo da contratação deve ser informado`, { variant: 'warning' })
              valid = false
              break
            }
            if (!listaChamados[x].funcoes[f].duration) {
              notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Duração em dias deve ser informado`, { variant: 'warning' })
              valid = false
              break
            }
            if (listaChamados[x].funcoes[f].duration < 7) {
              notify(`Local: ${x + 1} | Função: ${f + 1} - Deve ser superior ou igual a 7`, { variant: 'warning' })
              valid = false
              break
            }
          }

          if (listaChamados[x].funcoes[f].pcd === null || listaChamados[x].funcoes[f].pcd === undefined || listaChamados[x].funcoes[f].pcd === '') {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo PCD deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if ((listaChamados[x].funcoes[f].pcd === true || listaChamados[x].funcoes[f].pcd === 'true') && (listaChamados[x].funcoes[f].pcdRestriction === null || listaChamados[x].funcoes[f].pcdRestriction === undefined || listaChamados[x].funcoes[f].pcdRestriction === '')) {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Critério PCD deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if (listaChamados[x].funcoes[f].pcdRestriction && listaChamados[x].funcoes[f].pcdRestriction.length < 5) {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Critério PCD deve ser no minimo 5 caracteres`, { variant: 'warning' })
            valid = false
            break
          }
          if (listaChamados[x].funcoes[f].dangerousness === null || listaChamados[x].funcoes[f].dangerousness === undefined || listaChamados[x].funcoes[f].dangerousness === '') {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Periculosidade deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if (listaChamados[x].funcoes[f].unhealthy === null || listaChamados[x].funcoes[f].unhealthy === undefined || listaChamados[x].funcoes[f].unhealthy === '') {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Insalubridade deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }


          if (!listaChamados[x].funcoes[f].quantity) {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Quantidade deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if (Number(listaChamados[x].funcoes[f].quantity) === 0) {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Quantidade de vagas deve ser maior que zero`, { variant: 'warning' })
            valid = false
            break
          }
          if (!listaChamados[x].funcoes[f].startDate) {
            notify(`Local: ${x + 1} | Função: ${f + 1} - Campo Data de início deve ser informado`, { variant: 'warning' })
            valid = false
            break
          }
          if (listaChamados[x].funcoes[f].isEmployeeReplace) {
            if (Number(listaChamados[x].funcoes[f].quantity) !== listaChamados[x].funcoes[f].employeesToReplace.length) {
              notify(`Local: ${x + 1} | Função: ${f + 1} - A quantidade de Funcionarios a serem substituidos deve ser igual a quantidade de vagas`, { variant: 'warning' })
              return false
            }
          }
        }
        if (!valid) {
          break
        }
      }
      return valid
    }

    function formatObjToSend() {
      let objAllFuncoesList = []

      for (let x = 0; x < listaChamados.length; x++) {
        for (let f = 0; f < listaChamados[x].funcoes.length; f++) {
          let chamadoUnitario: any = {}

          chamadoUnitario.cttId = listaChamados[x].cttId

          chamadoUnitario.customerManager = listaChamados[x].funcoes[f].customerManager
          chamadoUnitario.costCenter = listaChamados[x].funcoes[f].costCenter
          chamadoUnitario.quantity = listaChamados[x].funcoes[f].quantity
          chamadoUnitario.startDate = listaChamados[x].funcoes[f].startDate
          chamadoUnitario.optionalData = listaChamados[x].funcoes[f].optionalData
          chamadoUnitario.functionName = listaChamados[x].funcoes[f].functionName
          chamadoUnitario.remuneration = listaChamados[x].funcoes[f].remuneration
          chamadoUnitario.scale = listaChamados[x].funcoes[f].scale
          chamadoUnitario.jobStartHour = listaChamados[x].funcoes[f].jobStartHour
          chamadoUnitario.jobFinishHour = listaChamados[x].funcoes[f].jobFinishHour
          chamadoUnitario.duration = listaChamados[x].funcoes[f].duration ? listaChamados[x].funcoes[f].duration : null
          chamadoUnitario.recruitmentDemandHiringReasonId = listaChamados[x].funcoes[f].recruitmentDemandHiringReasonId === '' || listaChamados[x].funcoes[f].recruitmentDemandHiringReasonId === 'null' ? null : listaChamados[x].funcoes[f].recruitmentDemandHiringReasonId
          chamadoUnitario.pcd = listaChamados[x].funcoes[f].pcd
          chamadoUnitario.pcdRestriction = listaChamados[x].funcoes[f].pcdRestriction || null
          chamadoUnitario.dangerousness = listaChamados[x].funcoes[f].dangerousness
          chamadoUnitario.unhealthy = listaChamados[x].funcoes[f].unhealthy
          chamadoUnitario.unhealthyRate = listaChamados[x].funcoes[f].unhealthyRate
          chamadoUnitario.fastJobId = listaChamados[x].funcoes[f].fastJobId ? listaChamados[x].funcoes[f].fastJobId : null

          chamadoUnitario.employeesToReplace = []
          chamadoUnitario.benefits = listaChamados[x].funcoes[f].benefits
          chamadoUnitario.profileItems = listaChamados[x].funcoes[f].profileItems

          if (listaChamados[x].funcoes[f].isEmployeeReplace) {
            chamadoUnitario.employeesToReplace = listaChamados[x].funcoes[f].employeesToReplace
          }


          objAllFuncoesList.push(chamadoUnitario)
        }
      }

      return objAllFuncoesList
    }

    if (validarListChamados()) {
      let objAllFuncoes = formatObjToSend()

      try {
        const resTicketData = await trackPromise(services.api.newResChamadoData(objAllFuncoes))
        if (resTicketData.fail) {
          // let erroparse = JSON.parse(resTicketData.error)
          // if (erroparse) {
          //   let indexError = erroparse['Index'][0]
          //   let indexErrorFm = Object.values(erroparse)[0]
          //   if (indexError && indexErrorFm) {
          //     throw new Error(
          //       `${indexErrorFm}`
          //     )
          //   }
          // }

          throw new Error(
            resTicketData.error || 'Não foi possível criar o chamado'
          )
        }

        notify('chamado criado com sucesso', { variant: 'success' })
        navigate(`/app/chamados/meus-chamados`)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

  }

  useEffect(() => {
    const init = async () => {
      try {
        const res = await trackPromise(services.api.getLocais(idTypeContrato?.toUpperCase() === 'TEMPORARIA' ? true : false))
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível Locais'
          )
        }
        setEmpresas(res.data.map((item: any) => {
          return {
            ...item,
            label: `${item.cttId} - ${item.res} - ${item.xnGrup} - ${item.desc01} - ${services.mask.cnpj(item.cgc)}`
          }
        }))
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }

      try {
        const res = await trackPromise(services.api.getEscalasList())
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível Carregar Escalas'
          )
        }
        setEscalasList(res.data)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }

      try {
        const res = await trackPromise(services.api.getDemandreasons())
        if (res.fail) {
          throw new Error(
            res.error || 'Não foi possível consultar Demand reasons'
          )
        }
        let tiposlistmotivoRef: any = []
        let tiposlistmotivo: any = []
        let motivosFormat = res.data.map((item: IRazaoContratacao) => {
          // let typeString: any = item?.type ? item.type.split(' ') : ''
          // typeString = typeString[typeString.length - 1]
          // item.typeMotivo = typeString
          // if (item.typeMotivo === 'Previsível' || item.typeMotivo === 'Imprevisível' || item.typeMotivo ===  'Transitória') {
          //   if (!tiposlistmotivoRef.includes(item.typeMotivo)) {
          //     tiposlistmotivoRef.push(item.typeMotivo)
          //     tiposlistmotivo.push(item.type)
          //   } 
          // }
          if (!tiposlistmotivoRef.includes(item.type)) {
            tiposlistmotivoRef.push(item.type)
            tiposlistmotivo.push(item.type)
          }
          return item
        })
        setRazoesContratacao([...motivosFormat])
        setTiposContratacao(tiposlistmotivo)
      } catch (error: any) {
        notify(error.message, { variant: 'error' })
      }
    }

    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="wrapper-container-content bgwhite">
      <div className="wrapper-page-content" id="wrapper-page-content">
        <PageTitle>
          <div>Solicitação de vagas</div>
        </PageTitle>
        <Grid container justifyContent="center" spacing={{ xs: 3 }}>
          <Grid item md={10} xs={12}>
            <div className="panel">
              <div className="panel-header">
                Solicitação de vagas - {idTypeContrato === 'TEMPORARIA' ? 'Temporário' : 'R&S'} - Um local com múlplas
                funções
              </div>
              <div className="panel-body">
                {/* CABEÇARIO  */}
                <DadosSolicitante
                  name={user?.fullName || ''}
                  email={user?.email || ''}
                  data={dataAtual}
                />
                <ResumoSolicitacao
                  refObj={'byLocal'}
                  list={listaChamados}
                />

                <form onSubmit={openChamado}>
                  {/* LOCAIS  */}
                  <Grid container spacing={{ xs: 3 }}>
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                      <div className="flex gap-10 justify-between items-center mb-10"
                           style={{ borderBottom: '1px solid rgb(234 234 234)', paddingBottom: '10px' }}>
                        <div>Locais</div>
                        <Button
                          variant="contained"
                          type="button"
                          size="small"
                          color="secondary"
                          onClick={addNewGroup}
                          startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
                        >
                          Adicionar Local
                        </Button>
                      </div>
                      {listaChamados.map((item: any, index: number) => (
                        <div className="chamadoTicketItem" key={index}>
                          <div className="chamadoTicketItem_Title main">
                            <div
                              className="chamadoTicketItem_Title_text">#{index + 1} {item?.cttRecnoDescricao ? item?.cttRecnoDescricao : ''}</div>
                            <div className="chamadoTicketItem_Title_actions">
                              <div>
                                <span onClick={() => handleToggleChamado(index)} className="flex items-center pointer">
                                  {item.open ? ' Recolher Local' : ' Expandir Local'}
                                  {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </span>
                              </div>
                              <div className="flex gap-6">
                                <IconButton
                                  style={{
                                    backgroundColor: '#f4f4f4',
                                    color: '#cccccc',
                                    marginRight: '0px',
                                    borderRadius: '4px'
                                  }}
                                  onClick={() => handleCopyChamado(index)}
                                  edge="end"
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                                <IconButton
                                  style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    marginRight: '0px',
                                    borderRadius: '4px'
                                  }}
                                  onClick={() => handleTrashChamado(index)}
                                  edge="end"
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                          <div className={`chamadoTicketItem_Content ${item.open ? 'open' : ''}`}>
                            {item.open &&
                              <>
                                <div>
                                  <LocaisAutoComplete
                                    action={setChamado}
                                    mainList={empresas}
                                    index={index}
                                    required={true}
                                  />

                                </div>
                                <div className="mt-20">
                                  <div className="flex gap-10 justify-between items-center mb-10"
                                       style={{ borderBottom: '1px solid rgb(234 234 234)', paddingBottom: '10px' }}>
                                    <div>Cadastro de funções</div>
                                    <Button
                                      variant="contained"
                                      type="button"
                                      size="small"
                                      color="secondary"
                                      onClick={() => addNewFuncao(index)}
                                      startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
                                    >
                                      Adicionar Função
                                    </Button>
                                  </div>
                                  {
                                    item.funcoes.map((funcao: any, indexFuncao: number) => (
                                      <div className="chamadoTicketItem" key={indexFuncao}>
                                        <div className="chamadoTicketItem_Title">
                                          <div className="chamadoTicketItem_Title_text">
                                            #{indexFuncao + 1} {' '}
                                            {funcao?.functionName ? `- ${funcao?.functionName}` : ''} {' '}
                                            {funcao?.quantity ? `| ${funcao.quantity}` : ''} {funcao?.quantity ? `${funcao.quantity > 1 ? 'Posições' : 'Posição'}` : ''} {' '}
                                          </div>
                                          <div className="chamadoTicketItem_Title_actions">
                                            <div>
                                              <span onClick={() => handleToggleFuncao(index, indexFuncao)}
                                                    className="flex items-center pointer">
                                                {funcao.open ? ' Recolher dados da função' : ' Expandir dados da função'}
                                                {funcao.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                              </span>
                                            </div>
                                            <div className="flex gap-6">
                                              <IconButton
                                                style={{
                                                  backgroundColor: '#f4f4f4',
                                                  color: '#cccccc',
                                                  marginRight: '0px',
                                                  borderRadius: '4px'
                                                }}
                                                onClick={() => handleCopyFuncao(index, indexFuncao)}
                                                edge="end"
                                              >
                                                <ContentCopyIcon />
                                              </IconButton>
                                              <IconButton
                                                style={{
                                                  backgroundColor: 'red',
                                                  color: 'white',
                                                  marginRight: '0px',
                                                  borderRadius: '4px'
                                                }}
                                                onClick={() => handleTrashFuncao(index, indexFuncao)}
                                                edge="end"
                                              >
                                                <DeleteForeverIcon />
                                              </IconButton>
                                            </div>
                                          </div>
                                        </div>

                                        <div className={`chamadoTicketItem_Content ${funcao.open ? 'open' : ''}`}>
                                          {funcao.open &&
                                            <>
                                              <DadosVaga
                                                chamado={funcao}
                                                setChamado={setFuncao}
                                                indexFuncao={indexFuncao}
                                                indexLocal={index}
                                                tiposContratacao={tiposContratacao}
                                                razoesContratacao={razoesContratacao}
                                                escalasList={escalasList}
                                                disabledSubstituicao={false}
                                              />

                                              <Grid container spacing={{ xs: 3 }}>
                                                <Grid item xs={12} className="mt-30">
                                                  <Perfis
                                                    list={funcao.profileItems}
                                                    remove={removePerfil}
                                                    add={addPerfil}
                                                    indexLocal={index}
                                                    indexFuncao={indexFuncao}
                                                  />
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <Beneficios
                                                    beneficios={funcao.benefits}
                                                    removeBeneficio={removeBeneficio}
                                                    addBeneficio={addBeneficio}
                                                    indexLocal={index}
                                                    indexFuncao={indexFuncao}
                                                  />
                                                </Grid>
                                                {
                                                  funcao.isEmployeeReplace ?
                                                    <Grid item xs={12}>
                                                      <Employees
                                                        employees={funcao.employeesToReplace}
                                                        remove={removeRecruitment}
                                                        add={addRecruitment}
                                                        indexLocal={index}
                                                        indexFuncao={indexFuncao}
                                                        quantidade={funcao.quantity}
                                                      />
                                                    </Grid>
                                                    : ''
                                                }
                                              </Grid>

                                              <Grid item xs={12} className="mt-30">
                                                <TextField
                                                  fullWidth
                                                  multiline
                                                  maxRows={10}
                                                  label="Dados Opcionais"
                                                  inputProps={{ maxLength: 400 }}
                                                  value={funcao.optionalData ? funcao.optionalData : ''}
                                                  InputLabelProps={{ shrink: true }}
                                                  onChange={e =>
                                                    setFuncao(index, indexFuncao, 'optionalData', e.target.value)
                                                  }
                                                />
                                              </Grid>
                                            </>
                                          }
                                        </div>
                                      </div>
                                    ))
                                  }

                                </div>
                              </>
                            }
                          </div>
                        </div>
                      ))}

                    </Grid>
                  </Grid>

                  <div className="flex justify-end mt-30 mb-30">
                    <Button
                      variant="contained"
                      type="submit"
                      size="small"
                      color="success"
                      disabled={promiseInProgress}
                    >
                      Abrir Solicitação
                    </Button>
                  </div>

                </form>
              </div>
            </div>
          </Grid>
        </Grid>

      </div>

    </div>
  )
}

export default ChamadoNewMultFuncoes
