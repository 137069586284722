import { memo } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'

import CancelIcon from '@mui/icons-material/Cancel'
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'

import './styles.scss'

interface IModal {
  open: boolean
  close: any
  titulo: string
  size?: 'md' | 'sm' | 'lg'
  children: any
  fullWidth?: boolean
  fullScreen?: boolean
  fullScreenOnlyMobile?: boolean
  forceOpen?: boolean
  ignoreStopPropagation?: boolean
}

const Modal = (props: IModal) => {
  const {
    open,
    close,
    titulo,
    size,
    children,
    fullWidth,
    fullScreen,
    fullScreenOnlyMobile,
    forceOpen,
    ignoreStopPropagation
  } = props
  const theme = useTheme()
  const fullScreenResponsive = useMediaQuery(theme.breakpoints.down('md'))
  const { promiseInProgress } = usePromiseTracker()

  return (
    <Dialog
      open={open}
      onClick={event => {
        if (!ignoreStopPropagation) event.stopPropagation()
      }}
      onFocus={event => {
        if (!ignoreStopPropagation) event.stopPropagation()
      }}
      onClose={!forceOpen ? close : null}
      aria-labelledby="customized-dialog-title"
      maxWidth={size || 'md'}
      fullWidth={fullWidth}
      fullScreen={
        fullScreenOnlyMobile ? fullScreenResponsive : fullScreen || false
      }
      sx={{ zIndex: 999 }}
    >
      <DialogTitle
        id="form-dialog-title"
        className={`${fullScreen || fullScreenOnlyMobile ? 'color' : ''}`}
      >
        <Typography datatest-id={'titulo_test_id'}>{titulo}</Typography>
        {!forceOpen && (
          <IconButton
            aria-label="close"
            onClick={close}
            datatest-id={'close_modal_test_id'}
            id={'close_modal_btn'}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            disabled={promiseInProgress}
          >
            <CancelIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent datatest-id={'content_test_id'} dividers>
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default memo(Modal)
